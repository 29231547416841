import { useState } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { get } from 'lodash';
import { ButtonV2, Card, Typography } from '@vartanainc/design-system';
import { formatCapital } from '../../utils/helpers';
import { ReactComponent as UserBadgeIcon } from '../../assets/user_badge.svg';
import { labelValueField } from '../../constants/common.constants';
import AutoLoad from '../../components/AutoLoad';
import ResetUserPasswordModal from '../../components/Modals/ResetUserPasswordModal';
import { HasPermission } from '../../components/HasPermission/HasPermission';
import usePermissions from '../../utils/hooks/permissions';
import { sessionVar } from '../../graphql/cache';

function UserCard({
  cardTitle,
  showResetPassword,
  selectedUser,
  onEditClicked,
  isLoading,
}) {
  const { number, firstName, lastName, email, phone, title, role } = selectedUser;
  const session = useReactiveVar(sessionVar);
  const [isResetUserPasswordDialogOpen, setIsResetUserPasswordDialogOpenOpen] =
    useState(false);
  const [, userHasPermission] = usePermissions();

  const isLoggedInUser = () => {
    return get(session, 'session.user.email', null) === email;
  };

  return (
    <Card
      variant="fullWidth"
      icon={<UserBadgeIcon />}
      title={(
        <div className="flex items-center justify-between w-full">
          <Typography variant="heading18" bold color="color-black-100">
            {cardTitle || '--'}
          </Typography>

          {userHasPermission('user', 'update') || isLoggedInUser() ? (
            <ButtonV2
              onClick={onEditClicked}
              text="Edit"
              variant={{ type: 'ghost', typography: 'paragraph12' }}
            />
          ) : null}
        </div>
      )}
      content={(
        <>
          <AutoLoad loading={isLoading}>
            <div className="flex justify-end"></div>
            <div className="grid grid-cols-2 gap-4">
              {labelValueField('First name', firstName || '--')}
              {labelValueField('Last name', lastName || '--')}
              {labelValueField('Email', email || '--')}
              {labelValueField('Phone', phone || '--')}
              {labelValueField('Job title', title || '--')}
              {labelValueField('Role', formatCapital(role) || '--')}
              {showResetPassword && !isLoggedInUser() ? (
                <div className="flex-1">
                  <HasPermission resource="user" action="reset_password">
                    <p className="break-all vartana-p-small-bold text-vartana-gray-50">
                      Account
                    </p>
                    <button
                      className="vp-text-link-bold text-vartana-blue-60"
                      onClick={() => setIsResetUserPasswordDialogOpenOpen(true)}
                    >
                      Reset password
                    </button>
                  </HasPermission>
                </div>
              ) : null}
            </div>
          </AutoLoad>
          <ResetUserPasswordModal
            open={isResetUserPasswordDialogOpen}
            selectedUser={{
              email,
              number,
            }}
            handleClose={() => setIsResetUserPasswordDialogOpenOpen(false)}
          />
        </>
      )}
    >
    </Card>
  );
}

UserCard.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  showResetPassword: PropTypes.bool,
  selectedUser: PropTypes.shape({
    number: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    title: PropTypes.string,
    phone: PropTypes.string,
    role: PropTypes.string,
  }),
  onEditClicked: PropTypes.func,
  isLoading: PropTypes.bool,
};

UserCard.defaultProps = {
  selectedUser: {},
  showResetPassword: false,
  onEditClicked: () => {},
  isLoading: false,
};

export default UserCard;
