import { InMemoryCache, gql, makeVar } from '@apollo/client';
import { createFragmentRegistry } from '@apollo/client/cache';

export const sessionTypeVar = makeVar('');
export const sessionVar = makeVar({});
export const requestModelAmountVar = makeVar(false);
export const metabaseUrlVar = makeVar({});

export const cache = new InMemoryCache({
  fragments: createFragmentRegistry(gql`
    fragment ProductDetails on Product {
      minInitialLoanAmount
      minSubsequentLoanAmount
      maxLoanAmount
      maxDurationAllowed
      allowedDurations
      paymentOptions
      availablePaymentTerms
      availablePaymentFrequencies
      availableContractLengths
      availableOrderTypes
      formattedAvailablePaymentTerms
      defaultPaymentTerm
      formattedDefaultPaymentTerm
      defaultPaymentFrequency
      defaultOrderType
      defaultContractLength
      variableBlindDiscount
      applyMaxBlindDiscount
      spiffMode
      defaultSpiffRate
    }
  `),
});
