import { ReactElement, useState } from 'react';
import { camelCase } from 'lodash';
import { StepDetails } from './StepDetails';
import { FinancialDetails } from './FinancialDetails';

interface StepProps {
  page: string;
  status: string;
  name: string;
}

export interface FinancialDocsMetaProps {
  details: string[];
  types?: string[];
  why: string;
  disclaimer: string;
}
interface MoreInfoStepProps {
  steps: StepProps[];
  activeStep: string;
  stepsMeta?: {
    authorizedSigner?: {
      why: string;
      who: string;
    };
    businessDetails?: {
      why: string;
      who: string;
    };
    salesQuote?: {
      why: string;
      who: string;
    };
    financialDocuments?: FinancialDocsMetaProps;
  };
}

const stepDetailsData = {
  'authorized-signer': {
    title: 'Authorized signer',
  },
  'business-details': {
    title: 'Business details',
  },
  'sales-quote': {
    title: 'Sales quote',
  },
};

export function StepDetailsWrapper({
  steps,
  activeStep,
  stepsMeta,
}: MoreInfoStepProps): ReactElement {
  const [showDocumentTypesAccordion, setShowDocumentTypesAccordion] = useState(false);

  const toggleShowDocumentTypesAccordion = (): void => {
    setShowDocumentTypesAccordion(!showDocumentTypesAccordion);
  };

  return (
    <>
      {(steps || []).map((step) => {
        if (step.page === 'financial-documents') {
          return (
            <FinancialDetails
              key={step.name}
              isActiveStep={activeStep === 'financial-documents'}
              isCompleted={step.status === 'completed'}
              toggleShowDocumentTypesAccordion={toggleShowDocumentTypesAccordion}
              showDocumentTypesAccordion={showDocumentTypesAccordion}
              meta={stepsMeta?.financialDocuments}
            />
          );
        }
        return (
          <StepDetails
            key={step.name}
            title={stepDetailsData[step.page]?.title}
            isActiveStep={activeStep === step.page}
            isCompleted={step.status === 'completed'}
            meta={stepsMeta?.[camelCase(step.page)]}
          />
        );
      })}
    </>
  );
}
