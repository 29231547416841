import PropTypes from 'prop-types';
import { get } from 'lodash';

import { ACTIONS, PERMISSIONS } from '../../constants/permissions';
import usePermissions from '../../utils/hooks/permissions';

export function HasPermission({ resource, action, customer, children }) {
  const [, hasPermission] = usePermissions();

  if (hasPermission(resource, action, customer)) return children || null;
  return null;
}

HasPermission.propTypes = {
  resource: PropTypes.oneOf(Object.keys(PERMISSIONS)).isRequired,
  action: PropTypes.oneOf([...ACTIONS]).isRequired,
  customer: PropTypes.shape({
    buyerRelationToVendor: PropTypes.string,
    seller: PropTypes.shape({
      resellerPermissions: PropTypes.shape({}).isRequired,
    }),
  }),
  children: PropTypes.node.isRequired,
};

HasPermission.defaultProps = {
  customer: null,
};

/**
 * Return children if all permissions available; otherwise, return null.
 *
 * @param {Object.<string, string[]>} permissions - Required permissions
 * @param {node} children - JSX element(s)
 * @returns {node|null}
 */
export function HasPermissions({ permissions, children }) {
  const [userPermissions] = usePermissions();

  const hasAllPermissions = Object.entries(permissions).every(([resource, actions]) => {
    const permittedActions = get(userPermissions, resource, []);
    return actions.every((action) => permittedActions.includes(action));
  });

  if (hasAllPermissions) return children;
  return null;
}

HasPermissions.propTypes = {
  permissions: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.oneOf([...ACTIONS])))
    .isRequired,
  children: PropTypes.node.isRequired,
};
