import PropTypes from 'prop-types';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation, useReactiveVar } from '@apollo/client';
import { get } from 'lodash';

import { SELLER_TYPE } from '../../../../constants/common.constants';
import { isReseller, isVendor } from '../../../../utils/helpers';
import NavItem from '../NavItem/NavItem';

import { LOGOUT_MUTATION } from '../../../../graphql/queries/profile';
import { sessionVar } from '../../../../graphql/cache';
import { HasPermission } from '../../../../components/HasPermission/HasPermission';

import BuildSvg from '../../../../assets/build.svg';
import CorporateFareSvg from '../../../../assets/corporate_fare.svg';
import DoorOpenSvg from '../../../../assets/door_open.svg';
import HomeWorkSvg from '../../../../assets/home_work.svg';
import InsertChartSvg from '../../../../assets/insert_chart.svg';
import PaymentsSvg from '../../../../assets/payments-gold.svg';
import PersonAddSvg from '../../../../assets/person_add.svg';
import ReceiptLongSvg from '../../../../assets/receipt_long.svg';
import RocketLaunchSvg from '../../../../assets/rocket_launch.svg';
import StoreSvg from '../../../../assets/store.svg';
import InviteTeamModal from '../../MainBody/InviteTeamModal/InviteTeamModal';

import ColorMap from '../../../../components/ColorMap/ColorMap';

interface NavProps {
  desktop: boolean;
}

const Nav = ({ desktop }: NavProps): JSX.Element => {
  const { pathname } = useLocation();
  const sessionData = useReactiveVar(sessionVar);
  const loggedCompany = get(sessionData, 'session.user.company', { sellerType: '' });
  const resellerWorkflowEnabled = get(loggedCompany, 'useResellerWorkflow', false);
  const isDistributor =
    loggedCompany.sellerType === SELLER_TYPE.VENDOR_RESELLER && resellerWorkflowEnabled;

  const [logout] = useMutation(LOGOUT_MUTATION);

  const [showInviteTeamModal, setShowInviteTeamModal] = useState(false);

  return (
    <>
      <nav className="px-2 " aria-label="Sidebar">
        <div className="flex flex-col gap-1">
          <NavItem
            title="Launchpad"
            desktop={desktop}
            active={pathname === '/launchpad'}
            iconComponent={RocketLaunchSvg}
            icon="rocket_launch"
            iconColor={ColorMap['color-blue-100']}
            to="/launchpad"
          />

          {isVendor(loggedCompany) && (
            <NavItem
              title="Analytics"
              desktop={desktop}
              active={pathname === '/dashboard/analytics'}
              iconComponent={InsertChartSvg}
              icon="insert_chart"
              iconColor={ColorMap['color-gold-100']}
              to="/dashboard/analytics"
            />
          )}

          <HasPermission resource="order" action="view">
            <NavItem
              title="Orders"
              desktop={desktop}
              iconComponent={ReceiptLongSvg}
              icon="receipt_long"
              active={pathname.includes('/dashboard/orders')}
              iconColor={ColorMap['color-blue-100']}
              to="/dashboard/orders"
            />
          </HasPermission>

          <HasPermission resource="customer" action="view">
            <NavItem
              title="Customers"
              desktop={desktop}
              active={pathname.includes('/dashboard/customers')}
              iconComponent={StoreSvg}
              icon="store"
              iconColor={ColorMap['color-red-90']}
              to="/dashboard/customers"
            />
          </HasPermission>

          {resellerWorkflowEnabled && (
            <NavItem
              title={isDistributor ? 'Resellers' : 'Partners'}
              desktop={desktop}
              to="/dashboard/resellers"
              iconComponent={HomeWorkSvg}
              icon="home_work"
              iconColor={ColorMap['color-blue-160']}
              active={pathname.includes('/dashboard/resellers')}
            />
          )}

          {isReseller(loggedCompany) && (
            <NavItem
              title="Partners"
              desktop={desktop}
              to="/dashboard/vendors"
              iconComponent={CorporateFareSvg}
              icon="corporate_fare"
              iconColor={ColorMap['color-blue-160']}
              active={pathname.includes('/dashboard/vendors')}
            />
          )}

          <HasPermission resource="payment" action="view">
            <NavItem
              title="Payments"
              desktop={desktop}
              iconComponent={PaymentsSvg}
              icon="payments"
              iconColor={ColorMap['color-gold-100']}
              active={pathname.includes('/dashboard/transactions')}
              to="/dashboard/transactions"
            />
          </HasPermission>
        </div>

        <hr className="h-px bg-[#DFE3E9] my-6 mx-4" />

        <div className="flex flex-col gap-1">
          <NavItem
            title="Settings"
            desktop={desktop}
            iconComponent={BuildSvg}
            icon="build"
            iconColor={ColorMap['color-steel-120']}
            active={pathname.includes('/settings')}
            to="/dashboard/settings/profile"
          />
          <NavItem
            title="Invite teammates"
            desktop={desktop}
            iconComponent={PersonAddSvg}
            icon="person_add"
            iconColor={ColorMap['color-steel-120']}
            active={false}
            to="#"
            onClick={() => setShowInviteTeamModal(true)}
          />
          <NavItem
            title="Logout"
            desktop={desktop}
            iconComponent={DoorOpenSvg}
            icon="door_open"
            iconColor={ColorMap['color-steel-120']}
            active={pathname.includes('/logout')}
            to="/login"
            onClick={() => {
              logout();
            }}
          />
        </div>
      </nav>

      {showInviteTeamModal && (
        <InviteTeamModal
          isModalOpen={showInviteTeamModal}
          onClose={() => setShowInviteTeamModal(false)}
        />
      )}
    </>
  );
};

Nav.propTypes = {
  desktop: PropTypes.bool,
};

Nav.defaultProps = {
  desktop: false,
};

export default Nav;
