import { ReactElement } from 'react';
import { Typography } from '@vartanainc/design-system';
import { ReactComponent as BlueCheckIcon } from '../../../assets/blue_check.svg';
import { ReactComponent as GrayCheckIcon } from '../../../assets/gray_check.svg';
import { ReactComponent as GrayFilledCheckIcon } from '../../../assets/gray_filled_check.svg';

interface StepDetailsProps {
  title: string;
  isActiveStep: boolean;
  isCompleted: boolean;
  meta: {
    who: string;
    why: string;
  };
}

export function StepDetails({
  title,
  isActiveStep,
  isCompleted,
  meta,
}: StepDetailsProps): ReactElement {
  let iconComponent;

  if (isActiveStep) {
    iconComponent = <BlueCheckIcon />;
  } else if (isCompleted) {
    iconComponent = <GrayFilledCheckIcon />;
  } else {
    iconComponent = <GrayCheckIcon />;
  }
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        {iconComponent}
        <Typography bold variant="heading16" color="color-black-100">
          {title}
        </Typography>
      </div>
      {isActiveStep && (
        <>
          {meta.who && (
            <Typography variant="paragraph12" className="ml-8" color="color-black-100">
              {meta.who}
            </Typography>
          )}

          {meta.why && (
            <div className="flex flex-col gap-1">
              <Typography
                bold
                variant="paragraph12"
                color="color-black-100"
                className="ml-8"
              >
                Why do we need this?
              </Typography>
              <Typography variant="paragraph12" className="ml-8" color="color-black-100">
                {meta.why}
              </Typography>
            </div>
          )}
        </>
      )}
    </div>
  );
}
