import React, { useState, useCallback } from 'react';
import { useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
import { useQuery, useMutation } from '@apollo/client';
import { get, isEmpty } from 'lodash';
import { useOutletContext } from 'react-router-dom';
import MultiCountryZipInput from '../../../../designSystem/MultiCountryZipInput/MultiCountryZipInput';
import AutoLoad from '../../../../components/AutoLoad';
import { GET_VENDOR_DETAILS } from '../../../../graphql/queries/vendor';
import { UPDATE_VENDOR } from '../../../../graphql/mutations';
import FormInput from '../../../../components/FormInput';
import CommonFooter from './CommonFooter';
import {
  getCountryOptions,
  getStateOptions,
  reportError,
  handleCountrySelect,
} from '../../../../utils/helpers';
import { BackButton } from '../../../../components/Button/BackButton';
import CommonHeader from './CommonHeader';
import TextInputField from '../../../../designSystem/TextInput/TextInputField';
import AddressInputField from '../../../../designSystem/AddressInput/AddressInput';
import CustomDropdown from '../../../../designSystem/FormikDropdownInput/FormikDropdownInput';
import { useSetAddressFields } from '../../../../utils/hooks';
import {
  ALL_COUNTRIES_SHORTHAND,
  zipSchema,
} from '../../../../constants/common.constants';

const schema = yup.object().shape({
  legalName: yup
    .string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .required('This field is required'),
  state: yup.string().required('This field is required').nullable(),
  city: yup.string().max(50, 'Too Long!').required('This field is required').nullable(),
  zip: zipSchema,
  street: yup.string().max(50, 'Too Long!').required('This field is required').nullable(),
  country: yup.string().required('This field is required'),
});

function VendorDetails() {
  const { goForward, goBack } = useOutletContext();

  const [initialValues, setInitialValues] = useState({
    legalName: '',
    state: '',
    city: '',
    zip: '',
    street: '',
    country: '',
  });

  const [updateVendor] = useMutation(UPDATE_VENDOR);

  const { loading: vendorLoading } = useQuery(GET_VENDOR_DETAILS, {
    onCompleted: (responseData) => {
      setInitialValues({
        legalName: get(responseData, 'seller.legalName', ''),
        state: get(responseData, 'seller.state', ''),
        city: get(responseData, 'seller.city', ''),
        zip: get(responseData, 'seller.zip', ''),
        street: get(responseData, 'seller.street', ''),
        country: get(responseData, 'seller.country', ''),
      });
    },
  });

  const onSubmit = useCallback(
    async (values, { setErrors }) => {
      try {
        const responseData = await updateVendor({
          variables: {
            ...values,
          },
        });
        const errors = get(responseData, 'errors', {});
        if (!isEmpty(errors)) {
          setErrors(errors);
          return;
        }
        goForward();
      } catch (e) {
        reportError(`Onboarding: While updating vendor details: ${e.message}`);
      }
    },
    [goForward, updateVendor]
  );

  const formikBag = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit,
  });

  const { isSubmitting, isValid, setFieldValue, validateField, handleSubmit } = formikBag;
  // hasCountry is true because country is shown in all cases in this step
  const setSelectedPlace = useSetAddressFields(setFieldValue, validateField, true);
  const disabled = isSubmitting || !isValid;

  return (
    <>
      <AutoLoad
        loading={vendorLoading}
        containerClassName="flex justify-center"
        className="absolute text-center top-2/4 transform-gpu -translate-y-2/4 mt-8"
      >
        <FormikProvider value={formikBag}>
          <form onSubmit={handleSubmit}>
            <FormInput containerClassName="hidden" name="id" type="hidden" />

            <fieldset disabled={isSubmitting}>
              <div className="space-y-6">
                <CommonHeader
                  title="Business details"
                  message="Tell us a little about your company."
                />

                <div className="space-y-4">
                  <div className="flex flex-row space-x-4">
                    <TextInputField name="legalName" label="Registered company name" />
                  </div>

                  <div className="flex flex-row space-x-4">
                    <AddressInputField
                      name="street"
                      label="Registered address"
                      countries={ALL_COUNTRIES_SHORTHAND}
                      afterPlaceSelect={(tempSelectedPlace) =>
                        setSelectedPlace(tempSelectedPlace)}
                    />
                  </div>

                  <div className="flex flex-row space-x-4">
                    <TextInputField name="city" type="text" label="City" />
                    <CustomDropdown
                      id="state"
                      name="state"
                      label="State"
                      options={getStateOptions(ALL_COUNTRIES_SHORTHAND)}
                      placeholder="Select state"
                    />
                    <MultiCountryZipInput
                      id="zip"
                      name="zip"
                      label="Zip"
                      customPlaceholder=" "
                    />
                  </div>
                  <div className="flex flex-row gap-4 mt-4 mb-2">
                    <div className="w-full">
                      <CustomDropdown
                        id="country"
                        name="country"
                        label="Country"
                        options={getCountryOptions(ALL_COUNTRIES_SHORTHAND)}
                        placeholder="Select"
                        onChange={(e) => handleCountrySelect(formikBag, e.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <CommonFooter>
                <div className="flex justify-between items-center">
                  <div>
                    <BackButton onClick={goBack} btnText="Back" />
                  </div>
                  <div>
                    <button
                      onClick={() => handleSubmit()}
                      disabled={disabled}
                      type="button"
                      className={`
                        border-2 vp-btn-text inline-block focus:outline-none px-10 py-4 rounded-md 
                        disabled:text-vartana-gray-40 disabled:bg-vartana-gray-30 disabled:border-vartana-gray-30 text-vartana-blue-60 border-vartana-blue-60
                      `}
                    >
                      <p>Next &gt;</p>
                    </button>
                  </div>
                </div>
              </CommonFooter>
            </fieldset>
          </form>
        </FormikProvider>
      </AutoLoad>
    </>
  );
}

export default VendorDetails;
