import React, { useMemo, useState } from 'react';
import { get } from 'lodash';
import { Card } from '@vartanainc/design-system';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';

import { formatCapital, isVendor } from '../../utils/helpers';
import { GET_PROFILE_DETAILS } from '../../graphql/queries/profile';
import { GET_NOTIFICATION_PREFS } from '../../graphql/queries/vendor';
import { RoleDetailCard } from '../../components/Role/RoleDetailCard';
import usePermissions from '../../utils/hooks/permissions';
import UserCard from '../User/UserCard';
import EditUserProfile from '../User/EditUserProfile';
import { ViewNotificationPreferences } from './NotificationPreferences';
import Tabs from '../../components/TabbedNavigation';
import { formatPreferenceSets } from './helpers';
import { sessionVar } from '../../graphql/cache';

const modes = ['view', 'edit'];

function Profile() {
  const [userPermissions, hasPermission] = usePermissions();
  const [profileData, setProfileData] = useState({});
  const [selectedMode, setSelectedMode] = useState(modes[0]);
  const sessionData = useReactiveVar(sessionVar);
  const showPermissionCard = isVendor(get(sessionData, 'session.user.company', {}));

  const [getNotificationPrefs, { data: notifPrefsData }] =
    useLazyQuery(GET_NOTIFICATION_PREFS);

  const { loading: isLoading, refetch: refetchUserProfile } = useQuery(
    GET_PROFILE_DETAILS,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (response) => {
        setProfileData(get(response, 'session.user', {}));
        getNotificationPrefs({
          variables: { roleId: +get(response, 'session.user.userRole.id', null) },
        });
      },
    }
  );

  const notifPreferences = get(notifPrefsData, 'seller.roleNotificationPreferences', []);
  const channelPreference = get(notifPrefsData, 'seller.roleChannelPreference', {});

  const preferenceSets = formatPreferenceSets(notifPreferences, channelPreference);

  const handleSuccess = () => {
    setSelectedMode(modes[0]);
    refetchUserProfile();
  };

  const permissionsCard = useMemo(() => {
    if (hasPermission('notification', 'view')) {
      return (
        <Tabs tabLabels={['Permissions', 'Notifications']} variant="card">
          <RoleDetailCard
            rolePermissions={userPermissions || {}}
            cardTitle="My permissions"
          />
          <ViewNotificationPreferences
            roleName={formatCapital(get(profileData, 'userRole.name', ''))}
            preferenceSets={preferenceSets}
          />
        </Tabs>
      );
    }

    return (
      <RoleDetailCard
        rolePermissions={userPermissions || {}}
        cardTitle="My permissions"
      />
    );
  }, [hasPermission, preferenceSets, profileData, userPermissions]);

  return (
    <div className="py-8 rounded-lg w-7/12 mx-auto small-card-width-range">
      {selectedMode === modes[0] ? (
        <div className="flex flex-col gap-y-6">
          <UserCard
            onEditClicked={() => setSelectedMode(modes[1])}
            cardTitle="My profile"
            isLoading={isLoading}
            selectedUser={{
              ...profileData,
              role: get(profileData, 'userRole.name'),
            }}
          />

          {showPermissionCard && (
            <Card variant="fullWidth" content={permissionsCard}></Card>
          )}
        </div>
      ) : (
        <EditUserProfile
          onCancelClicked={() => setSelectedMode(modes[0])}
          selectedUser={profileData}
          onSuccess={() => handleSuccess()}
        />
      )}
    </div>
  );
}

export default Profile;
