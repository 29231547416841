import React from 'react';
import PropTypes from 'prop-types';

function Loader({ className, containerClassName }) {
  return (
    <>
      <div className={containerClassName}>
        <div className={`border-2 block lds-dual-ring m-auto ${className}`} />
      </div>
    </>
  );
}

Loader.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
};

Loader.defaultProps = {
  className: 'w-12 h-12',
  containerClassName: '',
};

export default Loader;
