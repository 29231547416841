import { useEffect } from 'react';
import TabSummary from '../TabsSummary/TabsSummary';

const SignaturesTab = ({
  setActive,
  setSubPage,
  isCrm,
}: {
  setActive: () => void;
  setSubPage: (page: string) => void;
  isCrm: boolean;
}): JSX.Element => {
  useEffect(() => {
    setActive();
    setSubPage('');
  }, [setActive, setSubPage]);
  return <TabSummary status="signatures-tab" isCrm={isCrm} />;
};

export default SignaturesTab;
