import { Button, ButtonV2, Typography } from '@vartanainc/design-system';
import { get } from 'lodash';
import { ReactElement, useContext, useMemo } from 'react';
import { ReactComponent as ButtonChevron } from '../../../../assets/btn-chevron-left.svg';
import { ReactComponent as SellMoreDefer } from '../../../../assets/sell_more_defer.svg';
import { ReactComponent as SellMoreInstallment } from '../../../../assets/sell_more_installment.svg';
import { ReactComponent as SignatureLogo } from '../../../../assets/signature_banner.svg';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import { WidgetMetaContext } from '../../../../context/WidgetMetaContext/WidgetMetaContext';
import { APP_NAMES } from '../../../CommonWidgetV2/commonWidget.constants';
import CreditInformation from '../CreditInformation/CreditInformation';
import TrackRequestStatus from '../TrackStatus/TrackStatus';
import {
  PRE_QUALIFIED_OFFER_EXPIRED_CONTENT,
  PRE_QUALIFIED_OFFER_EXPIRED,
} from '../../../../constants/common.constants';

interface TabSummaryProps {
  status: string;
  onBackClick?: () => void;
  onButtonClick?: (actionProp) => void;
  isCrm?: boolean;
  companyNumber?: string;
  isTrackScreen?: boolean;
  appName?: string | null;
  isPreQualifiedOfferExpired?: boolean;
}

const EMPTY_STRING = '';
const SIGNATURES_TAB = 'signatures-tab';
const GET_STARTED_CONTAINER = 'get-started-container';
const SUMMARY_CONTAINER = 'summary-container';

const TabSummary = ({
  status,
  onBackClick,
  onButtonClick,
  isCrm = false,
  companyNumber = '',
  isTrackScreen,
  appName,
  isPreQualifiedOfferExpired,
}: TabSummaryProps): ReactElement => {
  const metaContext = useContext(WidgetMetaContext);
  const activeTab: string = get(metaContext, 'activeTab', '-');

  const containerClassName = useMemo(() => {
    if (status === EMPTY_STRING || status === SIGNATURES_TAB) {
      return GET_STARTED_CONTAINER;
    }

    return SUMMARY_CONTAINER;
  }, [status]);

  const getTitle = (): string => {
    if (isPreQualifiedOfferExpired) {
      return PRE_QUALIFIED_OFFER_EXPIRED;
    }

    switch (status) {
      case 'MISSING INFORMATION':
        return get(metaContext, 'meta.moreInformationDetails.title', '');
      case 'NEED INFORMATION':
        return get(metaContext, 'meta.moreInformationDetails.title', '');
      default:
        return get(metaContext, `${activeTab}.title`, '');
    }
  };

  const getPreQualifiedOfferExpiryContent = (): JSX.Element => {
    return (
      <Typography
        variant="paragraph12"
        color="color-black-100"
        className="mt-2 whitespace-pre-line"
      >
        {PRE_QUALIFIED_OFFER_EXPIRED_CONTENT}
      </Typography>
    );
  };

  const handleShowDetailsDialog = (action): void => {
    if ((!isCrm || appName === APP_NAMES.HUBSPOT) && onButtonClick) {
      onButtonClick(action);
    } else {
      window.open(`/forms/more-details?customerNumber=${companyNumber}`);
    }
  };

  const getContent = (): JSX.Element => {
    if (isPreQualifiedOfferExpired) {
      return getPreQualifiedOfferExpiryContent();
    }

    const action = (get(metaContext, `${activeTab}.actions`, []).find(
      (act) => get(act, 'type') === 'tertiary'
    ) || {}) as { label: string; slug: string };
    const subPageAction = (get(
      metaContext,
      'meta.moreInformationDetails.actions',
      []
    ).find((act) => get(act, 'type') === 'tertiary') || {}) as {
      label: string;
      slug: string;
    };
    switch (status) {
      case 'PRICING EXPIRED':
        return (
          <Typography
            variant="paragraph12"
            color="color-black-100"
            className="mt-2 whitespace-pre-line"
          >
            {get(metaContext, `${activeTab}.description`, '').replace('.', '. \n')}
          </Typography>
        );
      case 'DECLINED':
        return (
          <div className="flex flex-col gap-6 mt-2">
            <Typography variant="paragraph12" color="color-black-100">
              {get(metaContext, `${activeTab}.description`, '')}
            </Typography>
            <ButtonV2
              variant={{ type: 'text', typography: 'paragraph12' }}
              text={action.label}
              onClick={() => handleShowDetailsDialog(action)}
              id="learn-more-btn"
            />
          </div>
        );
      case 'MISSING INFORMATION':
        return (
          <>
            <div className="flex flex-col gap-1 mt-2">
              <Typography variant="paragraph12" color="color-black-100">
                {get(metaContext, 'meta.moreInformationDetails.description', '')}
              </Typography>

              <div className="flex flex-col self-center">
                <Typography
                  variant="paragraph12"
                  color="color-black-100"
                  className="whitespace-pre-line text-left"
                >
                  {get(metaContext, 'meta.moreInformationDetails.stepsMessage', '')}
                </Typography>

                <ButtonV2
                  variant={{
                    type: 'text',
                    typography: 'paragraph12',
                  }}
                  text={subPageAction.label}
                  onClick={() => handleShowDetailsDialog(subPageAction)}
                  id="learn-more-btn"
                  className="mt-5"
                />
              </div>
            </div>
          </>
        );
      case 'NEED INFORMATION':
        return (
          <>
            <div className="flex flex-col gap-1 mt-2">
              <Typography variant="paragraph12" color="color-black-100">
                {get(metaContext, 'meta.moreInformationDetails.description', '')}
              </Typography>

              <div className="flex self-center">
                <Typography
                  variant="paragraph12"
                  color="color-black-100"
                  className="whitespace-pre-line text-left"
                >
                  {get(metaContext, 'meta.moreInformationDetails.stepsMessage', '')}
                </Typography>
              </div>
              <ButtonV2
                variant={{
                  type: 'text',
                  typography: 'paragraph12',
                }}
                text={subPageAction.label}
                onClick={() => handleShowDetailsDialog(subPageAction)}
                id="learn-more-btn"
                className="mt-5"
              />
            </div>
          </>
        );
      case '':
        return (
          <>
            <div className="flex flex-col gap-1">
              <Typography
                variant="heading16"
                color="color-blue-180"
                bold
                className="description-typography w-[22rem]"
              >
                {get(metaContext, `${activeTab}.description`, '')}
              </Typography>
            </div>
          </>
        );
      case 'signatures-tab':
        return (
          <>
            <div className="flex flex-col gap-1">
              <Typography variant="heading16" color="color-blue-180" bold>
                {get(metaContext, `${activeTab}.description`, '')}
              </Typography>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className="flex flex-col gap-1 mt-2">
              <Typography className="w-72" variant="paragraph12" color="color-black-100">
                {get(metaContext, `${activeTab}.description`, '')}
              </Typography>
            </div>
          </>
        );
    }
  };

  const getIcon = (): JSX.Element => {
    if (isPreQualifiedOfferExpired) {
      return <SvgIcon name="hourglass_bottom" fill="#D79304" className="h-10 w-10" />;
    }

    switch (status) {
      case 'PRICING EXPIRED':
        return <SvgIcon name="hourglass_bottom" fill="#D79304" className="h-10 w-10" />;
      case 'EXPIRED':
        return <SvgIcon name="hourglass_bottom" fill="#D79304" className="h-10 w-10" />;
      case 'DECLINED':
        return <SvgIcon name="rainy" fill="color-gray-100" className="h-10 w-10" />;
      case 'signatures-tab':
        return <SignatureLogo />;
      case 'MISSING INFORMATION':
        return (
          <SvgIcon name="breaking_news_alt" fill="#D79304" className="h-10 w-10 mb-2" />
        );
      case 'NEED INFORMATION':
        return <SvgIcon name="demography" fill="#D79304" className="h-10 w-10 mb-2" />;
      case '':
        if (activeTab === 'offerInstallment') return <SellMoreInstallment />;
        if (activeTab === 'deferPayment') return <SellMoreDefer />;
        return <></>;
      default:
        return <></>;
    }
  };

  const showBack = (): boolean => {
    if (
      (activeTab === 'offerInstallment' || activeTab === 'deferPayment') &&
      (status === 'APPROVED' ||
        status === 'REQUEST IN REVIEW' ||
        status === 'IN REVIEW' ||
        status === 'MISSING INFORMATION' ||
        status === 'NEED INFORMATION' ||
        status === 'IN REVIEW')
    )
      return true;
    return false;
  };

  const getGap = (): string => {
    switch (status) {
      case 'DECLINED':
        return 'gap-2';
      case 'NEED INFORMATION':
        return 'gap-2';
      default:
        return 'gap-4';
    }
  };

  return (
    <div
      className={`summary-tab ${containerClassName} flex flex-col max-w-[28.1rem] ${getGap()} items-center justify-center relative w-full ${
        isTrackScreen ? 'h-[16.5rem]' : 'h-[19.25rem]'
      }`}
    >
      {!!onBackClick && showBack() && (
        <div className="absolute top-0 left-0">
          <Button
            size="xx-small"
            backgroundColor="tertiary"
            startIcon={<ButtonChevron />}
            onClick={onBackClick}
            id="back-to-home-btn"
          >
            <Typography variant="paragraph12" color="color-blue-120" bold>
              Home
            </Typography>
          </Button>
        </div>
      )}
      {status === 'APPROVED' ? (
        <CreditInformation companyNumber={companyNumber} />
      ) : (
        <>
          {get(metaContext, 'meta.trackRequestDetails', false) &&
          status !== 'signatures-tab' ? (
            <TrackRequestStatus
              title={get(metaContext, 'meta.trackRequestDetails.title', '')}
              description={get(metaContext, 'meta.trackRequestDetails.description', '')}
              lastActive={
                get(metaContext, 'meta.trackRequestDetails.lastActiveAt', '')?.replace(
                  ' @',
                  ''
                ) || ''
              }
              completed={get(metaContext, 'meta.trackRequestDetails.completed', false)}
              step={get(metaContext, 'meta.trackRequestDetails.step', 0)}
              progress={get(metaContext, 'meta.trackRequestDetails.progress', 0)}
              isActive={get(metaContext, 'meta.trackRequestDetails.active', false)}
              stepsCount={get(metaContext, 'meta.trackRequestDetails.stepsCount', 0)}
              stepDetails={get(metaContext, 'meta.trackRequestDetails.stepDetails', [])}
            />
          ) : (
            <>
              {' '}
              {getIcon()}
              <div className="flex flex-col items-center  max-w-[19.4375rem]">
                <Typography variant="heading16" bold color="color-blue-180">
                  {getTitle()}
                </Typography>
                {getContent()}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TabSummary;
