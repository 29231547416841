export interface ColorMap {
  [key: string]: `#${string}`;
}

const colors: ColorMap = {
  'color-black-100': '#121212',
  'color-black-90': '#171717',
  'color-gray-160': '#333333',
  'color-gray-140': '#545454',
  'color-gray-120': '#767676',
  'color-gray-110': '#929292',
  'color-gray-100': '#afafaf',
  'color-gray-90': '#b8b8b8',
  'color-gray-80': '#c4c4c4',
  'color-gray-40': '#e2e2e2',
  'color-gray-30': '#eeeeee',
  'color-gray-30-50p': '#eeeeee80',
  'color-gray-20': '#fafafa',
  'color-gray-10': '#f4f6f9',
  'color-white-100': '#ffffff',
  'color-blue-190': '#021e50',
  'color-blue-180': '#152d5d',
  'color-blue-160': '#21438b',
  'color-blue-160-20p': '#21438b33',
  'color-blue-120': '#054bc7',
  'color-blue-100': '#1460e7',
  'color-blue-90': '#5d8cec',
  'color-blue-80': '#8bb1ff',
  'color-blue-70': '#adc7f7',
  'color-blue-60': '#c1d3f7',
  'color-blue-50': '#e4edff',
  'color-blue-40': '#bcd1f5',
  'color-blue-30': '#e4edff',
  'color-blue-20': '#fafbff',
  'color-blue-10': '#fafbff',
  'color-green-160': '#106b4d',
  'color-green-120': '#209f79',
  'color-green-100': '#1fc198',
  'color-green-90': '#49c8a2',
  'color-green-80': '#65ceac',
  'color-green-50': '#a4e1ca',
  'color-green-30': '#c0f5e2',
  'color-green-20': '#e8fff7',
  'color-yellow-100': '#ffe272',
  'color-yellow-20': '#fffbe7',
  'color-red-160': '#c23934',
  'color-red-120': '#ea001e',
  'color-red-100': '#f05d5d',
  'color-red-90': '#ff655a',
  'color-red-80': '#ff847b',
  'color-red-60': '#ffa39c',
  'color-red-40': '#ffc1bd',
  'color-red-20': '#fff0ef',
  'color-purple-140': '#7036ae',
  'color-purple-100': '#9602c7',
  'color-purple-30': '#ebd9ff',
  'color-purple-20': '#faf5ff',
  'color-gold-60': '#f6d795',
  'color-gold-100': '#d79304',
  'color-gold-120': '#e4a82a',
  'color-gold-160': '#935600',
  'color-steel-120': '#79818E',
  'color-steel-140': '#4C5564',
  'color-steel-180': '#142B43',
};

export default colors;
