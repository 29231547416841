import { createContext } from 'react';

export const SidebarContext = createContext();
export const MainbodyContext = createContext();
export const AppContext = createContext({
  vendor: {},
});

// Holds permissions available to currently signed-in user
export const PermissionsContext = createContext({
  rolePermissions: {},
  globalResellerPermissions: {},
  setPermissions: () => {},
  userRole: {},
});
