export const US_STATES = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District Of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

export const US_STATES_SMALL = [
  { value: 'AL', label: 'AL' },
  { value: 'AK', label: 'AK' },
  { value: 'AZ', label: 'AZ' },
  { value: 'AR', label: 'AR' },
  { value: 'CA', label: 'CA' },
  { value: 'CO', label: 'CO' },
  { value: 'CT', label: 'CT' },
  { value: 'DE', label: 'DE' },
  { value: 'DC', label: 'DC' },
  { value: 'FL', label: 'FL' },
  { value: 'GA', label: 'GA' },
  { value: 'HI', label: 'HI' },
  { value: 'ID', label: 'ID' },
  { value: 'IL', label: 'IL' },
  { value: 'IN', label: 'IN' },
  { value: 'IA', label: 'IA' },
  { value: 'KS', label: 'KS' },
  { value: 'KY', label: 'KY' },
  { value: 'LA', label: 'LA' },
  { value: 'ME', label: 'ME' },
  { value: 'MD', label: 'MD' },
  { value: 'MA', label: 'MA' },
  { value: 'MI', label: 'MI' },
  { value: 'MN', label: 'MN' },
  { value: 'MS', label: 'MS' },
  { value: 'MO', label: 'MO' },
  { value: 'MT', label: 'MT' },
  { value: 'NE', label: 'NE' },
  { value: 'NV', label: 'NV' },
  { value: 'NH', label: 'NH' },
  { value: 'NJ', label: 'NJ' },
  { value: 'NM', label: 'NM' },
  { value: 'NY', label: 'NY' },
  { value: 'NC', label: 'NC' },
  { value: 'ND', label: 'ND' },
  { value: 'OH', label: 'OH' },
  { value: 'OK', label: 'OK' },
  { value: 'OR', label: 'OR' },
  { value: 'PA', label: 'PA' },
  { value: 'RI', label: 'RI' },
  { value: 'SC', label: 'SC' },
  { value: 'SD', label: 'SD' },
  { value: 'TN', label: 'TN' },
  { value: 'TX', label: 'TX' },
  { value: 'UT', label: 'UT' },
  { value: 'VT', label: 'VT' },
  { value: 'VA', label: 'VA' },
  { value: 'WA', label: 'WA' },
  { value: 'WV', label: 'WV' },
  { value: 'WI', label: 'WI' },
  { value: 'WY', label: 'WY' },
];

export const CANADA_PROVINCES = [
  {
    label: 'Alberta',
    value: 'AB',
  },
  {
    label: 'British Columbia',
    value: 'BC',
  },
  {
    label: 'Manitoba',
    value: 'MB',
  },
  {
    label: 'New Brunswick',
    value: 'NB',
  },
  {
    label: 'Newfoundland and Labrador',
    value: 'NL',
  },
  {
    label: 'Northwest Territories',
    value: 'NT',
  },
  {
    label: 'Nova Scotia',
    value: 'NS',
  },
  {
    label: 'Nunavut',
    value: 'NU',
  },
  {
    label: 'Ontario',
    value: 'ON',
  },
  {
    label: 'Prince Edward Island',
    value: 'PE',
  },
  {
    label: 'Quebec',
    value: 'QC',
  },
  {
    label: 'Saskatchewan',
    value: 'SK',
  },
  {
    label: 'Yukon',
    value: 'YT',
  },
];

export const CANADA_PROVINCES_SMALL = [
  { value: 'AB', label: 'AB' },
  { value: 'BC', label: 'BC' },
  { value: 'MB', label: 'MB' },
  { value: 'NB', label: 'NB' },
  { value: 'NL', label: 'NL' },
  { value: 'NT', label: 'NT' },
  { value: 'NS', label: 'NS' },
  { value: 'NU', label: 'NU' },
  { value: 'ON', label: 'ON' },
  { value: 'PE', label: 'PE' },
  { value: 'QC', label: 'QC' },
  { value: 'SK', label: 'SK' },
  { value: 'YT', label: 'YT' },
];

export const entityTypes = [
  { value: 'llc', label: 'LLC' },
  { value: 'c_corp', label: 'C-Corp' },
  { value: 's_corp', label: 'S-Corp' },
];

export const CustomerLoanDecisionOptions = [
  { id: 1, value: '', label: 'All' },
  { id: 2, value: 'pending_review', label: 'Pending review' },
  { id: 3, value: 'declined', label: 'Declined' },
  { id: 4, value: 'approved', label: 'Approved' },
  { id: 5, value: 'need_information', label: 'Need information' },
];

export const CreditConditions = [
  { id: 3, value: '', label: 'All' },
  { id: 4, value: 'Corporate Guarantee', label: 'Corporate guarantee' },
  { id: 1, value: 'Personal Guarantee', label: 'Personal guarantee' },
  { id: 2, value: 'Financial Statements', label: 'Financial statements' },
];

export const CreditStatuses = [
  { id: 4, value: '', label: 'All' },
  { id: 1, value: 'active', label: 'Active' },
  { id: 2, value: 'expired', label: 'Expired' },
  { id: 3, value: 'canceled', label: 'Canceled' },
  { id: 6, value: 'pre_qualified', label: 'Pre-Qualified' },
];

export const OrderStatusOptions = [
  { value: '', label: 'All' },
  { value: 'pending', label: 'Pending' },
  { value: 'checkout', label: 'Checkout' },
  { value: 'agreement', label: 'Agreement' },
  { value: 'invoice', label: 'Invoice' },
  { value: 'awaiting_acceptance', label: 'Awaiting acceptance' },
  { value: 'partially_paid', label: 'Partially paid' },
  { value: 'fully_paid', label: 'Fully paid' },
  { value: 'canceled', label: 'Canceled' },
  { value: 'approval_required', label: 'Approval required' },
  { value: 'vendor_signature', label: 'Signature required' },
  { value: 'expired', label: 'Expired' },
  { value: 'completed', label: 'Completed' },
  { value: 'payoff_in_review', label: 'Payoff in review' },
  { value: 'awaiting_remittance', label: 'Awaiting remittance' },
  { value: 'payoff_complete', label: 'Payoff complete' },
  { value: 'charged_off', label: 'Charged off' },
  { value: 'review_purchase_order', label: 'Review purchase order' },
  { value: 'pending_po_review', label: 'Pending review' },
];

export const TransactionStatusOptions = [
  { id: 1, value: '', label: 'All' },
  { id: 1, value: 'draft', label: 'Draft' },
  { id: 2, value: 'posted', label: 'Posted' },
  { id: 3, value: 'paid', label: 'Paid' },
  { id: 4, value: 'pending', label: 'Pending' },
  { id: 5, value: 'refused', label: 'Refused' },
  { id: 5, value: 'void', label: 'Void' },
];

export const DisplayByOptions = [
  { id: 1, value: 'month', label: 'Month' },
  { id: 2, value: 'quarter', label: 'Quarter' },
  { id: 3, value: 'year', label: 'Year' },
];

export const CurrencyOptions = [
  { id: 1, value: 'USD', label: 'USD' },
  { id: 2, value: 'CAD', label: 'CAD' },
];

export const COUNTRY_NAME_TO_STATES_MAP = {
  US: {
    fullNames: [...US_STATES],
    shortNames: [...US_STATES_SMALL],
  },
  CA: {
    fullNames: [...CANADA_PROVINCES],
    shortNames: [...CANADA_PROVINCES_SMALL],
  },
};

export const COUNTRY_TO_CURRENCY_MAP = {
  US: 'USD', // United States Dollar
  CA: 'CAD', // Canadian Dollar
  GB: 'GBP', // British Pound
  DE: 'EUR', // Euro (Germany)
  FR: 'EUR', // Euro (France)
  AU: 'AUD', // Australian Dollar
  JP: 'JPY', // Japanese Yen
  CN: 'CNY', // Chinese Yuan Renminbi
  IN: 'INR', // Indian Rupee
  BR: 'BRL', // Brazilian Real
  RU: 'RUB', // Russian Ruble
  ZA: 'ZAR', // South African Rand
  // Add more entries as needed
};

export const COUNTRY = {
  US: 'US',
  CA: 'CA',
  GB: 'GB',
  DE: 'DE',
  FR: 'FR',
  AU: 'AU',
  JP: 'JP',
  CN: 'CN',
  IN: 'IN',
  BR: 'BR',
  RU: 'RU',
  ZA: 'ZA',
};
