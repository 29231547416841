import { getAPIURL, reportError } from '../../../../../utils/helpers';

export async function getLinkToken() {
  const defaultResponse = {
    link_token: '',
  };

  try {
    const response = await fetch(`${getAPIURL()}/plaid/link_token`, {
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    });

    if (response.status === 200) {
      return response.json();
    }

    reportError(`Failed to fetch Plaid link token: ${response.statusText}`);
    return defaultResponse;
  } catch (e) {
    reportError(`Failed to fetch Plaid link token: ${e.message}`);
    return defaultResponse;
  }
}

export async function exchangePublicToken(publicToken) {
  try {
    const response = await fetch(`${getAPIURL()}/plaid/public_token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ public_token: publicToken }),
    });

    if (response.ok) return response.json();

    reportError(`Failed /plaid/public_token: ${response.statusText}`);
    return {};
  } catch (e) {
    reportError(`Failed /plaid/public_token: ${e.message}`);
    return null;
  }
}

export async function getAccount({ accessToken, accountId }) {
  try {
    const response = await fetch(`${getAPIURL()}/plaid/account`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        access_token: accessToken,
        account_id: accountId,
      }),
    });
    if (response.status === 200) {
      return response.json();
    }

    reportError(`Failed /plaid/account: ${response.statusText}`);
    return null;
  } catch (e) {
    reportError(`Failed /plaid/account: ${e.message}`);
    return null;
  }
}
