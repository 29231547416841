import { useCallback, useEffect } from 'react';
import { Typography, Button } from '@vartanainc/design-system';
import { useLocation, Link } from 'react-router-dom';
import { getWebsiteURL } from '../../utils/helpers';

import { ReactComponent as LogoIcon } from '../../assets/logo.svg';
import LoginBg from '../../assets/desktop-sign-in.svg';
import { ReactComponent as GoogleIcon } from '../../assets/google-icon-16p.svg';
import { ReactComponent as MicrosoftIcon } from '../../assets/microsoft-icon-16p.svg';
import { handleGoogleSignIn, handleMicrosoftSignIn } from '../../api/utils';

import MarketingText from './MarketingText';
import { useSsoHandler } from './sso-handler.hook';

const SignUp = () => {
  const location = useLocation();
  const { showModal, resetModal, openModal, setWindowSize, getWindowSize } =
    useSsoHandler();

  const handleGoogleSubmit = useCallback(() => {
    // Google button clicked
    handleGoogleSignIn().then((result) => {
      openModal(result);
    });
  }, [openModal]);

  const handleMicrosoftSubmit = useCallback(() => {
    // Microsoft button clicked
    handleMicrosoftSignIn().then((result) => {
      openModal(result);
    });
  }, [openModal]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
      resetModal();
    }

    window.addEventListener('resize', handleWindowResize);
    window.addEventListener('focus', () => {
      resetModal();
    });

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('focus', () => {
        resetModal();
      });
    };
  }, [resetModal, setWindowSize, getWindowSize]);

  return (
    <div className="min-h-screen bg-gray-50 flex">
      <img src={LoginBg} alt="" className="absolute inset-0 h-full w-full object-cover" />
      <div className="absolute flex justify-center lg:inset-0">
        <div className="px-8 py-8 flex flex-1 flex-col max-w-7xl gap-14 lg:py-16 lg:px-24 md:px-16">
          <LogoIcon className="h-auto w-40" />
          <div className="flex flex-col h-full justify-center gap-8 items-center lg:flex-row">
            <div className="flex grow-0 w-full">
              <MarketingText isSignUp />
            </div>
            <div className="w-full bg-vartana-blue-100 rounded-b-lg rounded-t pt-1 max-w-lg h-auto grow-0 shadow-2xl lg:w-auto">
              <div className="w-full h-full bg-white rounded-b-lg ">
                <div className="flex justify-center p-10">
                  <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div className="mb-11">
                      <Typography variant="heading24" bold color="color-blue-180">
                        Get started with Vartana{' '}
                      </Typography>
                    </div>

                    <div className="gap-4 flex flex-col mb-11">
                      <Button
                        type="button"
                        size="large"
                        rounded
                        borderColor="color-gray-100"
                        backgroundColor="secondary"
                        startIcon={<GoogleIcon className="h-4 w-auto object-contain" />}
                        variant="outlined"
                        fullWidth
                        className="h-auto"
                        onClick={handleGoogleSubmit}
                      >
                        <Typography variant="heading14" bold color="color-blue-180">
                          Sign up with Google
                        </Typography>
                      </Button>
                      <Button
                        type="button"
                        size="large"
                        rounded
                        borderColor="color-gray-100"
                        backgroundColor="secondary"
                        startIcon={
                          <MicrosoftIcon className="h-4 w-auto object-contain" />
                        }
                        variant="outlined"
                        fullWidth
                        onClick={handleMicrosoftSubmit}
                      >
                        <Typography variant="heading14" bold color="color-blue-180">
                          Sign up with Microsoft
                        </Typography>
                      </Button>
                    </div>

                    <div className="flex justify-center gap-1">
                      <Typography variant="paragraph14" color="color-blue-180">
                        By signing up, you agree to Vartana’s
                      </Typography>
                      <Typography variant="heading14" bold color="color-blue-120">
                        <button
                          onClick={() =>
                            window.open(`${getWebsiteURL()}/terms-and-conditions`)}
                        >
                          Terms & Privacy
                        </button>
                      </Typography>
                    </div>
                    <div className="flex justify-center gap-1 mt-2">
                      <Typography variant="paragraph14" color="color-blue-180">
                        Have an account?
                      </Typography>
                      <Link
                        to={`/login${location.search}`}
                        className="relative inline-flex font-medium vp-text-link text-vartana-blue-60 hover:text-indigo-500"
                      >
                        <Typography variant="heading14" bold>
                          Sign in
                        </Typography>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="fixed w-full h-full bg-vartana-gray-70 bg-opacity-80 flex justify-center items-center cursor-default"></div>
      )}
    </div>
  );
};

export default SignUp;
