import { useCallback, useRef, useState } from 'react';
import { useField } from 'formik';
import { TypeAheadDropdownField } from '@vartanainc/design-system';
import { useMapsServices } from '../../api/google';

function AddressInputField({
  id,
  name,
  label,
  afterPlaceSelect,
  showError,
  showWarn,
  countries,
  ...rest
}: {
  id?: string;
  name: string;
  label: string;
  showError?: boolean;
  showWarn?: boolean;
  countries?: string[];
  afterPlaceSelect: (places) => void;
}): React.JSX.Element {
  const [field, meta, { setValue }] = useField(name);
  const { getPlacePredictions, getPlaceDetails } = useMapsServices();
  const [addresses, setAddresses] = useState([]);
  const [debounceId, setDebounceId] = useState();

  const stateRef = useRef(addresses);
  stateRef.current = addresses;
  const [addressComponents, setAddressComponents] = useState({
    city: '',
    state: { shortName: '', longName: '' },
    zip: '',
    country: { shortName: '', longName: '' },
  });

  const loadPlaceSuggestions = useCallback(
    (inputValue) => {
      // TODO: Nuyaan95 to add countries fetched from BE
      getPlacePredictions(inputValue, countries, (predictions) => {
        if (predictions.length > 0) {
          setAddresses(
            predictions.map((prediction) => ({
              label: prediction?.description,
              value: prediction?.structured_formatting?.main_text,
              placeId: prediction?.place_id,
            }))
          );
        }
      });
    },
    [countries, getPlacePredictions]
  );
  const handledebounceId = (e): void => {
    setDebounceId(e);
  };
  const handleChange = (e): void => {
    const inputValue = e.target.value;
    if (inputValue.length === 0) setAddresses([]);
    setValue(inputValue);
    if (debounceId) clearTimeout(debounceId);
    loadPlaceSuggestions(inputValue);
    handledebounceId(setTimeout(() => loadPlaceSuggestions(inputValue), 1000));
  };

  const handleSelectOption = (option): void => {
    setValue(option.value);
    getPlaceDetails(option.placeId, (place) => {
      if (place) {
        const newAddressComponents = { ...addressComponents };
        place.address_components.forEach((component) => {
          const { types } = component;
          if (types.includes('administrative_area_level_1')) {
            newAddressComponents.state.longName = component.long_name;
            newAddressComponents.state.shortName = component.short_name;
          } else if (types.includes('postal_code')) {
            newAddressComponents.zip = component.long_name;
          } else if (types.includes('locality')) {
            newAddressComponents.city = component.long_name;
          } else if (types.includes('country')) {
            newAddressComponents.country.longName = component.long_name;
            newAddressComponents.country.shortName = component.short_name;
          }
        });
        setAddressComponents(newAddressComponents);
        afterPlaceSelect(newAddressComponents);
      }
    });
  };

  return (
    <TypeAheadDropdownField
      {...field}
      {...rest}
      id={id}
      name={name}
      fullWidth
      label={label}
      showError={meta.touched && showError}
      showWarning={meta.touched && showWarn}
      helperText={meta.error}
      onChange={handleChange}
      options={addresses}
      onSelectOption={handleSelectOption}
      autoComplete="off"
    />
  );
}

export default AddressInputField;
