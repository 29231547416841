import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Link element with color `vartana-blue-60`
 *
 * @param to
 * @param children
 * @param rest
 * @return {JSX.Element}
 * @constructor
 */
function BlueLink({ to, children, ...rest }) {
  return (
    <Link to={to} className="vp-text-link-bold text-vartana-blue-60" {...rest}>
      {children}
    </Link>
  );
}
BlueLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BlueLink;
