import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { ButtonV2, Typography } from '@vartanainc/design-system';
import CancelConfirmationModal from '../../../components/Modals/CancelConfirmation';
import AutoLoad from '../../../components/AutoLoad';
import { ReactComponent as EnvelopeCheck } from '../../../assets/envelope-check.svg';
import { NotificationSet } from './NotificationSet';
import { showErrorToast, showToast } from '../../../utils/helpers';
import { UPDATE_NOTIFICATION_PREFS } from '../../../graphql/mutations';

export function EditNotificationPreferences({ roleName, preferenceSets, onClose }) {
  const { id: roleId } = useParams();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [updateNotificationPrefs, { loading: isSubmitting }] = useMutation(
    UPDATE_NOTIFICATION_PREFS,
    {
      onError: () => showErrorToast(),
      refetchQueries: ['getNotificationPreferences'],
    }
  );

  const handleSubmit = useCallback(
    async (values, touched) => {
      // Only send updated values of touched fields to server
      const updatedPreferences = [];
      Object.entries(touched).forEach(([cat, catData]) => {
        Object.keys(catData).forEach((subCat) => {
          updatedPreferences.push({
            category: cat,
            subCategory: subCat,
            settings: values[cat][subCat],
          });
        });
      });

      const { data } = await updateNotificationPrefs({
        variables: { updatedPreferences, roleId: +roleId },
      });

      if (data?.updateNotificationPreferences) {
        showToast('success', 'Notification preferences updated successfully');
        onClose();
      }
    },
    [onClose, roleId, updateNotificationPrefs]
  );

  return (
    <div id="edit-notification-card" className="flex flex-col gap-10">
      <Formik initialValues={preferenceSets} onSubmit={() => null}>
        {({ dirty, touched, values }) => {
          return (
            <>
              <CancelConfirmationModal
                open={openConfirmationModal}
                onClose={() => setOpenConfirmationModal(false)}
                onConfirm={onClose}
              />
              <main className="bg-white rounded-lg border border-vartana-blue-80-1 shadow-lift-4 p-10">
                <hgroup className="flex flex-col gap-1">
                  <h1 className="flex justify-between">
                    <header className="flex gap-2">
                      <EnvelopeCheck />
                      <Typography variant="heading20" bold>
                        {roleName} notifications{' '}
                      </Typography>
                    </header>
                  </h1>
                  <Typography variant="paragraph14">
                    Customize notification preferences for this role
                  </Typography>
                </hgroup>

                <Form className="flex flex-col divide-y divide-vartana-gray-40-v3">
                  {Object.entries(preferenceSets).map(([category, categoryData]) => {
                    return (
                      <NotificationSet
                        key={category}
                        category={category}
                        items={categoryData}
                        editMode
                      />
                    );
                  })}
                </Form>
                <footer className="flex justify-between items-center mt-6 ">
                  <ButtonV2
                    variant={{ type: 'ghost', typography: 'paragraph14' }}
                    type="button"
                    text="Cancel"
                    iconLeft="chevron_left"
                    onClick={() => {
                      if (dirty) setOpenConfirmationModal(true);
                      else onClose();
                    }}
                  />
                  <AutoLoad loading={isSubmitting} containerClassName="px-10">
                    <ButtonV2
                      type="submit"
                      disabled={!dirty}
                      text="Submit"
                      onClick={() => handleSubmit(values, touched)}
                      variant={{ type: 'primary', typography: 'paragraph14' }}
                    />
                  </AutoLoad>
                </footer>
              </main>
            </>
          );
        }}
      </Formik>
    </div>
  );
}

EditNotificationPreferences.propTypes = {
  onClose: PropTypes.func.isRequired,
  roleName: PropTypes.string.isRequired,
  preferenceSets: PropTypes.shape({}).isRequired,
};
