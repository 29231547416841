import PropTypes from 'prop-types';

function CommonHeader({ title, message, className }) {
  return (
    <header className={`space-y-2 ${className}`}>
      <h1 className="vp-card-title-bold text-vartana-black">{title}</h1>
      <p className="vp-body">{message}</p>
    </header>
  );
}

CommonHeader.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CommonHeader.defaultProps = {
  className: '',
};

export default CommonHeader;
