import { ReactElement, useEffect, useMemo } from 'react';
import { FormikProvider, FormikProps } from 'formik';
import { Typography } from '@vartanainc/design-system';

import MoneyInputField from '../../designSystem/MoneyInput/MoneyInputField';
import TextAreaInput from '../../designSystem/TextAreaInput/TextAreaInput';
import DropdownInput from '../../designSystem/DropdownInput/DropdownInput';
import { MAX_ALLOWED_TCV } from '../../pages/Orders/order.constants';
import TermsDropdown from '../../designSystem/CompactFields/TermsDropdown';

export interface ModifyTermformValueProps {
  totalContractAmount: number | null;
  paymentOption: string;
  paymentTerm: number;
  billingFrequency: string;
  term: number;
  modificationReason: string;
}

interface ModifyTermsFormProps {
  paymentOptions: { label: string; value: string }[];
  paymentTermOptions: { label: string; value: number }[];
  billingFrequencyOptions: { label: string; value: string }[];
  termOptions: { label: string; value: number }[];
  formikBag: FormikProps<ModifyTermformValueProps>;
}

const paymentOptionTypes = {
  installment: 'installment_plan',
  defer: 'full_payment',
};

function ModifyTermsForm({
  paymentOptions,
  paymentTermOptions,
  billingFrequencyOptions,
  termOptions,
  formikBag,
}: ModifyTermsFormProps): ReactElement {
  const {
    handleSubmit,
    values: { paymentOption, paymentTerm, billingFrequency },
    setFieldValue,
  } = formikBag;

  const filteredPaymentTermOptions = useMemo(() => {
    // If payment option is defer payment, no need to show payment upon receipt option
    if (paymentOption === paymentOptionTypes.defer)
      return paymentTermOptions.filter((option) => option.value !== 0);
    return paymentTermOptions;
  }, [paymentTermOptions, paymentOption]);

  useEffect(() => {
    // If payment option is changed and previously selected term isnt valid anymore, reset it to the first valid option
    const defaultPaymentTerm = paymentTerm === filteredPaymentTermOptions[0]?.value;
    const autoSetDefaultPaymentTerm = !filteredPaymentTermOptions.some(
      (option) => option.value === paymentTerm
    );
    if (!defaultPaymentTerm && autoSetDefaultPaymentTerm)
      setFieldValue('paymentTerm', filteredPaymentTermOptions[0]?.value);
  }, [filteredPaymentTermOptions, paymentTerm, setFieldValue]);

  return (
    <FormikProvider value={formikBag}>
      <Typography variant="heading18" bold>
        Order details
      </Typography>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <div className="mt-4 pb-8 gap-6">
            <div className="flex flex-row space-x-4 mb-4">
              <MoneyInputField
                id="totalContractAmount"
                name="totalContractAmount"
                label="Total contract value"
                placeholder={0}
                suffix=""
                // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get currency from product/credit appraisal
                prefix="$"
                minAllowedValue={1}
                maxAllowedValue={MAX_ALLOWED_TCV}
                decimalScale={2}
                fixedDecimalScale={false}
                showFieldError={false}
              />
              <DropdownInput
                label="Payment option"
                name="paymentOption"
                options={paymentOptions}
                disabled={paymentOptions.length === 1}
                value={paymentOption}
                onChange={({ value }) => {
                  formikBag.setFieldValue('paymentOption', value);
                }}
              />
            </div>
            {paymentOption === paymentOptionTypes.installment && (
              <div className="flex flex-row space-x-4 mb-4">
                <TermsDropdown
                  name="term"
                  label="Contract length"
                  options={termOptions}
                  resetOptionsOnBlur
                />
                <DropdownInput
                  label="Billing frequency"
                  name="billingFrequency"
                  options={billingFrequencyOptions}
                  disabled={billingFrequencyOptions.length === 1}
                  value={billingFrequency}
                  onChange={({ value }) => {
                    formikBag.setFieldValue('billingFrequency', value);
                  }}
                />
              </div>
            )}
            <div className="mb-4">
              <DropdownInput
                label="Net terms"
                name="paymentTerm"
                options={filteredPaymentTermOptions}
                disabled={paymentTermOptions.length === 1}
                value={paymentTerm}
                onChange={({ value }) => {
                  formikBag.setFieldValue('paymentTerm', value);
                }}
              />
            </div>

            <div className="flex flex-row">
              <TextAreaInput
                id="modificationReason"
                name="modificationReason"
                label="Reason for modification (required)"
                tooltipElement={null}
                placeholder="Provide a detail description of your request..."
              />
            </div>
          </div>
        </fieldset>
      </form>
    </FormikProvider>
  );
}

export default ModifyTermsForm;
