import { Button, Typography } from '@vartanainc/design-system';
import { ReactElement, useState, useContext, useRef, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { get } from 'lodash';
import { sessionVar } from '../../../../graphql/cache';

import {
  BUTTON_TYPE,
  PROGEN_CTA,
  downloadStatuses,
  WIDGET_WIDTH_VARIANTS,
  BUTTON_TEXT,
} from '../../../../constants/common.constants';
import '../../../CommonWidgetV2/CommonWidget.scss';
import usePermissions from '../../../../utils/hooks/permissions';
import { WidgetContext } from '../../../../context/WidgetContext';
import VendorSignatureRequired from '../../../Orders/Summary/VendorSignatureRequired/VendorSignatureRequired';
import AutoLoad from '../../../../components/AutoLoad';
import { ReactComponent as MenuIcon } from '../../../../assets/menu_dots.svg';
import {
  PROGEN_CTA_SLUG_TO_PERMISSION_SLUG_MAP,
  PROGEN_FOOTER_ACTION_TYPES,
  PROGEN_FOOTER_CTA_COUNT_MAP,
} from '../widgetV2.constants';
import { isSmallScreen } from '../widgetUtils';
import LoadableButton from '../../../../components/Button/LoadableButton';
import { DownloadButton } from '../../../../components/Button/DownloadButton';

// define the type for Footer CTAs
type actionsType = {
  label: string;
  type: string;
  isEnabled: boolean;
  slug: string;
};
interface ProgenFooterProps {
  actions: actionsType[];
  handleClick(slug: string): Promise<boolean | void>;
  disableGenerateProposal?: boolean;
  downloadProposalStatus?: string;
  downloadAgreementStatus?: string;
  isOrderFooter?: boolean;
  createOrderDisabled?: boolean;
  calculatorHasErrors?: boolean;
  isCrm?: boolean;
  isLowerMdBreakpoint?: boolean;
}

const ProgenFooter = ({
  actions,
  handleClick,
  disableGenerateProposal,
  downloadProposalStatus,
  downloadAgreementStatus,
  isOrderFooter,
  createOrderDisabled = false,
  calculatorHasErrors,
  isCrm,
  isLowerMdBreakpoint,
}: ProgenFooterProps): JSX.Element => {
  const sessionData = useReactiveVar(sessionVar);
  const [loadingButtons, setLoadingButtons] = useState<string[]>([]);
  const [disabledButtons, setDisabledButtons] = useState<string[]>([]);
  const [showVendorSignature, setShowVendorSignature] = useState(true);
  const widgetContext = useContext(WidgetContext);
  const widthVariant = get(widgetContext, 'widthVariant', '');
  const [, hasPermission] = usePermissions();

  const selectedCustomer = get(widgetContext, 'selectedCompany', {
    buyerRelationToVendor: '',
    seller: null,
  });

  // Check if the action is allowed based on customer permissions
  const isActionAllowed = (slug: string): boolean => {
    const permissionResource = PROGEN_CTA_SLUG_TO_PERMISSION_SLUG_MAP[slug]?.resource;
    const permissionAction = PROGEN_CTA_SLUG_TO_PERMISSION_SLUG_MAP[slug]?.action;
    return (
      !permissionResource ||
      !permissionAction ||
      hasPermission(permissionResource, permissionAction, selectedCustomer)
    );
  };

  // Function to check if an action is a primary action
  const isPrimaryAction = (action: actionsType): boolean => {
    return action.type === PROGEN_FOOTER_ACTION_TYPES.primary;
  };

  const isSecondaryAction = (action: actionsType): boolean => {
    return action.type === PROGEN_FOOTER_ACTION_TYPES.secondary;
  };

  // Calculate the number of primary actions so that we always show them in the footer
  const primaryActionsCount = actions?.filter((action) => {
    const isAllowedPrimaryAction =
      isPrimaryAction(action) && isActionAllowed(action.slug);
    return isSmallScreen(widthVariant, !!isCrm) || isLowerMdBreakpoint
      ? isAllowedPrimaryAction && action?.isEnabled
      : isAllowedPrimaryAction;
  })?.length;

  // Calculate the number of secondary actions
  const secondaryActionsCount = actions?.filter(
    (action) => isActionAllowed(action.slug) && isSecondaryAction(action)
  )?.length;

  const getFooterButtonsCount = (): number => {
    return isLowerMdBreakpoint
      ? PROGEN_FOOTER_CTA_COUNT_MAP[WIDGET_WIDTH_VARIANTS.sm]
      : PROGEN_FOOTER_CTA_COUNT_MAP[widthVariant];
  };

  // Function to determine whether an action should be added to buttonActions or menuActions
  const shouldAddToButtonActions = (
    action: actionsType,
    buttonActions: actionsType[]
  ): boolean => {
    // if the primary action button is disabled for the sm breakpoint
    // we will prioritize secondary action.
    const shouldPrioritizeSecondaryAction =
      isPrimaryAction(action) &&
      (isSmallScreen(widthVariant, !!isCrm) || isLowerMdBreakpoint) &&
      (!action.isEnabled || !isActionAllowed(action.slug)) &&
      secondaryActionsCount;

    const footerActionsCount = buttonActions.length + primaryActionsCount;

    // showing all buttons if it is not a CRM widget
    if (!isCrm) return true;

    if (shouldPrioritizeSecondaryAction) return false;

    // in normal case we always show the primary actions
    if (isPrimaryAction(action)) return true;

    // if the number of remaining actions is less than the max allowed actions, we show them in button actions
    if (footerActionsCount < getFooterButtonsCount()) return true;

    return false;
  };

  // Split actions into buttonActions and menuActions based on widthVariant and primaryActionsCount
  const { buttonActions, menuActions } = actions.reduce(
    (footerCTA, action) => {
      if (isActionAllowed(action.slug)) {
        if (shouldAddToButtonActions(action, footerCTA.buttonActions)) {
          footerCTA.buttonActions.push(action);
        } else {
          // adding the remaining actions to menuActions
          footerCTA.menuActions.push(action);
        }
      }
      return footerCTA;
    },
    { buttonActions: [], menuActions: [] } as {
      buttonActions: actionsType[];
      menuActions: actionsType[];
    }
  );

  const isDownloadButtonEnabled =
    !disableGenerateProposal &&
    !calculatorHasErrors &&
    downloadProposalStatus === downloadStatuses.DEFAULT;

  const isDownloadAgreementButtonEnabled =
    !calculatorHasErrors && downloadAgreementStatus === downloadStatuses.DEFAULT;

  const handleButtonClick = async (slug: string): Promise<void> => {
    // these buttons should get disabled after clicking
    const endpointButtons = [
      PROGEN_CTA.generateNewTerms,
      PROGEN_CTA.updateRates,
      PROGEN_CTA.customerCheckout,
      PROGEN_CTA.getStarted,
    ];
    if (endpointButtons.includes(slug)) setDisabledButtons([...disabledButtons, slug]);
    // maintaining a state for all buttons that are loading
    setLoadingButtons([...loadingButtons, slug]);
    await handleClick(slug);
    // removing the button from loading state after the action is completed
    setLoadingButtons(loadingButtons.filter((btn) => btn !== slug));
  };

  const RenderFooterCTAMenu = (footerMenuActions: actionsType[]): ReactElement => {
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    // clickAway listener outside click function.
    // this triggers when user clicks outside the menu and the button
    const handleClickOutside = (event): void => {
      const shouldCloseMenu =
        menuRef.current && // checks if the menuRef is not null
        buttonRef.current && // checks if the buttonRef is not null
        !menuRef.current.contains(event.target as Node) && // checks if the click is outside the menu
        !buttonRef.current.contains(event.target as Node); // checks if the click is outside the button

      if (shouldCloseMenu) setShowMenu(false);
    };

    // Set up and clean up event listener
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    useEffect(() => {
      if (widthVariant === WIDGET_WIDTH_VARIANTS.xl) {
        setShowMenu(false);
      }
      // adding es-lint disabled because we want it to run whenever the screen size changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widthVariant]);

    // dont render menu button if there are no menu actions
    if (!footerMenuActions?.length || !isCrm) return <></>;

    return (
      <div className="relative">
        <button
          className="footer-cta-menu-button"
          onClick={() => setShowMenu(!showMenu)}
          ref={buttonRef}
        >
          <MenuIcon className="menu-button-icon" />
        </button>

        <div
          className={`footer-menu-container ${
            showMenu ? 'opacity-100' : 'hidden w-0 opacity-0'
          } transition-all duration-300`}
          ref={menuRef}
        >
          {footerMenuActions?.map((menuAction) => {
            const { label, slug, isEnabled } = menuAction;
            if (!showMenu) return <></>;

            const isDownloadButton = slug === PROGEN_CTA.downloadProposal;
            const isDisabled = isDownloadButton ? !isDownloadButtonEnabled : !isEnabled;
            return (
              <button
                className="menu-item hover:bg-vartana-blue-30"
                onClick={() => {
                  handleButtonClick(slug);
                  setShowMenu(false);
                }}
                disabled={isDisabled}
              >
                <Typography variant="paragraph12" color="color-black-100">
                  {label}
                </Typography>
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  const renderFooter = (
    footerButtons: actionsType[],
    menuButtons: actionsType[]
  ): ReactElement => {
    const buttons = footerButtons.map(({ label, type, isEnabled, slug }) => {
      const renderLabel = (): string => {
        // TODO: Hamzasiddiqui3, Nuyaan95 -- Move this logic to BE and remove this
        const signatureCtaLabel = 'Collect signature';
        const fallBackLabel = 'Get started';
        if (slug === PROGEN_CTA.getStarted && label === signatureCtaLabel) {
          return fallBackLabel;
        }
        return label;
      };

      const generateDownloadProposalButtonStyle = (): string => {
        if (type !== BUTTON_TYPE.PRIMARY) {
          return 'bg-white border-vartana-blue-120 border-[1.5px] br-2 hover:bg-vartana-blue-110 active:bg-vartana-blue-150';
        }

        return `bg-vartana-blue-120 hover:bg-vartana-blue-40 active:bg-vartana-blue-130`;
      };

      const generateDownloadProposalButtonTextStyle = (): string => {
        if (type !== BUTTON_TYPE.PRIMARY) {
          return 'text-vartana-blue-120';
        }

        return 'text-white';
      };

      const isBtnLoading = loadingButtons.includes(slug);
      const isButtonDisabled =
        !isEnabled ||
        (slug === PROGEN_CTA.createOrder && createOrderDisabled) ||
        isBtnLoading;

      switch (slug) {
        case PROGEN_CTA.downloadProposal:
          return (
            <LoadableButton
              isDisabled={!isDownloadButtonEnabled}
              btnClassName={`${
                isDownloadButtonEnabled
                  ? generateDownloadProposalButtonStyle()
                  : 'bg-vartana-gray-40-v3'
              } py-2.5 w-40 rounded h-[2.375rem]`}
              textClassName={`${
                isDownloadButtonEnabled
                  ? generateDownloadProposalButtonTextStyle()
                  : 'text-vartana-gray-50'
              } vartana-p-small-bold`}
              btnText="Download proposal"
              onClick={async () => handleClick(slug)}
            />
          );
        case PROGEN_CTA.resendOrder:
          return (
            <LoadableButton
              btnText={label}
              onClick={async () => handleClick(slug)}
              isPrimary
            />
          );
        case PROGEN_CTA.getAgreement:
          return (
            <DownloadButton
              isDisabled={!isDownloadAgreementButtonEnabled}
              btnClassName={`${
                isDownloadAgreementButtonEnabled
                  ? 'bg-white border-vartana-blue-120 border-[1.5px] br-2 hover:bg-vartana-blue-30 active:bg-vartana-blue-150'
                  : 'bg-vartana-gray-40-v3'
              } py-2.5 w-[7.813rem] rounded h-[2.375rem]`}
              textClassName={`${
                isDownloadAgreementButtonEnabled
                  ? 'text-vartana-blue-120'
                  : 'text-vartana-gray-50'
              } vartana-p-small-bold`}
              btnText={BUTTON_TEXT.GET_AGREEMENT}
              onClick={() => handleClick(slug)}
              downloadingStatus={downloadAgreementStatus}
            />
          );
        case PROGEN_CTA.inviteReseller:
          return get(sessionData, 'session.user.company.useResellerWorkflow', false) ? (
            <Button
              onClick={() => handleClick(slug)}
              size="x-small"
              disabled={!isEnabled}
              backgroundColor={
                type === PROGEN_FOOTER_ACTION_TYPES.primary ? 'primary' : 'secondary'
              }
              variant={
                type === PROGEN_FOOTER_ACTION_TYPES.secondary ? 'outlined' : 'contained'
              }
              id="progen-cta"
            >
              {label}
            </Button>
          ) : (
            <></>
          );
        case PROGEN_CTA.reviewAndSign:
          return showVendorSignature ? (
            <VendorSignatureRequired
              orderNo={get(widgetContext, 'selectedOrder.number', '')}
              testMode={get(widgetContext, 'selectedCompany.seller.testMode', false)}
              updatedAt={get(widgetContext, 'selectedOrder.updatedAt', '')}
              onSigned={() => setShowVendorSignature(false)}
              isTrack
            />
          ) : (
            <></>
          );
        default:
          return (
            <Button
              onClick={() => handleButtonClick(slug)}
              key={label}
              size="x-small"
              disabled={isButtonDisabled}
              backgroundColor={
                type === PROGEN_FOOTER_ACTION_TYPES.primary ? 'primary' : 'secondary'
              }
              variant={
                type === PROGEN_FOOTER_ACTION_TYPES.secondary ? 'outlined' : 'contained'
              }
              id="progen-cta"
              className={`cursor-pointer ${
                disabledButtons.includes(slug) ? 'opacity-70 pointer-events-none' : ''
              } ${
                type === PROGEN_FOOTER_ACTION_TYPES.secondary
                  ? 'active:bg-[#21438a33]'
                  : ''
              } ${isBtnLoading ? 'loading' : ''}`}
            >
              {isBtnLoading && <AutoLoad loading className="w-4 h-4 mr-1" />}
              {renderLabel()}
            </Button>
          );
      }
    });

    // this would be used to move the single CTA to the center of the footer without disturbing margins
    const shouldCenterSingleCTA = buttons.length === 1 && menuButtons.length;

    return (
      <div className="flex flex-row justify-between text-center items-center">
        <div
          className={`footer-buttons-container ${
            shouldCenterSingleCTA ? 'translate-x-3' : ''
          }`}
        >
          {buttons}
        </div>
        {RenderFooterCTAMenu(menuButtons)}
      </div>
    );
  };

  return (
    <div
      className={`progen-footer w-full rounded-b-lg${
        isOrderFooter ? 'order-footer pt-3 pr-2 pl-4' : ''
      }`}
    >
      {renderFooter(buttonActions, menuActions)}
    </div>
  );
};

export default ProgenFooter;
