import { gql } from '@apollo/client';

export const GET_PROFILE_DETAILS = gql`
  query getProfileDetails {
    session {
      user {
        id
        firstName
        lastName
        email
        title
        phone
        isSsoUser
        userRole {
          id
          name
        }
      }
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation logout {
    signOut {
      id
    }
  }
`;

export const SEND_RESET_PASSWORD_INSTRUCTIONS = gql`
  mutation sendResetPasswordInstructions($email: String!) {
    resetPasswordInstructions(email: $email) {
      id
      email
      fullName
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $newPassword: String!
    $confirmPassword: String!
    $firstName: String
    $lastName: String
    $token: String!
    $mode: String!
  ) {
    resetPasswordConfirm(
      newPassword: $newPassword
      confirmPassword: $confirmPassword
      firstName: $firstName
      lastName: $lastName
      token: $token
      mode: $mode
    ) {
      id
      email
      fullName
    }
  }
`;
