import { ReactElement } from 'react';
import { Typography, ButtonV2, AlertBox } from '@vartanainc/design-system';
import { ReactComponent as BlueCheckIcon } from '../../../assets/blue_check.svg';
import { ReactComponent as GrayCheckIcon } from '../../../assets/gray_check.svg';
import { ReactComponent as GrayFilledCheckIcon } from '../../../assets/gray_filled_check.svg';
import { FinancialDocsMetaProps } from './StepDetailsWrapper';

interface FinancialDetailsProps {
  isActiveStep: boolean;
  isCompleted: boolean;
  toggleShowDocumentTypesAccordion: () => void;
  showDocumentTypesAccordion: boolean;
  meta?: FinancialDocsMetaProps;
}

export function FinancialDetails({
  isActiveStep,
  isCompleted,
  toggleShowDocumentTypesAccordion,
  showDocumentTypesAccordion,
  meta,
}: FinancialDetailsProps): ReactElement {
  let iconComponent;

  if (isActiveStep) {
    iconComponent = <BlueCheckIcon />;
  } else if (isCompleted) {
    iconComponent = <GrayFilledCheckIcon />;
  } else {
    iconComponent = <GrayCheckIcon />;
  }

  const renderDocsAccordion = (): ReactElement | null => {
    if (!meta?.types || !meta.types.length) return null;
    return (
      <div className="flex flex-col gap-1">
        <ButtonV2
          rounded
          text="Document Type"
          variant={{
            type: 'text',
            typography: 'paragraph12',
          }}
          iconRight="chevron_right"
          className={`ml-6 w-[8rem] ${
            showDocumentTypesAccordion ? 'expanded-accordion' : ''
          }`}
          onClick={toggleShowDocumentTypesAccordion}
        />
        {showDocumentTypesAccordion && (
          <ul className="list-disc ml-12">
            {meta.types?.map((type) => (
              <li key={type}>
                <Typography variant="paragraph12" color="color-black-100">
                  {type}
                </Typography>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        {iconComponent}
        <Typography bold variant="heading16" color="color-black-100">
          Financial documents
        </Typography>
      </div>
      {isActiveStep && (
        <>
          {meta?.details && !!meta.details.length && (
            <ul className="list-disc ml-12 pr-1">
              {meta.details?.map((detail) => (
                <li key={detail}>
                  <Typography variant="paragraph12" color="color-black-100">
                    {detail}
                  </Typography>
                </li>
              ))}
            </ul>
          )}

          {renderDocsAccordion()}

          {meta?.why && (
            <div className="flex flex-col gap-1">
              <Typography
                bold
                variant="paragraph12"
                color="color-black-100"
                className="ml-8"
              >
                Why do we need this?
              </Typography>
              <Typography variant="paragraph12" className="ml-8" color="color-black-100">
                {meta.why}
              </Typography>
            </div>
          )}

          {meta?.disclaimer && (
            <div className="tax-alert-text">
              <AlertBox
                variant="alert-box"
                backgroundColor="gray-dark"
                content={meta.disclaimer}
                onClose={() => null}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
