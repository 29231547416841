import { useMemo, useCallback, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import { get } from 'lodash';
import { useMediaQuery } from 'react-responsive';

import { GET_ORDER_BY } from '../../../graphql/queries/order';
import { GET_COMPANY_BY } from '../../../graphql/queries/offers';
import AutoLoad from '../../../components/AutoLoad';
import CommonWidgetV2 from '../../CommonWidgetV2/commonWidget';
import { WidgetContext } from '../../../context/WidgetContext';
import { appVariants, WIDGET_WIDTH_VARIANTS } from '../../../constants/common.constants';

function Widget(): JSX.Element {
  const location = useLocation();
  const useQueryParams = new URLSearchParams(location.search);
  const crmAccountId = useQueryParams.get('crmAccountId');
  const crmOpportunityId = useQueryParams.get('crmOpportunityId');
  const tab = useQueryParams.get('tab');
  const [customerNumber, setCustomerNumber] = useState(
    useQueryParams.get('customerNumber')
  );

  const { data: orderData, loading: orderLoading } = useQuery(GET_ORDER_BY, {
    variables: {
      crmOpportunityId,
    },
  });

  const [getCompanyByNumber, { loading: isCompanyByNumberLoading, data: companyData }] =
    useLazyQuery(GET_COMPANY_BY, {
      onCompleted: () => {
        const selectedCompany = get(companyData, 'companyBy', null);
        setCustomerNumber(
          selectedCompany === null ? null : get(selectedCompany, 'number', '')
        );
      },
      onError: (error) => {
        console.error('[GET_COMPANY_BY]', error);
        setCustomerNumber(null);
      },
    });

  const getApprovedOffers = useCallback(async () => {
    return getCompanyByNumber({
      variables: {
        number: customerNumber,
        crmAccountId,
      },
    });
  }, [getCompanyByNumber, customerNumber, crmAccountId]);

  const orderExists = useMemo(() => {
    return get(orderData, 'orderBy', null) !== null;
  }, [orderData]);

  useEffect(() => {
    if (!orderExists) {
      getApprovedOffers();
    }
  }, [orderData, getApprovedOffers, orderExists]);

  const isSmall = useMediaQuery({ query: '(max-width: 319px)' });
  const isMedium = useMediaQuery({ query: '(min-width: 320px) and (max-width: 399px)' });
  const isLarge = useMediaQuery({ query: '(min-width: 400px) and (max-width: 449px)' });
  const isExtraLarge = useMediaQuery({ query: '(min-width: 450px)' });

  const widthVariant = useMemo(() => {
    if (isSmall) return WIDGET_WIDTH_VARIANTS.sm;
    if (isMedium) return WIDGET_WIDTH_VARIANTS.md;
    if (isLarge) return WIDGET_WIDTH_VARIANTS.lg;
    if (isExtraLarge) return WIDGET_WIDTH_VARIANTS.xl;
    return WIDGET_WIDTH_VARIANTS.xl;
  }, [isSmall, isMedium, isLarge, isExtraLarge]);

  const widgetContext = useMemo(
    () => ({
      selectedOrder: get(orderData, 'orderBy', {}),
      selectedCompany: get(companyData, 'companyBy'),
      variant: appVariants.widget,
      widthVariant,
    }),
    [companyData, orderData, widthVariant]
  );

  if (tab === 'help') return <Navigate to="/forms/help?tab=help" replace />;

  if ((customerNumber || crmAccountId) && crmOpportunityId) {
    return (
      <AutoLoad
        loading={orderLoading || isCompanyByNumberLoading}
        containerClassName="flex justify-center"
        className="absolute text-center top-2/4 transform-gpu -translate-y-2/4"
      >
        <WidgetContext.Provider value={widgetContext}>
          <CommonWidgetV2 />
        </WidgetContext.Provider>
      </AutoLoad>
    );
  }

  return <Navigate to="/something-went-wrong" />;
}

export default Widget;
