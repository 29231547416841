import React, { useEffect, useState, useMemo, useReducer } from 'react';
import { useParams, useLocation, Outlet, useNavigate } from 'react-router-dom';

import { get } from 'lodash';

import { useQuery, useMutation } from '@apollo/client';
import { Typography, Button } from '@vartanainc/design-system';

import { GET_ORDER_BY_NUMBER, CANCEL_ORDER } from '../../graphql/queries/order';
import { CREATE_AMENDMENT_REQUEST } from '../../graphql/mutations';

import AutoLoad from '../../components/AutoLoad';
import CloseConfirmation from '../../components/Modals/CloseConfirmation';
import Loader from '../../components/Loader';

import { OrderShowContext } from '../../context/OrderContext';

import crossIcon from '../../assets/cross.svg';
import chevronIcon from '../../assets/chevron.svg';
import { ACTION, RESOURCE } from '../../constants/permissions';
import { titleize, showToast } from '../../utils/helpers';
import DocumentationFeeModal from '../../components/Modals/DocumentationFeeModal';
import { HasPermission } from '../../components/HasPermission/HasPermission';
import { TabsWithRouter } from '../../components/TabbedNavigation';
// Todo: Uncomment after release.
// import { orderNoDocFeeUpdate } from '../../constants/common.constants';

const notificationInitialState = {
  title: '',
  message: '',
  show: false,
};

function notificationReducer(state = notificationInitialState, { type, payload }) {
  switch (type) {
  case 'show':
    return {
      ...state,
      show: true,
    };
  case 'hide':
    return {
      ...state,
      show: false,
    };
  case 'update':
    return {
      ...state,
      title: get(payload, 'title', state.title),
      message: get(payload, 'message', state.message),
      show: get(payload, 'show', state.show),
    };
  case 'reset':
    return { ...notificationInitialState };
  default:
    return { ...state };
  }
}

function OrderShow() {
  const { orderNumber } = useParams();
  const [orderShowData, setOrderShowData] = useState({});
  const { state } = useLocation();
  const navigate = useNavigate();

  const [notificationStatus, setNotificationStatus] = useReducer(
    notificationReducer,
    notificationInitialState
  );

  const [showOrderCancelModal, toggleOrderCancelModal] = useState(false);
  const [showUpdateDocFeeModal, toggleShowUpdateDocFeeModal] = useState(false);
  const [cancelOrder, { loading: cancellingOrder }] = useMutation(CANCEL_ORDER);

  useEffect(() => {
    if (state?.title && state?.message && state?.show) {
      setNotificationStatus({
        type: 'update',
        payload: {
          title: state.title,
          message: state.message,
          show: true,
        },
      });
    }
  }, [state]);

  useEffect(() => {
    if (notificationStatus.show) {
      setTimeout(
        () =>
          setNotificationStatus({
            type: 'reset',
          }),
        5000
      );
    }
  }, [notificationStatus, setNotificationStatus]);

  const {
    loading: orderDataLoading,
    data: orderData,
    refetch: refetchOrderData,
  } = useQuery(GET_ORDER_BY_NUMBER, {
    variables: { number: orderNumber },
    notifyOnNetworkStatusChange: true,
  });

  const selectedCustomer = get(orderData, 'order.company', {});
  const selectedOrder = get(orderData, 'order', {});

  useEffect(() => {
    setOrderShowData(get(orderData, 'order'));
  }, [orderData]);

  const orderShowContext = useMemo(
    () => ({
      // needs to be constant across rerenders
      setNotificationStatus,
      orderDataLoading,
      refetchOrderData,
      selectedCustomer,
      selectedOrder,
    }),
    [orderDataLoading, refetchOrderData, selectedCustomer, selectedOrder]
  );

  const notification = useMemo(() => {
    if (notificationStatus.show) {
      return (
        <div className="shadow rounded bg-vartana-light-green py-4 px-6 w-full">
          <div className="flex justify-between">
            <h2 className="vp-card-subtitle-bold text-vartana-dark-green inline-block">
              {notificationStatus.title}
            </h2>
            <button
              className="cursor-pointer"
              onClick={() => setNotificationStatus({ type: 'reset' })}
            >
              <img alt="close" src={crossIcon} />
            </button>
          </div>
          <p className="vp-body text-vartana-dark-green mt-2">
            {notificationStatus.message}
          </p>
        </div>
      );
    }
    return null;
  }, [notificationStatus]);

  const orderCanceled = useMemo(() => {
    return get(orderData, 'order.status', 'canceled') === 'canceled';
  }, [orderData]);

  const createNewOrder = useMemo(() => {
    const creditAppraisalApproved =
      get(orderShowData, 'company.creditCheck.loanDecision') === 'approved';
    const creditAppraisalExpired = get(
      orderShowData,
      'company.creditCheck.expired',
      false
    );

    const customerNumber = get(orderShowData, 'company.number', '');

    if (creditAppraisalApproved && !creditAppraisalExpired && orderCanceled) {
      return (
        <HasPermission resource="order" action="create" customer={selectedCustomer}>
          <Button
            onClick={() =>
              navigate(`/dashboard/orders/new?customerNumber=${customerNumber}`)}
          >
            Create order
          </Button>
        </HasPermission>
      );
    }
    return null;
  }, [orderShowData, orderCanceled, selectedCustomer, navigate]);

  const cancelOrderButton = useMemo(() => {
    const canCancel = get(orderData, 'order.canCancel', false);

    if (canCancel) {
      return (
        <HasPermission
          resource={RESOURCE.order}
          action={ACTION.cancel}
          customer={selectedCustomer}
        >
          <Button onClick={() => toggleOrderCancelModal(true)}>Cancel order</Button>
        </HasPermission>
      );
    }

    return null;
  }, [orderData, selectedCustomer]);

  const [requestBuyout, { loading: loadingBuyout }] = useMutation(
    CREATE_AMENDMENT_REQUEST,
    {
      variables: { number: get(orderData, 'order.number') },
      onCompleted: () => {
        const message = 'We received your request for an early payoff.';
        showToast('success', message);
      },
      refetchQueries: [GET_ORDER_BY_NUMBER],
      onError: () => {
        const message =
          'There was an error while raising request for early payoff. Please contact Vartana helpdesk for further assistance.';
        showToast('error', message);
      },
    }
  );

  const buyoutButton = useMemo(() => {
    const canRequestBuyout = get(orderData, 'order.canRequestBuyout', false);
    if (canRequestBuyout) {
      return (
        <HasPermission
          resource={RESOURCE.order}
          action={ACTION.request_buyout}
          customer={selectedCustomer}
        >
          <Button size="x-large" disabled={loadingBuyout} onClick={() => requestBuyout()}>
            {loadingBuyout ? (
              <Loader className="w-5 h-5 border-l-white border-r-white" />
            ) : (
              'Request for early payoff'
            )}
          </Button>
        </HasPermission>
      );
    }
    return null;
  }, [orderData, requestBuyout, selectedCustomer, loadingBuyout]);

  // Todo: Uncomment after release.
  // const updateDocFee = useMemo(() => {
  //   const currentOrderState = get(orderData, 'order.state', false);
  //   if (!orderDataLoading && !orderNoDocFeeUpdate.includes(currentOrderState)) {
  //     return (
  //       <button
  //         onClick={() => toggleShowUpdateDocFeeModal(true)}
  //         className="border-2 border-vartana-blue-60 vp-btn-text px-5 py-4 rounded-md text-vartana-blue-60 inline-block focus:outline-none"
  //       >
  //         Update Documentation Fee
  //       </button>
  //     );
  //   }
  //   return null;
  // }, [orderData, orderDataLoading]);
  return (
    <>
      <CloseConfirmation
        open={showOrderCancelModal}
        onClose={() => toggleOrderCancelModal(false)}
        onConfirm={async (cancelReasons) => {
          await cancelOrder({
            variables: { number: orderNumber, cancelReasons },
          });
          await refetchOrderData();
          toggleOrderCancelModal(false);
        }}
        title="Are you sure?"
        message="Canceling an order will disable customer’s checkout process."
        confirmLabel="Cancel order"
        cancelLabel="Not now"
        disabled={cancellingOrder}
        loading={cancellingOrder}
      />

      <DocumentationFeeModal
        open={showUpdateDocFeeModal}
        orderNumber={get(orderShowData, 'number', '')}
        onClose={() => toggleShowUpdateDocFeeModal(false)}
        vendorName={get(orderShowData, 'company.seller.name', '')}
        buyerName={get(orderShowData, 'company.name', '')}
      />

      <div className="flex flex-col h-screen bg-gray-50">
        <div className="w-full lg:flex px-8 border-b border-gray-200 justify-between bg-white items-center">
          <div className="flex justify-between align-middle w-full pb-4 lg:pb-0 break-allr">
            <div className="flex gap-2 items-center h-[6.375rem]">
              {get(orderShowData, 'company.name') ? (
                <>
                  <Typography variant="heading24" color="color-black-100">
                    {`${titleize(get(orderShowData, 'company.name', null))}`}
                  </Typography>
                  <img alt="chevron" src={chevronIcon} />
                </>
              ) : null}
              <Typography variant="heading24" color="color-black-100">
                {' '}
                {`Order # ${orderNumber}`}
              </Typography>
            </div>
          </div>
          <div className="flex gap-4">
            {/* Todo: Uncomment after release. */}
            {/* {updateDocFee} */}
            {buyoutButton}
            {cancelOrderButton}
            {createNewOrder}
          </div>
        </div>

        <OrderShowContext.Provider value={orderShowContext}>
          <AutoLoad
            loading={orderDataLoading}
            containerClassName="flex justify-center"
            className="absolute text-center top-2/4 transform-gpu -translate-y-2/4"
          >
            {notification}
            <TabsWithRouter
              tabs={[
                { name: 'Summary', path: 'summary' },
                { name: 'Track', path: 'track' },
              ]}
            />
            <div className="flex-1 bg-vartana-steel-20">
              <div className="flex p-6 bg-vartana-steel-20 gap-6 w-full max-w-[85.375rem] min-w-[68.75rem] mx-auto ">
                <Outlet context={{ orderShowData }} />
              </div>
            </div>
          </AutoLoad>
        </OrderShowContext.Provider>
      </div>
    </>
  );
}

export default OrderShow;
