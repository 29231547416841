import PropTypes from 'prop-types';
import { ButtonV2, Typography } from '@vartanainc/design-system';
import { ReactComponent as EnvelopeCheck } from '../../../assets/envelope-check.svg';
import { NotificationSet } from './NotificationSet';
import usePermissions from '../../../utils/hooks/permissions';

export function ViewNotificationPreferences({ roleName, onEditClick, preferenceSets }) {
  const [, hasPermission] = usePermissions();

  const preferenceGroups = Object.entries(preferenceSets).map(([category, items]) => (
    <NotificationSet key={category} category={category} items={items} />
  ));

  return (
    <div id="view-notifications-card" className="flex flex-col gap-2 mx-4 mt-8">
      <hgroup className="flex flex-col gap-1">
        <h1 className="flex justify-between">
          <header className="flex gap-2">
            <EnvelopeCheck />
            <Typography variant="heading20" bold>
              {roleName} notifications
            </Typography>
          </header>
          {onEditClick && hasPermission('notification', 'edit') && (
            <ButtonV2
              onClick={() => onEditClick()}
              text="Edit"
              variant={{ type: 'ghost', typography: 'paragraph12' }}
            />
          )}
        </h1>
        <Typography variant="paragraph14">
          The following is a list of default notifications grouped by categories.
        </Typography>
      </hgroup>

      <main className="flex flex-col divide-y divide-vartana-gray-40-v3">
        {preferenceGroups}
      </main>
    </div>
  );
}

ViewNotificationPreferences.propTypes = {
  roleName: PropTypes.string.isRequired,
  onEditClick: PropTypes.func,
  preferenceSets: PropTypes.shape({}).isRequired,
};

ViewNotificationPreferences.defaultProps = {
  onEditClick: undefined,
};
