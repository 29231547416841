import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { ButtonV2 } from '@vartanainc/design-system';
import { REQUEST_PAYMENT_METHOD_INVOICE } from '../../graphql/mutations';
import PayByInvoiceModal from '../Modals/PayByInvoiceModal';
import { titleize, showToast } from '../../utils/helpers';

export function RequestToPayByInvoiceButton({
  companyName,
  companyNumber,
  hasPreFinancedOrders,
}) {
  const [showModal, setShowModal] = useState(false);

  const [requestPaymentMethodInvoice] = useMutation(REQUEST_PAYMENT_METHOD_INVOICE, {
    variables: { number: companyNumber },
    onCompleted: () => {
      const formattedName = titleize(companyName);
      const message = `Your request to pay by invoice for ${formattedName} has been sucessfully received.`;
      showToast('success', message);
    },
    onError: () => {
      const message =
        'There was an error while raising request to pay by invoice. Please contact Vartana helpdesk for further assistance.';
      showToast('error', message);
    },
  });

  const handleBuyerPayByInvoice = useCallback(() => {
    setShowModal(false);
    requestPaymentMethodInvoice();
  }, [requestPaymentMethodInvoice]);

  return (
    <>
      <ButtonV2
        variant={{ type: 'ghost', typography: 'paragraph12' }}
        type="button"
        text="Request"
        onClick={() =>
          hasPreFinancedOrders ? setShowModal(true) : handleBuyerPayByInvoice()}
      />
      <PayByInvoiceModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={handleBuyerPayByInvoice}
      />
    </>
  );
}

RequestToPayByInvoiceButton.propTypes = {
  hasPreFinancedOrders: PropTypes.bool.isRequired,
  companyName: PropTypes.string.isRequired,
  companyNumber: PropTypes.string.isRequired,
};
