import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import { FreeTextDropdownField } from '@vartanainc/design-system';
import { useMapsServices } from '../../api/google';

function AddressInputField({
  id,
  name,
  label,
  afterPlaceSelect,
  countries,
  resetAddressSwitch = false,
  ...rest
}) {
  const [field, meta, { setValue }] = useField(name);
  const [addresses, setAddresses] = useState([]);
  const [debounceId, setDebounceId] = useState(null);
  const { getPlacePredictions, getPlaceDetails } = useMapsServices();

  const stateRef = useRef();
  stateRef.current = addresses;

  const defaultAddressComponent = {
    city: '',
    state: { shortName: '', longName: '' },
    zip: '',
    country: { shortName: '', longName: '' },
  };

  useEffect(() => {
    // reset addresses whenever the resetAddressesSwitch is toggled
    setAddresses([]);
  }, [resetAddressSwitch]);

  const loadPlaceSuggestions = useCallback(
    (inputValue) => {
      getPlacePredictions(inputValue, countries, (predictions) => {
        if (predictions.length > 0) {
          setAddresses(
            predictions.map((prediction) => ({
              label: prediction?.description,
              value: prediction?.structured_formatting?.main_text,
              placeId: prediction?.place_id,
            }))
          );
        }
      });
    },
    [countries, getPlacePredictions]
  );

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length === 0) setAddresses([]);
    setValue(inputValue);
    if (debounceId) clearTimeout(debounceId);
    setDebounceId(setTimeout(() => loadPlaceSuggestions(inputValue), 1000));
  };

  const handleSelectOption = (option) => {
    setValue(option.value);
    getPlaceDetails(option.placeId, (place) => {
      if (place) {
        const newAddressComponents = { ...defaultAddressComponent };
        place.address_components.forEach((component) => {
          const { types } = component;
          if (types.includes('administrative_area_level_1')) {
            newAddressComponents.state.longName = component.long_name;
            newAddressComponents.state.shortName = component.short_name;
          } else if (types.includes('postal_code')) {
            newAddressComponents.zip = component.long_name;
          } else if (types.includes('locality')) {
            newAddressComponents.city = component.long_name;
          } else if (types.includes('country')) {
            newAddressComponents.country.longName = component.long_name;
            newAddressComponents.country.shortName = component.short_name;
          }
        });
        afterPlaceSelect(newAddressComponents);
      }
    });
  };

  return (
    <FreeTextDropdownField
      {...field}
      {...rest}
      id={id}
      name={name}
      fullWidth
      label={label}
      showError={meta.touched && meta.error}
      helperText={meta.error}
      onChange={handleChange}
      options={addresses}
      onSelectOption={handleSelectOption}
      autoComplete="off"
    />
  );
}

AddressInputField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  afterPlaceSelect: PropTypes.func,
  countries: PropTypes.arrayOf(PropTypes.string),
  resetAddressSwitch: PropTypes.bool,
};

AddressInputField.defaultProps = {
  id: '',
  name: '',
  placeholder: '',
  label: '',
  afterPlaceSelect: () => {},
  countries: ['us'],
  resetAddressSwitch: false,
};

export default AddressInputField;
