import { get } from 'lodash';
import { loanDecision } from '../constants/common.constants';

export const getAppraisalStates = (selectedAppraisal) => {
  const decision = get(selectedAppraisal, 'loanDecision');
  const appraisalExpired = get(selectedAppraisal, 'expired', false);
  const creditApplication = get(selectedAppraisal, 'creditApplication', null);
  const appraisalCanceled = decision === loanDecision.canceled;
  const appraisalDeclined = decision === loanDecision.declined;
  const appraisalPending = decision === loanDecision.pendingReview;
  const appraisalApproved = decision === loanDecision.approved;
  const appraisalNotCreated = selectedAppraisal === null;
  const changeRequestExists =
    (appraisalPending || decision === loanDecision.needInformation) &&
    get(selectedAppraisal, 'changeRequest.id', null);
  const changeRequest = get(selectedAppraisal, 'changeRequestStatus', {});
  const changeRequestCreatedBy = get(
    selectedAppraisal,
    'changeRequest.createdByName',
    ''
  );
  const applicationRequested =
    decision === loanDecision.needInformation &&
    creditApplication &&
    ['application_forwarded', 'request_sent_to_vendor'].includes(creditApplication.state);
  const changeRequestSent =
    decision === loanDecision.needInformation &&
    get(selectedAppraisal, 'applicationRequired', false) &&
    ['', 'requested', 'received'].includes(get(selectedAppraisal, 'moreInfo', ''));
  const applicationInReview =
    decision === loanDecision.pendingReview &&
    get(selectedAppraisal, 'moreInfo') === 'received';
  const pgRequired = get(selectedAppraisal, 'pgRequired', false);
  const applicationPgRequired = get(selectedAppraisal, 'conditionalToPg', false);

  return {
    appraisalExpired,
    appraisalCanceled,
    appraisalDeclined,
    appraisalPending,
    appraisalApproved,
    appraisalNotCreated,
    applicationRequested,
    applicationInReview,
    changeRequestExists,
    changeRequest,
    changeRequestCreatedBy,
    changeRequestSent,
    pgRequired,
    applicationPgRequired,
  };
};
