import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as BackIcon } from '../../assets/back_icon.svg';

export function BackButton({ onClick, btnText, className, ...rest }) {
  const navigate = useNavigate();

  return (
    <button
      type="button"
      className={`flex gap-2 items-center vp-btn-text text-vartana-blue-40 ${className}`}
      onClick={() => (onClick ? onClick() : navigate(-1))}
      {...rest}
    >
      <BackIcon /> {btnText || 'Go back'}
    </button>
  );
}

BackButton.propTypes = {
  onClick: PropTypes.func,
  btnText: PropTypes.string,
  className: PropTypes.string,
};

BackButton.defaultProps = {
  onClick: undefined,
  btnText: '',
  className: '',
};
