import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import React from 'react';
import OnboardingProgress from './OnboardingProgress';
import AutoLoad from '../../../../components/AutoLoad';
import LogoIcon from '../../../../assets/logo_full.svg';
import { ReactComponent as RemoveIcon } from '../../../../assets/remove.svg';

function Onboarding({
  open,
  loading,
  currentStepIndex,
  goForward,
  goBack,
  currentStep,
  onClose,
  isPureReseller,
  isOnboarding,
}) {
  if (!open)
    return <Navigate to={isPureReseller ? '/dashboard/vendors' : '/dashboard'} replace />;
  const isCalendlyStep = currentStep === 'calendly';

  return (
    <Modal isOpen={open} className="modal">
      <div className="absolute top-10 left-1/2 transform-gpu -translate-x-2/4">
        <div
          className={`flex flex-col space-y-6 rounded-lg bg-white p-10 min-w-half max-w-screen-xl max-h-onboarding overflow-y-auto ${
            isCalendlyStep ? 'w-onboarding-calendly' : 'w-onboarding'
          }`}
        >
          <AutoLoad
            loading={loading}
            containerClassName="flex justify-center"
            className="text-center top-2/4 transform-gpu -translate-y-2/4"
          >
            <div className="space-y-10">
              <div>
                <img className="h-8 w-auto" src={LogoIcon} alt="Workflow" />
                {isCalendlyStep && (
                  <span className="absolute top-2 right-2">
                    <RemoveIcon className="h-5 w-5" onClick={onClose} />
                  </span>
                )}
              </div>

              <OnboardingProgress currentStep={currentStepIndex} />

              <div className="px-20">
                <Outlet context={{ goForward, goBack, isOnboarding }} />
              </div>
            </div>

            <div className="mt-auto" id="common-footer-root"></div>
          </AutoLoad>
        </div>
      </div>
    </Modal>
  );
}

Onboarding.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  currentStepIndex: PropTypes.number.isRequired,
  goForward: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  currentStep: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isPureReseller: PropTypes.bool,
  isOnboarding: PropTypes.bool,
};

Onboarding.defaultProps = {
  open: false,
  isPureReseller: false,
  isOnboarding: false,
};

export default Onboarding;
