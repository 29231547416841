import PropTypes from 'prop-types';
import { ReactComponent as OkIcon } from '../../assets/ok.svg';

export function LockedCheck({ isChecked, containerClassName }) {
  return (
    <div className={containerClassName}>
      {isChecked ? <OkIcon /> : <span className="text-vartana-gray-80">--</span>}
    </div>
  );
}

LockedCheck.propTypes = {
  containerClassName: PropTypes.string,
  isChecked: PropTypes.bool.isRequired,
};

LockedCheck.defaultProps = {
  containerClassName: '',
};
