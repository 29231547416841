import { ReactElement, useState } from 'react';
import { ButtonV2, Typography } from '@vartanainc/design-system';
import { useLocation, useNavigate } from 'react-router-dom';

import { MoreInfoWrapper } from '../../../../macro_components/MoreInfo/MoreInfoWrapper';
import AutoLoad from '../../../../components/AutoLoad';
import SubmissionComplete from '../RequestHigherLimitV2/SubmissionComplete';
import { getPageUrl, isRenderedFromHubspot } from '../../../../utils/helpers';
import { HUBSPOT_QUERY_PARAM } from '../../../../constants/common.constants';

export default function MoreInfoPage(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const useQueryParams = new URLSearchParams(location.search);
  const customerNumber = useQueryParams.get('companyNumber');

  const [loading, setLoading] = useState(true);
  const [companyName, setCompanyName] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleFormSubmission = (): void => {
    setFormSubmitted(true);
  };

  const shareLinkParams = isRenderedFromHubspot() ? `&${HUBSPOT_QUERY_PARAM}` : '';

  return (
    <>
      <AutoLoad loading={loading} containerClassName="absolute top-1/2 left-1/2" />
      <div
        className={`flex flex-col h-full bg-vartana-steel-20 ${
          loading ? 'hidden' : ''
        } min-h-[calc(100vh-4rem)]`}
      >
        <div className="px-8 border-b border-gray-200 flex items-center min-h-[6.375rem] justify-between">
          <div className="flex flex-col gap-2 pt-6 border-b border-gray-200 min-h-[6.375rem] align-middle">
            <Typography
              className="capitalize"
              variant="heading24"
              color="color-black-100"
            >
              {companyName}
            </Typography>
            <Typography variant="paragraph10" bold color="color-gray-160">
              More information
            </Typography>
          </div>
          {!formSubmitted && (
            <ButtonV2
              variant={{
                type: 'secondary',
                typography: 'paragraph14',
              }}
              onClick={() => {
                navigate(
                  getPageUrl({
                    page: 'shareLink',
                    customerNumber: customerNumber || '',
                    otherParams: shareLinkParams,
                  })
                );
              }}
              text="Share link"
            />
          )}
        </div>
        {formSubmitted ? (
          <SubmissionComplete />
        ) : (
          <MoreInfoWrapper
            setLoading={setLoading}
            setCompanyName={setCompanyName}
            onSuccess={handleFormSubmission}
          />
        )}
      </div>
    </>
  );
}
