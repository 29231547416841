import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { Typography, Button } from '@vartanainc/design-system';
import { TabsWithRouter } from '../../components/TabbedNavigation';
import usePermissions from '../../utils/hooks/permissions';
import { HasPermission } from '../../components/HasPermission/HasPermission';
import { ACTION, RESOURCE } from '../../constants/permissions';

function Settings() {
  const [, hasPermission] = usePermissions();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const tabsList = useMemo(() => {
    const tabs = [];
    tabs.push({ name: 'Profile', path: 'profile' });
    if (hasPermission('companySetting', 'integration'))
      tabs.push({ name: 'Integrations', path: 'integrations' });
    if (hasPermission('role', 'view_all') && hasPermission('role', 'view'))
      tabs.push({ name: 'Roles', path: 'roles' });
    if (hasPermission('user', 'view') && hasPermission('user', 'view_all'))
      tabs.push({ name: 'Users', path: 'users' });

    return tabs;
  }, [hasPermission]);

  return (
    <div className="flex flex-col h-full">
      {/* Title Bar */}
      <div className="flex justify-between items-center p-8 border-b border-gray-200 h-[6.375rem] bg-white">
        <div className="text-gray-900 inline-block">
          <Typography variant="heading24" color="color-black-100">
            Settings
          </Typography>
        </div>

        {pathname.includes('settings/users') && !pathname.includes('create') && (
          <div>
            <HasPermission resource={RESOURCE.user} action={ACTION.create}>
              <Button size="large" onClick={() => navigate('users/create')}>
                Create new user
              </Button>
            </HasPermission>
          </div>
        )}
      </div>

      {/*  Tabs Bar */}
      <TabsWithRouter tabs={tabsList} />
      <div className="h-[calc(100%-10rem)] overflow-y-auto bg-vartana-steel-20">
        <Outlet />
      </div>
    </div>
  );
}

export default Settings;
