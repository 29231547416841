import PropTypes from 'prop-types';
import { Button } from '@vartanainc/design-system';

const BeforeDeactivate = ({ integration, goBack, goForward }) => {
  return (
    <div className="flex flex-col items-center p-4 rounded-lg">
      {/* Heading & Subheading */}
      <div className="h-24 px-10 py-6 self-stretch">
        <h1 className="text-vartana-black text-lg font-semibold">Are you sure?</h1>
        <p className="text-sm">
          Deactivating the integration will no longer sync customers from{' '}
          {integration.title}.
        </p>
      </div>

      <div className="flex flex-col justify-between px-10 pt-6 h-96 self-stretch">
        {/* Logo & Title */}
        <div
          className={`
            flex flex-row justify-start items-center gap-5 py-7 
            border-t border-b border-vartana-gray-30
          `}
        >
          <div>
            <img src={integration.iconUrl} alt="Logo" width="50" height="50" />
          </div>
          <div>
            <h2 className="vp-card-title text-vartana-black">{integration.title}</h2>
          </div>
        </div>

        {/* Footer */}
        <div className="flex flex-row justify-between items-center mt-20">
          <div>
            {/** New Buttons added here */}
            <Button onClick={goBack} variant="outlined">
              {' '}
              &lt; Cancel
            </Button>
          </div>
          <div>
            <Button onClick={goForward} variant="outlined">
              Yes, deactivate &nbsp; &gt;
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

BeforeDeactivate.propTypes = {
  integration: PropTypes.shape({
    title: PropTypes.string.isRequired,
    iconUrl: PropTypes.string.isRequired,
  }).isRequired,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
};

export default BeforeDeactivate;
