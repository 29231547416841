// Importing necessary components and hooks from libraries and local files
import { FreeTextDropdownField } from '@vartanainc/design-system';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { get } from 'lodash';
import { useLocation } from 'react-router-dom';
import { sessionVar } from '../../graphql/cache';
import { formatContactDetails, getAuthorisedSignerContacts } from '../../api/utils';
import { MIN_CHAR_FOR_SEARCH_CALL } from '../../constants/common.constants';
import { SEARCH_AUTHORIZED_SIGNER_DEBOUNCE_TIME } from '../../pages/Orders/order.constants';
import { ContactOption, DesignSystemDropdownOption } from '../../utils/commonInterfaces';
import { ReactComponent as DownArrow } from '../../assets/arrow_down_blue.svg';

interface SelectedOption {
  phone?: string;
  email?: string;
  title?: string;
  lastName?: string;
  firstName?: string;
}

// Defining the props for the AuthUserSearchField component
interface AuthUserSearchFieldProps {
  name: string; // The name of the field
  label: string; // The label to display for the field
  onSelectOption: (option: SelectedOption) => void;
  value: string; // The current value of the field
  placeHolder?: string; // Placeholder text for the input
  companyNumber?: string;
  disabled?: boolean;
}

// Functional component for searching authorized users
function AuthUserSearchField({
  name,
  label,
  onSelectOption,
  value,
  placeHolder,
  companyNumber = '',
  disabled = false,
}: AuthUserSearchFieldProps): JSX.Element {
  // State to hold the options for the dropdown
  const [contactOptions, setContactOptions] = useState<DesignSystemDropdownOption[]>([]);

  // Accessing Formik's context to manage form state
  const formikBag = useFormikContext();
  const location = useLocation();
  const useQueryParams = new URLSearchParams(location.search);

  // State to hold the details of contacts fetched from the API
  const [contactDetails, setContactDetails] = useState<ContactOption[]>([]);

  // Get the seller number from the session data
  const sessionData = useReactiveVar(sessionVar);
  const sellerNumber = get(sessionData, 'session.user.company.number', '');

  const crmAccountId =
    useQueryParams.get('crmAccountId') || location.state?.crmAccountId || '';

  // Handler for when the input value changes
  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    // Update the Formik field value with the new input
    formikBag.setFieldValue(name, e.target.value);

    // Clear contact options if there's any input
    if (e.target.value?.length) {
      setContactOptions([]);
    }
  };

  // Handler for when a contact option is selected from the dropdown
  const onSelectContactOption = (option: { value: string }): void => {
    // Find the selected contact from the details
    const selectedOption = contactDetails.find(
      (userOption: ContactOption) =>
        [userOption.FirstName, userOption.LastName].filter(Boolean).join(' ') ===
        option.value
    ) as ContactOption | undefined;

    // If a valid option is selected, format and pass it to the callback
    if (selectedOption) {
      const formattedContact = {
        firstName: selectedOption.FirstName || '',
        lastName: selectedOption.LastName || '',
        title: selectedOption.Title || '',
        email: selectedOption.Email || '',
        phone: selectedOption.Phone || '',
      };
      onSelectOption(formattedContact);
    }
  };

  // Effect to fetch contact options based on input value
  useEffect(() => {
    const getContactOptions = setTimeout(() => {
      const shouldCallSearchAPI =
        value?.length >= MIN_CHAR_FOR_SEARCH_CALL &&
        sellerNumber &&
        (companyNumber || crmAccountId);
      // Check if the input length is sufficient for a search
      if (shouldCallSearchAPI) {
        // Fetch authorized signer contacts from the API
        getAuthorisedSignerContacts(value, sellerNumber, companyNumber, crmAccountId)
          .then((contacts) => {
            // If contacts are found, format and set them
            if (contacts?.length) {
              const formattedOptions = formatContactDetails(contacts);
              setContactDetails(contacts);
              setContactOptions(
                formattedOptions.map((option) => ({
                  ...option,
                  value: String(option.value),
                }))
              );
            }
          })
          .catch((error) => {
            // Log any errors encountered during the fetch
            console.error('Error fetching authorized signer contacts:', error);
          });
      }
    }, SEARCH_AUTHORIZED_SIGNER_DEBOUNCE_TIME);

    // Cleanup function to clear the timeout
    return () => clearTimeout(getContactOptions);
  }, [companyNumber, crmAccountId, sellerNumber, value]);

  // Rendering the dropdown field for user search
  return (
    <FreeTextDropdownField
      id={name} // Unique identifier for the field
      name={name} // Name of the field for Formik
      label={label} // Label for the dropdown
      options={contactOptions} // Options to display in the dropdown
      onChange={handleFirstNameChange} // Handler for input change
      onSelectOption={onSelectContactOption} // Handler for option selection
      value={value} // Current value of the field
      placeHolder={placeHolder} // Placeholder text
      suffixIcon={disabled ? <></> : <DownArrow />} // Icon to display on the right
      disabled={disabled}
      onBlur={() => formikBag.setFieldTouched(name, true)}
      showError={get(formikBag, `touched.${name}`) && get(formikBag, `errors.${name}`)}
      helperText={get(formikBag, `errors.${name}`)}
    />
  );
}

// Exporting the component for use in other parts of the application
export default AuthUserSearchField;
