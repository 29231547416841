/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import React, { useMemo, useCallback, useEffect, useRef } from 'react';

import { matchSorter } from 'match-sorter';
import { get, upperCase } from 'lodash';
import { Link } from 'react-router-dom';
import { Typography } from '@vartanainc/design-system';
import {
  getStatusClass,
  formatDate,
  formatCapital,
  titleize,
} from '../../../utils/helpers';
import Loader from '../../../components/Loader';

import Table from '../../../components/Table';
import SortableHeader from '../../../components/Table/SortableHeader';
import DangerousTooltip from '../../../components/DangerousTooltip';

function TransactionsTable({
  filterOptions,
  transactionListLoading,
  isLoading,
  hasNextPage,
  afterCursor,
  fetchMore,
  transactionsList,
}) {
  const loader = useRef(null);
  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (
        target.isIntersecting &&
        !transactionListLoading &&
        !isLoading &&
        hasNextPage &&
        afterCursor
      ) {
        fetchMore();
      }
    },
    [transactionListLoading, isLoading, hasNextPage, afterCursor, fetchMore]
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver, loader]);

  const infiniteLoaders = useCallback(() => {
    if (isLoading) {
      return <Loader isLoading className="w-12 h-12" containerClassName="" />;
    }
    if (!isLoading && hasNextPage) {
      return <div ref={loader} />;
    }
    return null;
  }, [isLoading, hasNextPage, loader]);

  const debitColumns = useMemo(
    () => [
      {
        Header: 'Order #',
        accessor: 'order_number',
        Cell: (props) => (
          <Link
            to={`/dashboard/orders/${props.value}/summary`}
            className="vp-text-link-bold text-vartana-blue-60"
          >
            {props.value}
          </Link>
        ),
      },
      {
        Header: 'Order form #',
        accessor: 'order_form_number',
        Cell: (props) => (
          <Typography variant="paragraph14" color="color-black-100">
            {props.value}
          </Typography>
        ),
      },
      {
        Header: 'Customer',
        accessor: 'company',
        Cell: (props) => (
          <div className="flex items-start flex-col gap-1">
            <Link to={`/dashboard/customers/${props.value.number}/summary`}>
              <Typography variant="paragraph14" bold color="color-blue-120">
                {titleize(props.value.name)}
              </Typography>
            </Link>
            <Typography variant="paragraph12" color="color-gray-140">
              {props.value.number}
            </Typography>
          </div>
        ),
      },
      {
        Header: (props) => <SortableHeader props={props} title="Amount" />,
        accessor: 'amount.raw',
        Cell: (props) => (
          <Typography variant="paragraph14" color="color-black-100">
            {props.row.original.amount.formatted}
          </Typography>
        ),
      },
      {
        Header: 'Transaction #',
        accessor: 'number',
        Cell: (props) => (
          <Typography variant="paragraph14" color="color-black-100">
            {props.value}
          </Typography>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        filter: (rows, id, filterValue) => {
          return matchSorter(rows, filterValue, { keys: [(row) => row.values.status] });
        },
        Cell: (props) =>
          get(props, 'row.original.statusTooltip', null) ? (
            <DangerousTooltip
              content={get(props, 'row.original.statusTooltip', '')}
              icon={(
                <span
                  className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusClass(
                    props.value
                  )}`}
                >
                  {upperCase(props.value)}
                </span>
              )}
            />
          ) : (
            <span
              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusClass(
                props.value
              )}`}
            >
              {upperCase(props.value)}
            </span>
          ),
      },
      {
        Header: 'Payment method',
        accessor: 'payment_method',
        Cell: (props) => (
          <div className="flex items-start flex-col gap-1">
            <Typography variant="paragraph14" bold color="color-black-100">
              {props.value.name}
            </Typography>
            <Typography variant="paragraph12" color="color-gray-140">
              {props.value.number}
            </Typography>
          </div>
        ),
      },
      {
        Header: 'Payout date',
        accessor: 'payout_date',
        Cell: (props) => (
          <Typography variant="paragraph14" color="color-black-100">
            {props.value}
          </Typography>
        ),
      },
      {
        Header: (props) => <SortableHeader props={props} title="Updated on" />,
        accessor: 'effectiveDate.raw',
        Cell: (props) => (
          <Typography variant="paragraph14" color="color-black-100">
            {props.row.original.effectiveDate.formatted}
          </Typography>
        ),
      },
    ],
    []
  );

  const debitData = useMemo(() => {
    let formattedCreditList = [];
    if (!transactionListLoading) {
      formattedCreditList = transactionsList.map((transaction) => {
        return {
          order_number: get(transaction, 'node.order.number', '-'),
          number: get(transaction, 'node.number', '-'),
          order_form_number: get(transaction, 'node.externalInvoiceNumber', '-'),
          company: {
            name: formatCapital(get(transaction, 'node.order.company.name', '-')),
            number: get(transaction, 'node.order.company.number', '-'),
          },
          payment_method: {
            name: get(
              transaction,
              'node.vendorInvoicePayments[0].payment.paymentMethod.accountType',
              ''
            ),
            number: get(
              transaction,
              'node.vendorInvoicePayments[0].payment.paymentMethod.accountNumber',
              ''
            ),
          },
          amount: {
            raw: get(transaction, 'node.amount', ''),
            formatted: get(transaction, 'node.formattedAmount', '-'),
          },
          status: get(transaction, 'node.status', ''),
          payout_date:
            formatDate(
              get(transaction, 'node.vendorInvoicePayments[0].payment.createdAt', null)
            ) || '',
          statusTooltip: get(transaction, 'node.statusTooltip', ''),
          effectiveDate: {
            formatted: formatDate(get(transaction, 'node.createdAt', '')),
            raw: Date.parse(get(transaction, 'node.createdAt', '')) || '',
          },
        };
      });
    }
    return formattedCreditList;
  }, [transactionListLoading, transactionsList]);

  const sortBy = useMemo(() => [{ id: 'amount.raw' }, { id: 'effectiveDate.raw' }], []);

  const dataTable = useMemo(() => {
    if (debitData.length) {
      return (
        <div className="overflow-x-auto">
          <Table
            columns={debitColumns}
            data={debitData}
            filterOptions={filterOptions}
            sortBy={sortBy}
            getHeaderProps={() => ({
              className:
                'px-4 md:pl-8 md:pr-4 py-4 text-left text-xs font-medium text-vartana-gray-60 tracking-wider whitespace-nowrap uppercase',
            })}
            cellClassName="md:pl-8"
          />
          {infiniteLoaders()}
        </div>
      );
    }
    return <></>;
  }, [debitData, debitColumns, filterOptions, sortBy, infiniteLoaders]);

  return dataTable;
}

export default TransactionsTable;
