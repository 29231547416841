import { Typography } from '@vartanainc/design-system';

type typographyVariant =
  | 'heading55'
  | 'heading40'
  | 'heading32'
  | 'heading24'
  | 'heading20'
  | 'heading18'
  | 'heading16'
  | 'heading14'
  | 'paragraph18'
  | 'paragraph14'
  | 'paragraph12'
  | 'paragraph10'
  | 'paragraph9';

interface TruncatedTextProps {
  variant: typographyVariant;
  color: string;
  bold: boolean;
  maxChar: number;
  text: string;
  className: string | null;
  showTooltip: boolean;
  multilineTooltip: boolean;
}

/**
 * Component for truncating text and optionally providing a tooltip with the full text.
 *
 * @param {string} variant The variant of typography to use.
 * @param {string} color The color of the text.
 * @param {boolean} bold Indicates whether the text should be bold.
 * @param {number} maxChar The maximum number of characters before truncation.
 * @param {string} text The text to be truncated.
 * @param {string | null} className Additional CSS class names for styling.
 * @param {boolean} showTooltip Indicates whether to show the tooltip.
 * @param {boolean} multilineTooltip Indicates whether the tooltip can display multiline text.
 */
function TruncatedText({
  variant,
  color,
  bold,
  maxChar,
  text,
  className,
  showTooltip = true,
  multilineTooltip,
}: TruncatedTextProps): JSX.Element {
  if (text.length > maxChar) {
    return (
      <div className="flex relative">
        <Typography
          variant={variant}
          bold={bold}
          color={color}
          className={`${className} truncated-text flex`}
        >
          {text.slice(0, maxChar)}...
        </Typography>
        {showTooltip && (
          <span
            className={`truncated-tooltip ${
              multilineTooltip ? 'multi-line-tooltip' : 'single-line-tooltip'
            }`}
          >
            {text}
          </span>
        )}
      </div>
    );
  }
  return (
    <Typography variant={variant} bold={bold} color={color}>
      {text}
    </Typography>
  );
}

export default TruncatedText;
