// FYI - https://github.com/JedWatson/react-select/issues/4094

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */

import tw from 'twin.macro';
import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import Select, { components as selectComponents } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { useField } from 'formik';

import { css } from '@emotion/react';

import FormLabel from '../FormLabel';
import FormFieldMsg from '../FormFieldMsg';
import { appVariants } from '../../constants/common.constants';

const CustomInput = (props) => {
  return <selectComponents.Input {...props} autoComplete="nope" />;
};
const CustomPlaceholder = (props) => {
  return (
    <selectComponents.Placeholder {...props} className="placeholder-vartana-gray-30" />
  );
};

const CustomIndicatorSeparator = () => null;
const CustomValueContainer = (props) => {
  return <selectComponents.ValueContainer {...props} />;
};

const CustomControl = ({ cx, getStyles, innerRef, children, ...props }) => {
  return (
    <selectComponents.Control
      {...props}
      innerRef={innerRef}
      getStyles={getStyles}
      cx={cx}
      className={cx(css(getStyles('control', props)))}
    >
      {children}
    </selectComponents.Control>
  );
};

function CustomSelect({
  className,
  withError,
  isDisabled,
  isLoading,
  isClearable,
  isRtl,
  isMulti,
  isSearchable,
  name,
  label,
  options,
  onInputSelect,
  placeholder,
  components,
  asyncCreatable,
  loadOptions,
  onInputChange,
  formatCreateLabel,
  createOptionPosition,
  refCallback,
  onFocus,
  onMenuClose,
  noOptionsMessage,
  styles,
  onMenuOpen,
  onBlur,
  value,
  variant,
  tooltipElement,
}) {
  const selectRef = useRef();

  const SelectComponent = asyncCreatable ? AsyncSelect : Select;

  const [field, { touched, error }, { setValue }] = useField(name);

  const customStyles = useMemo(
    () => ({
      text: variant === appVariants.vendor ? 'vp-field-text' : 'vp-p-small',
    }),
    [variant]
  );

  useEffect(() => {
    if (value) {
      const opt = options.find((option) => option.value === value);
      setValue(opt?.value);
      onInputSelect(opt);
    }
  }, [value, onInputSelect]); // eslint-disable-line react-hooks/exhaustive-deps

  let customProps = {
    components,
    onInputChange,
    placeholder,
    isDisabled,
    isLoading,
    isClearable,
    isRtl,
    isMulti,
    isSearchable,
    options,
    onMenuOpen,
    ref: (r) => {
      refCallback(r);
      selectRef.current = r;
    },
    className: `tw-w-full ${customStyles.text}`,
    classNamePrefix: 'react-custom-select',
    menuPosition: 'fixed',
    menuPortalTarget: document.body,
    styles: {
      ...styles,
      control: (base, state) => {
        return {
          ...base,
          ...tw`shadow-sm`,
          borderColor: (() => {
            if (touched && error) return '#EA001E'; // vartana-bright-red
            return state.isFocused ? '#054BC7' : '#EEEEEE'; // vartana-blue-120 : vartana-gray-30
          })(),
          '&:hover': (() => {
            if (touched && error) return '#EA001E'; // vartana-bright-red
            return state.isFocused ? '#054BC7' : '#EEEEEE'; // vartana-blue-120 : vartana-gray-30
          })(),
        };
      },
      input: (base) => ({
        ...base,
        ...tw`focus-within:shadow-none`,
        'input:focus': {
          boxShadow: 'none',
        },
      }),
      placeholder: (base) => ({
        ...base,
        color: touched && error ? '#C23934' : base.color, // vartana-dark-red
      }),
      option: (base) => {
        if (variant === appVariants.vendor) return { ...base, ...tw`text-sm` }; // vp-field-text
        if (variant === appVariants.widget) return { ...base, ...tw`text-xs` }; // p-xsmall

        return base;
      },
      menu: (base) => ({
        ...base,
        marginTop: '0.125rem',
      }),
      menuList: (base) => {
        const menuStyles = {
          ...base,
          paddingTop: 0,
          paddingBottom: 0,
        };

        if (variant === appVariants.vendor) return { ...menuStyles, ...tw`text-sm` }; // vp-field-text
        if (variant === appVariants.widget)
          return { ...menuStyles, ...tw`text-[0.625rem]` }; // p-xsmall

        return menuStyles;
      },
      singleValue: (base) => ({
        ...base,
        color: isDisabled ? base.color : '#121212',
      }),
    },
    value: options
      ? options.find((option) => {
        if (field.value === 0) return option.value === 0;
        return option.value === (field.value || value);
      })
      : '',
    name: field.name,
    inputId: field.name,
    onChange: isMulti
      ? (opts, action) => {
        setValue(opts || []);
        onInputSelect(opts, action);
      }
      : (option, action) => {
        setValue(typeof option?.value === 'undefined' ? '' : option?.value);
        onInputSelect(option, action);
      },
    onFocus,
    onMenuClose,
    noOptionsMessage,
    onBlur: (e) => {
      field.onBlur(e);
      onBlur(e);
    },
  };
  if (SelectComponent === AsyncCreatableSelect) {
    customProps = {
      ...customProps,
      cacheOptions: false,
      loadOptions: (inputValue, callback) => {
        loadOptions(inputValue, callback);
      },
      formatCreateLabel,
      createOptionPosition,
    };
  }
  if (SelectComponent === AsyncSelect) {
    customProps = {
      ...customProps,
      loadOptions,
      defaultOptions: true,
      onInputChange,
    };
  }

  return (
    <div className={`${className}`}>
      <FormLabel
        variant={variant}
        containerClassName="mb-1"
        labelClassName={
          touched && error ? 'text-vartana-dark-red' : 'text-vartana-gray-60'
        }
        name={field.name}
        label={label}
        tooltipElement={tooltipElement}
      />
      <SelectComponent
        {...customProps}
        components={{
          Input: CustomInput,
          IndicatorSeparator: CustomIndicatorSeparator,
          ValueContainer: CustomValueContainer,
          Control: CustomControl,
          Placeholder: CustomPlaceholder,
        }}
      />
      <FormFieldMsg
        show={touched && withError}
        msg={error}
        className="text-vartana-dark-red mt-2"
      />
    </div>
  );
}

CustomSelect.propTypes = {
  label: PropTypes.string,
  withError: PropTypes.bool,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isClearable: PropTypes.bool,
  isRtl: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    })
  ),
  onInputSelect: PropTypes.func,
  placeholder: PropTypes.string,
  components: PropTypes.shape({
    DropdownIndicator: PropTypes.func,
    IndicatorSeparator: PropTypes.func,
  }),
  asyncCreatable: PropTypes.bool,
  loadOptions: PropTypes.func,
  onInputChange: PropTypes.func,
  formatCreateLabel: PropTypes.func,
  createOptionPosition: PropTypes.string,
  refCallback: PropTypes.func,
  onFocus: PropTypes.func,
  onMenuClose: PropTypes.func,
  noOptionsMessage: PropTypes.func,
  styles: PropTypes.objectOf(PropTypes.func),
  onMenuOpen: PropTypes.func,
  onBlur: PropTypes.func,
  variant: PropTypes.oneOf(Object.values(appVariants)),
  tooltipElement: PropTypes.node,
};

CustomSelect.defaultProps = {
  label: '',
  withError: true,
  className: '',
  isDisabled: false,
  isLoading: false,
  isClearable: false,
  isRtl: false,
  isMulti: false,
  isSearchable: true,
  options: [
    {
      label: 'label',
      value: 'value',
    },
  ],
  onInputSelect: () => {},
  placeholder: 'Select...',
  components: {},
  asyncCreatable: false,
  loadOptions: () => {},
  onInputChange: () => {},
  formatCreateLabel: (value) => {
    return `Create "${value}"`;
  },
  createOptionPosition: 'last',
  refCallback: () => {},
  onFocus: () => {},
  onMenuClose: () => {},
  noOptionsMessage: () => {
    return 'No options';
  },
  styles: {
    control: (styles) => ({ ...styles }),
    option: (styles) => ({ ...styles }),
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles) => ({ ...styles }),
  },
  onMenuOpen: () => undefined,
  onBlur: () => {},
  variant: appVariants.vendor,
  tooltipElement: null,
};

export default CustomSelect;
