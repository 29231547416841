/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import React, {
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect,
  useContext,
} from 'react';

import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { get } from 'lodash';
import {
  Typography,
  Button,
  InputField,
  ReactDatePicker,
} from '@vartanainc/design-system';

import { useReactiveVar, useMutation, useQuery } from '@apollo/client';
import { CREDIT_STATUS } from '../../constants/common.constants';
import TruncatedText from '../../components/TruncatedText/TruncatedText';
import { GET_CUSTOMERS } from '../../graphql/queries/customer';
import { GET_VENDOR_BUYERS_DATA } from '../../graphql/queries/vendor';
import { GENERATE_CUSTOMERS_CSV } from '../../graphql/mutations';
import { ReactComponent as CalenderIcon } from '../../assets/calender_month.svg';

import {
  CustomerLoanDecisionOptions,
  CreditConditions,
  CreditStatuses,
} from '../../static';

import Table from '../../components/Table';
import Loader from '../../components/Loader';
import ZeroState from '../../components/ZeroState/ZeroState';
import NoDataState from '../../components/NoDataState/NoDataState';
import SyncWithIntegrations from './SyncWithIntegrations';
import '../searchFilter.scss';
import {
  getStatusClass,
  formatCapital,
  useVartanaFeatureFlags,
  showToast,
  getUSDateFormat,
  titleize,
} from '../../utils/helpers';
import { useLazyQuery } from '../../utils/hooks';
import { ACTION, RESOURCE } from '../../constants/permissions';
import { MainbodyContext } from '../../context/DashboardContext';

import launchIcon from '../../assets/launch_rocket.svg';
import { HasPermission } from '../../components/HasPermission/HasPermission';
import usePermissions from '../../utils/hooks/permissions';
import { ReactComponent as NeedInfoIcon } from '../../assets/need_info_big.svg';
import DropdownInput from '../../designSystem/DropdownInput/DropdownInput';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { sessionVar } from '../../graphql/cache';
import './Summary/CardSummary.scss';

export function Customers() {
  const [, userHasPermission] = usePermissions();
  const { csvExport: showCsvExport } = useVartanaFeatureFlags();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [companies, setCompanies] = useState([]);
  const { vendor } = useContext(MainbodyContext);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const [showCRM, setShowCRM] = useState(false);
  const [afterCursor, setAfterCursor] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(true);

  const [companyName, setCompanyName] = useState('');
  const [vendorName, setVendorName] = useState(searchParams.get('vendorName'));
  const [customerDecision, setCustomerDecision] = useState('');
  const [creditCondition, setCreditCondition] = useState('');
  const [creditStatusFilter, setCreditStatusFilter] = useState('');

  const [debounceId, setDebounceId] = useState(null);

  const [decisionFilterEndDate, setDecisionFilterEndDate] = useState();
  const [decisionFilterStartDate, setDecisionFilterStartDate] = useState();

  const [validUntilFilterEndDate, setValidUntilFilterEndDate] = useState();
  const [validUntilFilterStartDate, setValidUntilFilterStartDate] = useState();

  const sessionData = useReactiveVar(sessionVar);
  const resellerLoggedIn = get(sessionData, 'session.user.company.canActAsReseller');

  const onDecisionDateFilterChange = (dates) => {
    const [start, end] = dates;
    setDecisionFilterStartDate(start);
    setDecisionFilterEndDate(end);
  };

  const onValidUntilDateFilterChange = (dates) => {
    const [start, end] = dates;
    setValidUntilFilterStartDate(start);
    setValidUntilFilterEndDate(end);
  };

  const { loading: customerListLoading, executer: fetchMoreCustomers } =
    useLazyQuery(GET_CUSTOMERS);

  const [sendCustomerCsv, { loading: csvGenerating }] = useMutation(
    GENERATE_CUSTOMERS_CSV,
    {
      onCompleted: () => {
        showToast('success', 'Reports have been emailed to you.');
      },
    }
  );

  const loader = useRef(null);

  const creditStatus = useCallback((customer) => {
    const isPreQualified = get(customer, 'creditCheck.preQualified', false);
    const isPreQualifiedOfferExpired = get(
      customer,
      'creditCheck.preQualifiedOfferExpired',
      false
    );
    let creditState = '';
    if (get(customer, 'creditCheck.expired', '')) {
      creditState = 'expired';
    } else if (get(customer, 'creditCheck.loanDecision', 'pending') === 'canceled') {
      creditState = 'canceled';
    } else if (
      get(customer, 'creditCheck.loanDecision', 'pending') === 'approved' ||
      get(customer, 'creditCheck.loanDecision', 'pending') === 'declined'
    ) {
      creditState = 'active';
    } else if (isPreQualified && !isPreQualifiedOfferExpired) {
      creditState = CREDIT_STATUS.PRE_QUALIFIED;
    }
    return creditState;
  }, []);

  const fetchMoreCustomersWithFilters = useCallback(
    (variables, concat) => {
      const defaultVariables = {
        first: null,
        after: null,
        name: null,
        status: null,
        isResellerUser: resellerLoggedIn,
        ...variables,
      };

      return new Promise((resolve, reject) => {
        setFetchMoreLoading(true);
        return fetchMoreCustomers(defaultVariables)
          .then((fetchMoreResult) => {
            const newEdges = get(fetchMoreResult, 'data.buyers.edges', []);
            const pageInfo = get(fetchMoreResult, 'data.buyers.pageInfo', {});
            if (pageInfo) {
              setAfterCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);
            }
            if (concat) {
              setCompanies((prev) => [...prev, ...newEdges]);
            } else {
              setCompanies(newEdges);
            }
            setFetchMoreLoading(false);
            resolve();
          })
          .catch((error) => {
            setFetchMoreLoading(false);
            reject(error);
          });
      });
    },
    [fetchMoreCustomers, setCompanies, setAfterCursor, setHasNextPage, resellerLoggedIn]
  );

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (
        target.isIntersecting &&
        !customerListLoading &&
        !fetchMoreLoading &&
        hasNextPage &&
        afterCursor
      ) {
        fetchMoreCustomersWithFilters(
          {
            first: 10,
            after: afterCursor,
            name: companyName,
            vendorName,
            loanDecision: customerDecision,
            creditCondition,
            creditStatus: creditStatusFilter,
            decisionStartDate: decisionFilterStartDate,
            decisionEndDate: decisionFilterEndDate,
            vaildUntilStartDate: validUntilFilterStartDate,
            validUntilEndDate: validUntilFilterEndDate,
          },
          true
        );
      }
    },
    [
      afterCursor,
      companyName,
      vendorName,
      customerDecision,
      customerListLoading,
      hasNextPage,
      fetchMoreLoading,
      fetchMoreCustomersWithFilters,
      creditCondition,
      creditStatusFilter,
      decisionFilterStartDate,
      decisionFilterEndDate,
      validUntilFilterStartDate,
      validUntilFilterEndDate,
    ]
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver, loader]);

  useEffect(() => {
    if (
      (decisionFilterStartDate !== null && decisionFilterEndDate === null) ||
      (validUntilFilterStartDate !== null && validUntilFilterEndDate === null)
    ) {
      return;
    }
    const shouldFetchMoreCustomers =
      !companyName &&
      !customerDecision &&
      !creditCondition &&
      !decisionFilterEndDate &&
      !validUntilFilterEndDate &&
      !creditStatusFilter;
    if (shouldFetchMoreCustomers) {
      // filters cleared
      fetchMoreCustomersWithFilters({ first: 10, vendorName }, false);
    } else {
      // filters we set
      setDebounceId(
        setTimeout(
          () =>
            fetchMoreCustomersWithFilters(
              {
                first: 10,
                name: companyName,
                vendorName,
                loanDecision: customerDecision,
                creditCondition,
                creditStatus: creditStatusFilter,
                decisionStartDate: decisionFilterStartDate,
                decisionEndDate: decisionFilterEndDate,
                vaildUntilStartDate: validUntilFilterStartDate,
                validUntilEndDate: validUntilFilterEndDate,
              },
              false
            ),
          500
        )
      );
    }
  }, [
    companyName,
    vendorName,
    customerDecision,
    creditCondition,
    creditStatusFilter,
    decisionFilterStartDate,
    decisionFilterEndDate,
    validUntilFilterStartDate,
    validUntilFilterEndDate,
    fetchMoreCustomersWithFilters,
  ]);

  const columns = useMemo(() => {
    const cols = [
      {
        Header: '',
        accessor: 'icon.src',
        Cell: (props) => (
          <>
            {props.row.values.loanDecision === 'need_information' &&
            props.row.values.creditStatus !== 'expired' ? (
                <div className="flex justify-center pl-2">
                  <NeedInfoIcon />
                </div>
              ) : null}
          </>
        ),
      },
      {
        Header: 'Customer',
        accessor: 'name.name',
        Cell: (props) => (
          <div className="flex items-start flex-col gap-1">
            <div>
              <Link to={`/dashboard/customers/${props.row.original.name.number}/summary`}>
                <TruncatedText
                  variant="paragraph14"
                  bold
                  color="color-blue-120"
                  maxChar={30}
                  text={titleize(props.row.original.name.name)}
                />
              </Link>
            </div>
            <div>
              <Typography variant="paragraph12" color="color-blue-120">
                {props.row.original.name.number}
              </Typography>
            </div>
          </div>
        ),
      },
      {
        Header: 'Decision',
        accessor: 'loanDecision',
        Cell: (props) => (
          <span className={getStatusClass(props.value)}>
            {props.value.replaceAll('_', ' ')}
          </span>
        ),
      },
      {
        Header: 'Credit status',
        accessor: 'creditStatus',
        Cell: (props) => (
          <span className={getStatusClass(props.value)}>
            {props.value.replaceAll('_', ' ')}
          </span>
        ),
      },
      {
        Header: 'Decision date',
        accessor: 'decisionDate.raw',
        Cell: (props) => (
          <Typography variant="paragraph14" color="color-black-100">
            {props.row.original.decisionDate.formatted}
          </Typography>
        ),
      },
      {
        Header: 'Valid until',
        accessor: 'expireAt.raw',
        Cell: (props) => (
          <Typography variant="paragraph14" color="color-black-100">
            {props.row.original.expireAt.formatted}
          </Typography>
        ),
      },
      {
        Header: 'Credit conditions',
        accessor: 'creditConditions',
        Cell: (props) => (
          <div className="flex flex-col gap-2 items-start">
            {props.row.original.creditConditions.map((condition) => (
              <p className="pill primary">{condition}</p>
            ))}
          </div>
        ),
      },
      {
        Header: 'Owner',
        accessor: 'accountOwner.email',
        Cell: ({ row }) => (
          <div>
            {userHasPermission('user', 'view_all', row.original) ? (
              <Link
                className="flex relative"
                to="/dashboard/settings/users/view"
                state={{ selectedUser: row.original.accountOwner }}
              >
                <TruncatedText
                  variant="paragraph14"
                  bold
                  color="color-blue-120"
                  maxChar={30}
                  text={formatCapital(get(row.original, 'accountOwner.fullName', '--'))}
                />
              </Link>
            ) : (
              <div className="flex max-w-[50ch] relative">
                <Typography
                  variant="paragraph14"
                  bold
                  className="capitalize truncated-text"
                >
                  {formatCapital(get(row.original, 'accountOwner.fullName', '--'))}
                </Typography>
                {row.original.accountOwner?.fullName?.length > 50 ? (
                  <span className="truncated-tooltip">
                    {formatCapital(get(row.original, 'accountOwner.fullName', '--'))}
                  </span>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: 'Updated',
        accessor: 'lastUpdated.raw',
        Cell: (props) => (
          <Typography variant="paragraph14" color="color-black-100">
            {props.row.original.lastUpdated.formatted}
          </Typography>
        ),
      },
    ];

    if (resellerLoggedIn) {
      cols.splice(2, 0, {
        Header: 'Partner',
        accessor: 'seller',
        Cell: ({ value: seller }) => (
          <div className="flex flex-col gap-1">
            <Typography variant="paragraph14" bold color="color-black-100">
              {seller.name}
            </Typography>
            <Typography variant="paragraph12" color="color-black-100">
              {seller.number}
            </Typography>
          </div>
        ),
      });
    }

    return cols;
  }, [resellerLoggedIn, userHasPermission]);

  const data = useMemo(() => {
    return companies
      .filter((edge) => edge.node)
      .map((edge) => {
        const customer = edge.node;
        return {
          ...customer,
          cursor: edge.cursor,
          name: {
            name: get(customer, 'name', ''),
            number: get(customer, 'number', ''),
          },
          seller: get(customer, 'seller', {}),
          expireAt: {
            formatted:
              get(customer, 'creditCheck.loanDecision', 'pending') === 'approved'
                ? getUSDateFormat(get(customer, 'creditCheck.formattedExpireAt', ''))
                : '',
            raw:
              get(customer, 'creditCheck.loanDecision', 'pending') === 'approved'
                ? Date.parse(get(customer, 'formattedExpireAt', '')) || ''
                : '',
          },
          financedAmount: get(customer, 'formattedBuyerUtilizedCredit', ''),
          creditConditions: get(customer, 'creditCheck.creditConditionsForApproval', []),
          creditStatus: creditStatus(customer),
          fees: {
            raw: get(customer, 'discount', 0),
            formatted: customer.discount ? get(customer, 'formattedFee', '') : '',
            percentage: get(customer, 'formattedFeePercentage', ''),
          },
          totalValue: {
            raw: get(customer, 'amount', 0),
            formatted: get(customer, 'formattedAmount', ''),
          },
          status: get(customer, 'status', ''),
          loanDecision:
            get(customer, 'creditCheck.loanDecision', '') === 'canceled'
              ? 'pending review'
              : get(customer, 'creditCheck.loanDecision', ''),
          accountOwner: customer?.accountExecutive || {},
          decisionDate: {
            formatted:
              get(customer, 'creditCheck.loanDecision', 'pending') === 'approved' ||
              get(customer, 'creditCheck.loanDecision', 'pending') === 'declined' ||
              get(customer, 'creditCheck.loanDecision', 'pending') === 'canceled'
                ? getUSDateFormat(get(customer, 'creditCheck.createdAt', ''))
                : '',
            raw:
              get(customer, 'creditCheck.loanDecision', 'pending') === 'approved' ||
              get(customer, 'creditCheck.loanDecision', 'pending') === 'declined' ||
              get(customer, 'creditCheck.loanDecision', 'pending') === 'canceled'
                ? Date.parse(get(customer, 'creditCheck.createdAt', '')) || ''
                : '',
          },
          lastUpdated: {
            formatted: getUSDateFormat(get(customer, 'updatedAt', '')),
            raw: Date.parse(get(customer, 'updatedAt', '')) || '',
          },
        };
      });
  }, [companies, creditStatus]);

  const sortBy = useMemo(
    () => [
      { id: 'lastUpdated.raw', desc: true },
      { id: 'name.name' },
      { id: 'financedAmount' },
      { id: 'totalValue.raw' },
      { id: 'accountOwner.email' },
      { id: 'decisionDate.raw' },
    ],
    []
  );

  const infiniteLoaders = useCallback(() => {
    if (fetchMoreLoading) return <Loader isLoading className="w-12 h-12" />;
    if (hasNextPage) return <div ref={loader} />;
    return null;
  }, [fetchMoreLoading, hasNextPage]);

  const [customerExists, setCustomerExists] = useState(false);
  const { loading: countLoading } = useQuery(GET_VENDOR_BUYERS_DATA, {
    onCompleted: (info) => {
      setCustomerExists(info.vendorBuyersData.buyersExist);
    },
  });

  const onboardingSteps = get(vendor, 'seller.onboardingSteps', []);
  const importStep = onboardingSteps.find((step) => step.name === 'import');
  const showZeroState =
    get(importStep, 'status', 'complete') === 'complete' && !customerExists;
  const noDataState =
    get(importStep, 'status', 'complete') === 'complete' &&
    customerExists &&
    data.length === 0;

  const mainBody = useMemo(() => {
    if (data.length) {
      return (
        <div className="flex flex-1  flex-col">
          <div className="-my-2 sm:-ml-6 lg:-ml-8 overflow-x-auto">
            <div className="py-2 align-middle inline-block min-w-full sm:pl-6 lg:pl-8">
              <div className="overflow-hidden border-b border-gray-200 sm:rounded-none">
                <Table
                  columns={columns}
                  data={data}
                  sortBy={sortBy}
                  getHeaderProps={() => ({
                    className:
                      'px-4 py-4 text-left text-xs font-medium text-vartana-gray-60 tracking-wider whitespace-nowrap uppercase',
                  })}
                  getCustomRowProps={(row) => ({
                    className:
                      (row.values.loanDecision === 'need_information' ||
                        row.values.creditStatus === CREDIT_STATUS.PRE_QUALIFIED) &&
                      row.values.creditStatus !== 'expired'
                        ? 'bg-vartana-yellow-20'
                        : '',
                  })}
                />
                {infiniteLoaders()}
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (showZeroState) {
      return <ZeroState page="customers" cta="add_customer" />;
    }

    if (noDataState) {
      return <NoDataState />;
    }

    return (
      <div className="flex justify-center">
        <div className="py-20 text-center space-y-4">
          <div>
            <img alt="launch" src={launchIcon} />
          </div>
          <div className="">
            <Typography variant="heading24" color="color-black-100">
              Add Customer to start offering payment options!
            </Typography>
          </div>
          <div>
            <HasPermission resource="companySetting" action="import_customer_csv">
              <Button onClick={() => setShowCRM(true)}>Import now</Button>
            </HasPermission>
          </div>
        </div>
      </div>
    );
  }, [data, showZeroState, noDataState, columns, sortBy, infiniteLoaders]);

  return (
    <>
      {customerListLoading || countLoading ? (
        <Loader
          isLoading={customerListLoading || countLoading}
          containerClassName="h-full flex"
        />
      ) : (
        <>
          <div className="flex flex-col justify-stretch">
            <div className="flex-row justify-between bg-white items-center ">
              <div className="md:flex px-8 justify-between items-center border-b border-gray-200 w-full h-[6.375rem]">
                <div className="flex items-center">
                  <Typography variant="heading24" color="color-black-100">
                    Customers
                  </Typography>
                </div>
                {!showZeroState && (
                  <div className="space-x-4 flex">
                    <HasPermission resource={RESOURCE.customer} action={ACTION.create}>
                      <Button
                        size="large"
                        onClick={() => navigate('/dashboard/customers/new')}
                      >
                        Add customer
                      </Button>
                    </HasPermission>

                    <HasPermission resource="companySetting" action="import_customer_csv">
                      <Button
                        size="large"
                        variant="outlined"
                        onClick={() => setShowCRM(true)}
                      >
                        Import customers
                      </Button>
                    </HasPermission>
                  </div>
                )}
              </div>

              {!showZeroState && (
                <div className="border-b border-vartana-gray-40-v3 bg-white">
                  <div className="pt-4 pb-6 px-8">
                    <div className="filter-container-responsive">
                      <div className="filter-responsive">
                        <InputField
                          label="Customer"
                          name="customer-name"
                          suffixIcon={<SearchIcon />}
                          fullWidth
                          onChange={(e) => {
                            clearTimeout(debounceId);
                            setCompanyName(e.target.value);
                          }}
                        />
                      </div>
                      {resellerLoggedIn && (
                        <div className="filter-responsive">
                          <InputField
                            label="Partner"
                            name="vendor-number"
                            suffixIcon={<SearchIcon />}
                            fullWidth
                            value={vendorName}
                            onChange={(e) => {
                              clearTimeout(debounceId);
                              setVendorName(e.target.value);
                            }}
                          />
                        </div>
                      )}
                      <div className="filter-responsive">
                        <DropdownInput
                          label="Decision"
                          name="customer-decision"
                          options={CustomerLoanDecisionOptions}
                          value={customerDecision}
                          onChange={({ value }) => {
                            clearTimeout(debounceId);
                            setCustomerDecision(value);
                          }}
                        />
                      </div>
                      <div className="filter-responsive">
                        <DropdownInput
                          label="Credit status"
                          name="credit-status"
                          options={CreditStatuses}
                          value={creditStatusFilter}
                          onChange={({ value }) => {
                            clearTimeout(debounceId);
                            setCreditStatusFilter(value);
                          }}
                        />
                      </div>
                      <div className="filter-responsive">
                        <DropdownInput
                          label="Credit condition"
                          name="credit-condition"
                          options={CreditConditions}
                          value={creditCondition}
                          onChange={({ value }) => {
                            clearTimeout(debounceId);
                            setCreditCondition(value);
                          }}
                        />
                      </div>
                      <div className="filter-responsive">
                        <ReactDatePicker
                          label="Decision date"
                          placeHolder="MM/DD/YY-MM/DD/YY"
                          selected={decisionFilterStartDate}
                          onChange={onDecisionDateFilterChange}
                          startDate={decisionFilterStartDate}
                          endDate={decisionFilterEndDate}
                          selectsRange
                          postFixIcon={<CalenderIcon />}
                        />
                      </div>
                      <div className="filter-responsive">
                        <ReactDatePicker
                          label="Valid until"
                          placeHolder="MM/DD/YY-MM/DD/YY"
                          selected={validUntilFilterStartDate}
                          onChange={onValidUntilDateFilterChange}
                          startDate={validUntilFilterStartDate}
                          endDate={validUntilFilterEndDate}
                          selectsRange
                          postFixIcon={<CalenderIcon />}
                        />
                      </div>

                      {showCsvExport ? (
                        <div className="flex items-end">
                          <Button
                            size="x-small"
                            variant="outlined"
                            disabled={csvGenerating || companies?.length === 0}
                            onClick={() =>
                              sendCustomerCsv({
                                variables: {
                                  name: companyName,
                                  vendorName,
                                  loanDecision: customerDecision,
                                  creditCondition,
                                  creditStatus: creditStatusFilter,
                                  decisionStartDate: decisionFilterStartDate,
                                  decisionEndDate: decisionFilterEndDate,
                                  vaildUntilStartDate: validUntilFilterStartDate,
                                  validUntilEndDate: validUntilFilterEndDate,
                                },
                              })}
                          >
                            {csvGenerating ? (
                              <Loader className="w-5 h-5 border-l-white border-r-white" />
                            ) : (
                              'Export CSV'
                            )}
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {mainBody}
          {showCRM && (
            <SyncWithIntegrations isOpen={showCRM} onClose={() => setShowCRM(false)} />
          )}
        </>
      )}
    </>
  );
}
