import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useMutation } from '@apollo/client';

import crossIcon from '../../assets/cross.svg';
import { ReactComponent as SendIcon } from '../../assets/arrow-blue.svg';
import { showToast } from '../../utils/helpers';
import { RESET_PASSWORD_FOR_OTHER_USER } from '../../graphql/queries/user';

// Todo: will remove this laster.
function ResetUserPasswordModal({ open, handleClose, selectedUser }) {
  const { email, number } = selectedUser || {};
  const [sendInstructions] = useMutation(RESET_PASSWORD_FOR_OTHER_USER);

  const handleSend = async () => {
    const { data } = await sendInstructions({
      variables: {
        userNumber: number,
      },
    });
    if (data) showToast('success', 'Password reset email sent successfully.');
    handleClose();
  };

  return (
    <Modal isOpen={open} className="modal" ariaHideApp={false}>
      <div className="absolute top-48 left-1/2 transform-gpu -translate-x-2/4">
        <div className="rounded-lg bg-white p-10 flex flex-col gap-4">
          <div className="absolute right-0 top-0 p-3">
            <button onClick={handleClose} type="button">
              <img alt="close" src={crossIcon} />
            </button>
          </div>
          <div className="flex items-center gap-2">
            <SendIcon />
            <h3 className="page-title-small">Reset password</h3>
          </div>
          <p className="font-normal text-sm leading-5">
            Password reset instructions will be sent to {email}
          </p>
          <div className="flex justify-between mt-6">
            <div className="flex items-center">
              <button onClick={handleClose} className="flex items-center gap-1">
                <span className="vp-btn-text text-vartana-blue-40">Cancel</span>
              </button>
            </div>
            <button
              type="button"
              onClick={() => handleSend()}
              className="py-4 px-10 bg-vartana-blue-60 rounded-md"
            >
              <p className="vp-btn-text text-white">Send</p>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ResetUserPasswordModal.propTypes = {
  open: PropTypes.bool,
  selectedUser: PropTypes.shape({
    email: PropTypes.string,
    number: PropTypes.string,
  }).isRequired,
  handleClose: PropTypes.func,
};

ResetUserPasswordModal.defaultProps = {
  open: false,
  handleClose: () => {},
};

export default ResetUserPasswordModal;
