import React from 'react';
import PropTypes from 'prop-types';

import { DropdownField } from '@vartanainc/design-system';

function DropdownInput({
  name,
  label,
  options,
  onChange,
  value,
  isSearchable,
  disabled,
}) {
  return (
    <DropdownField
      id={name}
      name={name}
      options={options}
      value={options.find((option) => option.value === value)}
      fullWidth
      label={label}
      isSearchable={isSearchable}
      onChange={(e) => onChange(e)}
      disabled={disabled}
    />
  );
}

DropdownInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    })
  ),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  isSearchable: PropTypes.bool,
  disabled: PropTypes.bool,
};

DropdownInput.defaultProps = {
  label: '',
  options: [
    {
      label: 'label',
      value: 'value',
    },
  ],
  onChange: () => {},
  value: '',
  isSearchable: true,
  disabled: false,
};

export default DropdownInput;
