import { useField } from 'formik';
import { CompactInputField } from '@vartanainc/design-system';

function InputField({
  name,
  label,
  placeholder,
  showError,
  showWarn,
  disabled,
  ...rest
}: {
  name: string;
  label: string;
  placeholder?: string;
  showError?: boolean;
  showWarn?: boolean;
  disabled?: boolean;
}): JSX.Element {
  const [field, meta] = useField(name);
  return (
    <CompactInputField
      {...field}
      {...rest}
      placeholder={placeholder}
      label={label}
      showError={meta.touched && showError}
      showWarn={meta.touched && showWarn}
      disabled={disabled}
    />
  );
}

export default InputField;
