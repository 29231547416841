import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import Button from '../Button';
import Select from '../Select';
import FormTextArea from '../FormTextArea';
import { ORDER_CANCELLATION_REASONS } from '../../constants/order';
import crossIcon from '../../assets/cross.svg';
import Loader from '../Loader';
import SvgIcon from '../SvgIcon/SvgIcon';

// User-facing text
const CANCEL_REASONS_LABEL =
  'Before proceeding with cancellation, please select reason(s) for not approving an order';
const CANCEL_REASON_PLACEHOLDER = 'Cancellation reason(s)...';
const CANCEL_REASON_REQUIRED_MSG =
  'Please select at least one cancellation reason before proceeding';
const CANCELLATION_DETAILS_PLACEHOLDER = 'Tell us more about your order cancellation...';
const CANCELLATION_DETAILS_ERROR_MESSAGE =
  'Please provide details for this cancellation.';
const CANCELLATION_DETAILS_FIELD_LABEL = 'Cancellation details';

const initialValues = { orderCancelReasons: [], cancellationDetails: '' };

const validationSchema = yup.object().shape({
  orderCancelReasons: yup
    .array()
    .required(CANCEL_REASON_REQUIRED_MSG)
    .min(1, CANCEL_REASON_REQUIRED_MSG),
  cancellationDetails: yup.string().when('orderCancelReasons', {
    is: (orderCancelReasons) => {
      const selectedReasons = orderCancelReasons.map(({ label }) => label);
      return selectedReasons.includes(ORDER_CANCELLATION_REASONS[4].label);
    },
    then: yup.string().required(CANCELLATION_DETAILS_ERROR_MESSAGE),
  }),
});

function CloseConfirmation({
  open,
  onConfirm,
  onClose,
  title,
  message,
  confirmLabel,
  cancelLabel,
  disabled,
  loading,
}) {
  // Handle form submission
  const handleSubmit = (values) => {
    const cancellationReasons = values?.orderCancelReasons.map(({ label }) => label);
    // Check if "Other" reason is selected
    if (values?.orderCancelReasons.includes(ORDER_CANCELLATION_REASONS[4])) {
      // Add the custom cancellation reason to the list
      cancellationReasons.push(values?.cancellationDetails);
    }
    // Call the onConfirm callback with the cancellation reasons in an array
    onConfirm(cancellationReasons);
  };

  const formikBag = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const { setErrors, setFieldTouched, values } = formikBag;

  // Reset cancellationDetails field when orderCancelReasons change
  useEffect(() => {
    setErrors('cancellationDetails', false); // Reset any validation errors for cancellationDetails field
    setFieldTouched('cancellationDetails', false); // Mark cancellationDetails field as not touched
  }, [setErrors, setFieldTouched, values.orderCancelReasons]);

  return (
    <Modal isOpen={open} className="modal">
      <div className="absolute top-1/4 left-1/2 transform-gpu -translate-x-2/4">
        <div className="rounded-lg bg-white py-4 px-6">
          <div className="flex flex-row-reverse">
            <button
              className="focus:outline-none cursor-pointer"
              onClick={onClose}
              disabled={disabled}
            >
              <img alt="close" src={crossIcon} />
            </button>
          </div>
          <div className="space-y-1">
            <div className="flex gap-2 items-center">
              <SvgIcon
                name="error"
                fill="color-blue-120"
                variant="thick"
                className="w-8 h-8"
              />
              <h1 className="vp-card-title inline-block">{title}</h1>
            </div>
            <p className="vp-body text-vartana-black">{message}</p>
          </div>
          <FormikProvider value={formikBag}>
            <Form>
              <Select
                name="orderCancelReasons"
                label={CANCEL_REASONS_LABEL}
                options={ORDER_CANCELLATION_REASONS}
                placeholder={CANCEL_REASON_PLACEHOLDER}
                className="my-5"
                isMulti
              />
              <FormTextArea
                name="cancellationDetails"
                label={CANCELLATION_DETAILS_FIELD_LABEL}
                placeholder={CANCELLATION_DETAILS_PLACEHOLDER}
                className="flex-1"
              />
              <div className="flex justify-end mt-8">
                <Button
                  onClick={onClose}
                  disabled={disabled}
                  className="focus:outline-none vp-text-link text-vartana-blue-60 px-4 py-2"
                  element={cancelLabel}
                />
                <Button
                  className={`focus:outline-none vp-btn-text text-white w-28 rounded py-4 ${
                    disabled ? 'bg-vartana-gray-40' : 'bg-vartana-blue-60'
                  }`}
                  disabled={disabled}
                  type="submit"
                  element={
                    loading ? (
                      <Loader containerClassName="flex justify-center" className="p-3" />
                    ) : (
                      confirmLabel
                    )
                  }
                />
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
    </Modal>
  );
}

CloseConfirmation.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

CloseConfirmation.defaultProps = {
  open: false,
  onConfirm: () => {},
  onClose: () => {},
  title: '',
  message: '',
  confirmLabel: 'Okay',
  cancelLabel: 'Cancel',
  disabled: false,
  loading: false,
};

export default CloseConfirmation;
