import PropTypes from 'prop-types';

import { Typography } from '@vartanainc/design-system';
import DangerousTooltip from '../../../components/DangerousTooltip';
import { LockedCheck } from '../../../components/FormCheckboxV1/LockedCheck';

export function PermissionSet({ group, actions, title, tooltip }) {
  if (actions.length === 0) return null;

  return (
    <div id={`permission-set-${group}`} className="grid grid-cols-2 py-6">
      <header className="flex gap-2 items-center h-fit">
        <Typography variant="heading16" bold>
          {group}
        </Typography>
        {tooltip && <DangerousTooltip defaultIconClassName="w-5" content={tooltip} />}
      </header>

      <main className="flex flex-col gap-4">
        <span className="card-categories items-center">{title}</span>
        {actions.map((action) => (
          <div key={`${group}-${action.text}`} className="flex gap-2 items-center">
            <LockedCheck isChecked={action.enabled} />
            <Typography variant="paragraph14">{action.text}</Typography>
          </div>
        ))}
      </main>
    </div>
  );
}

PermissionSet.propTypes = {
  group: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      enabled: PropTypes.bool,
      text: PropTypes.string,
    })
  ).isRequired,
  title: PropTypes.string,
  tooltip: PropTypes.node,
};

PermissionSet.defaultProps = {
  title: '',
  tooltip: null,
};
