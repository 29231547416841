import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import vartanaLogo from '../../assets/vartana_logo.svg';
import Button from '../../components/Button';
import { setVendor } from '../setVendor';
import TextInputField from '../../designSystem/TextInput/TextInputField';

const TokenSchema = yup.object().shape({
  apiKey: yup.string().required('This field is required'),
  hubId: yup.string().required('This field is required'),
});

export default function Token() {
  const [error, setError] = useState('');
  const location = useLocation();

  const useQueryParams = new URLSearchParams(location.search);
  const hubId = useQueryParams.get('hub_id');

  function handleSubmit(values) {
    setVendor(values)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          window.location.href = result.url;
        } else {
          setError(result.error);
        }
      });
  }
  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      <img className="my-8" alt="powered-by-vartana" src={vartanaLogo} />
      <div className="bg-white flex flex-col w-1/2 border-solid rounded border border-grey-1 p-7">
        <div className="sm:justify-start sm:justify-left flex flex-row  flex-1 pt-2">
          <div className="flex-1 items-center justify-center">
            <h5 className="error-title font-bold text-center">
              Please Enter your API Key!
            </h5>
          </div>
        </div>
        <div className="error-msg mt-2">
          <div className="mt-8">
            <div className="mt-6">
              <Formik
                initialValues={{
                  apiKey: '',
                  hubId,
                }}
                validationSchema={TokenSchema}
                onSubmit={(values, actions) => {
                  handleSubmit(values, actions);
                }}
              >
                {({ isValid, dirty }) => {
                  const submitDisabled = !(isValid && dirty);

                  return (
                    <Form className="space-y-6">
                      <fieldset>
                        <div className="space-y-4">
                          <TextInputField name="apiKey" label="API Key" type="password" />
                          <TextInputField name="hubId" label="Hub ID" type="text" />
                        </div>
                      </fieldset>

                      {error !== '' ? (
                        <div className="flex items-center justify-between">
                          <div className="text-sm">
                            <p className="font-medium text-red-600 hover:text-red-500">
                              {error}
                            </p>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="flex flex-row justify-center mt-6">
                        <Button
                          type="submit"
                          disabled={submitDisabled}
                          element={(
                            <p
                              className={`sf-button-text text-white ${
                                submitDisabled ? 'bg-sf-vartana-gray-40' : 'bg-sf-blue'
                              } px-24 py-2 rounded`}
                            >
                              Connect
                            </p>
                          )}
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-8">
        Can’t find your API key? Reach out to us at{' '}
        <a className="text-sf-blue" href="mailto:support@vartana.com">
          support@vartana.com
        </a>
        .
      </div>
    </div>
  );
}
