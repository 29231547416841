import { useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { get } from 'lodash';
import { sessionVar } from '../../graphql/cache';
import { GET_RESELLERS } from '../../graphql/queries/resellers';

/**
 *  Returns a list of
 *  - resellers {String[]} - List of reseller company names
 *  - loading {Boolean} - Is true while reseller data is being fetched
 *
 * @returns {[string[], boolean]}
 */
function useResellers() {
  const [resellerNames, setResellerNames] = useState([]);
  const sessionData = useReactiveVar(sessionVar);
  const currentCompany = get(sessionData, 'session.user.company', {});

  const { loading: resellersLoading } = useQuery(GET_RESELLERS, {
    onCompleted: (resellersData) => {
      const resellers = get(resellersData, 'resellers', []);
      setResellerNames(
        resellers
          .filter((reseller) => reseller.number !== currentCompany.number)
          .map((reseller) => ({
            label: reseller.name,
            value: reseller.name,
            number: reseller.number,
          }))
      );
    },
  });

  return [resellerNames, resellersLoading];
}

export default useResellers;
