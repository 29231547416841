import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import { InputFieldMoneyFormat } from '@vartanainc/design-system';

function MoneyInputField({
  name,
  label,
  placeholder,
  prefix,
  suffix,
  minAllowedValue,
  maxAllowedValue,
  showFieldError,
  applySetValueDebounce = false,
  ...rest
}) {
  const [field, meta, { setValue }] = useField(name);
  const [fieldValue, setFieldValue] = useState(field.value);

  // debouncing the value with timeout
  useEffect(() => {
    const timeout = setTimeout(() => setValue(fieldValue), 500);

    return () => clearTimeout(timeout);
  }, [setValue, fieldValue]);

  return (
    <InputFieldMoneyFormat
      {...field}
      {...rest}
      value={field.value}
      placeholder={placeholder}
      customPrefix={prefix}
      customSuffix={suffix}
      fullWidth
      type="input"
      label={label}
      minAllowedValue={minAllowedValue}
      maxAllowedValue={maxAllowedValue}
      showError={showFieldError || (meta.touched && meta.error)}
      helperText={meta.error}
      onChange={(valuesObj) => {
        if (applySetValueDebounce) {
          setFieldValue(valuesObj.floatValue || valuesObj.value);
          return;
        }
        setValue(valuesObj.floatValue || valuesObj.value);
      }}
    />
  );
}

MoneyInputField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  minAllowedValue: PropTypes.number,
  maxAllowedValue: PropTypes.number,
  showFieldError: PropTypes.bool,
  applySetValueDebounce: PropTypes.bool,
};

MoneyInputField.defaultProps = {
  name: '',
  label: '',
  placeholder: '',
  // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
  prefix: '$',
  suffix: '',
  minAllowedValue: undefined,
  maxAllowedValue: undefined,
  showFieldError: false,
  applySetValueDebounce: false,
};

export default MoneyInputField;
