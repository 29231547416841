import { gql } from '@apollo/client';

export const REQUEST_PAYMENT_METHOD_INVOICE = gql`
  mutation requestPaymentMethodInvoice($number: String!) {
    requestPaymentMethodInvoice(number: $number) {
      id
      number
      name
    }
  }
`;

export const GENERATE_ORDERS_CSV = gql`
  mutation generateCsvOrders(
    $number: String
    $company: String
    $vendorName: String
    $status: String
    $decision: String
  ) {
    generateCsvOrders(
      number: $number
      company: $company
      vendorName: $vendorName
      status: $status
      decision: $decision
    ) {
      id
    }
  }
`;

export const GENERATE_CUSTOMERS_CSV = gql`
  mutation generateCsvCustomers(
    $name: String
    $vendorName: String
    $status: String
    $loanDecision: String
    $companyNumber: String
    $creditCondition: String
    $decisionStartDate: ISO8601Date
    $decisionEndDate: ISO8601Date
    $vaildUntilStartDate: ISO8601Date
    $validUntilEndDate: ISO8601Date
    $creditStatus: String
  ) {
    generateCsvCustomers(
      name: $name
      vendorName: $vendorName
      status: $status
      loanDecision: $loanDecision
      companyNumber: $companyNumber
      creditCondition: $creditCondition
      decisionStartDate: $decisionStartDate
      decisionEndDate: $decisionEndDate
      vaildUntilStartDate: $vaildUntilStartDate
      validUntilEndDate: $validUntilEndDate
      creditStatus: $creditStatus
    ) {
      name
    }
  }
`;

export const UPDATE_NOTIFICATION_PREFS = gql`
  mutation updateNotificationPrefs(
    $updatedPreferences: [InputNotificationPreference!]!
    $roleId: Int!
  ) {
    updateNotificationPreferences(
      updatedPreferences: $updatedPreferences
      roleId: $roleId
    )
  }
`;

export const UPSERT_FINANCE_USER_DETAILS = gql`
  mutation upsertFinanceUser(
    $id: ID
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String
  ) {
    upsertFinanceUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
    ) {
      id
    }
  }
`;

export const UPDATE_PROFILE_DETAILS = gql`
  mutation updateProfileDetails(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String
    $title: String
    $phone: String
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      title: $title
      phone: $phone
    ) {
      id
      user {
        id
        firstName
        lastName
        email
        phone
        title
      }
    }
  }
`;

export const UPDATE_VENDOR = gql`
  mutation updateVendor(
    $name: String
    $legalName: String
    $street: String
    $suite: String
    $city: String
    $state: String
    $zip: String
    $country: String
  ) {
    updateVendor(
      name: $name
      legalName: $legalName
      street: $street
      suite: $suite
      city: $city
      state: $state
      zip: $zip
      country: $country
    ) {
      id
    }
  }
`;

export const INVITE_RESELLER = gql`
  mutation inviteReseller(
    $customerNumber: String!
    $reseller: InputReseller!
    $message: String
  ) {
    inviteReseller(
      customerNumber: $customerNumber
      reseller: $reseller
      message: $message
    ) {
      number
    }
  }
`;

export const CREATE_AMENDMENT_REQUEST = gql`
  mutation createAmendmentRequest($number: String!) {
    createAmendmentRequest(number: $number) {
      number
      canRequestBuyout
    }
  }
`;

export const UPSERT_PAYMENT_METHOD = gql`
  mutation upsertPaymentMethod(
    $id: ID
    $bank: String
    $paymentMode: String!
    $accountName: String
    $accountType: String
    $routingNumber: String
    $accountNumber: String
    $invoiceType: String
    $validateOnly: Boolean
    $institutionNumber: String
    $transitNumber: String
    $contactName: String
  ) {
    upsertPaymentMethod(
      id: $id
      bank: $bank
      paymentMode: $paymentMode
      accountName: $accountName
      accountType: $accountType
      routingNumber: $routingNumber
      accountNumber: $accountNumber
      invoiceType: $invoiceType
      validateOnly: $validateOnly
      institutionNumber: $institutionNumber
      transitNumber: $transitNumber
      contactName: $contactName
    ) {
      user {
        company {
          defaultPaymentMethod {
            id
            paymentMode
            invoiceType
          }
        }
      }
    }
  }
`;

export const INVITE_USERS = gql`
  mutation inviteUsers($emails: [String!]!) {
    inviteUsers(emails: $emails)
  }
`;
