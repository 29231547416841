import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';

import { ButtonV2, Card } from '@vartanainc/design-system';
import { showErrorToast, showToast } from '../../utils/helpers';
import { ReactComponent as UserBadgeIcon } from '../../assets/user_badge.svg';
import AutoLoad from '../../components/AutoLoad';
import { CREATE_USER, UPDATE_USER } from '../../graphql/queries/user';
import { GET_ROLES } from '../../graphql/queries/roles';
import TextInputField from '../../designSystem/TextInput/TextInputField';
import PatternInput from '../../designSystem/PatternInput/PatternInput';
import CustomDropdown from '../../designSystem/FormikDropdownInput/FormikDropdownInput';
import CancelConfirmationModal from '../../components/Modals/CancelConfirmation';
import { commonRegex, FIELD_INVALID_MESSAGES } from '../../constants/common.constants';

// Todo: Update scheme when integrating API.
const ProfileDetailsFormSchema = yup.object().shape({
  number: yup.string().nullable(),
  firstName: yup
    .string()
    .nullable()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required')
    .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.firstName),
  lastName: yup
    .string()
    .nullable()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required')
    .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.lastName),
  email: yup
    .string()
    .nullable()
    .typeError('Invalid email')
    .email('Invalid email')
    .required('This field is required'),
  title: yup.string().nullable(),
  role: yup.string().nullable().required('This field is required'),
  phone: yup.string().nullable(),
});

export const initialProfileFormValues = {
  number: '',
  firstName: '',
  lastName: '',
  title: '',
  email: '',
  phone: '',
  role: '',
};

function UpsertUser({ mode, onClose, selectedUser }) {
  const navigate = useNavigate();

  const [roles, setRoles] = useState([]);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState(initialProfileFormValues);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [upsertUser] = useMutation(mode === 'create' ? CREATE_USER : UPDATE_USER);
  const { loading: rolesLoading } = useQuery(GET_ROLES, {
    onCompleted: (response) =>
      setRoles(
        get(response, 'roles', []).map((role) => ({
          value: role.id,
          label: role.name,
        }))
      ),
  });

  useEffect(() => {
    if (Object.keys(selectedUser).length) {
      setInitialFormValues({
        ...initialProfileFormValues,
        ...selectedUser,
        role: get(selectedUser, 'userRole.id') || '',
      });
    }
  }, [selectedUser]);

  const handleSubmit = (values, { setErrors }) => {
    setIsFormSubmitting(true);
    upsertUser({
      variables: {
        ...values,
        roleId: +values.role,
        id: +values.id,
        phone: values.phone === '' ? null : values.phone, // with the new PhoneInput component we will only have empty values
      },
    })
      .then((response) => {
        const errors = get(response, 'errors', {});
        if (Object.keys(errors).length) {
          setErrors(errors);
        } else {
          const successToastMsg =
            mode === 'create'
              ? `${values.firstName} ${values.lastName} is now a user on your team. An email is sent to help them activate and setup their account`
              : 'User details are updated successfully.';
          showToast('success', successToastMsg);
          if (mode === 'create') {
            navigate('/dashboard/settings/users');
          } else {
            onClose({
              ...values,
              userRole: {
                id: values.role,
                name: roles.find((role) => role.value === values.role).label,
              },
            });
          }
        }
      })
      .catch(() => {
        showErrorToast();
      })
      .finally(() => setIsFormSubmitting(false));
  };

  const handleCancel = () => {
    if (mode === 'create') {
      navigate('/dashboard/settings/users');
    } else {
      onClose();
    }
  };

  return (
    <div className={mode === 'create' ? 'p-6' : ''}>
      <Formik
        initialValues={initialFormValues}
        enableReinitialize
        validationSchema={ProfileDetailsFormSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid, dirty }) => (
          <Form>
            <Card
              tabindex={0}
              variant="fullWidth"
              title={mode === 'create' ? 'Add a new user' : 'Edit user details'}
              icon={<UserBadgeIcon />}
              content={(
                <>
                  <CancelConfirmationModal
                    open={openConfirmationModal}
                    onClose={() => setOpenConfirmationModal(false)}
                    onConfirm={() => handleCancel()}
                  />
                  <div className="grid grid-cols-2 gap-4">
                    <TextInputField name="firstName" label="First Name" />
                    <TextInputField name="lastName" label="Last Name" />
                    <TextInputField
                      name="email"
                      label="Email / User ID"
                      disabled={mode === 'edit'}
                    />
                    <PatternInput name="phone" label="Phone" mask="_" />
                    <TextInputField name="title" label="Job Title" />
                    <CustomDropdown
                      name="role"
                      label="Role"
                      options={roles}
                      isLoading={rolesLoading}
                      placeholder="Select role"
                    />
                  </div>
                  <div className="flex flex-row justify-between mt-8">
                    <ButtonV2
                      variant={{ type: 'ghost', typography: 'paragraph14' }}
                      type="button"
                      text="Cancel"
                      iconLeft="chevron_left"
                      onClick={() => {
                        if (dirty) {
                          setOpenConfirmationModal(true);
                        } else handleCancel();
                      }}
                    />
                    <AutoLoad loading={isFormSubmitting}>
                      <ButtonV2
                        type="submit"
                        disabled={!isValid || !dirty}
                        text="Submit"
                        variant={{ type: 'primary', typography: 'paragraph14' }}
                      />
                    </AutoLoad>
                  </div>
                </>
              )}
            >
            </Card>
          </Form>
        )}
      </Formik>
    </div>
  );
}

UpsertUser.propTypes = {
  onClose: PropTypes.func,
  mode: PropTypes.oneOf(['edit', 'create']),
  selectedUser: PropTypes.shape({}),
};

UpsertUser.defaultProps = {
  onClose: () => {},
  mode: 'create',
  selectedUser: {},
};

export default UpsertUser;
