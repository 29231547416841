import { createContext } from 'react';

export const PaymentInfoContext = createContext({
  stepDetails: {
    step: null,
    progress: null,
    isComplete: false,
    inActive: true,
    needFundingInvoice: false,
  },
});
