// module imports
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
// local imports
import CustomDropdown from '../../designSystem/FormikDropdownInput/FormikDropdownInput';
import { ADD_CUSTOMER_OPTION_LABEL, ADD_CUSTOMER_OPTION_VALUE } from './order.constants';

function SelectCustomer({
  companies,
  onInputChange,
  handleSelectCustomer,
  handleSelectNewCustomer,
  isLoadingCustomers,
  name,
  isDisabled,
  autoFocus,
  openMenuOnFocus,
  isCreateAndSelect,
  onBlur = () => {},
}) {
  const selectCustomerPlaceholder = 'Add or select a customer to begin';
  // calculated to show 10 customers and a create new customer option
  // 37(option height) * 10 + 46(add customer height) = 416
  const selectCustomerMaxDropdownHeight = 416;
  const addCustomerOption = useMemo(() => {
    return {
      id: companies.length,
      value: ADD_CUSTOMER_OPTION_VALUE,
      label: ADD_CUSTOMER_OPTION_LABEL,
    };
  }, [companies.length]);

  const customerOptions = useMemo(() => {
    const options = companies.map((company) => ({
      id: company.id,
      value: company.number,
      label: company.name,
    }));
    return options;
  }, [companies]);

  // Function to handle the selection of an option from the dropdown
  const handleOnInputSelect = (selectedOption, inputValue) => {
    // Check if it's a new customer flow
    const isNewCustomerFlow =
      (isCreateAndSelect && selectedOption.value === ADD_CUSTOMER_OPTION_VALUE) ||
      !selectedOption.id;

    // If it's a new customer flow, call the handleSelectNewCustomer function
    if (isNewCustomerFlow) {
      handleSelectNewCustomer(inputValue);
    } else {
      // Find the selected customer from the companies array
      const selectedCustomer = companies.find(
        (c) => c.number === get(selectedOption, 'value', '')
      );

      // If a selected customer is found, call the handleSelectCustomer function
      if (selectedCustomer) {
        handleSelectCustomer(selectedCustomer);
      }
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div>
        <CustomDropdown
          id={name}
          name={name}
          placeholder={selectCustomerPlaceholder}
          options={customerOptions}
          onChange={handleOnInputSelect}
          disabled={isDisabled}
          onInputChange={onInputChange}
          isLoading={isLoadingCustomers}
          autoFocus={autoFocus}
          openMenuOnFocus={openMenuOnFocus}
          maxMenuHeight={selectCustomerMaxDropdownHeight}
          isCreateAndSelect={isCreateAndSelect}
          createOption={addCustomerOption}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
}

SelectCustomer.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ).isRequired,
  handleSelectCustomer: PropTypes.func,
  handleSelectNewCustomer: PropTypes.func,
  onInputChange: PropTypes.func,
  isLoadingCustomers: PropTypes.bool,
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  openMenuOnFocus: PropTypes.bool,
  autoFocus: PropTypes.bool,
  isCreateAndSelect: PropTypes.bool,
  onBlur: PropTypes.func,
};

SelectCustomer.defaultProps = {
  handleSelectCustomer: () => {},
  handleSelectNewCustomer: () => {},
  onInputChange: () => {},
  isLoadingCustomers: false,
  isDisabled: false,
  openMenuOnFocus: false,
  autoFocus: false,
  isCreateAndSelect: false,
  onBlur: () => {},
};

export default SelectCustomer;
