import { isEmpty, get } from 'lodash';
import { getAPIURL, reportError } from '../utils/helpers';

export async function getProposalInformation(proposalNumber) {
  const response = await fetch(
    `${getAPIURL()}/utils/proposal_information?app=vendor&proposal_number=${proposalNumber}`,
    {
      credentials: 'include',
    }
  );
  if (response.ok) return response.json();
  throw new Error(`[fetch Proposal Information]: ${await response.text()}`);
}

/**
 * Fetches available terms for a given company number asynchronously.
 *
 * @param {string} companyNumber The company number for which available terms are to be fetched.
 * @returns {Promise} A Promise that resolves to the JSON representation of available terms, or throws an error if the request fails.
 */
export async function availableTerms(companyNumber) {
  const response = await fetch(
    `${getAPIURL()}/utils/available-terms?company_number=${companyNumber}`,
    {
      credentials: 'include',
    }
  );
  if (response.ok) return response.json();
  throw new Error(`[Fetch Available Terms]: ${await response.text()}`);
}

export async function getAuthorisedSignerContacts(
  name,
  sellerNumber,
  companyNumber,
  crmAccountId
) {
  const company = companyNumber || null;
  try {
    const response = await fetch(
      `${getAPIURL()}/utils/auth-signer-search?seller_number=${sellerNumber}&company_number=${company}&name=${name}&crm_account_id=${crmAccountId}`,
      { method: 'GET', credentials: 'include' }
    );

    if (response.ok) {
      try {
        return await response.json();
      } catch (jsonError) {
        // Handling case where the response is not valid JSON
        return `[Fetch authorized signer contacts]: Invalid JSON response`;
      }
    } else {
      return `[Fetch authorized signer contacts]: HTTP error ${response.status}`;
    }
  } catch (error) {
    const errorMessage = `[Fetch authorized signer contacts]: ${error.message || error}`;
    reportError(errorMessage);
    return errorMessage;
  }
}

export async function getEntityTypes() {
  const response = await fetch(`${getAPIURL()}/utils/entity-types`, {
    credentials: 'include',
  });

  if (response.ok) return response.json().then((data) => data.entity_types);
  throw new Error(`[fetchEntityTypes]: ${await response.text()}`);
}

export async function handleSignIn(values) {
  const response = await fetch(`${getAPIURL()}/signin`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ...values,
      app: 'vendor-app',
    }),
    credentials: 'include',
  });
  const responseData = await response.json();

  if (!response.ok) {
    reportError(`SignIn failed: ${responseData}`);
  }

  return responseData;
}

export async function getCustomerCrmFileUrls(customerNumber, crmOpportunityId) {
  try {
    const response = await fetch(
      `${getAPIURL()}/utils/fetch-urls?company_number=${customerNumber}&crm_opportunity_id=${crmOpportunityId}`,
      { method: 'GET', credentials: 'include' }
    );

    if (response.ok) {
      return await response.json(); // Return the parsed JSON for a successful response
    }
    // Handle error responses
    const contentType = response.headers.get('Content-Type') || '';

    if (contentType.includes('application/json')) {
      // Attempt to parse JSON error message
      const errorJson = await response.json();
      return { error: errorJson.message, status: response.status };
    }
    // Fallback to plain text error message
    const errorText = await response.text();
    return { error: errorText, status: response.status };
  } catch (error) {
    reportError(
      `[Fetch file urls from crm for customer number: ${customerNumber}, opportunityId: ${crmOpportunityId}]: ${error.message}`
    );
    return { error: error.message };
  }
}

export async function getCrmDocumentsContent(customerNumber, docIds, crmOpportunityId) {
  try {
    const response = await fetch(
      `${getAPIURL()}/utils/fetch-documents-content?company_number=${customerNumber}&doc_ids=${docIds}&crm_opportunity_id=${crmOpportunityId}`,
      { method: 'GET', credentials: 'include' }
    );
    if (response.ok) return response.json();
  } catch (error) {
    const errorMessage = `[Fetch selected crm files content for customer number: ${customerNumber}, opportunityId: ${crmOpportunityId}]: ${await error}`;
    reportError(errorMessage);
    return errorMessage;
  }
  return null;
}

export async function handleGoogleSignIn() {
  return fetch(`${getAPIURL()}/sso/google`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      app: 'vendor-app',
    }),
    credentials: 'include',
  }).then((response) => response.json());
}

export async function handleMicrosoftSignIn() {
  return fetch(`${getAPIURL()}/sso/microsoft`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      app: 'vendor-app',
    }),
    credentials: 'include',
  }).then((response) => response.json());
}

export async function fetchUrl(path, init = {}, base = getAPIURL()) {
  const url = new URL(path, base);
  if (isEmpty(init)) {
    return fetch(url.toString())
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error(`Response failed for API ${url}: ${response.statusText}`);
      })
      .catch((error) => {
        throw new Error(`Fetch error: ${error.message}`);
      });
  }

  return fetch(url.toString(), init)
    .then((response) => {
      if (response.ok) return response.json();
      throw new Error(`Response failed for API ${url}: ${response.statusText}`);
    })
    .catch((error) => {
      throw new Error(`Fetch error: ${error.message}`);
    });
}

export const handleDocumentDownload = (docObj, number) => {
  const title = get(docObj, 'title');
  const doc = get(docObj, 'document');
  const linkSource = `data:application/pdf;base64,${doc}`;
  const downloadLink = document.createElement('a');
  const fileName = `${number}-${title}.pdf`;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const formattedTermDuration = (term) => {
  const differenceInMonthYears = parseFloat(term, 10).toFixed(1);
  const noOfMonths = differenceInMonthYears.split('.')[0];
  const noOfDays = differenceInMonthYears.split('.')[1];
  let formattedValue = `${noOfMonths} months`;
  if (noOfDays !== '0') {
    formattedValue += ` ${(30 / 10) * noOfDays} days`;
  }
  return formattedValue;
};

export const formatContactDetails = (contacts) => {
  if (!Array.isArray(contacts)) {
    return [];
  }

  return contacts.map((option) => {
    const { FirstName, LastName } = option || {};
    // if first name or last name is empty, then dont add that in the combined name
    const fullname = [FirstName, LastName].filter(Boolean).join(' ');

    return {
      label: fullname,
      value: fullname,
    };
  });
};
