import { ReactDatePicker } from '@vartanainc/design-system';
import { useField } from 'formik';
import { ReactElement } from 'react';
import { ReactComponent as CalenderIcon } from '../../assets/calender_month.svg';
import './PersonalGuarantorForm.scss';

interface DateOfBirthComponentProps {
  name: string;
  label: string;
  placeholder: string;
  onChange?: (value: unknown) => void;
  dateFormat?: string;
}

export function DateOfBirthComponent({
  name,
  label,
  placeholder,
  onChange,
  dateFormat = 'MM/DD/YYYY',
}: DateOfBirthComponentProps): ReactElement {
  const [field, meta, { setTouched, setValue }] = useField(name);

  return (
    <div className="w-full">
      <ReactDatePicker
        {...field}
        name={name}
        label={label}
        placeHolder={placeholder}
        selected={field.value}
        postFixIcon={<CalenderIcon />}
        helperText={meta.error}
        showError={!!(meta.touched && meta.error)}
        onChange={(e) => {
          if (onChange) onChange(e);
          setValue(e);
        }}
        onBlur={() => {
          setTouched(true);
        }}
        dateFormat={dateFormat}
      />
    </div>
  );
}
