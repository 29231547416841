import ReactModal from 'react-modal';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';

export function Modal({ isOpen, onClose, onRequestClose, className, children, ...rest }) {
  return (
    <ReactModal
      isOpen={isOpen}
      onClose={onClose}
      onRequestClose={onRequestClose}
      overlayClassName="flex justify-center items-center fixed inset-0 bg-vartana-gray-70/80"
      className={twMerge('w-full max-w-screen-sm bg-white rounded-lg', className)}
      {...rest}
    >
      {children}
    </ReactModal>
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onRequestClose: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Modal.defaultProps = {
  isOpen: true,
  onClose: () => {},
  onRequestClose: () => {},
  className: '',
};
