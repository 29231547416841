import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@vartanainc/design-system';
import SelectorButton from '../../components/SelectorButton/SelectorButton';
import { BUYER_TYPE } from '../../constants/common.constants';
import { OrderFormContext } from '../../context/OrderContext';
import TextAreaInput from '../../designSystem/TextAreaInput/TextAreaInput';

export function PayoutSection({ resellers, payoutRecipients }) {
  const { selectedCustomer } = useContext(OrderFormContext);
  const isDirectCustomer = selectedCustomer?.buyerRelationToVendor === BUYER_TYPE.DIRECT;

  const getPreselectedValues = () => {
    if (!isDirectCustomer) {
      return resellers
        .filter((reseller) => reseller.companyName)
        .map((reseller) => reseller.companyName);
    }
    if (payoutRecipients.length > 0) {
      const preSelectedValue = [];
      if (payoutRecipients.indexOf('vendor') !== -1) {
        preSelectedValue.push(selectedCustomer?.seller?.name);
      }
      preSelectedValue.push(
        ...resellers
          .filter((reseller) => payoutRecipients.indexOf(reseller.uuid) !== -1)
          .map((reseller) => reseller.companyName)
      );
      return preSelectedValue;
    }
    return [];
  };

  return (
    <div className="space-y-4">
      <Typography variant="heading18" bold>
        Payout specifications
      </Typography>
      <div className="flex flex-col gap-2">
        <Typography variant="paragraph14">Who will receive purchase order?</Typography>
        <div className="mb-2">
          <SelectorButton
            name="payoutRecipients"
            disabled={!isDirectCustomer}
            preSelectedValues={getPreselectedValues()}
            multiSelect
            options={[
              {
                label: selectedCustomer?.seller?.name,
                id: 'vendor',
              },
              ...resellers
                .filter((reseller) => reseller.companyName)
                .map((reseller) => ({
                  label: reseller.companyName,
                  id: reseller.uuid,
                })),
            ]}
          />
        </div>
      </div>
      <TextAreaInput
        name="payoutInformation"
        label="Purchase order information"
        disabled={!isDirectCustomer}
        placeholder={isDirectCustomer ? 'Please provide instructions on payout' : ''}
        tooltipElement={(
          <div className="flex">
            <Typography variant="paragraph14" className="text-left p-1.5">
              Please provide specifications <br /> on partner payouts
            </Typography>
          </div>
        )}
      />
    </div>
  );
}

PayoutSection.propTypes = {
  resellers: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      companyName: PropTypes.string.isRequired,
    })
  ).isRequired,
  payoutRecipients: PropTypes.arrayOf(PropTypes.string).isRequired,
};
