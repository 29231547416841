import { Typography } from '@vartanainc/design-system';
import { ReactElement } from 'react';

import { ReactComponent as ActiveStepIcon } from '../../../../assets/step_check_active.svg';
import { ReactComponent as CompletedStepIcon } from '../../../../assets/step_check_complete.svg';
import { ReactComponent as PendingStepIcon } from '../../../../assets/step_check_pending.svg';
import { ReactComponent as InActiveStepIcon } from '../../../../assets/step_red_alert.svg';
import { stepDetailsProps } from '../TrackStatus/TrackStatus';

export function MoreInfoStepTracker({
  stepDetails,
}: {
  stepDetails: stepDetailsProps[];
}): ReactElement {
  const renderIcon = (type: string): ReactElement => {
    let iconComponent = <PendingStepIcon />;
    if (type === 'active') {
      iconComponent = <ActiveStepIcon />;
    } else if (type === 'completed') {
      iconComponent = <CompletedStepIcon />;
    } else if (type === 'inactive') {
      iconComponent = <InActiveStepIcon />;
    }
    return iconComponent;
  };

  return (
    <div className="relative pb-5 overflow-hidden step-tracker-container">
      <div className="more-info-time-line" />
      {stepDetails.map((step, index) => {
        const isCurrentStep = step.status === 'active' || step.status === 'inactive';
        return (
          <div className="flex flex-col gap-4">
            <div className={`flex items-center gap-2 ${index === 0 ? 'mt-5' : 'mt-6'}`}>
              <div className="self-start more-info-step-icon">
                {renderIcon(step.status)}
              </div>
              <div className="flex flex-col gap-2 items-start">
                <Typography
                  variant="paragraph14"
                  bold
                  className={isCurrentStep ? 'mt-[3px]' : ''}
                >
                  {step.name}
                </Typography>
                {isCurrentStep && (
                  <>
                    <Typography
                      variant="paragraph12"
                      color="color-black-100"
                      className="max-w-[11.5rem] text-left"
                    >
                      {step.text}
                    </Typography>
                    <Typography variant="paragraph12" color="color-gray-110">
                      {step.lastActiveAt}
                    </Typography>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
