/**
 * Custom hook to manage job titles.
 *
 * The useJobTitles hook is responsible for fetching job titles from an API or cache,
 * caching them in session storage for performance, and providing them to the component.
 * This hook ensures that job titles are only fetched when needed and re-fetched
 * only if necessary, using session storage for caching.
 *
 * @param fetchJobTitles - Function that fetches job titles from an API.
 * @returns An array of job titles.
 */
import { useState, useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { JOB_TITLES_KEY } from '../constants/common.constants';
import { reportError, fetchJobTitles } from '../utils/helpers';

// Define the type for job titles response

export function useJobTitles(): string[] {
  const [jobTitles, setJobTitles] = useState<string[]>([]);

  /**
   * Fetches job titles from the API or retrieves them from session storage.
   *
   * This function checks if job titles are already cached in session storage.
   * - If cached, it retrieves them and updates the state.
   * - If not cached, it fetches job titles from the provided `fetchJobTitles` function,
   *   updates the state, and caches the result in session storage.
   *
   * Wrapped in `useCallback` to memoize the function, ensuring it doesn't
   * get recreated on every render, which is more efficient.
   */
  const fetchAndCacheJobTitles = useCallback(async (): Promise<void> => {
    try {
      // Check if job titles are already in session storage
      const cachedTitles = sessionStorage.getItem(JOB_TITLES_KEY);
      if (cachedTitles) {
        // Parse and set job titles from cache if available
        setJobTitles(JSON.parse(cachedTitles));
      } else {
        // Parse and set job titles from cache if available
        const response = await fetchJobTitles();
        setJobTitles(response.titles);

        // Cache the fetched job titles in session storage
        sessionStorage.setItem(JOB_TITLES_KEY, JSON.stringify(response.titles));
      }
    } catch (e) {
      const errorMessage = get(e, 'message', '');
      reportError(errorMessage);
    }
  }, []);

  /**
   * useEffect hook to call `fetchAndCacheJobTitles` on the initial render.
   *
   * The `fetchAndCacheJobTitles` function will be called once when the component mounts,
   * or whenever its dependencies change (although this is unlikely due to useCallback memoization).
   */

  useEffect(() => {
    fetchAndCacheJobTitles();
  }, [fetchAndCacheJobTitles]);

  return jobTitles;
}
