import { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { FormikProvider, useFormik, Form } from 'formik';
import * as yup from 'yup';
import { Typography, Button } from '@vartanainc/design-system';

import { showErrorToast, showToast } from '../../utils/helpers';

import { SEND_RESET_PASSWORD_INSTRUCTIONS } from '../../graphql/queries/profile';
import { ReactComponent as LogoIcon } from '../../assets/logo.svg';
import loginBg from '../../assets/desktop-sign-in.svg';
import { ReactComponent as BackIcon } from '../../assets/back_icon_blue.svg';
import AutoLoad from '../../components/AutoLoad';
import TextInputField from '../../designSystem/TextInput/TextInputField';
import MarketingText from './MarketingText';

const ForgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email')
    .typeError('Invalid email')
    .required('This field is required'),
});

const ForgotPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [sendInstructions] = useMutation(SEND_RESET_PASSWORD_INSTRUCTIONS);
  const emailRef = useRef();

  const [formValues, setFormValues] = useState(null);
  const [formErrors, setFormErrors] = useState(null);
  const [serverTimer, setServerTimer] = useState(false);

  const onSubmit = async ({ email }) => {
    setTimeout(() => {
      if (serverTimer)
        showErrorToast('Something went wrong. Please try again in a few moments.');
    }, 3000);
    if (formErrors && formValues && (formErrors.email || formValues.email === '')) {
      emailRef.current.focus();
    } else {
      setServerTimer(true);
      try {
        await sendInstructions({ variables: { email } });
        setServerTimer(false);
        navigate(`/login/${location.search}`);
      } catch (error) {
        setServerTimer(false);
      } finally {
        if (!serverTimer) {
          showToast(
            'success',
            'Thank you! If your email is associated with an account, you will receive reset password instructions shortly.'
          );
        }
      }
    }
  };

  const handleResetClick = (values) => {
    if (formErrors && formValues && (formErrors.email || formValues.email === '')) {
      emailRef.current?.focus();
    } else {
      onSubmit(values);
    }
  };

  const formikBag = useFormik({
    initialValues: { email: location.state?.email || '' },
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values) => handleResetClick(values),
  });

  const { isSubmitting, handleSubmit } = formikBag;

  useEffect(() => {
    setFormValues(formikBag.values);
    setFormErrors(formikBag.errors);
  }, [formikBag.values, formikBag.errors]);

  return (
    <div className="min-h-screen bg-gray-50 flex">
      <img src={loginBg} alt="" className="absolute inset-0 h-full w-full object-cover" />
      <div className="w-full absolute flex justify-center lg:inset-0">
        <div className="px-8 py-8 flex flex-1 flex-col max-w-7xl gap-14 lg:py-16 lg:px-24 md:px-16">
          <LogoIcon className="h-auto w-40" />
          <div className="flex flex-col h-full justify-center gap-8 items-center lg:flex-row">
            <div className="flex grow-0 w-full justify-center">
              <MarketingText />
            </div>
            <div className="w-full bg-vartana-blue-100 rounded-b-lg rounded-t pt-1 max-w-lg h-auto grow-0 shadow-2xl lg:w-auto">
              <div className="w-full h-full bg-white rounded-b-lg ">
                <div className="flex justify-center p-10">
                  <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div className="mb-10">
                      <Typography variant="heading24" bold color="color-blue-180">
                        Reset account password
                      </Typography>
                      <Link
                        to={`/login${location.search}`}
                        className="flex mt-4 items-center gap-1"
                      >
                        <BackIcon />
                        <Typography variant="heading14" bold color="color-blue-120">
                          Back to sign-in
                        </Typography>
                      </Link>
                    </div>
                    <FormikProvider value={formikBag}>
                      <Form className="flex flex-col gap-4" onSubmit={handleSubmit}>
                        <TextInputField
                          id="email"
                          name="email"
                          type="email"
                          label="Work email"
                          autoComplete="off"
                          placeholder="example@email.com"
                          fullWidth
                          ref={emailRef}
                        />
                        <div className="flex justify-center mt-6 mb-4">
                          <AutoLoad loading={isSubmitting}>
                            <Button
                              type="submit"
                              size="x-small"
                              BackgroundColor="primary"
                            >
                              Reset password
                            </Button>
                          </AutoLoad>
                        </div>
                      </Form>
                    </FormikProvider>
                    <div className="flex justify-center gap-1">
                      <Typography variant="paragraph14" color="color-blue-180">
                        Need an account?
                      </Typography>
                      <Link
                        to={`/login/signup${location.search}`}
                        className="relative inline-flex font-medium vp-text-link text-vartana-blue-60 hover:text-indigo-500"
                      >
                        <Typography variant="heading14" bold>
                          Sign up
                        </Typography>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
