import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { IntercomProvider } from 'react-use-intercom';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from '@rollbar/react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import * as serviceWorker from './serviceWorker';
import history from './utils/browser_history';
import client from './graphql';
import RootComponent from './root';
import {
  widgetBaseUrls,
  launchDarklyDefaultContextKey,
} from './constants/common.constants';

import './index.scss';
import clientWidget from './graphql/indexWidget';
import { rollbarConfig } from './configs';

const isWidget = widgetBaseUrls.some((el) => history.location.pathname.includes(el));
(async () => {
  const clientSideID =
    process.env.REACT_APP_NODE_ENV === 'development'
      ? ''
      : process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID;
  const LDProvider = await asyncWithLDProvider({
    clientSideID,
    context: {
      kind: 'company',
      key: launchDarklyDefaultContextKey,
    },
    options: {
      streaming: true,
    },
  });

  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <BrowserRouter>
          <Provider config={rollbarConfig}>
            <ApolloProvider client={isWidget ? clientWidget : client}>
              <IntercomProvider appId={process.env.REACT_APP_INTERCOM_ID}>
                <RootComponent />
              </IntercomProvider>
            </ApolloProvider>
          </Provider>
        </BrowserRouter>
      </LDProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
