/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';

import DangerousTooltip from '../DangerousTooltip';
import { appVariants } from '../../constants/common.constants';

function FormLabel({
  name,
  label,
  tooltipElement,
  tooltipPosition,
  labelClassName,
  containerClassName,
  variant,
}) {
  if (label) {
    const labelFont =
      variant === appVariants.widget ? 'p-xsmall font-normal' : 'vp-field-label';

    return (
      <div className={`flex flex-row space-x-1 ${containerClassName}`}>
        <label className={`block ${labelFont} ${labelClassName}`} htmlFor={name}>
          {label}
        </label>
        {tooltipElement && (
          <DangerousTooltip
            content={tooltipElement}
            defaultIconClassName={variant === appVariants.widget ? 'w-3 h-3' : ''}
            position={tooltipPosition}
          />
        )}
      </div>
    );
  }
  return null;
}

FormLabel.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  tooltipElement: PropTypes.node,
  labelClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(appVariants)),
  tooltipPosition: PropTypes.string,
};

FormLabel.defaultProps = {
  tooltipElement: null,
  labelClassName: '',
  containerClassName: '',
  variant: appVariants.vendor,
  tooltipPosition: 'top',
};

export default FormLabel;
