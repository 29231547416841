import { isEmpty } from 'lodash';

export const plaidConnectionStates = {
  INIT: 'INIT',
  LOADING: 'LOADING',
  CONNECTED: 'CONNECTED',
  CANCELLED: 'CANCELLED',
  ERROR: 'ERROR',
};

export const paymentModes = {
  ach: 'ach',
  plaid: 'plaid',
  invoice: 'invoice',
  eft: 'eft',
};

export function isValidAccount(account) {
  const paymentMode = account?.paymentMode;
  if (!paymentMode) return false;
  if (paymentMode === paymentModes.invoice) return !isEmpty(account);

  if ([paymentModes.ach, paymentModes.plaid].includes(paymentMode)) {
    return account?.accountName && account?.accountType && account?.accountNumber;
  }
  return false;
}

export const maskAccountNumberString = (str) => `x${str.slice(-4)}`;
