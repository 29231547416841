import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';

function Wizard({ step, progress, isComplete, inActive, needFundingInvoice }) {
  const styleClass = useMemo(() => {
    let className = '';
    let color = '';

    if (isComplete) {
      className = 'finished';
      color = '#1FC198';
    } else if (inActive) {
      className = 'inactive';
      color = '#FF8A72';
    } else if (needFundingInvoice) {
      className = 'need-invoice';
      color = '#D79304';
    } else {
      className = 'complete';
      color = '#1B6DFF';
    }
    return {
      className,
      color,
    };
  }, [isComplete, inActive, needFundingInvoice]);

  function getProgress(stepValue, stepProgress) {
    switch (stepValue) {
    case 0:
      return 0;
    case 1:
      return stepProgress * 50;
    case 2:
      return 50 + stepProgress * 50;
    case 3:
      return 100;
    default:
      return 0;
    }
  }

  return (
    <ProgressBar
      percent={getProgress(step, progress)}
      filledBackground={styleClass.color}
      height={3}
    >
      <Step>
        {({ accomplished }) => (
          <div
            className={`indexed-step ${
              accomplished && getProgress(step, progress) > 0 ? styleClass.className : ''
            }`}
          >
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished }) => (
          <div
            className={`indexed-step ${
              accomplished && getProgress(step, progress) > 0 ? styleClass.className : ''
            }`}
          >
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished }) => (
          <div
            className={`indexed-step ${
              accomplished && getProgress(step, progress) > 0 ? styleClass.className : ''
            }`}
          >
          </div>
        )}
      </Step>
    </ProgressBar>
  );
}

Wizard.propTypes = {
  step: PropTypes.number,
  progress: PropTypes.number,
  isComplete: PropTypes.bool,
  inActive: PropTypes.bool,
  needFundingInvoice: PropTypes.bool,
};

Wizard.defaultProps = {
  step: 0,
  progress: 0,
  isComplete: false,
  inActive: false,
  needFundingInvoice: false,
};

export default Wizard;
