import { ReactElement, useEffect, useImperativeHandle } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Typography } from '@vartanainc/design-system';

import TextAreaInput from '../../../designSystem/TextAreaInput/TextAreaInput';
import MultiFileUpload from '../../../components/MultiFileUpload';
import { APPLICATION_MAX_UPLOAD_FILES } from '../../../constants/common.constants';

interface SalesQuoteProps {
  handleSubmit: () => void;
  formRef: React.RefObject<unknown>;
  setSubmissionDisabled: (isDisabled: boolean) => void;
}

export function SalesQuoteForm({
  handleSubmit,
  formRef,
  setSubmissionDisabled,
}: SalesQuoteProps): ReactElement {
  const formikBag = useFormik({
    initialValues: {
      salesQuote: [],
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const { values, isSubmitting } = formikBag;

  useEffect(() => {
    const isDisabled = values.salesQuote?.length === 0 || isSubmitting;
    setSubmissionDisabled(isDisabled);
  }, [values, isSubmitting, setSubmissionDisabled]);

  useImperativeHandle(formRef, () => formikBag);

  return (
    <FormikProvider value={formikBag}>
      <Typography variant="heading18" bold>
        Sales quote
      </Typography>
      <fieldset>
        <div className="mt-4 flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <Typography variant="paragraph14" color="color-black-100">
              Multiple quotes are accepted
            </Typography>

            <MultiFileUpload
              uploadText="Upload or drag and drop PDF file"
              name="salesQuote"
              acceptMultipleFiles
              maxFiles={APPLICATION_MAX_UPLOAD_FILES}
              hideOverflowScroll
            />
          </div>

          <div className="flex flex-row gap-4 more-info-text-area">
            <TextAreaInput
              id="notes"
              name="notes"
              label="Additional notes (optional)"
              placeholder="Add a note for the reviewer"
            />
          </div>
        </div>
      </fieldset>
    </FormikProvider>
  );
}
