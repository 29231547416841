import { gql } from '@apollo/client';

export const GET_ROLES = gql`
  query getRoles {
    roles {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const GET_ROLE_DETAIL = gql`
  query getRoleDetail($id: Int!) {
    role(id: $id) {
      id
      name
      rolePermissions
    }
  }
`;
