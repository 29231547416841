import { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { FormikErrors } from 'formik';
import { get, omit } from 'lodash';

import { VALIDATE_CUSTOMER } from '../graphql/queries/customer';

interface CustomerFormValuesType {
  name: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  maxUnits?: string;
  requestedAmount?: string;
  requestedTerm?: string;
  country: string;
  currency: string;
  crmAccountId: string;
  crmOpportunityId: string;
}

/**
 * Custom hook to validate customer form values before actually placing the mutation
 * @param customerValues The values to validate
 */
export const useValidateCustomer = (): [
  (
    customerValues: CustomerFormValuesType
  ) => Promise<FormikErrors<CustomerFormValuesType> | null>,
  boolean
] => {
  const [validateCustomer, { loading: isValidatingCustomer }] =
    useMutation(VALIDATE_CUSTOMER);

  const validateCustomerValues = useCallback(
    async (customerValues: CustomerFormValuesType) => {
      let hasErrors;
      let errors;
      try {
        // place validation call while omitting unwanted fields
        const responseData = await validateCustomer({
          variables: omit(customerValues, [
            'number',
            'contactNumber',
            'addressId',
            'docs',
          ]),
        });

        // check if the validation response has errors
        hasErrors = !!Object.keys(responseData.errors || {}).length;
        errors = get(responseData, 'errors', {});
      } catch (e) {
        reportError(`While validating customer: ${e}`);
      }

      // return null if no errors, errors object otherwise
      if (hasErrors) return errors as FormikErrors<CustomerFormValuesType>;
      return null;
    },
    [validateCustomer]
  );

  return useMemo(
    () => [validateCustomerValues, isValidatingCustomer],
    [validateCustomerValues, isValidatingCustomer]
  );
};
