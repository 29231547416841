import { v4 as uuidv4 } from 'uuid';

import { get } from 'lodash';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import AutoLoad from '../../../../components/AutoLoad';
import NeedMoreInfoModal from '../../../../components/Modals/NeedMoreInfo';
import { GET_COMPANY_BY } from '../../../../graphql/queries/offers';
import CreditAppraisalAndChangeRequestStatusModalV2 from '../../../../components/Modals/CreditAppraisalAndChangeRequestStatusModalV2';
import { getAppraisalStates } from '../../../../utils/appraisalUtils';

function MoreDetail() {
  const location = useLocation();
  const useQueryParams = new URLSearchParams(location.search);
  const customerNumber = useQueryParams.get('customerNumber');

  const { data: companyData, loading: companyLoading } = useQuery(GET_COMPANY_BY, {
    variables: { number: customerNumber },
    onError: (error) => {
      console.error('[GET_COMPANY_BY]', error);
    },
  });

  const { appraisalDeclined, applicationRequested, changeRequest } = getAppraisalStates(
    get(companyData, 'companyBy.creditCheck', null)
  );

  return (
    <AutoLoad loading={companyLoading}>
      {appraisalDeclined && (
        <CreditAppraisalAndChangeRequestStatusModalV2
          open
          standardReasons={get(
            companyData,
            'companyBy.creditCheck.declineReasonsWithStatus.standard_reasons',
            []
          ).map((reason) => ({ reason, key: uuidv4() }))}
          customReason={get(
            companyData,
            'companyBy.creditCheck.declineReasonsWithStatus.custom_reason',
            ''
          )}
          message={get(
            companyData,
            'companyBy.creditCheck.declineReasonsWithStatus.modal_message',
            null
          )}
          title={get(
            companyData,
            'companyBy.creditCheck.declineReasonsWithStatus.modal_title',
            null
          )}
          status={get(
            companyData,
            'companyBy.creditCheck.declineReasonsWithStatus.credit_appraisal_status',
            null
          )}
          onClose={() => window.close()}
        />
      )}
      {applicationRequested && (
        <NeedMoreInfoModal
          open
          title={get(
            companyData,
            'companyBy.creditCheck.currentStepDetails.modal_title',
            null
          )}
          onClose={window.close}
          message={get(
            companyData,
            'companyBy.creditCheck.currentStepDetails.step_details',
            null
          )}
        />
      )}
      {changeRequest && (
        <CreditAppraisalAndChangeRequestStatusModalV2
          open
          standardReasons={get(changeRequest, 'standard_reasons', []).map((reason) => ({
            reason,
            key: uuidv4(),
          }))}
          customReason={get(changeRequest, 'custom_reason', '')}
          message={get(changeRequest, 'modal_message', null)}
          title={get(changeRequest, 'modal_title', null)}
          status={get(changeRequest, 'change_request_status', null)}
          onClose={() => window.close()}
        />
      )}
    </AutoLoad>
  );
}

export default MoreDetail;
