import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState } from 'react';
import { Form, Formik } from 'formik';
import { Button, Typography } from '@vartanainc/design-system';
import { isEmpty } from 'lodash';
import { BackButton } from '../../../../components/Button/BackButton';
import Loader from '../../../../components/Loader';
import { CrossButton } from '../../../../components/Button/CrossButton';
import TextAreaInput from '../../../../designSystem/TextAreaInput/TextAreaInput';
import { ReactComponent as SendIcon } from '../../../../assets/arrow-blue.svg';
import { REVIEW_PURCHASE_ORDERS } from './queries';

const MODAL = {
  edit: 'Provide edit request details',
  success: 'Success modal once edit request is submitted',
};

const schema = Yup.object().shape({
  purchaseOrderEditRequest: Yup.string().required('This field is required'),
});

export function EditPurchaseOrdersForm({ orderNumber, onCancel, onSuccess }) {
  const [currentModal, setCurrentModal] = useState(MODAL.edit);

  const [reviewPurchaseOrders, { loading }] = useMutation(REVIEW_PURCHASE_ORDERS, {
    variables: { orderNumber, approved: false },
  });

  const handleSubmit = async ({ purchaseOrderEditRequest }, { setErrors }) => {
    const { errors } = await reviewPurchaseOrders({
      variables: { purchaseOrderEditRequest },
    });

    if (isEmpty(errors)) {
      setCurrentModal(MODAL.success);
    } else {
      setErrors(errors);
    }
  };

  return (
    <div className="bg-white w-1/2 rounded-xl">
      <div className="relative h-10 flex justify-end">
        <CrossButton onClick={onCancel} />
      </div>

      <div className="px-10 pb-10 flex flex-col gap-6">
        {currentModal === MODAL.edit && (
          <Formik
            initialValues={{ purchaseOrderEditRequest: '' }}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({ dirty, isValid }) => {
              const submitDisabled = !dirty || !isValid;

              return (
                <Form className="flex flex-col gap-6">
                  <header className="flex gap-1 items-center">
                    <SendIcon />
                    <Typography variant="heading20" bold>
                      Request to edit purchase orders
                    </Typography>
                  </header>
                  <TextAreaInput
                    name="purchaseOrderEditRequest"
                    label="Modifications to purchase orders"
                    placeholder="Tell us what needs to be updated in the purchase orders"
                  />
                  <footer className="flex justify-between">
                    <BackButton onClick={onCancel} btnText="Cancel" />
                    <Button
                      className="disabled:cursor-not-allowed"
                      disabled={submitDisabled || loading}
                      type="submit"
                    >
                      {loading ? <Loader className="w-8 h-8" /> : 'Send'}
                    </Button>
                  </footer>
                </Form>
              );
            }}
          </Formik>
        )}
        {currentModal === MODAL.success && (
          <div className="flex flex-col gap-6">
            <header className="flex gap-1 items-center">
              <SendIcon />
              <Typography variant="heading20" bold>
                Request submitted successfully
              </Typography>
            </header>
            <p>
              Thank you for reaching out to Vartana. A member of our review team will get
              in touch shortly.
            </p>
            <Button className="place-self-end py-40" size="large" onClick={onSuccess}>
              Got it, close window
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

EditPurchaseOrdersForm.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
