import PropTypes from 'prop-types';
import { useLocation, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as SomethingWentWrongIcon } from '../../assets/something-went-wrong.svg';
import { ReactComponent as NotFoundIcon } from '../../assets/not-found.svg';
import { ERROR_MESSAGE } from '../../constants/common.constants';

const styles = {
  title: {
    vendor: 'text-2xl',
    widget: 'card-subtitle-bold',
  },
  message: {
    vendor: 'text-base',
    widget: 'vp-p-small',
  },
};

export default function ErrorPage({ errorTitle, errorMessage, className }) {
  const [searchParams] = useSearchParams();
  const variant = searchParams.get('variant') || 'vendor';

  const { state } = useLocation();
  const errorTitleText = state?.errorTitle || errorTitle;
  const errorMessageText = state?.errorMsg || errorMessage;
  const selectedIcon = [ERROR_MESSAGE.pageNotFound].includes(errorTitleText) ? (
    <NotFoundIcon className="w-80 md:w-96 h-56 md:h-64" />
  ) : (
    <SomethingWentWrongIcon className="w-80 md:w-96 h-56 md:h-64" />
  );

  return (
    <div className={twMerge('flex flex-col w-screen h-screen', className)}>
      <div className="flex flex-col justify-center items-center bg-white h-full">
        {selectedIcon}
        <h5 className={`font-bold text-vartana-gray-140 pt-8 ${styles.title[variant]}`}>
          {errorTitleText}
        </h5>
        <div
          className={`mt-2 text-vartana-gray-140 text-center max-w-sm md:max-w-md ${styles.message[variant]}`}
        >
          {errorMessageText}
        </div>
      </div>
    </div>
  );
}

ErrorPage.propTypes = {
  errorTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  errorMessage: PropTypes.string,
  className: PropTypes.string,
};

ErrorPage.defaultProps = {
  errorTitle: ERROR_MESSAGE.somethingWentWrong,
  errorMessage: 'Please try again later.',
  className: '',
};
