/**
 * CurrencyPill.tsx
 *
 * This exports a pill that can be added to show the currency and flag.
 * It takes the currency as input and detects the flag using the getFlagByCurrency
 * in the helpers function file.
 *
 */

import { upperCase } from 'lodash';
import { Typography } from '@vartanainc/design-system';

import './CurrencyPill.scss';
import { getFlagByCurrency } from '../../utils/helpers';

interface CurrencyPillProps {
  compactPill?: boolean;
  currency: string;
}

export function CurrencyPill({ compactPill, currency }: CurrencyPillProps): JSX.Element {
  return (
    <p>
      <span
        className={`items-center gap-1 h-6 bg-vartana-steel-30 rounded-[2rem] ${
          compactPill ? 'compact-currency-pill' : 'default-currency-pill'
        }`}
      >
        <img
          alt="flag"
          src={getFlagByCurrency(currency)}
          className={`flex w-[15px] flex-col flag-icon ${
            compactPill ? 'h-[10px]' : 'h-[12px]'
          }`}
        />
        <Typography
          bold
          variant={compactPill ? 'paragraph10' : 'paragraph12'}
          color="color-steel-180"
          className={compactPill ? 'compact-flag-text' : 'default-flag-text'}
        >
          {upperCase(currency)}
        </Typography>
      </span>
    </p>
  );
}
