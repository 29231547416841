import { Typography } from '@vartanainc/design-system';
import './OutlinedPill.scss';

interface OutlinedPillProps {
  text: string;
  variant: 'primary' | 'secondary';
  containerClassName?: string;
}

function OutlinedPill({
  text,
  variant,
  containerClassName,
}: OutlinedPillProps): JSX.Element {
  const PILL_VARIANTS_TEXT_COLORS = {
    primary: 'color-blue-100',
    secondary: 'color-gray-120',
  };

  const PILL_VARIANTS_CLASSNAMES = {
    primary: 'rectangular-outlined-primary-pill',
    secondary: 'rectangular-outlined-secondary-pill',
  };

  const textColor = PILL_VARIANTS_TEXT_COLORS[variant];
  const className = PILL_VARIANTS_CLASSNAMES[variant];

  return (
    <div className={`pill-container ${className} ${containerClassName}`}>
      <Typography variant="paragraph10" bold color={textColor}>
        {text}
      </Typography>
    </div>
  );
}

export default OutlinedPill;
