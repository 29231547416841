import { gql } from '@apollo/client';

export const GET_VENDOR_INVOICES = gql`
  query getVendorInvoices($first: Int, $after: String, $number: String, $status: String) {
    vendorInvoices(first: $first, after: $after, number: $number, status: $status) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          number
          externalInvoiceNumber
          amount
          formattedAmount
          status
          statusTooltip
          createdAt
          order {
            number
            company {
              name
              number
            }
          }
          vendorInvoicePayments {
            id
            number
            createdAt
            updatedAt
            payment {
              id
              number
              formattedAmount
              createdAt
              updatedAt
              paymentMethod {
                accountType
                accountNumber
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ACCOUNT_BALANCE = gql`
  query getAccountBalance {
    seller {
      accountBalance
    }
  }
`;
