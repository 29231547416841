import { useContext } from 'react';
import { Typography, Card } from '@vartanainc/design-system';
import { get } from 'lodash';

import UploadDocumentModal from '../../../components/Modals/UploadDocumentModal';
import MilestoneItem from '../Summary/MilestoneItem';
import Wizard from '../TrackStatus/Updates/Wizard';
import { PaymentInfoContext } from './context';
import AutoLoad from '../../../components/AutoLoad';
import { ReactComponent as TitleIcon } from '../../../assets/calendar-icon.svg';
import { MILESTONES_STATES } from '../../../constants/order';
import {
  uploadVendorInvoiceTitle,
  MODAL_CONSTANTS,
  INVOICE_UPLOAD_TOAST_MESSAGE,
} from '../../../constants/common.constants';
import { PaymentInfoModal } from './PaymentInformation';
import { showToast } from '../../../utils/helpers';

interface OrderMeta {
  id: string;
  number: string;
  status: string;
  isLoading: boolean;
  updatedAt?: string;
}

interface MilestoneData {
  sequenceNumber: number;
  formattedPayoutAmount: string;
  formattedPayoutRate: string;
  status: string;
  number: string;
  enableInvoiceUpload: boolean;
  vendorMilestoneStatus: string;
  milestoneInvoice: {
    url: string;
  };
  isSpiff: boolean;
}

interface MilestonesFundingInvoiceProps {
  orderMeta: OrderMeta;
  milestonesData: MilestoneData[];
  showInvoiceModal: boolean;
  onInvoiceSubmit: () => void;
  toggleDocumentUploadModal: (value: boolean) => void;
  onInvoiceUploadButton: (milestoneNumber: string) => void;
  selectedMilestone: string;
  paymentModal: string;
  setPaymentModal: (value: string) => void;
  onSuccess: () => void;
}

const MilestonesFundingInvoice: React.FC<MilestonesFundingInvoiceProps> = ({
  orderMeta,
  milestonesData,
  showInvoiceModal,
  onInvoiceSubmit,
  toggleDocumentUploadModal,
  onInvoiceUploadButton,
  selectedMilestone,
  paymentModal,
  setPaymentModal,
  onSuccess,
}) => {
  const { stepDetails } = useContext(PaymentInfoContext);
  const showWizard = stepDetails.progress !== undefined;

  return (
    <>
      <UploadDocumentModal
        open={showInvoiceModal}
        onClose={() => toggleDocumentUploadModal(false)}
        title={uploadVendorInvoiceTitle}
        onSubmit={onInvoiceSubmit}
      />

      <PaymentInfoModal
        isOpen={paymentModal === MODAL_CONSTANTS.paymentInfo}
        onSuccess={() => {
          showToast(MODAL_CONSTANTS.success, INVOICE_UPLOAD_TOAST_MESSAGE);
          onSuccess();
        }}
        onClose={() => setPaymentModal('')}
        orderId={get(orderMeta, 'id', '')}
        milestoneNumber={selectedMilestone}
      />

      <AutoLoad loading={orderMeta?.isLoading}>
        <div className="flex flex-col align-center rounded-lg border-2 border-vartana-dark-mustard shadow">
          <Card
            variant="fullWidth"
            containerClassName="payout-panel-padding"
            content={(
              <>
                <div className="flex items-center gap-4">
                  {showWizard && (
                    <div className="py-4 px-10 w-7/12">
                      <Wizard {...stepDetails} />
                    </div>
                  )}
                  <div className="flex items-center gap-2 pb-2">
                    <TitleIcon />
                    <Typography color="color-black-100" variant="heading16" bold>
                      Payout milestones
                    </Typography>
                  </div>
                </div>
                <div className="overflow-x-auto">
                  <table className="min-w-full mt-4 border-none">
                    <thead>
                      <tr className="text-left">
                        <th>
                          <Typography color="color-gray-110" variant="paragraph12" bold>
                            Milestone
                          </Typography>
                        </th>
                        <th className="px-3">
                          <Typography color="color-gray-110" variant="paragraph12" bold>
                            Amount
                          </Typography>
                        </th>
                        <th className="text-center pr-3">
                          <Typography color="color-gray-110" variant="paragraph12" bold>
                            Milestone status
                          </Typography>
                        </th>
                        <th className="pr-3"></th>
                      </tr>
                    </thead>

                    <tbody>
                      {milestonesData.map((milestone) => (
                        <MilestoneItem
                          key={milestone.sequenceNumber}
                          onPrimaryClick={() => onInvoiceUploadButton(milestone.number)}
                          primaryButtonText="Upload invoice"
                          sequenceNumber={milestone.sequenceNumber}
                          payoutAmount={milestone.formattedPayoutAmount}
                          payoutPercentage={milestone.formattedPayoutRate}
                          status={milestone.vendorMilestoneStatus}
                          number={milestone.number}
                          enabledInvoice={milestone.enableInvoiceUpload}
                          showPrimaryButton={
                            milestone.vendorMilestoneStatus ===
                            MILESTONES_STATES.NEED_INVOICE
                          }
                          milestoneInvoiceUrl={milestone.milestoneInvoice.url}
                          isSpiff={milestone.isSpiff}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          />
        </div>
      </AutoLoad>
    </>
  );
};

export default MilestonesFundingInvoice;
