import { useIntercom } from 'react-use-intercom';
import { Button, Card, Typography } from '@vartanainc/design-system';
import PropTypes from 'prop-types';
import { Modal } from '../../components/Modals/Modal';
import { CrossButton } from '../../components/Button/CrossButton';
import { BackButton } from '../../components/Button/BackButton';
import { ReactComponent as WarningIcon } from '../../assets/warning.svg';

export function ResellerErrorModal({ onClose }) {
  const { show: showIntercom } = useIntercom();

  return (
    <Modal className="absolute top-1/4 w-fit max-w-screen-xl">
      <Card
        variant="fullWidth"
        content={(
          <>
            <CrossButton onClick={onClose} />
            <div className="flex flex-col gap-6">
              <header className="flex gap-2 items-center">
                <WarningIcon />
                <Typography variant="heading20">
                  Partners could not be verified
                </Typography>
              </header>

              <Typography variant="paragraph14">
                Try re-entering the partner&apos;s company name or contact email. If issue
                persists, <br />
                contact the Vartana help desk for support.
              </Typography>

              <footer className="flex justify-between items-center mt-2">
                <BackButton onClick={onClose} btnText="Cancel" />
                <Button onClick={showIntercom}>Get support</Button>
              </footer>
            </div>
          </>
        )}
      />
    </Modal>
  );
}

ResellerErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
