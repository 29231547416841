import * as yup from 'yup';
import {
  commonRegex,
  MIN_PASSWORD_LENGTH,
  PASSWORD_ERRORS,
} from '../constants/common.constants';

export const AddressValidator = {
  state: yup.string(),
  city: yup.string().max(50, 'Too Long!'),
  zip: yup.number(),
  street: yup.string().max(50, 'Too Long!'),
  suite: yup.string().max(50, 'Too Long!'),
};

// Validates that the password field meets specific criteria:
// - Required, minimum length of 8 characters
// - Contains at least one numeric digit
// - Contains at least one uppercase and one lowercase letter
export const passwordFieldValidation = yup
  .string()
  .required('This field is required')
  .test((value, { createError }) => {
    const errors = [];

    if (value?.length < MIN_PASSWORD_LENGTH) {
      errors.push(PASSWORD_ERRORS.lengthError);
    }
    if (!commonRegex.atLeastOneNumber.test(value)) {
      errors.push(PASSWORD_ERRORS.numberError);
    }
    if (
      !(
        commonRegex.atLeastOneSmallAlphabet.test(value) &&
        commonRegex.atLeastOneCapitalAlphabet.test(value)
      )
    ) {
      errors.push(PASSWORD_ERRORS.casingError);
    }

    if (errors.length === 0) {
      return true;
    }

    return createError({
      message: {
        errors,
      },
    });
  });
