import { getAPIURL } from '../utils/helpers';

export const fetchCookie = (token) => {
  let path = getAPIURL().replace('/crm', '');
  path = path.replace('/vendor', '');

  return fetch(`${path}/set-cookie`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      token,
      app: 'widget-app',
    }),
    credentials: 'include',
  });
};
