/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React, { useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { useField } from 'formik';

import FormLabel from '../FormLabel';
import FormFieldMsg from '../FormFieldMsg';
import { appVariants, currencyFormatter } from '../../constants/common.constants';

function isEncrypted(value = '') {
  return value?.includes('*');
}

function FormInputField({ mask, inputProps }) {
  if (mask && !isEncrypted(inputProps.value)) {
    return (
      <InputMask mask={mask} maskPlaceholder={inputProps.placeholder} {...inputProps} />
    );
  }
  return <input {...inputProps} />;
}

function Prefix({ symbol, className = '' }) {
  if (symbol)
    return <div className={`text-sm absolute pl-3 mt-0.5 ${className}`}>{symbol}</div>;
  return null;
}

function Suffix({ symbol, className = '' }) {
  if (symbol) return <div className={`text-sm mt-0.5 -ml-6 ${className}`}>{symbol}</div>;
  return null;
}

const isNumberKey = (evt) => {
  const evtCode = evt.code;
  const val = evt.target.value;
  if (evt.key === '.') {
    return val && val.indexOf('.') === -1;
  }
  const allowedChars = [
    'Backspace',
    'Delete',
    'Arrow',
    'Tab',
    'MetaLeft',
    'MetaRight',
    'ControlLeft',
    'ControlRight',
  ];
  const copyPasteKeys = ['a', 'c', 'v', 'x'];

  return (
    Number.isInteger(+evt.key) ||
    allowedChars.some((char) => evtCode.includes(char)) ||
    ((evt.metaKey || evt.ctrlKey) && copyPasteKeys.includes(evt.key))
  );
};

function FormInput({
  name,
  placeholder,
  label,
  type,
  customType,
  max,
  value,
  className,
  disabled,
  mask,
  prefix,
  suffix,
  disableFullstoryRecording,
  tooltipElement,
  tooltipPosition,
  onBlur,
  onChange,
  autoComplete,
  variant,
}) {
  const [field, meta, { setValue }] = useField(name);
  const [warningMsg, setWarningMsg] = useState('');

  const inputRef = useRef();

  const defaultClasses = useMemo(() => 'border border-solid rounded', []);
  const disabledClasses = useMemo(
    () => 'cursor-not-allowed bg-vartana-gray-20 text-vartana-gray-40',
    []
  );

  const styleClasses = useMemo(() => {
    const textFont = variant === appVariants.widget ? 'vp-p-small' : 'vp-field-text';
    const textColor = placeholder && meta.value === '' ? 'text-vartana-gray-40' : '';

    return {
      default: {
        borders:
          'border-vartana-gray-30 focus:outline-none focus:ring-0 focus:border-vartana-blue-60',
        text: `${textFont} placeholder-vartana-gray-40`,
        label: 'text-vartana-gray-60',
        prePostFix: `${textFont} ${textColor}`,
      },
      error: {
        borders:
          'border-vartana-bright-red focus:outline-none focus:ring-0 focus:border-current',
        text: `${textFont} text-vartana-dark-red placeholder-vartana-dark-red`,
        label: 'text-vartana-dark-red',
        prePostFix: `${textFont} text-vartana-dark-red`,
      },
      warning: {
        borders:
          'border-vartana-dark-mustard focus:outline-none focus:ring-0 focus:border-current',
        text: `${textFont} text-vartana-dark-mustard placeholder-vartana-dark-mustard`,
        label: 'text-vartana-dark-mustard',
        prePostFix: `${textFont} text-vartana-dark-mustard`,
      },
    };
  }, [meta, placeholder, variant]);

  const formStateClasses = useMemo(() => {
    let classes = styleClasses.default;
    if (meta.touched && meta.error) {
      classes = styleClasses.error;
    } else if (meta.touched && warningMsg) {
      classes = styleClasses.warning;
    }
    return classes;
  }, [styleClasses, meta, warningMsg]);

  const inputProps = {
    ...field,
    ref: inputRef,
    onPaste: (e) => {
      if (customType === 'money') {
        e.preventDefault();
        const pastedData = (e.clipboardData || window.clipboardData).getData('text');
        const extractedNumbersAndDecimals = pastedData.match(/\d|\./g);
        if (extractedNumbersAndDecimals && extractedNumbersAndDecimals.length) {
          const splittedNumbers = extractedNumbersAndDecimals.join('').split('.');
          setValue(`${splittedNumbers[0]}.${splittedNumbers.slice(1).join('')}`);
        } else {
          setValue('');
        }
      }
      return e;
    },
    type,
    name: field.name,
    id: field.name,
    value: value || field.value,
    placeholder,
    disabled,
    autoComplete,
    onKeyDown: (e) => {
      if (customType === 'money') {
        return isNumberKey(e) ? e : e.preventDefault();
      }
      return e;
    },
    onFocus: (e) => {
      if (customType === 'money') {
        setValue(e.target.value.replace(/,/g, ''));
        return e.target.select();
      }
      return e;
    },
    onChange: (e) => {
      if (customType === 'money') {
        setValue(e.target.value);
      } else if (customType === 'subsidy') {
        // Restricitng the subsidy field to XX.XX pattern
        setValue(
          e.target.value
            .toString()
            .split('.')
            .map((v) => v.split('').slice(0, 2).join(''))
            .join('.')
        );
      } else {
        setValue(e.target.value);
        onChange(e, { setValue, setWarningMsg });
      }
      if (max && e.target.value > max) {
        setValue(e.target.value.slice(0, -1));
      }
    },
    onBlur: (e) => {
      field.onBlur(e);
      if (customType === 'money') {
        const currentValue = e.target.value;
        setValue(
          currentValue
            ? // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
            currencyFormatter.format(+currentValue.replace(/,/g, '')).replace('$', '')
            : currentValue
        );
        onBlur(e);
      } else {
        onBlur(e, { setWarningMsg });
      }
    },
    onWheel: (e) => {
      if (e.target.type === 'number') {
        e.target.blur();

        // Prevent the page/container scrolling
        e.stopPropagation();

        // Refocus immediately
        setTimeout(() => {
          e.target.focus();
        }, 0);
      }
    },
    className: `
      py-2 w-full h-9 shadow-sm
      ${defaultClasses}
      ${formStateClasses.borders}
      ${formStateClasses.text}
      ${prefix ? 'pl-6' : 'pl-3'}
      ${suffix ? 'pr-6' : ''}
      ${disabled ? disabledClasses : ''}
      ${disableFullstoryRecording ? 'fs-mask' : ''}
    `,
  };

  return (
    <div className={`${className}`}>
      <FormLabel
        variant={variant}
        containerClassName="mb-1"
        labelClassName={`${formStateClasses.label}`}
        name={field.name}
        label={label}
        tooltipElement={tooltipElement}
        tooltipPosition={tooltipPosition}
      />
      <div className={`${prefix ? 'relative' : ''} flex items-center rounded-md`}>
        <Prefix className={formStateClasses.prePostFix} symbol={prefix} />
        <FormInputField mask={mask} inputProps={inputProps} />
        <Suffix className={formStateClasses.prePostFix} symbol={suffix} />
      </div>
      <FormFieldMsg
        show={meta.touched}
        msg={meta.error || warningMsg}
        className={`${formStateClasses.text} mt-2`}
      />
    </div>
  );
}

FormInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  max: PropTypes.number,
  customType: PropTypes.oneOf(['', 'money', 'subsidy']),
  value: PropTypes.string,
  disabled: PropTypes.bool,
  mask: PropTypes.string,
  className: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  disableFullstoryRecording: PropTypes.bool,
  tooltipElement: PropTypes.node,
  tooltipPosition: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  autoComplete: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(appVariants)),
};

FormInput.defaultProps = {
  name: '',
  placeholder: '',
  label: '',
  type: '',
  customType: '',
  value: '',
  disabled: false,
  mask: '',
  className: '',
  prefix: '',
  suffix: '',
  max: null,
  disableFullstoryRecording: false,
  tooltipElement: null,
  tooltipPosition: 'top',
  onBlur: () => '',
  onChange: () => {},
  autoComplete: 'off',
  variant: appVariants.vendor,
};

export default FormInput;
