import { gql } from '@apollo/client';

export const GET_VENDOR_PRODUCT = gql`
  query getVendorProduct {
    seller {
      demoVendor
      uploadVendorDocs
      product {
        id
        name
        number
        paymentOptions
        availableOrderTypes
        multipleCountriesEnabled
        enabledCurrencies
        enabledCountries
      }
    }
  }
`;

export const GET_CUSTOMER_VENDOR_PRODUCT = gql`
  query getCustomerVendorProduct($customerNumber: String!) {
    company(number: $customerNumber) {
      seller {
        uploadVendorDocs
        product {
          id
          name
          number
          paymentOptions
          availableOrderTypes
          multipleCountriesEnabled
          enabledCurrencies
          enabledCountries
        }
      }
    }
  }
`;
