import { ReactElement, useMemo, useState } from 'react';

import Button from './index';
import AutoLoad from '../AutoLoad';
import { ReactComponent as CheckIcon } from '../../assets/check_icon.svg';

interface LoadableButtonProps {
  btnClassName?: string;
  textClassName?: string;
  btnText: string;
  onClick: () => Promise<void | boolean>;
  isDisabled?: boolean;
  isPrimary?: boolean;
}

const BUTTON_RESET_DELAY = 700;
const LOAD_STATES = {
  DEFAULT: 'default',
  LOADING: 'loading',
  LOADED: 'loaded',
};

const LoadableButton = ({
  btnClassName = '',
  isDisabled = false,
  textClassName = '',
  btnText,
  onClick,
  isPrimary = false,
}: LoadableButtonProps): ReactElement => {
  const [loadingState, setLoadingState] = useState(LOAD_STATES.DEFAULT);

  // this function handles the button loading states based on the onClick function progress
  const handleButtonClick = async (): Promise<void> => {
    setLoadingState(LOAD_STATES.LOADING);
    const showSuccess = await onClick();

    if (showSuccess !== false) {
      setLoadingState(LOAD_STATES.LOADED);

      // reset the button state after a delay so that it can be clicked again
      setTimeout(() => {
        setLoadingState(LOAD_STATES.DEFAULT);
      }, BUTTON_RESET_DELAY);
    } else {
      setLoadingState(LOAD_STATES.DEFAULT);
    }
  };

  const buttonClassName = useMemo(() => {
    const commontButtonClasses = 'border-[1.5px] py-2.5 rounded h-[2.375rem]';
    if (!isPrimary) return btnClassName;
    if (loadingState === LOAD_STATES.DEFAULT)
      return `${commontButtonClasses} bg-vartana-blue-60 border-vartana-blue-120 br-2 hover:bg-[#0546b6] active:bg-[#21438a33] w-[6.188rem]`;
    if (loadingState !== LOAD_STATES.DEFAULT)
      return `${commontButtonClasses} bg-vartana-gray-40-v3 border-gray-40-v3  w-[7.938rem]`;

    return '';
  }, [isPrimary, loadingState, btnClassName]);

  const buttonLabelClassName = useMemo(() => {
    if (!isPrimary) return textClassName;
    if (loadingState === LOAD_STATES.DEFAULT) return 'text-white vartana-p-small-bold';
    if (loadingState !== LOAD_STATES.DEFAULT)
      return 'text-vartana-gray-50 vartana-p-small-bold';

    return '';
  }, [isPrimary, loadingState, textClassName]);

  return (
    <Button
      className={`endpoint-btn ${buttonClassName}`}
      type="submit"
      disabled={isDisabled || loadingState !== LOAD_STATES.DEFAULT}
      element={(
        <div className="flex justify-center gap-2">
          {loadingState === LOAD_STATES.LOADED && <CheckIcon />}
          {loadingState === LOAD_STATES.LOADING && (
            <AutoLoad loading className="w-4 h-4" />
          )}
          <p className={buttonLabelClassName}>{btnText}</p>
        </div>
      )}
      onClick={handleButtonClick}
    />
  );
};

export default LoadableButton;
