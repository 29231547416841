import { Typography } from '@vartanainc/design-system';
import { truncateText } from '../../utils/helpers';
import { MAX_FILE_NAME_LENGTH } from '../../constants/common.constants';
import { ReactComponent as FileIcon } from '../../assets/file.svg';
import { ReactComponent as FileIconDisabled } from '../../assets/file_disabled.svg';
import Checkbox from '../Checkbox/Checkbox';

interface FileItemProps {
  id: string;
  fileName: string;
  isChecked: boolean;
  isDisabled: boolean;
  onClick: () => void;
}

const FileItem: React.FC<FileItemProps> = ({
  id,
  fileName,
  isChecked,
  isDisabled,
  onClick,
}) => {
  const handleFileClick = (): void => {
    if (!isDisabled) {
      onClick();
    }
  };

  const buttonClassnames = `flex items-center justify-between w-full p-4 rounded-md border-[1px] ${
    isDisabled ? ' bg-vartana-gray-30' : ''
  }`;

  const truncatedFileName = truncateText(fileName, MAX_FILE_NAME_LENGTH);

  return (
    <button className={buttonClassnames} onClick={handleFileClick} disabled={isDisabled}>
      <div className="flex items-center gap-2">
        {isDisabled ? <FileIconDisabled /> : <FileIcon />}
        <Typography
          variant="paragraph14"
          color={isDisabled ? 'color-gray-110' : 'color-black-100'}
        >
          {truncatedFileName}
        </Typography>
      </div>
      <Checkbox checked={isChecked} disabled={isDisabled} onCheck={onClick} />
    </button>
  );
};

export default FileItem;
