import { useParams, Link, useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { useQuery } from '@apollo/client';
import { ButtonV2, Card, Typography } from '@vartanainc/design-system';
import AutoLoad from '../../../../components/AutoLoad';
import { GET_CONTACT_BY_NUMBER } from '../../../../graphql/queries/contact';
import { humanTimeFormat, formatCapital } from '../../../../utils/helpers';
import { HasPermission } from '../../../../components/HasPermission/HasPermission';
import { ReactComponent as ContactIcon } from '../../../../assets/contact-icon-blue.svg';

function Show() {
  const navigate = useNavigate();
  const { contactNumber, companyNumber } = useParams();

  const { data: contactDetails, loading: contactLoading } = useQuery(
    GET_CONTACT_BY_NUMBER,
    {
      variables: {
        number: contactNumber,
        companyNumber,
      },
    }
  );

  return (
    <AutoLoad
      loading={contactLoading}
      containerClassName="flex justify-center"
      className="absolute text-center top-2/4 transform-gpu -translate-y-2/4"
    >
      <div className="gap-x-4 h-full w-full bg-vartana-steel-20">
        <div className="pt-8 rounded-lg w-7/12 mx-auto small-card-width-range">
          <Card
            variant="fullWidth"
            tabindex="0"
            title={(
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center gap-1">
                  <ContactIcon />
                  <Typography variant="heading18" color="color-black-100">
                    Customer contact
                  </Typography>
                </div>
                {!get(contactDetails, 'user.attested', false) ? (
                  <HasPermission resource="customer" action="update">
                    <ButtonV2
                      onClick={() => navigate('edit')}
                      text="Edit"
                      variant={{ type: 'ghost', typography: 'paragraph12' }}
                    />
                  </HasPermission>
                ) : null}
              </div>
            )}
            content={(
              <>
                <div className="grid auto-rows-auto gap-y-4">
                  <div className="grid grid-cols-2 relative">
                    {/* row */}
                    <div className="grid auto-rows-min">
                      <p className="break-all vartana-p-small-bold text-vartana-gray-50">
                        First name
                      </p>
                      <p className="break-all vp-body vartana-black">
                        {formatCapital(get(contactDetails, 'user.firstName', '-'))}
                      </p>
                    </div>
                    <div className="grid auto-rows-min">
                      <p className="break-all vartana-p-small-bold text-vartana-gray-50">
                        Last name
                      </p>
                      <p className="break-all vp-body vartana-black">
                        {formatCapital(get(contactDetails, 'user.lastName', '-'))}
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-cols-2">
                    {/* row */}
                    <div className="grid auto-rows-min">
                      <p className="break-all vartana-p-small-bold text-vartana-gray-50">
                        Email
                      </p>
                      {contactDetails?.user?.email ? (
                        <p className="break-all vp-body vartana-black">
                          {get(contactDetails, 'user.email', '-')}
                        </p>
                      ) : (
                        <Link
                          to={`/dashboard/customers/${companyNumber}/contacts/${contactNumber}/edit`}
                        >
                          <p className="break-all vp-text-link-bold  text-vartana-blue-60">
                            Add email
                          </p>
                        </Link>
                      )}
                    </div>
                    <div className="grid auto-rows-min">
                      <p className="break-all vartana-p-small-bold text-vartana-gray-50">
                        Phone
                      </p>
                      {contactDetails?.user?.phone ? (
                        <p className="break-all vp-body vartana-black">
                          {get(contactDetails, 'user.formattedPhone', '-')}
                        </p>
                      ) : (
                        <Link
                          to={`/dashboard/customers/${companyNumber}/contacts/${contactNumber}/edit`}
                        >
                          <p className="break-all vp-text-link-bold  text-vartana-blue-60">
                            Add number
                          </p>
                        </Link>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-2">
                    {/* row */}
                    <div className="grid auto-rows-min">
                      <p className="break-all vartana-p-small-bold text-vartana-gray-50">
                        Primary contact
                      </p>
                      <p className="break-all vp-body vartana-black">
                        {get(contactDetails, 'user.primary', false) ? 'Yes' : 'No'}
                      </p>
                    </div>
                    <div className="grid auto-rows-min">
                      <p className="break-all vartana-p-small-bold text-vartana-gray-50">
                        Receive invoice
                      </p>
                      <p className="break-all vp-body vartana-black">
                        {get(
                          contactDetails,
                          'user.emailPreferences.receive_invoice',
                          false
                        )
                          ? 'Yes'
                          : 'No'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid auto-rows-auto gap-y-4 pt-4">
                  <div className="grid grid-cols-2">
                    <div className="grid auto-rows-min">
                      <Typography variant="paragraph12" bold color="color-gray-110">
                        Contact ID
                      </Typography>
                      <Typography
                        variant="paragraph12"
                        color="color-black-100"
                        className="break-all"
                      >
                        {get(contactDetails, 'user.number', '-')}
                      </Typography>
                    </div>
                    {/* row */}
                    <div className="grid auto-rows-min">
                      <Typography variant="paragraph12" bold color="color-gray-110">
                        Created on
                      </Typography>
                      <Typography
                        variant="paragraph12"
                        color="color-black-100"
                        className="break-all"
                      >
                        {humanTimeFormat(get(contactDetails, 'user.createdAt', ''))}
                      </Typography>
                    </div>
                  </div>
                </div>
              </>
            )}
          >
          </Card>
        </div>
      </div>
    </AutoLoad>
  );
}

export default Show;
