import React from 'react';
import { ApolloClient, gql, InMemoryCache } from '@apollo/client';
import { createFragmentRegistry } from '@apollo/client/cache';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { toast } from 'react-toastify';
import { get, capitalize, omit } from 'lodash';

import { convertKeysToCamelCase, getAPIURL, reportError } from '../utils/helpers';
import history from '../utils/browser_history';
import { navigationService } from './navigation.service';
import CustomNotification from '../components/CustomNotification';
import { SPAM_ALERTS } from '../constants/common.constants';
import { HIDDEN_FIELDS, IGNORED_QUERIES } from './utils';

const extensions = {
  SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
  ALREADY_EXISTS: 'ALREADY_EXISTS',
  NOT_ALLOWED: 'NOT_ALLOWED',
  NOT_FOUND: 'NOT_FOUND',
};

function showErrorNotification(
  message = 'Something went wrong. Please contact support.'
) {
  toast.error(({ toastProps }) => (
    <CustomNotification type={toastProps.type} message={message} />
  ));
}

const onErrorLink = onError(({ response, graphQLErrors, networkError, operation }) => {
  const operationVars = omit(operation.variables, HIDDEN_FIELDS);
  const isMutation = operation.query.definitions.some(
    (def) => def.operation === 'mutation'
  );

  if (graphQLErrors && !IGNORED_QUERIES.includes(operation.operationName)) {
    const err = graphQLErrors[0];
    const errorString = `[${err?.extensions?.code || 'UNHANDLED_ERROR'}] Operation: ${
      operation.operationName
    }, Variables: ${JSON.stringify(operationVars)}, Message: ${err.message}`;

    switch (err?.extensions?.code) {
    case extensions.NOT_FOUND: {
      // Do nothing: Widget logic works based on this error
      break;
    }
    case extensions.NOT_ALLOWED: {
      navigationService.navigate('/access-denied?variant=widget');
      break;
    }
    case extensions.VALIDATION_ERROR: {
      const parsedErrors = JSON.parse(err.message);
      response.errors = convertKeysToCamelCase(parsedErrors);
      break;
    }
    case extensions.ALREADY_EXISTS: {
      const parsedErrors = JSON.parse(err.message);
      showErrorNotification(capitalize(get(parsedErrors, 'base.0', 'Error')));
      break;
    }
    case extensions.AUTHENTICATION_ERROR: {
      if (!['thank-you', '/crm/connect'].includes(history.location.pathname)) {
        navigationService.navigate('/error?variant=widget', {
          state: {
            errorTitle: 'Authentication failure',
            errorMsg: "We couldn't verify your identity",
          },
        });
      }
      break;
    }
    case extensions.SERVER_ERROR: {
      reportError(errorString);

      if (isMutation) showErrorNotification();
      else navigationService.navigate('/error?variant=widget');
      break;
    }
    default: {
      reportError(errorString);

      if (isMutation) showErrorNotification();
      else navigationService.navigate('/error');
    }
    }
  } else if (networkError) {
    const errorString = `[NETWORK_ERROR] Operation: ${
      operation.operationName
    }, Variables: ${JSON.stringify(operationVars)},  Message: ${networkError.message}`;
    if (SPAM_ALERTS.includes(networkError.message)) {
      navigationService.rollbar.info(errorString, networkError);
    } else {
      reportError(errorString);
    }
    if (isMutation) showErrorNotification();
    else navigationService.navigate('/error?variant=widget');
  }
});

const httpLink = createUploadLink({
  uri: `${getAPIURL()}/graphql`,
  credentials: 'include',
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const clientWidget = new ApolloClient({
  cache: new InMemoryCache({
    fragments: createFragmentRegistry(gql`
      fragment ProductDetails on Product {
        minInitialLoanAmount
        minSubsequentLoanAmount
        maxLoanAmount
        maxDurationAllowed
        allowedDurations
        paymentOptions
        availablePaymentTerms
        availablePaymentFrequencies
        availableContractLengths
        availableOrderTypes
        formattedAvailablePaymentTerms
        defaultPaymentTerm
        formattedDefaultPaymentTerm
        defaultPaymentFrequency
        defaultOrderType
        defaultContractLength
        variableBlindDiscount
        applyMaxBlindDiscount
        spiffMode
        defaultSpiffRate
      }
    `),
  }),
  link: onErrorLink.concat(httpLink),
  defaultOptions,
});

export default clientWidget;
