import Modal from 'react-modal';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import crossIcon from '../../../assets/cross.svg';

function CommonModal({ mainBody, isOpen, onClose, className, isOnboarding }) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="modal" ariaHideApp={false}>
      <div className={`${className} top-20 rounded-lg bg-white`}>
        <div className="flex flex-row justify-end">
          <Button
            className="focus:outline-none cursor-pointer"
            onClick={onClose}
            element={<img alt="close" src={crossIcon} />}
          />
        </div>
        <div className={`${isOnboarding ? 'px-1' : 'px-10'} pt-5`}>
          {mainBody}
          {/* Footer is used when mainBody = <Import /> component */}
          <div className="my-11" id="common-footer-root"></div>
        </div>
      </div>
    </Modal>
  );
}

CommonModal.propTypes = {
  mainBody: PropTypes.element.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  isOnboarding: PropTypes.bool,
};

CommonModal.defaultProps = {
  className: '',
  isOnboarding: false,
};

export default CommonModal;
