import { gql } from '@apollo/client';

export const GET_ORDERS_BY_STATUS = gql`
  query getOrdersByStatus(
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
    $currency: String!
  ) {
    seller {
      ordersByStatus(startDate: $startDate, endDate: $endDate, currency: $currency)
    }
  }
`;

export const GET_ORDERS_AMOUNT_AND_FEES = gql`
  query getOrdersAmountAndFees(
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
    $group: String!
    $currency: String!
  ) {
    seller {
      ordersAmountAndFees(
        startDate: $startDate
        endDate: $endDate
        group: $group
        currency: $currency
      )
    }
  }
`;

export const GET_ORDERS_AMOUNT_AND_FEES_BY_SIZE = gql`
  query getOrdersAmountAndFeesBySize(
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
    $currency: String!
  ) {
    seller {
      ordersAmountAndFeesBySize(
        startDate: $startDate
        endDate: $endDate
        currency: $currency
      )
    }
  }
`;

export const GET_ORDERS_STATS_BY_SIZE = gql`
  query getOrderStatsBySize(
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
    $currency: String!
  ) {
    seller {
      ordersStatsBySize(startDate: $startDate, endDate: $endDate, currency: $currency)
    }
  }
`;
