import PropTypes from 'prop-types';
import { Typography } from '@vartanainc/design-system';
import AutoLoad from '../../../components/AutoLoad';
import IntegrationCard from './IntegrationCard';
import fileIcon from '../../../assets/file.svg';

const IntegrationOptions = ({
  loading,
  integrations,
  handleActivate,
  trayUserId,
  handleFileUpload,
}) => (
  <div className="flex flex-col items-center">
    {/* Heading & Subheading */}
    <div className="py-5 w-9/12">
      <Typography variant="heading18" bold>
        Import customers
      </Typography>
      <div>
        <Typography variant="paragraph14">
          Integrate with your CRM to automatically import customers and generate their
          payment options.
        </Typography>
      </div>
    </div>

    {/* Tiles for each Solution */}
    <AutoLoad loading={loading} className="my-16 w-20 h-20">
      <div className="overflow-x-auto flex flex-row w-full gap-7 pb-5 mt-5 max-w-min">
        {integrations.length > 0 &&
          integrations.map((integration) => {
            return (
              <IntegrationCard
                integration={integration}
                onClick={() => handleActivate(integration, trayUserId)}
                key={integration.id}
                buttonLabel="Activate"
                imageIcon={
                  <img src={integration.iconUrl} alt="CRM Logo" width="70" height="70" />
                }
              />
            );
          })}
        <IntegrationCard
          integration={{
            id: 'File upload',
            title: 'File upload',
            isActive: false,
          }}
          onClick={handleFileUpload}
          buttonLabel="Import"
          imageIcon={<img src={fileIcon} alt="File Icon" width="50" height="50" />}
          key="file-upload"
        />
      </div>
    </AutoLoad>
  </div>
);

IntegrationOptions.propTypes = {
  loading: PropTypes.bool.isRequired,
  integrations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      iconUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleActivate: PropTypes.func.isRequired,
  trayUserId: PropTypes.string.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
};

export default IntegrationOptions;
