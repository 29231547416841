import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Card } from '@vartanainc/design-system';
import Wizard from '../../TrackStatus/Updates/Wizard';
import { PaymentInfoContext } from '../../PaymentAndFundingInvoice/context';
import AutoLoad from '../../../../components/AutoLoad';

function ActionRequired({
  title,
  message,
  icon,
  secondaryMessage,
  onPrimaryClick,
  onSecondaryClick,
  primaryButtonText,
  secondaryButtonText,
  msgBelowBtn,
  isLoading,
  showPrimaryButton,
}) {
  const { stepDetails } = useContext(PaymentInfoContext);
  const showWizard = stepDetails.progress || stepDetails.progress === 0;

  return (
    <AutoLoad loading={isLoading}>
      <div className="text-center flex flex-col justify-center align-center rounded-lg border-2 border-vartana-dark-mustard shadow">
        <Card
          variant="fullWidth"
          content={(
            <>
              <div className="flex flex-col items-center gap-4">
                {showWizard && (
                  <div className="py-4 px-10 w-7/12">
                    <Wizard {...stepDetails} />
                  </div>
                )}
                <div className="flex flex-col gap-2">
                  <Typography variant="heading16" bold>
                    {title}
                  </Typography>
                  {icon}
                  <Typography variant="paragraph12">{message}</Typography>
                  {secondaryMessage && (
                    <Typography variant="paragraph14" color="color-gray-110">
                      {secondaryMessage}
                    </Typography>
                  )}
                </div>
              </div>
              <div className="flex-1 flex flex-col justify-center items-center mt-6 gap-4">
                <div className="flex gap-4">
                  {secondaryButtonText && (
                    <Button variant="outlined" size="x-large" onClick={onSecondaryClick}>
                      {secondaryButtonText}
                    </Button>
                  )}
                  {showPrimaryButton && (
                    <Button onClick={onPrimaryClick} size="x-large">
                      {primaryButtonText}
                    </Button>
                  )}
                </div>
                {msgBelowBtn && (
                  <Typography variant="paragraph-14">{msgBelowBtn}</Typography>
                )}
              </div>
            </>
          )}
        >
        </Card>
      </div>
    </AutoLoad>
  );
}

ActionRequired.propTypes = {
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node,
  secondaryMessage: PropTypes.string,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func,
  primaryButtonText: PropTypes.string.isRequired,
  secondaryButtonText: PropTypes.string,
  msgBelowBtn: PropTypes.string,
  isLoading: PropTypes.bool,
  showPrimaryButton: PropTypes.bool,
};

ActionRequired.defaultProps = {
  title: '',
  message: '',
  icon: null,
  secondaryMessage: '',
  msgBelowBtn: '',
  secondaryButtonText: null,
  isLoading: false,
  onSecondaryClick: null,
  showPrimaryButton: true,
};

export default ActionRequired;
