import React, { useMemo } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { get } from 'lodash';

import { useQuery, useMutation } from '@apollo/client';

import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button } from '@vartanainc/design-system';
import { SEND_PARTNER_CHECKOUT_LINK } from '../../graphql/queries/order';
import { GET_CUSTOMER_NAME_BY_NUMBER } from '../../graphql/queries/customer';
import Loader from '../../components/Loader';
import { showErrorToast, showToast } from '../../utils/helpers';

import {
  commonRegex,
  FIELD_INVALID_MESSAGES,
  requiredPhoneFieldValidation,
} from '../../constants/common.constants';
import TextInputField from '../../designSystem/TextInput/TextInputField';
import PatternInput from '../../designSystem/PatternInput/PatternInput';

const ContactFormSchema = yup.object().shape({
  companyName: yup
    .string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Company name is required')
    .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.firstName),
  firstName: yup
    .string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('First name is required')
    .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.lastName),
  lastName: yup
    .string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Last name is required'),
  email: yup
    .string()
    .typeError('Invalid email')
    .email('Invalid email')
    .required('Email is required'),
  phone: requiredPhoneFieldValidation,
});

function OrderCheckout() {
  const location = useLocation();
  const navigate = useNavigate();

  const companyId = useMemo(() => {
    const prm = new URLSearchParams(location.search);
    return prm.get('customerId');
  }, [location.search]);

  const { data: customerData, loading: customerLoading } = useQuery(
    GET_CUSTOMER_NAME_BY_NUMBER,
    {
      variables: {
        number: companyId,
      },
    }
  );

  const [sendCheckoutLink] = useMutation(SEND_PARTNER_CHECKOUT_LINK);

  async function handleSubmit(values, { setErrors }) {
    try {
      let responseData = {};
      responseData = await sendCheckoutLink({
        variables: {
          companyId: get(customerData, 'company.id', ''),
          ...values,
        },
      });
      const errors = get(responseData, 'errors', {});
      if (Object.keys(errors).length) setErrors(errors);
      else {
        showToast('success', 'Checkout link sent successfully!');
        navigate(`/dashboard/customers/${companyId}/summary`);
      }
    } catch (e) {
      showErrorToast();
    }
  }

  return (
    <Formik
      initialValues={{
        companyName: get(customerData, 'company.name', ''),
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      }}
      enableReinitialize
      validationSchema={ContactFormSchema}
      onSubmit={async (values, actions) => {
        await handleSubmit(values, actions);
      }}
    >
      {() => {
        return (
          <Form className="space-y-0">
            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
              <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                <div className="ml-4 mt-2">
                  <input type="hidden" id="id" name="id" />
                  <h3 className="text-lg leading-6 font-medium text-gray-900 py-2">
                    {`${get(customerData, 'company.name', '')}  >  Send new order link`}
                  </h3>
                </div>
                <div className="ml-4 mt-2 flex-shrink-0">
                  <button
                    type="submit"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-v-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-60"
                    disabled={customerLoading}
                  >
                    Send
                  </button>
                </div>
                <div className="ml-4 mt-2 flex-shrink-0">
                  <Button type="submit" disabled={customerLoading}>
                    Send
                  </Button>
                </div>
              </div>
            </div>
            {customerLoading ? (
              <Loader
                isLoading
                className="w-12 h-12 tw-mt-6"
                containerClassName="pt-52"
              />
            ) : (
              <div className="bg-white overflow-hidden rounded-none border-gray-200 border-b">
                <div className="px-4 py-5 sm:p-6 mt-3">
                  <div className="mt-10 sm:mt-0">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                      <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                          <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Contact detail
                          </h3>
                        </div>
                      </div>
                      <input type="hidden" id="id" name="id" />
                      <div className="mt-5 md:mt-0 md:col-span-2">
                        <div className="grid grid-cols-6 gap-6">
                          <TextInputField
                            id="companyName"
                            name="companyName"
                            label="Company name"
                          />
                          <TextInputField
                            id="firstName"
                            name="firstName"
                            label="First name"
                          />
                          <TextInputField
                            id="lastName"
                            name="lastName"
                            label="Last name"
                          />
                          <TextInputField id="email" name="email" label="Email" />
                          <PatternInput id="phone" name="phone" label="Phone" mask="_" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default OrderCheckout;
