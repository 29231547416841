import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Navigate, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { get, isEmpty } from 'lodash';

import PropTypes from 'prop-types';
import Sidenav from '../Launchpad/Sidenav/SideNav';
import MainBody from './MainBody';
import { sessionTypeVar, sessionVar } from '../../graphql/cache';
import { SidebarContext } from '../../context/DashboardContext';
import { sessionTypes } from '../../constants/common.constants';

function Dashboard({ openOnboarding }) {
  const [menu, toggleMenu] = useState(false);

  const { search } = useLocation();
  const useQueryParams = new URLSearchParams(search);
  const showIntercomWindow = useQueryParams.get('help') === 'true';

  const sessionType = useReactiveVar(sessionTypeVar);
  const session = useReactiveVar(sessionVar);

  const { show: showIntercom, boot: bootIntercom } = useIntercom();

  // Initialize with session user info
  useEffect(() => {
    if (session) {
      const userSession = get(session, 'session.user', {});
      if (!isEmpty(userSession)) {
        bootIntercom({
          name: get(userSession, 'fullName', ''),
          email: get(userSession, 'email', ''),
          created_at: new Date(get(userSession, 'createdAt', null)).valueOf(),
          customAttributes: {
            application: 'vendor-app',
            environment: process.env.REACT_APP_NODE_ENV,
            resourceId: get(userSession, 'id', -1),
            resourceType: 'User',
          },
        });
        if (showIntercomWindow) showIntercom();
      }
    }
  }, [sessionType, session, showIntercomWindow, bootIntercom, showIntercom]);

  if (openOnboarding) return <Navigate to="/onboarding" replace />;

  return (
    <>
      {sessionType === sessionTypes.ADMIN_USER ? (
        <div className="bg-red-600">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center justify-center">
                <span className="flex p-2 rounded-lg bg-red-800">
                  <svg
                    className="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                    />
                  </svg>
                </span>
                <p className="ml-3 font-lg text-white truncate uppercase">
                  This is a hijacked session!!!!
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="h-screen flex overflow-hidden bg-gray-100">
        <SidebarContext.Provider value={{ menu, toggleMenu }}>
          <Sidenav />
          <MainBody />
        </SidebarContext.Provider>
      </div>
    </>
  );
}

Dashboard.propTypes = {
  openOnboarding: PropTypes.bool.isRequired,
};

export default Dashboard;
