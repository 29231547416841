import PropTypes from 'prop-types';

import Button from './index';
import AutoLoad from '../AutoLoad';
import { downloadStatuses } from '../../constants/common.constants';
import { ReactComponent as CheckIcon } from '../../assets/check_icon.svg';

export const DownloadButton = ({
  btnClassName,
  isDisabled,
  textClassName,
  btnText,
  onClick,
  downloadingStatus,
}) => (
  <Button
    className={btnClassName}
    type="submit"
    disabled={isDisabled || downloadingStatus !== downloadStatuses.DEFAULT}
    element={(
      <div className="flex justify-center gap-2">
        {downloadingStatus === downloadStatuses.DOWNLOADED && <CheckIcon />}
        {downloadingStatus === downloadStatuses.DOWNLOADING && (
          <AutoLoad loading className="w-4 h-4" />
        )}
        <p className={textClassName}>{btnText}</p>
      </div>
    )}
    onClick={onClick}
  />
);

DownloadButton.propTypes = {
  btnClassName: PropTypes.string,
  textClassName: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  downloadingStatus: PropTypes.oneOf(['default', 'downloading', 'downloaded']),
};

DownloadButton.defaultProps = {
  downloadingStatus: 'default',
  btnClassName: '',
  isDisabled: false,
};
