import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Typography } from '@vartanainc/design-system';
import { BUYER_TYPE } from '../../constants/common.constants';
import { OrderFormContext } from '../../context/OrderContext';
import crossIcon from '../../assets/cross.svg';
import FreeTextDropdownInput from '../../designSystem/FreeTextDropdownInput/FreeTextDropdownInput';
import TextInputField from '../../designSystem/TextInput/TextInputField';

export function ResellerSection({
  index,
  resellerOptions,
  onRemove,
  haveMultipleResellers,
}) {
  const { selectedCustomer } = useContext(OrderFormContext);
  const inIndirectBuyer = selectedCustomer?.buyerRelationToVendor === BUYER_TYPE.INDIRECT;

  return (
    <section className="space-y-4 pt-6 first:pt-0 pb-10 last:pb-4">
      <header className="flex justify-between items-center">
        <Typography variant="heading18" bold>
          {haveMultipleResellers ? `Partner ${index + 1}` : 'Partner information'}
        </Typography>
        {haveMultipleResellers && (
          <button type="button" onClick={onRemove}>
            <img alt="close" src={crossIcon} />
          </button>
        )}
      </header>

      <div className="grid grid-cols-2 gap-4">
        <FreeTextDropdownInput
          label="Company name"
          name={`resellers.${index}.companyName`}
          options={resellerOptions}
          disabled={inIndirectBuyer}
        />
        <TextInputField
          label="Contact email"
          name={`resellers.${index}.email`}
          type="email"
          disabled={inIndirectBuyer}
          placeholder="example@email.com"
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <TextInputField
          name={`resellers.${index}.firstName`}
          label="Contact first name"
          disabled={inIndirectBuyer}
        />
        <TextInputField
          name={`resellers.${index}.lastName`}
          label="Last name"
          disabled={inIndirectBuyer}
        />
      </div>
    </section>
  );
}

ResellerSection.propTypes = {
  index: PropTypes.number.isRequired,
  resellerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  onRemove: PropTypes.func.isRequired,
  haveMultipleResellers: PropTypes.bool.isRequired,
};
