import { useState, useEffect, ReactElement } from 'react';
import colors from '../ColorMap/ColorMap';

// Variant options for the SvgIcon component
export type VariantProps = 'default' | 'thick';

export type SvgIconProps = {
  name: string; // Name of the SVG icon to import
  className?: string; // Optional additional CSS class
  width?: string; // Optional width of the SVG icon
  height?: string; // Optional height of the SVG icon
  fill?: string; // Fill color of the SVG icon
  variant?: VariantProps; // Variant type for different icon styles
};

// Union type for all possible SVG icon components
type IconComponentType = React.ComponentType<SvgIconProps>;

// SvgIcon component renders an SVG icon dynamically
const SvgIcon: React.FC<SvgIconProps> = ({
  name,
  variant = 'default',
  fill = 'blue',
  className = '',
  ...props
}: SvgIconProps): ReactElement | null => {
  const [IconComponent, setIconComponent] = useState<IconComponentType | null>(null);

  useEffect(() => {
    const importIcon = async (): Promise<void> => {
      try {
        // Dynamically import the SVG icon based on name and variant
        const module = await import(
          `../../assets/LivingDesignSystemIcons/${name}${
            variant === 'thick' ? '_thick' : ''
          }.svg`
        );
        const IconComponents = module.default as IconComponentType;
        setIconComponent(() => IconComponents);
      } catch (error) {
        console.error(`Error importing SVG icon ${name}:`, error);
      }
    };

    importIcon();
  }, [name, variant]);

  if (!IconComponent) {
    return null; // Render nothing if the icon component is not loaded
  }

  // Render the loaded SVG icon component
  return (
    <IconComponent
      className={`icon ${className}`} // Apply classes for styling
      data-testid="svg-icon" // Test ID for unit testing
      fill={fill.includes('color-') ? colors[fill] : fill} // Apply fill color dynamically
      {...(props as SvgIconProps)} // Spread other props to the SVG component
    />
  );
};

export default SvgIcon;
