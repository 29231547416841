import { ReactElement, useCallback, useMemo, useState } from 'react';
import { get, omit } from 'lodash';
import { FormikErrors, FormikProvider, useFormik } from 'formik';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import { ButtonV2, Typography } from '@vartanainc/design-system';
import AutoLoad from '../../../../components/AutoLoad';

import { ReactComponent as StoreIcon } from '../../../../assets/store-blue.svg';
import CreateCustomerForm from './CreateCustomerForm';
import PersonalGuarantor from '../../../../macro_components/PersonalGuarantor/PersonalGuarantor';

import { CREATE_CUSTOMER, VALIDATE_CUSTOMER } from '../../../../graphql/queries/customer';
import { requestModelAmountVar } from '../../../../graphql/cache';
import { useSessionValue } from '../../../../hooks/SessionValue';
import {
  countrySchema,
  customerFormSchema,
  maxUnitSchema,
  requestedAmountSchema,
  salesQuoteSchema,
} from '../../../../formikSchema/commonSchema';
import { useDirectUploadFiles } from '../../../../utils/hooks';
import { COUNTRY } from '../../../../static';

const FORM_STEPS = {
  CREATE_CUSTOMER: 'CREATE_CUSTOMER',
  PERSONAL_GUARANTOR: 'PERSONAL_GUARANTOR',
};

export interface InitialCustomerFormValues {
  number: string | null;
  name: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  employeeCount: string;
  annualRevenue: string;
  maxUnits?: string;
  requestedAmount?: string;
  requestedTerm?: string;
  contactNumber: string | null;
  addressId: string | null;
  country: string;
  currency: string;
  crmAccountId: string;
  crmOpportunityId: string;
}

interface CreateCustomerProps {
  handleCustomerCreated: () => void;
}

export default function CreateCustomer({
  handleCustomerCreated,
}: CreateCustomerProps): ReactElement {
  const location = useLocation();
  const useQueryParams = new URLSearchParams(location.search);
  const crmOpportunityId = useQueryParams.get('crmOpportunityId') || '';
  const crmAccountId = useQueryParams.get('crmAccountId') || '';

  // this is a switch to reset the address fields when the country is changed
  const [resetPGAddressSwitch, setResetPGAddressSwitch] = useState(false);

  const [createCustomer] = useMutation(CREATE_CUSTOMER);
  const [validateCustomer, { loading: isValidatingCustomer }] =
    useMutation(VALIDATE_CUSTOMER);

  const requestModel = useReactiveVar(requestModelAmountVar);

  const showCountryField = useSessionValue<boolean>(
    'session.user.company.product.multipleCountriesEnabled',
    false
  );
  const supportedCountries = useSessionValue<string[]>(
    'session.user.company.product.enabledCountries',
    []
  );
  const supportedCurrencies = useSessionValue<string[]>(
    'session.user.company.product.enabledCurrencies',
    []
  );
  const isSalesQuoteCompulsory = useSessionValue<boolean>(
    'session.user.company.salesQuoteRequiredAtCustomerCreation',
    false
  );

  const initialValues = useMemo((): InitialCustomerFormValues => {
    return {
      number: null,
      name: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      employeeCount: '',
      annualRevenue: '',
      maxUnits: '',
      requestedAmount: '',
      requestedTerm: '',
      contactNumber: null,
      addressId: null,
      country: showCountryField ? '' : supportedCountries[0],
      currency: showCountryField ? '' : supportedCurrencies[0],
      crmAccountId,
      crmOpportunityId,
    };
  }, [
    showCountryField,
    supportedCountries,
    supportedCurrencies,
    crmAccountId,
    crmOpportunityId,
  ]);

  const [attachSalesQuote] = useDirectUploadFiles();

  const formikSchema = useMemo(() => {
    let schemaToUse = customerFormSchema;
    if (requestModel) {
      schemaToUse = schemaToUse.concat(requestedAmountSchema);
    } else {
      schemaToUse = schemaToUse.concat(maxUnitSchema);
    }
    if (showCountryField) {
      schemaToUse = schemaToUse.concat(countrySchema);
    }
    if (isSalesQuoteCompulsory) {
      schemaToUse = schemaToUse.concat(salesQuoteSchema);
    }

    return schemaToUse || customerFormSchema;
  }, [requestModel, showCountryField, isSalesQuoteCompulsory]);

  const onSubmit = useCallback(
    async (values, { setErrors }) => {
      let errors;
      try {
        let valuesToBeSubmitted: Partial<typeof values> = {};
        if (requestModel) {
          valuesToBeSubmitted = omit(values, ['maxUnits']);
        } else {
          valuesToBeSubmitted = omit(values, ['requestedAmount', 'requestedTerm']);
        }

        const responseData = await createCustomer({
          variables: omit(valuesToBeSubmitted, [
            'number',
            'contactNumber',
            'addressId',
            'docs',
          ]),
        });

        const newCustomerId = get(responseData, 'data.createCustomer.id', null);
        const creditCheckId = get(
          responseData,
          'data.createCustomer.creditCheck.id',
          null
        );

        const errorsMsg = responseData.errors || {};
        const hasErrors = Object.keys(errorsMsg).length > 0;
        if (!hasErrors) {
          const { docs } = values;
          const attachedResource = {
            id: creditCheckId || newCustomerId,
            type: creditCheckId ? 'CreditEngine::CreditCheck' : 'Company',
          };
          const documentType = {
            documentType: 'sales_quote',
          };
          if (docs?.length)
            if (typeof attachSalesQuote === 'function') {
              await attachSalesQuote(docs, documentType, attachedResource);
            }
        }

        errors = get(responseData, 'errors', {});
        if (Object.keys(errors).length) {
          await setErrors(errors);
        } else {
          handleCustomerCreated();
        }
      } catch (e) {
        reportError(`While creating customer: ${e}`);
      }
      return errors;
    },
    [requestModel, createCustomer, attachSalesQuote, handleCustomerCreated]
  );
  const formikBag = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formikSchema,
    onSubmit,
  });

  const handleCustomerValidation = useCallback(async () => {
    let hasErrors;
    const { values, setErrors } = formikBag;
    try {
      let valuesToBeSubmitted = values;
      // omit maxUnits OR requestedAmount and requestedTerm based on Vendor's configuration
      if (requestModel) {
        valuesToBeSubmitted = omit(values, ['maxUnits']);
      } else {
        valuesToBeSubmitted = omit(values, ['requestedAmount', 'requestedTerm']);
      }

      // place validation call while omitting unwated fields
      const responseData = await validateCustomer({
        variables: omit(valuesToBeSubmitted, [
          'number',
          'contactNumber',
          'addressId',
          'docs',
        ]),
      });

      // check if the validation response has errors
      hasErrors = Object.keys(responseData.errors || {}).length;
      const errors = get(responseData, 'errors', {});
      if (hasErrors) {
        setErrors(errors as FormikErrors<InitialCustomerFormValues>);
      }
    } catch (e) {
      reportError(`While validating customer: ${e}`);
    }

    // return true if no errors, false otherwise
    return !hasErrors;
  }, [requestModel, formikBag, validateCustomer]);

  const [formStep, setFormStep] = useState(FORM_STEPS.CREATE_CUSTOMER);

  const getVisibilityClassName = (step): string => {
    // This function is used to display only the active step form and hide the rest without unmounting them to retain form state
    return formStep === step ? 'block' : 'hidden';
  };

  const handleNext = async (): Promise<void> => {
    const validationPass = await handleCustomerValidation();

    if (validationPass) setFormStep(FORM_STEPS.PERSONAL_GUARANTOR);
  };

  const handleSubmissionWithGuarantors = async (
    guarantors
  ): Promise<{ [key: string]: string[] }> => {
    await formikBag.setFieldValue('personalGuarantors', guarantors);
    const backendErrors = await formikBag.submitForm();
    return backendErrors;
  };

  const handleCountryChange = useCallback(async () => {
    const { country, requestedTerm } = formikBag.values;
    if (country === COUNTRY.CA && requestedTerm === '0') {
      await formikBag.setFieldValue('requestedTerm', '');
      await formikBag.validateForm();
    }
    setResetPGAddressSwitch((prev) => !prev);
  }, [formikBag]);

  return (
    <div className="grid gap-2.5 mb-[7rem]">
      <div className="flex gap-2 items-center">
        <StoreIcon />
        <Typography variant="heading20" bold color="color-black-100">
          Tell us about your customer
        </Typography>
      </div>
      <Typography variant="paragraph14" color="color-black-100">
        Please provide the following information to start financing review for your
        customer.
      </Typography>

      <div className={getVisibilityClassName(FORM_STEPS.CREATE_CUSTOMER)}>
        <FormikProvider value={formikBag}>
          <CreateCustomerForm handleCountryChange={handleCountryChange} />
        </FormikProvider>

        <div className="flex justify-end mt-2">
          <AutoLoad loading={isValidatingCustomer}>
            <ButtonV2
              type="button"
              text="Next"
              variant={{ type: 'primary', typography: 'paragraph14' }}
              onClick={handleNext}
              iconRight="chevron_right"
              disabled={!formikBag.isValid}
            >
              Next
            </ButtonV2>
          </AutoLoad>
        </div>
      </div>

      <div className={getVisibilityClassName(FORM_STEPS.PERSONAL_GUARANTOR)}>
        <PersonalGuarantor
          handleBackButtonClick={() => setFormStep(FORM_STEPS.CREATE_CUSTOMER)}
          handleSkipButtonClick={async () => {
            await formikBag.submitForm();
          }}
          setGuarantorsInForm={handleSubmissionWithGuarantors}
          showSinField={formikBag.values.country === COUNTRY.CA}
          selectedCountry={formikBag.values.country}
          resetPGAddressSwitch={resetPGAddressSwitch}
        />
      </div>
    </div>
  );
}
