import { forwardRef } from 'react';
import { NumericIFieldProps, NumericInputField } from '@vartanainc/design-system';
import { useField } from 'formik';

interface CompactNumericFieldProps extends NumericIFieldProps {
  name: string;
}

const CompactNumericField = forwardRef<HTMLInputElement, CompactNumericFieldProps>(
  (
    {
      id,
      name,
      label,
      type,
      customPrefix,
      customSuffix,
      placeholder,
      onValueChange,
      onBlur,
      showError,
      showWarn,
      showHoverShadow,
      ...rest
    },
    ref
  ) => {
    const [field, meta] = useField(name);
    return (
      <NumericInputField
        {...field}
        {...rest}
        id={id}
        placeholder={placeholder}
        customPrefix={customPrefix}
        customSuffix={customSuffix}
        name={name}
        label={label}
        onValueChange={onValueChange}
        onBlur={onBlur}
        showError={meta.touched && showError}
        showWarn={meta.touched && showWarn}
        ref={ref}
        showHoverShadow={showHoverShadow}
      />
    );
  }
);

export default CompactNumericField;
