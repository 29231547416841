import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader';

function Button({ disabled, loading, type, onClick, className, element }) {
  return (
    <>
      <button
        className={`${disabled ? 'cursor-not-allowed' : ''} ${className}`}
        type={type}
        disabled={disabled || loading}
        onClick={onClick}
      >
        {loading ? <Loader className="p-3" /> : element}
      </button>
    </>
  );
}

Button.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  element: PropTypes.node,
};

Button.defaultProps = {
  disabled: false,
  loading: false,
  type: '',
  onClick: () => {},
  className: '',
  element: null,
};

export default Button;
