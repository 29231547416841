/* eslint-disable dot-notation */
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { get, capitalize } from 'lodash';
import { Button, Typography } from '@vartanainc/design-system';
import { getPageUrl, isRenderedFromHubspot } from '../../utils/helpers';
import SvgIcon from '../SvgIcon/SvgIcon';

type reasonType = {
  key: string;
  reason: string;
};

function CreditAppraisalAndChangeRequestModalV2({
  open,
  onClose,
  title,
  message,
  standardReasons,
  customReason,
  status,
  currentStepMissingDetails,
}: {
  open?: boolean;
  onClose?: () => void;
  title: string;
  message: string;
  standardReasons?: reasonType[];
  customReason?: string;
  status: string;
  currentStepMissingDetails?: string;
}): JSX.Element {
  const { show: showIntercom } = useIntercom();
  const navigate = useNavigate();

  return (
    <Modal
      isOpen={open}
      className="modal flex justify-center items-center  "
      ariaHideApp={false}
    >
      <div className="bg-white w-[40.75rem] max-h-[40.75rem] rounded-xl">
        <div className="w-[40.75rem] p-2 bg-white flex-col justify-center items-center gap-6 inline-flex overflow-y-auto rounded-xl">
          <div className="self-stretch justify-end items-center inline-flex rounded-xl">
            <div className="grow shrink basis-0 self-stretch pl-4"></div>
            <div className="justify-end items-start gap-2.5 flex">
              <Button
                backgroundColor="none"
                size="x-small"
                onClick={onClose}
                style={{ padding: '0', height: 'auto' }}
              >
                <SvgIcon
                  name="close"
                  width="1.5rem"
                  height="1.5rem"
                  fill="color-gray-100"
                />
              </Button>
            </div>
          </div>
        </div>
        <div className="body flex flex-col p-[2.5rem] pt-0">
          <div className="content flex flex-col gap-[1.5rem] overflow-y-auto">
            <div className="Heading flex gap-[0.5rem] items-center">
              <SvgIcon
                name="demography"
                width="2rem"
                height="2rem"
                fill={
                  title?.toLowerCase().includes('information')
                    ? 'color-gold-100'
                    : 'color-blue-120'
                }
              />
              <Typography color="color-black-100" variant="heading20">
                {capitalize(title)}
              </Typography>
            </div>
            <div className="content flex flex-col gap-[1.5rem] max-h-[29rem] overflow-y-auto">
              <div className="flex flex-col gap-[0.5rem]">
                <Typography color="color-black-100" variant="paragraph14">
                  {message}{' '}
                </Typography>
                {standardReasons?.length ? (
                  <ul className="list-decimal flex flex-col gap-[0.5rem] items-start slef-stretch pl-8">
                    {standardReasons.map((standardReason) => (
                      <li key={standardReason.key}>
                        <Typography color="color-black-100" variant="paragraph14">
                          {standardReason.reason}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>

              {(get(customReason, 'length', 0) > 0 ||
                get(standardReasons, 'length', 0) > 0) &&
                status === 'applicationRequested' && (
                  <div className="flex flex-col gap-[0.5rem]">
                    <Typography color="color-black-100" variant="paragraph14">
                      Detail of information required again is given below
                    </Typography>
                  </div>
                )}
              {currentStepMissingDetails && (
                <>
                  {currentStepMissingDetails.includes(
                    'Contact Details for an Authorized Signer'
                  ) && (
                    <div className="flex flex-col gap-[0.5rem]">
                      <Typography color="color-black-100" variant="heading16" bold>
                        Authorized signer details
                      </Typography>
                      <Typography color="color-black-100" variant="paragraph14">
                        Information to help us identify and verify the authorized signer
                        and, if necessary, pre-screen credit for a personal guarantee.{' '}
                        <br />
                        <span className="italic">
                          Pre-screen will not affect their personal credit.
                        </span>
                      </Typography>
                    </div>
                  )}
                  {currentStepMissingDetails.includes(
                    'Contact Details for Personal Guarantors'
                  ) && (
                    <div className="flex flex-col gap-[0.5rem]">
                      <Typography color="color-black-100" variant="heading16" bold>
                        Personal guarantor details
                      </Typography>
                      <Typography color="color-black-100" variant="paragraph14">
                        Information to help identify and verify the authorized signer.
                        This person&apos;s credit history will be prescreened as a
                        candidate to provide a personal guarantee. Prescreening will not
                        affect their credit. An accepted guarantor:
                      </Typography>
                      <ul className="list-disc flex flex-col gap-[0.5rem] items-start slef-stretch pl-8">
                        <li>
                          <Typography color="color-black-100" variant="paragraph14">
                            should have ownership or executive responsibilities of the
                            company.
                          </Typography>
                        </li>
                        <li>
                          <Typography color="color-black-100" variant="paragraph14">
                            will be required to sign agreements for every order.
                          </Typography>
                        </li>
                        <li>
                          <Typography color="color-black-100" variant="paragraph14">
                            will assume responsibility for the loan if the business cannot
                            repay.
                          </Typography>
                        </li>
                      </ul>
                    </div>
                  )}
                  {currentStepMissingDetails.includes('Business Details') && (
                    <div className="flex flex-col gap-[0.5rem]">
                      <Typography color="color-black-100" variant="heading16" bold>
                        Business details
                      </Typography>
                      <Typography color="color-black-100" variant="paragraph14">
                        The registered legal name of the business to verify credit
                        history.
                      </Typography>
                    </div>
                  )}
                  {currentStepMissingDetails.includes(
                    'Last 2 years of financial statements'
                  ) && (
                    <div className="flex flex-col gap-[0.5rem]">
                      <Typography color="color-black-100" variant="heading16" bold>
                        Financial statements
                      </Typography>
                      <Typography color="color-black-100" variant="paragraph14">
                        Information to review detailed financial performance, acceptable
                        documents include:
                      </Typography>
                      <ul className="list-disc flex flex-col gap-[0.5rem] items-start slef-stretch pl-8">
                        <li>
                          <Typography color="color-black-100" variant="paragraph14">
                            2 years of CPA-prepared or audited financial statements,
                            including income statement, balance sheet, cashflow statement,
                            and debt schedules with CPA&apos;s note.
                          </Typography>
                        </li>
                        <li>
                          <Typography color="color-black-100" variant="paragraph14">
                            If CPA-prepared documents are not available, business tax
                            returns (Form
                            <a
                              className="relative cursor-pointer bg-white rounded-md font-medium text-vartana-blue-60 hover:text-blue focus-within:outline-none"
                              href={process.env['REACT_APP_F1065_SAMPLE_URL']}
                              target="_blank"
                              rel="noreferrer"
                            >
                              &nbsp;1065
                            </a>
                            ,&nbsp;
                            <a
                              className="relative cursor-pointer bg-white rounded-md font-medium text-vartana-blue-60 hover:text-blue focus-within:outline-none"
                              href={process.env['REACT_APP_F1120_SAMPLE_URL']}
                              target="_blank"
                              rel="noreferrer"
                            >
                              1120
                            </a>
                            &nbsp; or &nbsp;
                            <a
                              className="relative cursor-pointer bg-white rounded-md font-medium text-vartana-blue-60 hover:text-blue focus-within:outline-none"
                              href={process.env['REACT_APP_F1120S_SAMPLE_URL']}
                              target="_blank"
                              rel="noreferrer"
                            >
                              1120-S
                            </a>
                            &nbsp; including Schedule L) may be submitted.
                          </Typography>
                        </li>
                        <li>
                          <Typography color="color-black-100" variant="paragraph14">
                            If the end date of these documents is more than 6 months ago,
                            year-to-date documents through the most recent quarter-end are
                            required.
                          </Typography>
                        </li>
                      </ul>
                    </div>
                  )}
                </>
              )}
              {get(customReason, 'length', 0) > 0 && (
                <div className="flex flex-col gap-[0.5rem]">
                  <Typography color="color-black-100" variant="heading16" bold>
                    Additional notes from reviewer:
                  </Typography>
                  <Typography color="color-black-100" variant="paragraph14">
                    {customReason}
                  </Typography>
                </div>
              )}
            </div>
          </div>
          <div className="footer flex gap-4 pt-8 justify-end">
            <Button
              size="large"
              backgroundColor="secondary"
              variant="outlined"
              onClick={() =>
                isRenderedFromHubspot()
                  ? navigate(getPageUrl({ page: 'getHelp' }))
                  : showIntercom()}
            >
              Contact help desk
            </Button>
            <Button
              size="large"
              backgroundColor="primary"
              variant="contained"
              onClick={onClose}
            >
              Close window
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CreditAppraisalAndChangeRequestModalV2;
