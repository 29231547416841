import { get, isEmpty } from 'lodash';
import { Money } from 'ts-money';
import {
  orderStates,
  orderType,
  US_CA_PHONE_NUMBER_PREFIX,
} from '../../constants/common.constants';
import { isAppliedSubsidyZero } from '../../utils/helpers';
import { SIGNATURE_ORDER_PAYLOAD } from './order.constants';
import { ORDER_STATES_WHEN_INVOICE_CAN_BE_UPLOADED } from '../../constants/order';

/**
 * Highlight rows for orders that require some user action
 * @param {object} order
 * @param {string} order.stage - Current stage of the order checkout flow
 * @param {boolean} order.needFundingInvoice - Whether user needs to upload funding invoice
 * @returns {*|boolean}
 */
export const isHighlighted = (order) => {
  // Yellow highlighted stages of order
  const SIGN_REQUIRED = 'signature_required';
  const APPROVAL_REQUIRED = 'approval_required';
  const REVIEW_PO = orderStates.review_purchase_order;

  const { stage, needFundingInvoice, anyPendingInvoicesOnMilestone, milestones } = order;

  const canUploadInvoiceForSingleMilestone =
    milestones?.length === 1 && ORDER_STATES_WHEN_INVOICE_CAN_BE_UPLOADED.includes(stage);

  const canUploadInvoiceForMultipleMilestones =
    milestones?.length > 1 && ORDER_STATES_WHEN_INVOICE_CAN_BE_UPLOADED.includes(stage);

  if (canUploadInvoiceForSingleMilestone) return needFundingInvoice;

  if (canUploadInvoiceForMultipleMilestones) return anyPendingInvoicesOnMilestone;
  return [APPROVAL_REQUIRED, SIGN_REQUIRED, REVIEW_PO].includes(stage);
};

/*
    This Function Converts a string to float by keeping the traling zeros.
    example:
      - input = "1200.30"
      - output = 1200.30
    note: parseFloat removes trailing zeros.
*/

export const parseFloatWithTrailingZeros = (inputString) => {
  if (typeof inputString === 'string') {
    // Convert the string to a float
    const floatValue = parseFloat(inputString);

    // Check if the conversion is successful
    if (Number.isNaN(floatValue)) {
      return null;
    }

    // Adding the traling zeros back to the float
    const resultFloat = floatValue.toFixed(
      inputString.includes('.') ? inputString.split('.')[1].length : 0
    );

    return resultFloat;
  }
  return inputString;
};

export const formatDate = (date) => {
  const formattedDate = date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  });

  if (formattedDate === 'Invalid Date') {
    return null;
  }
  return formattedDate;
};

export const getProposalPayload = (
  proposal,
  index,
  isOrderEdit,
  getProposalSpiffRate,
  isCustomSchedule
) => {
  const applyOfferedBuyerInterestRate =
    !!proposal.interestRate && proposal.interestRate !== 'null' && !isCustomSchedule;
  const proposalFields = {
    uuid: proposal.key,
    title: proposal.title,
    sortOrder: index + 1,
    orderType:
      proposal.orderType === orderType.installments
        ? proposal.orderType
        : orderType.pay_in_full,
    startDate: new Date(proposal.startDate),
    endDate: new Date(proposal.endDate),
    useVartanaFinancing: proposal.vartanaFinancing,
    isDollar: proposal.isDollar,
    offeredBuyerInterestRate: proposal.interestRate
      ? parseFloat(proposal.interestRate)
      : 0,
    applyOfferedBuyerInterestRate,
  };
  const dollarSubsidy = proposal.isDollar ? parseFloat(proposal.subsidy) : 0;
  const percentageSubsidy = !proposal.isDollar ? parseFloat(proposal.subsidy) : 0;
  const baseSubsidy = proposal.isDollar
    ? proposal.subsidy / proposal.amount
    : proposal.subsidy / 100;

  if (isOrderEdit) {
    proposalFields.number = proposal.number || null;
    proposalFields.orderType =
      proposal.orderType === orderType.installments
        ? orderType.installments
        : orderType.pay_in_full;
  }
  switch (proposal.orderType) {
  case orderType.full_payment:
    return {
      ...proposalFields,
      ...SIGNATURE_ORDER_PAYLOAD,
    };
  default:
    return {
      ...proposalFields,
      amount: proposal.amount,
      billingFrequency: proposal.paymentFrequency || null,
      paymentTerm: proposal.paymentTerm,
      term:
          proposal.orderType === orderType.pay_in_full
            ? 0
            : +proposal.contractLength || 0,
      blindDiscount: applyOfferedBuyerInterestRate ? 0 : baseSubsidy,
      dollarBlindDiscount: applyOfferedBuyerInterestRate ? 0 : dollarSubsidy,
      percentageBlindDiscount: applyOfferedBuyerInterestRate ? 0 : percentageSubsidy,
      spiffRate: getProposalSpiffRate(proposal),
    };
  }
};

export const generateDocsMeta = (proposals) => {
  const docs = [];
  const docsMeta = [];
  proposals
    .filter((proposal) => !isEmpty(proposal.orderForm))
    .forEach((proposal) => {
      proposal.orderForm.forEach((vendorDoc) => {
        if (vendorDoc.size) {
          docs.push(vendorDoc);
          docsMeta.push(proposal.key);
        }
      });
    });
  return { docs, docsMeta };
};

export const getSignerDetails = (orderBuyer, orderState) => {
  const isPGOrder = get(orderBuyer, 'creditCheck.pgRequired', false);
  const isCCGOrder = get(orderBuyer, 'creditCheck.conditionalToCcg', false);

  if (isPGOrder) return get(orderBuyer, 'primaryUser', {});
  if (isCCGOrder && orderState === orderStates.corporate_guarantee)
    return get(orderBuyer, 'creditCheck.corporateGuarantor.primaryUser', {});

  // return empty object if no guarantor is required
  return {};
};

// Define a currency object manually
const USD = {
  code: 'USD',
  minorUnit: 2, // USD has 2 minor units (cents)
};
export const centsToDollars = (cents) => {
  if (!cents) {
    return null;
  }
  const money = new Money(cents, USD);
  const dollars = (money.amount / 100).toFixed(2);
  return dollars;
};
// adding prefix to the phone number if it does not exist
export const getUsCaFormattedPhoneNumber = (selectedOption) => {
  return selectedOption?.Phone?.includes(US_CA_PHONE_NUMBER_PREFIX)
    ? selectedOption?.Phone
    : `${US_CA_PHONE_NUMBER_PREFIX} ${selectedOption.Phone}`;
};

export const getBackfilledSubsidy = (appliedSubsidy, isDollar) => {
  const isSubsidyZero = isAppliedSubsidyZero(appliedSubsidy?.percentage);
  const percentageAppliedSubsidy = isSubsidyZero ? null : appliedSubsidy?.percentage;

  const dollarAppliedSubsidy = !appliedSubsidy?.cents
    ? null
    : centsToDollars(appliedSubsidy?.cents);

  return isDollar ? dollarAppliedSubsidy : percentageAppliedSubsidy;
};
