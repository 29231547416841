import { Typography } from '@vartanainc/design-system';
import { PASSWORD_ERRORS } from '../constants/common.constants';
import { ReactComponent as CrossIcon } from '../assets/btn-icon-cross-red.svg';
import { ReactComponent as CheckIcon } from '../assets/check_icon.svg';
import { ReactComponent as DotIcon } from '../assets/btn-icon-dot-gray.svg';

// handles password validation errors and set corresponding conditions.
// If errors are present, set conditions based on error types.
// If no errors but password is required or no value provided, set all conditions to false.
// Otherwise, set all conditions to true, indicating successful validation.
export const handlePasswordValidation = (errors, values, setPasswordConditions) => {
  if (errors && errors?.errors?.length > 0) {
    setPasswordConditions({
      lengthCondition: errors.errors.indexOf(PASSWORD_ERRORS.lengthError) === -1,
      numberCondition: errors.errors.indexOf(PASSWORD_ERRORS.numberError) === -1,
      letterCaseCondition: errors.errors.indexOf(PASSWORD_ERRORS.casingError) === -1,
    });
  } else if (errors?.errors === 'This field is required' || !values) {
    setPasswordConditions({
      lengthCondition: false,
      numberCondition: false,
      letterCaseCondition: false,
    });
  } else {
    setPasswordConditions({
      lengthCondition: true,
      numberCondition: true,
      letterCaseCondition: true,
    });
  }
};

export const getTextColor = (condition, passwordConditions, showRedHints) => {
  if (condition === 'isLengthConditionMet' && passwordConditions.lengthCondition) {
    return 'color-green-120';
  }
  if (
    condition === 'isLetterCaseConditionMet' &&
    passwordConditions.letterCaseCondition
  ) {
    return 'color-green-120';
  }
  if (condition === 'isNumberConditionMet' && passwordConditions.numberCondition) {
    return 'color-green-120';
  }
  return showRedHints ? 'color-red-160' : 'color-gray-120';
};

export const getConditionIcon = (condition, showRedHints) => {
  const iconStyles = 'h-4 w-auto object-contain';
  if (!condition && !showRedHints) {
    return <DotIcon className={iconStyles} />;
  }
  if (!condition && showRedHints) {
    return <CrossIcon className={iconStyles} />;
  }
  return <CheckIcon className={iconStyles} />;
};

export const getConditionText = (condition, passwordConditions, showRedHints, text) => {
  return (
    <Typography
      variant="paragraph10"
      color={getTextColor(condition, passwordConditions, showRedHints)}
    >
      {text}
    </Typography>
  );
};
