import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTable, useFilters, useSortBy } from 'react-table';
import { matchSorter } from 'match-sorter';
import { get } from 'lodash';

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function Table({
  columns,
  data,
  getHeaderProps,
  tableClass,
  filterOptions,
  rowKey,
  sortBy,
  getCustomRowProps,
  cellClassName,
}) {
  const [sortedObjects, setSortedObjects] = useState(sortBy);

  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
    }),
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      filterTypes,
      autoResetSortBy: false,
      initialState: { sortBy },
    },
    useFilters,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
    setSortBy,
  } = tableInstance;

  useEffect(() => {
    Object.keys(filterOptions).forEach((column) => {
      if (Object.prototype.hasOwnProperty.call(filterOptions, column)) {
        setFilter(column, filterOptions[column][0]);
      }
    });
  }, [filterOptions, setFilter]);

  return (
    <>
      <table
        {...getTableProps()}
        className={`min-w-full divide-y divide-gray-200 ${tableClass}`}
      >
        <thead className="bg-vartana-gray-30">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps([getHeaderProps(column)])}
                    scope="col"
                    onClick={() => {
                      const sortPresent = sortedObjects.filter(
                        ({ id }) => id === column.id
                      );
                      if (sortPresent.length) {
                        const desc = !(!!sortPresent[0].desc === true);

                        const newSort = [{ id: column.id, desc }].reduce(
                          (res, item) => res.filter((i) => i.id !== item.id).concat(item),
                          [...sortedObjects]
                        );

                        setSortedObjects(newSort);
                        setSortBy([{ id: column.id, desc }, ...newSort]);
                      }
                    }}
                  >
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
          {rows.length > 0 &&
            rows.map((row) => {
              prepareRow(row);
              const key = get(row, rowKey, '') || row.getRowProps().key;
              return (
                <tr {...row.getRowProps(getCustomRowProps(row))} key={key}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`px-4 py-6 whitespace-nowrap ${cellClassName}`}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
}

const defaultPropGetter = () => ({});

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getHeaderProps: PropTypes.func,
  getCustomRowProps: PropTypes.func,
  tableClass: PropTypes.string,
  cellClassName: PropTypes.string,
  filterOptions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
  rowKey: PropTypes.string,
  sortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
};

Table.defaultProps = {
  getHeaderProps: defaultPropGetter,
  tableClass: '',
  cellClassName: '',
  filterOptions: [],
  rowKey: '',
  sortBy: [],
  getCustomRowProps: () => {},
};

export default Table;
