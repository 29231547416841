import { ReactElement, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ButtonV2, Typography } from '@vartanainc/design-system';

import { MoreInfoWrapper } from '../../../macro_components/MoreInfo/MoreInfoWrapper';
import AutoLoad from '../../../components/AutoLoad';

export default function MoreInfoCustomers(): ReactElement {
  const [loading, setLoading] = useState(true);
  const [companyName, setCompanyName] = useState('');
  const { companyNumber } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <AutoLoad loading={loading} containerClassName="absolute top-1/2 left-1/2 " />
      <div className={`flex flex-col h-full ${loading ? 'hidden' : ''}`}>
        <div className="px-16 border-b border-gray-200 bg-white flex items-center min-h-[6.375rem] justify-between">
          <Typography className="capitalize" variant="heading24">
            {companyName}
          </Typography>
          <ButtonV2
            variant={{
              type: 'secondary',
              typography: 'paragraph14',
            }}
            onClick={() => navigate(`/dashboard/customers/${companyNumber}/track`)}
            text="Share link"
          />
        </div>
        <MoreInfoWrapper setLoading={setLoading} setCompanyName={setCompanyName} />
      </div>
    </>
  );
}
