import { Outlet, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { Typography } from '@vartanainc/design-system';
import { GET_RESELLER_BY_NUMBER } from '../../graphql/queries/resellers';
import { TabsWithRouter } from '../../components/TabbedNavigation';
import AutoLoad from '../../components/AutoLoad';

export function Reseller() {
  const { number } = useParams();

  const { data: resellerData, loading: resellerLoading } = useQuery(
    GET_RESELLER_BY_NUMBER,
    { variables: { number } }
  );

  const reseller = get(resellerData, 'resellerByNumber', {});

  return (
    <>
      <header className="w-full px-8 border-b border-b-vartana-gray-40-v3 bg-white">
        <div className="h-[6.375rem] flex justify-between items-center">
          <Typography variant="heading24" className="capitalize">
            {reseller.name}
          </Typography>
        </div>
      </header>
      <TabsWithRouter
        tabs={[
          { name: 'Profile', path: 'profile' },
          { name: 'Orders', path: 'orders' },
        ]}
      />
      <AutoLoad loading={resellerLoading}>
        <Outlet context={{ reseller }} />
      </AutoLoad>
    </>
  );
}
