/* eslint-disable react/prop-types */
import { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { getUSDateFormat, humanizeName } from '../../../utils/helpers';
import Table from '../../../components/Table';
import { BlueLink } from '../../../components/Link';
import Loader from '../../../components/Loader';
import usePermissions from '../../../utils/hooks/permissions';
import NoDataState from '../../../components/NoDataState/NoDataState';

const sortableHeaders = [
  { id: 'name' },
  { id: 'assignedTo' },
  { id: 'createdBy' },
  { id: 'updatedOn' },
];

function AllRoles() {
  const { roles, rolesLoading } = useOutletContext();
  const [, userHasPermission] = usePermissions();

  const data = useMemo(
    () =>
      roles.map((role) => ({
        role: {
          ...role,
          name: humanizeName(role.name),
        },
      })),
    [roles]
  );

  const tableColumns = useMemo(() => {
    const columns = [
      {
        Header: 'ROLE',
        accessor: 'name',
        Cell: ({ row: { original: { role } = {} } }) => (
          <BlueLink to={`${role.id}`}>{role.name}</BlueLink>
        ),
      },
      {
        Header: 'CREATED BY',
        accessor: 'createdBy',
        Cell: () => (
          <div className="vp-body text-vartana-black">Vartana admin</div> // TODO: Replace when available
        ),
      },
      {
        Header: 'UPDATED ON',
        accessor: 'updatedOn',
        Cell: ({ row: { original: { role } = {} } }) => (
          <div className="vp-body text-vartana-black">
            {getUSDateFormat(role.updatedAt)}
          </div>
        ),
      },
    ];

    if (userHasPermission('user', 'view_all')) {
      columns.splice(1, 0, {
        Header: 'ASSIGNED TO',
        accessor: 'assignedTo',
        Cell: ({ row: { original: { role } = {} } }) => (
          <BlueLink
            to="/dashboard/settings/users"
            state={{
              roleId: role.id,
            }}
          >
            View users
          </BlueLink>
        ),
      });
      return columns;
    }
    return columns;
  }, [userHasPermission]);

  if (rolesLoading) return <Loader containerClassName="absolute top-1/2 left-1/2" />;

  if (roles.length) {
    return (
      <Table
        cellClassName="px-8"
        columns={tableColumns}
        sortBy={sortableHeaders}
        data={data}
        getHeaderProps={() => ({
          className: `px-8 h-14 text-left vartana-p-small-bold text-vartana-gray-60 tracking-wider`,
        })}
      />
    );
  }

  return (
    <div className="flex justify-center items-center w-full h-full">
      <NoDataState />
    </div>
  );
}

export default AllRoles;
