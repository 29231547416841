import { ButtonV2, Typography } from '@vartanainc/design-system';
import { MilestoneStatusPill } from '../../../../components/Pill/MilestoneStatusPill';

interface MilestoneItemProps {
  onPrimaryClick: () => void;
  primaryButtonText: string;
  showPrimaryButton?: boolean;
  sequenceNumber: number;
  payoutAmount: string;
  payoutPercentage: string;
  status: string;
  enabledInvoice: boolean;
  number: string;
  milestoneInvoiceUrl?: string;
  isSpiff: boolean;
}

const MilestoneItem: React.FC<MilestoneItemProps> = ({
  onPrimaryClick,
  primaryButtonText,
  showPrimaryButton = true,
  sequenceNumber,
  payoutAmount,
  payoutPercentage,
  status,
  enabledInvoice,
  number,
  milestoneInvoiceUrl = '',
  isSpiff,
}) => {
  const SPIFF = 'SPIFF';
  return (
    <tr key={number}>
      <td className="text-center pr-3 py-3">
        <Typography variant="paragraph14">{sequenceNumber}</Typography>
      </td>
      <td className="px-4 py-3">
        <Typography variant="paragraph14">
          {`${payoutAmount} (${isSpiff ? SPIFF : payoutPercentage})`}
        </Typography>
      </td>
      <td className="pr-3 py-3">
        <MilestoneStatusPill status={status} />
      </td>
      <td className="pr-3 py-3">
        <div className="flex flex-row justify-center items-center">
          <div className="flex gap-4">
            {showPrimaryButton ? (
              <ButtonV2
                className="w-32"
                text={primaryButtonText}
                variant={{ type: 'primary', typography: 'paragraph12' }}
                onClick={onPrimaryClick}
                disabled={!enabledInvoice}
              />
            ) : (
              <a
                className="leading-none"
                rel="noreferrer"
                target="_blank"
                href={milestoneInvoiceUrl}
              >
                <ButtonV2
                  className="w-32"
                  text="View Invoice"
                  variant={{ type: 'ghost', typography: 'paragraph12' }}
                />
              </a>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};

export default MilestoneItem;
