import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import UploadDocumentModal from '../../../../components/Modals/UploadDocumentModal';
import { useDirectUploadFiles } from '../../../../utils/hooks';
import CustomNotification from '../../../../components/CustomNotification';

function UploadDocuments() {
  const location = useLocation();
  const useQueryParams = new URLSearchParams(location.search);
  const appraisalId = useQueryParams.get('appraisalId');
  const appraisalType = useQueryParams.get('appraisalType');
  const [showDocumentUploadModal, toggleDocumentUploadModal] = useState(true);
  const [directUploadFiles] = useDirectUploadFiles();

  const onDocumentSubmit = useCallback(
    async (values) => {
      directUploadFiles(
        [...values.financial],
        {
          documentType: 'financial',
        },
        {
          id: appraisalId,
          type: appraisalType || 'CreditAppraisal',
        }
      )
        .then(() => {
          toast.success(({ toastProps }) => {
            return (
              <CustomNotification
                type={toastProps.type}
                message="Documents submitted! Vartana is processing your application."
              />
            );
          });
          toggleDocumentUploadModal(false);
          setTimeout(() => {
            window.close();
          }, 5000);
        })
        .catch((error) => {
          console.error(error);
          toast.error(({ toastProps }) => {
            return (
              <CustomNotification
                type={toastProps.type}
                message="Something went wrong. Please try again later."
              />
            );
          });
          setTimeout(() => {
            window.close();
          }, 5000);
        });
    },
    [appraisalId, appraisalType, directUploadFiles]
  );

  const handleClose = () => {
    toggleDocumentUploadModal(false);
    window.close();
  };

  return (
    <UploadDocumentModal
      open={showDocumentUploadModal}
      onClose={handleClose}
      title="More information needed"
      onSubmit={onDocumentSubmit}
      documents={[{ label: '2 years of financial statements', type: 'financial' }]}
      acceptMultipleFiles
    />
  );
}

export default UploadDocuments;
