import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import { FreeTextDropdownField } from '@vartanainc/design-system';

function FreeTextDropdownInput({ id, name, label, options, ...rest }) {
  const [field, meta, { setValue }] = useField(name);
  const handleSelectOption = (selectedOption) => {
    // Todo: Need to fix this, value should be value but
    // in input field it should show the actual label.
    setValue(selectedOption.label);
  };

  return (
    <FreeTextDropdownField
      {...field}
      {...rest}
      id={id}
      name={name}
      value={meta.value}
      fullWidth
      label={label}
      showError={meta.touched && meta.error}
      helperText={meta.error}
      onSelectOption={handleSelectOption}
      options={options}
      autoComplete="off"
    />
  );
}

FreeTextDropdownInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    })
  ),
};

FreeTextDropdownInput.defaultProps = {
  id: '',
  name: '',
  placeholder: '',
  label: '',
  options: [
    {
      label: 'label',
      value: 'value',
    },
  ],
};

export default FreeTextDropdownInput;
