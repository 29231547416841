import PropTypes from 'prop-types';
import { Typography } from '@vartanainc/design-system';
import { ReactComponent as BulletIcon } from '../../assets/bullet.svg';

const MarketingText = ({ isSignUp }) => {
  const IconWithText = (text) => {
    return (
      <div className="flex gap-4">
        <BulletIcon className="h-6 w-auto" />
        <Typography variant="paragraph18">{text}</Typography>
      </div>
    );
  };

  return (
    <div className="align-middle flex flex-col justify-center mb-9">
      <div className="mb-9">
        <div className="max-w-[52ch]">
          <Typography variant="heading55">
            {isSignUp
              ? 'Self-service financing at your finger tips'
              : 'Payment flexibility designed for you.'}
          </Typography>
        </div>
      </div>
      <div className="flex gap-4 flex-col">
        <div className="flex gap-4 text-vartana-gray-60">
          {IconWithText('Instantly generate customer-facing payment options')}
        </div>
        <div className="flex gap-4 text-vartana-gray-60">
          {IconWithText('Offer monthly, quarterly, or annual payment terms')}
        </div>
        <div className="flex gap-4 text-vartana-gray-60">
          {isSignUp
            ? IconWithText('Close deals in minutes, not hours')
            : IconWithText('Turn quotes into signed orders in one go')}
        </div>
      </div>
    </div>
  );
};

MarketingText.propTypes = {
  isSignUp: PropTypes.bool,
};

MarketingText.defaultProps = {
  isSignUp: false,
};

export default MarketingText;
