import { gql } from '@apollo/client';

export const CANCEL_CREDIT_CHECK_CHANGE_REQUEST = gql`
  mutation cancelCreditCheckChangeRequest($companyNumber: String!) {
    cancelCreditCheckChangeRequest(companyNumber: $companyNumber) {
      id
      number
    }
  }
`;
