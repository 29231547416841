import { useCallback, useState } from 'react';
import { showErrorToast, getModalPlacementString } from '../../utils/helpers';

export const useSsoHandler = () => {
  const [showModal, setShowModal] = useState(false);

  const resetModal = useCallback(() => {
    setShowModal(false);
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const handleMessage = useCallback(
    (event) => {
      // IMPORTANT: check the origin of the data!
      if (event.origin === window.location.origin) {
        const message = event.data;
        if (message.type && message.type.includes('login-status')) {
          if (message.data.redirectUrl) {
            if (message.type === 'login-status-redirected') {
              resetModal();
              window.location.href = message.data.redirectUrl;
            } else if (message.type === 'login-status-error') {
              const queryParams = message.data.redirectUrl.split('?')[1];
              const redirectQueryParams = new URLSearchParams(`?${queryParams}`);
              const redirectError = redirectQueryParams.get('error') || '';
              resetModal();
              showErrorToast(redirectError);
            }
          }
          if (message.type === 'login-status-closed') {
            window.removeEventListener('message', handleMessage);
          }
        }
      }
    },
    [resetModal]
  );

  const openModal = useCallback(
    (result) => {
      setShowModal(true);

      const placementString = getModalPlacementString(windowSize);

      const newWindow = window.open(result.url, '_blank', placementString);
      if (newWindow) {
        newWindow.focus();
      }

      window.addEventListener('message', handleMessage);
    },
    [handleMessage, windowSize]
  );

  return { showModal, resetModal, openModal, setWindowSize, getWindowSize };
};
