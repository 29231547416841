import { useMemo } from 'react';

import { useField } from 'formik';
import { SelectorButton as SelectorButtons } from '@vartanainc/design-system';
import FormLabel from '../FormLabel';
import FormFieldMsg from '../FormFieldMsg';

import 'react-step-progress-bar/styles.css';

interface optionProps {
  label: string;
  id: string;
}

interface SelectorButtonProps {
  name: string;
  label: string;
  options: optionProps[];
  disabled: boolean;
  multiSelect: boolean;
  preSelectedValues: [];
  onSelect;
}

const SelectorButton = ({
  name,
  label = '',
  options,
  disabled,
  multiSelect = false,
  preSelectedValues = [],
  onSelect = null,
}: SelectorButtonProps): JSX.Element => {
  const [field, { touched, error }, { setValue }] = useField({
    name,
    type: 'select',
    multiple: true,
  });

  const styleClasses = useMemo(
    () => ({
      default: {
        text: 'placeholder-vartana-gray-40',
        label: 'text-vartana-gray-60',
      },
      error: {
        text: 'text-vartana-dark-red placeholder-vartana-dark-red',
        label: 'text-vartana-dark-red',
      },
    }),
    []
  );

  const formStateClasses = touched && error ? styleClasses.error : styleClasses.default;

  const onValueUpdated = (newValue): void => {
    let list = field.value || [];
    const valueExists = list.includes(newValue);
    if (valueExists) list = list.filter((val) => val !== newValue);
    else list = [...list, newValue];

    setValue(list);
  };

  const addNewValue = (optionsSelected): void => {
    const oldList = field.value;
    if (!onSelect) {
      const transformedList = options
        .filter((option) => optionsSelected.includes(option.label))
        .map((opt) => opt.id);
      let clickedValue;
      if (oldList.length < transformedList.length) {
        clickedValue = transformedList.filter((id) => !oldList.includes(id));
      } else {
        clickedValue = oldList.filter((id) => !transformedList.includes(id));
      }

      if (clickedValue.length > 0) {
        onValueUpdated(clickedValue[0]);
      }
    } else onSelect(optionsSelected);
  };

  return (
    <>
      <div>
        <FormLabel name={field.name} label={label} />
        <SelectorButtons
          disabled={disabled}
          multiSelect={multiSelect}
          options={options}
          preSelectedValues={preSelectedValues}
          onSelect={(e) => {
            addNewValue(e);
          }}
        />
      </div>
      <FormFieldMsg
        show={touched}
        msg={error}
        className={`${formStateClasses.text} mt-2`}
      />
    </>
  );
};

export default SelectorButton;
