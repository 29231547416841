import React from 'react';
import Modal from 'react-modal';
import { Button, Typography } from '@vartanainc/design-system';
import PropTypes from 'prop-types';
import { ReactComponent as BankIcon } from '../../../../assets/bank-icon.svg';

export function SuccessModal({ isOpen, onClose }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName="flex justify-center items-center fixed inset-0 bg-vartana-gray-70/80"
      className="w-full max-w-screen-sm bg-white rounded-lg"
    >
      <div className="flex flex-col gap-4 p-10">
        <header className="flex gap-2 items-center">
          <BankIcon />
          <Typography variant="heading20">Payout information received</Typography>
        </header>

        <Typography variant="paragraph14">
          Payout information received. We will get back to you shortly with updates.
        </Typography>

        <Button className="mt-2 place-self-end" onClick={onClose}>
          Close window
        </Button>
      </div>
    </Modal>
  );
}

SuccessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
