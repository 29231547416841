import { useContext } from 'react';
import { Transition } from '@headlessui/react';

import Nav from '../Nav/Nav';

import { SidebarContext } from '../../../../context/DashboardContext';

const MobileSidenav = (): JSX.Element => {
  const { menu, toggleMenu } = useContext(SidebarContext);

  return (
    <>
      {/* Off-canvas menu for mobile, show/hide based on off-canvas menu state. */}
      <div className="md:hidden">
        <Transition show={menu}>
          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="fixed inset-0"
            >
              <div
                onClick={() => toggleMenu(false)}
                role="button"
                tabIndex={0}
                className="absolute inset-0 bg-gray-600 opacity-75 cursor-default"
              />
            </Transition.Child>

            <Transition.Child
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
              className="relative flex-1 flex flex-col max-w-xs w-full bg-white"
            >
              <>
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    onClick={() => toggleMenu(false)}
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  >
                    <span className="sr-only">Close sidebar</span>
                    {/* Heroicon name: outline/x */}
                    <svg
                      className="h-6 w-6 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto space-y-2">
                  <div className="flex items-center px-6"></div>
                  <Nav />
                </div>
                <div className="flex-shrink-0 w-14">
                  {/* Force sidebar to shrink to fit close icon */}
                </div>
              </>
            </Transition.Child>
          </div>
        </Transition>
      </div>
    </>
  );
};

export default MobileSidenav;
