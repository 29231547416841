import { get } from 'lodash';

import {
  composeWizardUrl,
  updateTrayInstance,
  initializeTrayData,
  getIntegrationInstance,
  generateConfigAuthCode,
  configFinished,
  validateConfigData,
} from '../../../api/tray';
import { reportError } from '../../../utils/helpers';

export async function activateIntegration(trayUserId, integration) {
  const authCode = await generateConfigAuthCode(trayUserId);
  const instance = await getIntegrationInstance(trayUserId, integration);
  const trayWizardUrl = composeWizardUrl(instance?.id, authCode);
  const trayWorkflowId = get(integration, 'initWorkflowId');
  const triggerUrl = get(instance, 'workflows.edges', []).find(
    (edge) => get(edge, 'node.sourceWorkflowId') === trayWorkflowId
  )?.node?.triggerUrl;
  return { triggerUrl, trayWizardUrl, instance };
}

export async function deactivateIntegration(trayUserId, integration) {
  const instance = await getIntegrationInstance(trayUserId, integration);
  await updateTrayInstance({ instance, trayUserId, enable: false });
  return initializeTrayData();
}

export async function handleTrayEvent({
  event,
  selectedInstance,
  trayUserId,
  initialWebhook,
  setIntegrations,
  goBack,
  goForward,
  onReady,
}) {
  switch (get(event, 'data.type')) {
  case 'tray.configPopup.ready':
    onReady();
    break;
  case 'tray.configPopup.cancel':
    goBack();
    break;
  case 'tray.configPopup.error':
    reportError(get(event, 'data.err', 'Error in Tray Popup'));
    goBack();
    break;
  case 'tray.configPopup.finish': {
    await configFinished({
      selectedInstance,
      trayUserId,
      setIntegrations,
      initialWorkflowUrl: initialWebhook,
      onSuccess: goForward,
      onFailure: goBack,
    });
    break;
  }
  case 'tray.configPopup.validate': {
    const trayWindow = document.getElementById('Tray iFrame').contentWindow;
    validateConfigData(trayWindow, get(event, 'data.data'));
    break;
  }
  default:
  }
}
