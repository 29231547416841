import { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@vartanainc/design-system';
import PropTypes from 'prop-types';
import { activateIntegration, handleTrayEvent } from './tray';
import { initializeCustomerPullSolutions, TRAY_ICON_KEY } from '../../../api/tray';
import TrayWizard from './TrayWizard';
import SyncSuccessful from './SyncSuccessful';
import IntegrationOptions from './IntegrationOptions';
import Import from '../../Dashboard/MainBody/Onboarding/Import';
import CommonModal from './CommonModal';
// import Button from '../../../components/Button';
import { HasPermission } from '../../../components/HasPermission/HasPermission';
import { MainbodyContext } from '../../../context/DashboardContext';

const styles = {
  modal: 'absolute left-1/2 transform-gpu -translate-x-1/2 px-5 pt-5 w-5/12',
};

const screens = Object.freeze({
  crm: 'crm-screen',
  fileUpload: 'file-upload-screen',
  tray: 'tray-screen',
  syncSuccessful: 'sync-successful-screen',
});

/*
 First Modal screen presenting all the available integrations
 */
function SyncWithIntegrations({ isOpen, onClose }) {
  const navigate = useNavigate();
  const [trayUserId, setTrayUserId] = useState('');
  const [integrations, setIntegrations] = useState([]);
  const [selectedInstance, setSelectedInstance] = useState({});

  const [initialWebhook, setInitialWebhook] = useState('');
  const [configWizardUrl, setConfigWizardUrl] = useState('');

  const [loading, setLoading] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(screens.crm);

  const { vendor } = useContext(MainbodyContext);

  async function initialize() {
    const trayData = await initializeCustomerPullSolutions();
    setIntegrations(trayData.newIntegrations);
    setTrayUserId(trayData.userId);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    initialize();
    return () => setIntegrations([]);
  }, []);

  const handleActivate = useCallback(
    async (integration) => {
      setLoading(true);
      const { triggerUrl, trayWizardUrl, instance } = await activateIntegration(
        trayUserId,
        integration
      );

      setInitialWebhook(triggerUrl);
      setConfigWizardUrl(trayWizardUrl);
      setSelectedInstance(instance);
      setCurrentScreen(screens.tray);
    },
    [trayUserId]
  );

  const handleFileUpload = useCallback(() => {
    setCurrentScreen(screens.fileUpload);
  }, []);

  const handleIframeEvent = useCallback(
    async (event) => {
      await handleTrayEvent({
        event,
        selectedInstance,
        trayUserId,
        initialWebhook,
        setIntegrations,
        onReady: () => setLoading(false),
        goBack: () => setCurrentScreen(screens.crm),
        goForward: () => setCurrentScreen(screens.syncSuccessful),
      });
    },
    [initialWebhook, selectedInstance, trayUserId]
  );

  useEffect(() => {
    window.addEventListener('message', handleIframeEvent);
    return () => window.removeEventListener('message', handleIframeEvent);
  }, [handleIframeEvent]);

  const crmScreen = useMemo(() => {
    return (
      <>
        <IntegrationOptions
          loading={loading}
          trayUserId={trayUserId}
          integrations={integrations}
          handleActivate={handleActivate}
          handleFileUpload={handleFileUpload}
        />

        {/* Footer */}
        <div className="flex flex-row justify-between items-center mt-16">
          <div>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </div>
          <div>
            <HasPermission resource="companySetting" action="integration">
              <Button
                onClick={() => navigate('/dashboard/settings/integrations')}
                variant="outlined"
                size="x-large"
              >
                Go to integration
              </Button>
            </HasPermission>
          </div>
        </div>
      </>
    );
  }, [
    onClose,
    navigate,
    loading,
    trayUserId,
    integrations,
    handleActivate,
    handleFileUpload,
  ]);

  return (
    <>
      <TrayWizard
        isOpen={isOpen}
        onClose={() => setCurrentScreen(screens.crm)}
        srcUrl={configWizardUrl}
        className={styles.modal}
        show={currentScreen === screens.tray && !loading}
      />
      {(currentScreen === screens.crm || loading) && (
        <CommonModal
          onClose={onClose}
          isOpen={isOpen}
          className={styles.modal}
          mainBody={crmScreen}
        />
      )}
      {currentScreen === screens.syncSuccessful && (
        <CommonModal
          onClose={onClose}
          isOpen={isOpen}
          className={styles.modal}
          mainBody={(
            <SyncSuccessful
              isOpen={currentScreen === screens.syncSuccessful}
              onClose={onClose}
              activatedIntegration={selectedInstance?.solution}
              iconKey={TRAY_ICON_KEY}
              className={styles.modal}
            />
          )}
        />
      )}
      {currentScreen === screens.fileUpload && (
        <CommonModal
          onClose={onClose}
          isOpen={isOpen}
          className={styles.modal}
          mainBody={(
            <Import
              vendor={vendor}
              onClose={onClose}
              onBack={() => setCurrentScreen(screens.crm)}
            />
          )}
        />
      )}
    </>
  );
}

SyncWithIntegrations.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

SyncWithIntegrations.defaultProps = {
  isOpen: true,
};

export default SyncWithIntegrations;
