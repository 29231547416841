import React, { useState, useCallback, useEffect } from 'react';
import { useFormik, FormikProvider, Form } from 'formik';
import * as yup from 'yup';
import { useQuery, useMutation } from '@apollo/client';
import { get, isEmpty } from 'lodash';
import { useOutletContext } from 'react-router-dom';
import FormInput from '../../../../components/FormInput';
import AutoLoad from '../../../../components/AutoLoad';
import { GET_FINANCE_USER_DETAILS } from '../../../../graphql/queries/user';
import { UPSERT_FINANCE_USER_DETAILS } from '../../../../graphql/mutations';
import { reportError } from '../../../../utils/helpers';
import CommonFooter from './CommonFooter';
import { BackButton } from '../../../../components/Button/BackButton';
import CommonHeader from './CommonHeader';
import TextInputField from '../../../../designSystem/TextInput/TextInputField';
import PatternInput from '../../../../designSystem/PatternInput/PatternInput';
import {
  commonRegex,
  FIELD_INVALID_MESSAGES,
} from '../../../../constants/common.constants';

const schema = yup.object().shape({
  firstName: yup
    .string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required')
    .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.firstName),
  lastName: yup
    .string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required')
    .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.lastName),
  email: yup
    .string()
    .email('Enter a valid email address')
    .required('This field is required'),
  phone: yup.string(),
});

function FinanceUserDetails() {
  const { goForward, goBack } = useOutletContext();

  const { loading: userProfileLoading, data: financeUserProfile } = useQuery(
    GET_FINANCE_USER_DETAILS
  );
  const [upsertFinanceUser] = useMutation(UPSERT_FINANCE_USER_DETAILS);

  const [initialValues, setInitialValues] = useState({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const onSubmit = useCallback(
    async (values, { setErrors }) => {
      try {
        const responseData = await upsertFinanceUser({
          variables: {
            ...values,
          },
        });
        const errors = get(responseData, 'errors', {});
        if (!isEmpty(errors)) {
          setErrors(errors);
          return;
        }

        goForward();
      } catch (e) {
        reportError(`Onboarding: Upsert finance user details: ${e.message}`);
      }
    },
    [goForward, upsertFinanceUser]
  );

  // Pre-fill form fields if finance user already exists
  useEffect(() => {
    setInitialValues({
      id: get(financeUserProfile, 'financeUser.id', ''),
      firstName: get(financeUserProfile, 'financeUser.firstName', ''),
      lastName: get(financeUserProfile, 'financeUser.lastName', ''),
      email: get(financeUserProfile, 'financeUser.email', ''),
      phone: get(financeUserProfile, 'financeUser.phone', '') || '',
    });
  }, [financeUserProfile]);

  const formikBag = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit,
  });

  const { isSubmitting, isValid, touched, handleSubmit } = formikBag;

  const disabled = isSubmitting || !isValid || !touched;
  return (
    <AutoLoad
      loading={userProfileLoading}
      containerClassName="flex justify-center"
      className="absolute text-center top-2/4 transform-gpu -translate-y-2/4 mt-8"
    >
      <FormikProvider value={formikBag}>
        <Form onSubmit={handleSubmit}>
          <FormInput containerClassName="hidden" name="id" type="hidden" />

          <fieldset disabled={isSubmitting}>
            <div className="space-y-6">
              <CommonHeader
                title="Account receivable contact"
                message="Team member that will set up payout bank account and receive invoices."
              />

              <div className="space-y-4">
                <div className="flex flex-row space-x-4">
                  <TextInputField name="firstName" label="First Name" />
                  <TextInputField name="lastName" label="Last Name" />
                </div>

                <div className="flex flex-row space-x-4">
                  <TextInputField name="email" label="Email" />
                  <PatternInput
                    name="phone"
                    label="Phone"
                    mask="_"
                    disableFullStoryRecording
                  />
                </div>
              </div>
            </div>

            <CommonFooter>
              <div className="flex justify-between items-center">
                <BackButton onClick={goBack} btnText="Back" />
                <button
                  onClick={() => handleSubmit()}
                  type="button"
                  disabled={disabled}
                  className={`
                        border-2 vp-btn-text inline-block focus:outline-none px-10 py-4 rounded-md 
                        disabled:text-vartana-gray-40 disabled:bg-vartana-gray-30 disabled:border-vartana-gray-30 text-vartana-blue-60 border-vartana-blue-60
                      `}
                >
                  <p>Next &gt;</p>
                </button>
              </div>
            </CommonFooter>
          </fieldset>
        </Form>
      </FormikProvider>
    </AutoLoad>
  );
}

export default FinanceUserDetails;
