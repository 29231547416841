import { useCallback, useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { pick, get } from 'lodash';
import PropTypes from 'prop-types';
import { ReactComponent as AttentionIcon } from '../../../../assets/attention.svg';
import { exchangePublicToken, getAccount, getLinkToken } from './utils/plaid';
import { convertKeysToCamelCase } from '../../../../utils/helpers';
import { paymentModes, plaidConnectionStates } from './utils/helpers';
import { SelectorButton } from '../../../../components/Button/SelectorButton';

const ErrorAlertBox = () => (
  <div className="flex items-start gap-1 py-3 px-4 bg-vartana-red-20">
    <AttentionIcon className="w-4 h-4" />

    <span className="flex flex-col p-small text-vartana-red-160">
      Unable to connect bank account. Please retry or use a different account
    </span>
  </div>
);

const OPTIONS = {
  manual: 'manual',
  instant: 'instant',
};

/**
 * Options: "Manual" (fill form) or "Instant" (through Plaid)
 */
export const BankConnectingOptions = ({ onConnectPlaid, onInstant, onManual, isEft }) => {
  const [selectedOption, setSelectedOption] = useState(OPTIONS.manual);
  const [showPlaidError, setShowPlaidError] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    const createLinkToken = async () => {
      const { token: plaidToken = '' } = await getLinkToken();
      setToken(plaidToken);
    };

    createLinkToken();
  }, []);

  const accountCallback = useCallback(
    async (response) => {
      const accountResponse = await getAccount(response);
      if (accountResponse) {
        const mergedResponse = pick(
          {
            ...accountResponse,
            logo: get(accountResponse, 'institution.logo', null),
          },
          [
            'accessToken',
            'accountId',
            'accountName',
            'accountNumber',
            'accountType',
            'bank',
            'itemId',
            'logo',
            'requestId',
            'routingNumber',
          ]
        );
        const additionalDetails = {
          contactName: isEft ? 'Plaid Contact' : '',
          institutionNumber: get(accountResponse, 'institutionNumber', null),
          paymentMode: isEft ? paymentModes.eft : paymentModes.ach,
          transitNumber: get(accountResponse, 'branch', null),
        };
        onConnectPlaid({
          state: plaidConnectionStates.CONNECTED,
          payload: { ...mergedResponse, ...additionalDetails },
        });
      } else {
        setShowPlaidError(true);
        onConnectPlaid({ state: plaidConnectionStates.ERROR });
        setSelectedOption('');
      }
    },
    [onConnectPlaid, isEft]
  );

  const onSuccess = useCallback(
    async (publicToken, metaData) => {
      onConnectPlaid({ state: plaidConnectionStates.LOADING });
      const tokenResponse = await exchangePublicToken(publicToken, metaData.account_id);
      const cleanedResponse = convertKeysToCamelCase({
        ...tokenResponse,
        ...metaData,
      });
      await accountCallback(cleanedResponse);
    },
    [accountCallback, onConnectPlaid]
  );

  const onExit = () => {
    onConnectPlaid({ state: plaidConnectionStates.CANCELLED });
    setSelectedOption('');
  };

  const { open, ready } = usePlaidLink({
    token,
    onSuccess,
    onExit,
  });

  return (
    <>
      <p className="vp-field-label text-vartana-gray-60">Connect to bank for payout</p>
      <div className="flex flex-row justify-center gap-4">
        <SelectorButton
          selected={selectedOption === OPTIONS.instant}
          onClick={() => {
            setSelectedOption(OPTIONS.instant);
            setShowPlaidError(false);
            onInstant();
            open();
          }}
          disabled={!ready}
        >
          Instantly
        </SelectorButton>

        <SelectorButton
          selected={selectedOption === OPTIONS.manual}
          onClick={() => {
            setShowPlaidError(false);
            setSelectedOption(OPTIONS.manual);
            onManual();
          }}
        >
          Manually
        </SelectorButton>
      </div>
      {showPlaidError && <ErrorAlertBox />}
    </>
  );
};

BankConnectingOptions.propTypes = {
  onConnectPlaid: PropTypes.func.isRequired,
  onInstant: PropTypes.func.isRequired,
  onManual: PropTypes.func.isRequired,
  isEft: PropTypes.bool.isRequired,
};
