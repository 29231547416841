import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { UPSERT_PAYMENT_METHOD } from '../../../../../graphql/mutations';

/**
 * Update payment method with secure validation step
 *
 * @return {function} upsertPaymentMethod - GraphQL mutation for `UPSERT_PAYMENT_METHOD`
 */
export const useUpsertPaymentMethod = () => {
  const [upsertPaymentMethod, { loading }] = useMutation(UPSERT_PAYMENT_METHOD);

  const updatePaymentMethod = useCallback(
    async ({ payload, verificationRequired = false, onSuccess, onError }) => {
      if (verificationRequired) {
        const response = await upsertPaymentMethod({
          variables: { ...payload, validateOnly: true },
        });

        if (response.errors) {
          onError?.(response.errors);
          return response;
        }
      }

      const response = await upsertPaymentMethod({
        variables: { ...payload, validateOnly: false },
      });

      if (response.errors) {
        onError?.(response.errors);
      } else onSuccess?.(response);
      return response;
    },
    [upsertPaymentMethod]
  );

  return [updatePaymentMethod, { loading }];
};
