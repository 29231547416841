import { Typography } from '@vartanainc/design-system';
import { useField } from 'formik';
import {
  useState,
  useRef,
  useEffect,
  ReactElement,
  useMemo,
  useCallback,
  FC,
} from 'react';
import { DropdownOption } from '../../utils/commonInterfaces';
import { ReactComponent as ArrowDown } from '../../assets/arrow_down.svg';
import './interestRateSelect.scss';

// Props interface supporting both dropdown and radio mode with locked state handling
interface InterestRateSelectProps {
  name: string;
  options: DropdownOption[];
  placeholder: string;
  onChange: (option: DropdownOption) => void;
  className?: string;
  defaultValue?: DropdownOption;
  disabled?: boolean;
  label: string;
  lockedMode?: boolean; // Prevents user interaction when true
  dropdownMode?: boolean; // Toggles between dropdown and radio display
  shouldOpenDropdownOnBottom?: boolean;
}

const InterestRateSelect: FC<InterestRateSelectProps> = ({
  name,
  options,
  placeholder,
  onChange,
  className = '',
  defaultValue = {},
  disabled = false,
  label,
  lockedMode = false,
  dropdownMode = false,
  shouldOpenDropdownOnBottom,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isFieldValueSet, setIsFieldValueSet] = useState<boolean>(false);
  const [field, , { setValue }] = useField(name);
  // state for tracking focused option
  const [focusedIndex, setFocusedIndex] = useState<number>(-1);
  const dropdownRef = useRef<HTMLButtonElement>(null);

  // setting field value as the default value if it is empty
  useEffect(() => {
    if (!!defaultValue && !isFieldValueSet) {
      setValue(defaultValue.value);
      setIsFieldValueSet(true);
    }
  }, [defaultValue, isFieldValueSet, setValue]);

  // Determines button styling based on component state and mode
  const dropdownStateClassname = useMemo((): string => {
    const defaultClassname =
      lockedMode || !dropdownMode ? 'select-button-locked' : 'select-button-unlocked';
    if (disabled) {
      return `${defaultClassname} select-button-disabled`;
    }
    if (field.value) {
      return `${defaultClassname} select-button-selected`;
    }
    return `${defaultClassname} select-button-default`;
  }, [lockedMode, dropdownMode, disabled, field.value]);

  // Determines radio button styling based on selection and lock state
  const getRadioStateClassname = useMemo(
    () =>
      (optionValue: number | string): string => {
        if (lockedMode) {
          if (defaultValue?.value === optionValue) {
            return 'select-button-locked select-button-selected';
          }
          return 'select-button-disabled';
        }
        if (disabled) {
          return 'select-button-disabled';
        }
        if (field.value === optionValue) {
          return 'select-button-selected';
        }
        return 'select-button-default';
      },
    [lockedMode, defaultValue, disabled, field.value]
  );

  // Handles outside clicks to close dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (!dropdownRef.current?.contains(event.target as Node) && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isOpen]);

  // Updates form value and triggers onChange callback
  const handleOptionClick = useCallback(
    async (option: DropdownOption): Promise<void> => {
      if (dropdownMode) setIsOpen(false);
      await setValue(option.value);
      onChange?.(option);
    },
    [dropdownMode, onChange, setValue]
  );

  const handleArrowDown = useCallback((): void => {
    if (!isOpen) {
      setIsOpen(true);
      setFocusedIndex(0);
    } else {
      setFocusedIndex((prev) => (prev < options.length - 1 ? prev + 1 : 0));
    }
  }, [isOpen, options.length]);

  const handleArrowUp = useCallback((): void => {
    if (!isOpen) {
      setIsOpen(true);
      setFocusedIndex(0);
    } else {
      setFocusedIndex((prev) => (prev > 0 ? prev - 1 : options.length - 1));
    }
  }, [isOpen, options.length]);

  const handlePressEnterKey = useCallback((): void => {
    // handling select option on enter and space
    if (isOpen && focusedIndex >= 0) {
      handleOptionClick(options[focusedIndex]);
    } else {
      // handling open menu if not yet open
      setIsOpen(true);
      setFocusedIndex(0);
    }
  }, [focusedIndex, handleOptionClick, isOpen, options]);

  const handleKeyClose = useCallback((): void => {
    if (isOpen) {
      // closing on tab press
      setIsOpen(false);
      setFocusedIndex(-1);
    }
  }, [isOpen]);

  const handleSearchOptionOnKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>): void => {
      // Handle first-letter navigation
      // example: user can click 1 to jump to the options starting with 1 like 1.99.
      if (event.key.length === 1) {
        const index = options.findIndex((option) =>
          option.label.toLowerCase().startsWith(event.key.toLowerCase())
        );
        if (index >= 0) {
          setFocusedIndex(index);
        }
      }
    },
    [options]
  );

  // keyboard event handlers
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>): void => {
      if (!dropdownMode) return;

      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          handleArrowDown();
          break;

        case 'ArrowUp':
          event.preventDefault();
          // letting user open and close drop using arrow up key
          handleArrowUp();
          break;

        case 'Enter':
        case ' ':
          event.preventDefault();
          handlePressEnterKey();
          break;

        case 'Escape':
          event.preventDefault();
          // closing dropdown on esc key
          handleKeyClose();
          break;

        case 'Tab':
          event.preventDefault();
          handleKeyClose();
          break;

        default:
          handleSearchOptionOnKeyPress(event);
          break;
      }
    },
    [
      dropdownMode,
      handleArrowDown,
      handleArrowUp,
      handlePressEnterKey,
      handleKeyClose,
      handleSearchOptionOnKeyPress,
    ]
  );

  const findOption = useCallback(
    (value: number | string): DropdownOption | null => {
      return options.find((option) => option.value === value) ?? null;
    },
    [options]
  );

  // Update useEffect to reset focusedIndex when dropdown closes
  useEffect(() => {
    if (!isOpen) {
      setFocusedIndex(-1);
    }
  }, [isOpen]);

  // Update the dropdownComponent to include keyboard navigation
  const getDropdownComponent = useCallback((): ReactElement => {
    return (
      <button
        className={`select-container ${className}`}
        ref={dropdownRef}
        onKeyDown={handleKeyDown}
        tabIndex={0} // Make the container focusable
        type="button"
      >
        <button
          className={`select-button ${dropdownStateClassname} ${isOpen ? 'active' : ''}`}
          onClick={() => setIsOpen(!isOpen)}
          type="button"
          aria-haspopup="listbox"
          aria-expanded={isOpen}
          aria-controls="dropdown-options"
          aria-labelledby="dropdown-label"
        >
          <span className={`select-value ${!field.value ? 'placeholder' : ''}`}>
            {field.value ? findOption(field.value)?.label : placeholder}
          </span>
          {!lockedMode && (
            <ArrowDown className={`select-arrow ${isOpen ? 'open' : ''}`} />
          )}
        </button>

        {isOpen && (
          <div
            id="dropdown-options"
            className={`select-dropdown ${
              shouldOpenDropdownOnBottom
                ? 'select-dropdown-bottom'
                : 'select-dropdown-top'
            }`}
            role="listbox"
            tabIndex={0}
            aria-activedescendant={
              focusedIndex >= 0 ? `option-${options[focusedIndex].value}` : undefined
            }
          >
            {options.map((option, index) => (
              <button
                key={option.value}
                id={`option-${option.value}`}
                className={`select-option ${
                  field.value === option.value ? 'selected' : ''
                } ${focusedIndex === index ? 'focused' : ''}`}
                onClick={() => handleOptionClick(option)}
                role="option"
                type="button"
                aria-selected={field.value === option.value}
                tabIndex={-1} // Remove from tab order as we're handling focus manually
              >
                {option.label}
              </button>
            ))}
          </div>
        )}
      </button>
    );
  }, [
    className,
    handleKeyDown,
    dropdownStateClassname,
    isOpen,
    field.value,
    findOption,
    placeholder,
    lockedMode,
    shouldOpenDropdownOnBottom,
    focusedIndex,
    options,
    handleOptionClick,
  ]);

  // Renders radio button variant for simple option selection
  const getRadioComponent = useCallback((): ReactElement => {
    return (
      <div className="radio-container">
        {options.map((option) => (
          <button
            key={option.value}
            className={`select-button ${getRadioStateClassname(
              option.value
            )} radio-button-padding`}
            onClick={() => handleOptionClick(option)}
            type="button"
          >
            <span className={`select-value ${!field.value ? 'default' : 'selected'}`}>
              {option.label}
            </span>
          </button>
        ))}
      </div>
    );
  }, [options, getRadioStateClassname, field.value, handleOptionClick]);

  return (
    <div className="interest-rate-select">
      <Typography variant="paragraph9" color="color-gray-140">
        {label}
      </Typography>
      {dropdownMode ? getDropdownComponent() : getRadioComponent()}
    </div>
  );
};

export default InterestRateSelect;
