import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import { useMutation } from '@apollo/client';
import { ReactComponent as BackIcon } from '../../assets/back_icon.svg';
import crossIcon from '../../assets/cross.svg';
import DangerousTooltip from '../DangerousTooltip';
import { UPDATE_ORDER_DOCUMENTATION_FEE } from '../../graphql/queries/order';
import AutoLoad from '../AutoLoad';
import { showErrorToast } from '../../utils/helpers';

function DocumentationFeeModal({ open, onClose, vendorName, buyerName, orderNumber }) {
  const [selectedBtn, setSelectedBtn] = useState('vendor');
  const [updateDocFee, { loading: updatingFee }] = useMutation(
    UPDATE_ORDER_DOCUMENTATION_FEE
  );

  const handleUpdateDocFee = () => {
    updateDocFee({ variables: { orderNumber, selectedPayer: selectedBtn } })
      .then(() => {
        // Todo: handle success case when UI is available.
        onClose();
      })
      .catch(() => {
        // Todo: handle error here when UI is available.
        showErrorToast();
      });
  };

  return (
    <Modal isOpen={open} className="modal" ariaHideApp={false}>
      <div
        className="absolute top-48 left-1/2 
                      transform-gpu -translate-x-2/4 
                      w-2/5 rounded-lg bg-white py-4 
                      px-6"
      >
        <div className="flex flex-row-reverse">
          <button className="focus:outline-none cursor-pointer" onClick={onClose}>
            <img alt="close" src={crossIcon} />
          </button>
        </div>
        <div className="flex gap-1">
          <h1 className="vp-card-title inline-block">Documentation fee is paid by</h1>
          <DangerousTooltip
            content={(
              <p>
                A documentation fee is a one- <br />
                time charge that the seller <br />
                covers or will be added to the <br />
                customer&apos;s first invoice.
              </p>
            )}
          />
        </div>
        <div className="flex gap-4 mt-8 justify-center">
          <button
            data-tooltip-target="tooltip-default"
            onClick={() => setSelectedBtn('vendor')}
            className={`${
              selectedBtn === 'vendor' ? 'bg-vartana-blue-40' : 'bg-vartana-light-blue-20'
            } w-60 py-3 rounded-full px-5`}
          >
            {/* Todo: discuss case if vendor/company name is too long. */}
            <p
              className={`vp-btn-text  ${
                selectedBtn === 'vendor' ? 'text-white' : 'vartana-blue-80'
              }`}
            >
              {vendorName}
            </p>
          </button>
          <button
            onClick={() => setSelectedBtn('customer')}
            className={`${
              selectedBtn === 'customer'
                ? 'bg-vartana-blue-40'
                : 'bg-vartana-light-blue-20'
            } w-60 py-3 rounded-full px-5`}
          >
            <p
              className={`vp-btn-text ${
                selectedBtn === 'customer' ? 'text-white' : 'vartana-blue-80'
              }`}
            >
              {buyerName}
            </p>
          </button>
        </div>

        <div className="flex justify-between mt-6">
          <div className="flex items-center">
            <button onClick={onClose} className="flex items-center gap-1">
              <BackIcon />
              <span className="vp-btn-text text-vartana-blue-60">Cancel</span>
            </button>
          </div>
          <AutoLoad loading={updatingFee}>
            <button
              type="button"
              onClick={handleUpdateDocFee}
              className="py-4 px-14  bg-vartana-blue-60 rounded-md"
            >
              <p className="vp-btn-text text-white">Submit</p>
            </button>
          </AutoLoad>
        </div>
      </div>
    </Modal>
  );
}

DocumentationFeeModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  vendorName: PropTypes.string.isRequired,
  buyerName: PropTypes.string.isRequired,
  orderNumber: PropTypes.string.isRequired,
};

DocumentationFeeModal.defaultProps = {
  open: false,
  onClose: () => {},
};

export default DocumentationFeeModal;
