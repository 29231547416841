/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import React, { useMemo } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { get } from 'lodash';
import { TextPill, Typography } from '@vartanainc/design-system';
import Table from '../../../../components/Table';
import SortableHeader from '../../../../components/Table/SortableHeader';
import { formatCapital } from '../../../../utils/helpers';
import ZeroState from '../../../../components/ZeroState/ZeroState';

function CustomerContacts() {
  const { customerData, companyNumber } = useOutletContext();

  const contacts = useMemo(() => get(customerData, 'company.users', []), [customerData]);

  const columns = useMemo(
    () => [
      {
        Header: (props) => {
          return <SortableHeader props={props} title="Name" />;
        },
        accessor: 'name.fullname',
        Cell: (props) => {
          return (
            <Link
              to={`/dashboard/customers/${companyNumber}/contacts/${props.row.original.name.number}`}
            >
              <Typography variant="paragraph14" bold color="color-blue-120">
                {formatCapital(props.row.original.name.fullname)}
              </Typography>
            </Link>
          );
        },
      },
      {
        Header: (props) => <SortableHeader props={props} title="Email" />,
        accessor: 'email',
        Cell: (props) => {
          return props.value ? (
            <Typography variant="paragraph14" color="color-black-100">
              {props.value}
            </Typography>
          ) : (
            <Link
              to={`/dashboard/customers/${companyNumber}/contacts/${props.row.original.name.number}/edit`}
            >
              <Typography variant="paragraph14" bold color="color-blue-120">
                Add email
              </Typography>
            </Link>
          );
        },
      },
      {
        Header: (props) => <SortableHeader props={props} title="Phone" />,
        accessor: 'phone',
        Cell: (props) => {
          return props.value ? (
            <Typography variant="paragraph14" color="color-black-100">
              {props.value}
            </Typography>
          ) : (
            <Link
              to={`/dashboard/customers/${companyNumber}/contacts/${props.row.original.name.number}/edit`}
            >
              <Typography variant="paragraph14" bold color="color-blue-120">
                Add number
              </Typography>
            </Link>
          );
        },
      },
      {
        Header: () => {
          return (
            <div className="flex space-x-2">
              <p>TAGS</p>
            </div>
          );
        },
        accessor: 'primary',
        Cell: (props) => {
          const showPrimary = props.value;
          const showInvoice = props.cell.row.original.receiveInvoice;

          return (
            <div className="flex flex-col gap-2">
              {showPrimary && (
                <TextPill
                  text="Primary Contact"
                  color="color-steel-180"
                  bgColor="color-blue-70"
                />
              )}
              {showInvoice && (
                <TextPill
                  text="Invoice Recipient"
                  color="color-steel-180"
                  bgColor="color-blue-70"
                />
              )}
            </div>
          );
        },
      },
    ],
    [companyNumber]
  );

  const data = useMemo(() => {
    return contacts.map((contact) => {
      return {
        name: {
          fullname: formatCapital(contact.fullName),
          number: contact.number,
        },
        email: contact.email,
        phone: contact.phone,
        owner: contact.isOwner,
        primary: get(contact, 'primary', false),
        receiveInvoice: contact.receiveInvoice,
      };
    });
  }, [contacts]);

  const sortBy = useMemo(
    () => [{ id: 'name.fullname' }, { id: 'email' }, { id: 'phone' }],
    []
  );

  const dataTable = useMemo(() => {
    if (data.length) {
      return (
        <Table
          columns={columns}
          data={data}
          sortBy={sortBy}
          getHeaderProps={() => ({
            className:
              'px-4 py-4 text-left text-xs font-medium text-vartana-gray-60 tracking-wider whitespace-nowrap uppercase',
          })}
        />
      );
    }
    return (
      <div className="h-full w-full flex items-center">
        <ZeroState page="customer_contact" cta="none" />
      </div>
    );
  }, [data, columns, sortBy]);

  return dataTable;
}

export default CustomerContacts;
