import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import Loader from '../Loader';

export const SelectorButton = ({
  selected,
  disabled,
  loading,
  onClick,
  children,
  ...rest
}) => (
  <button
    type="button"
    disabled={disabled}
    onClick={onClick}
    className={twMerge(
      'shadow-lift-2 h-12 w-full py-3 rounded-full bg-white disabled:bg-vartana-gray-30 hover:bg-vartana-blue-20 text-vartana-gray-70 disabled:text-vartana-gray-110 disabled:shadow-transparent vp-btn-text',
      selected
        ? 'text-white bg-vartana-blue-40 hover:bg-vartana-blue-100 shadow-lift-3-inverted'
        : ''
    )}
    {...rest}
  >
    {loading ? <Loader /> : children}
  </button>
);

SelectorButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

SelectorButton.defaultProps = {
  disabled: false,
  loading: false,
  onClick: () => {},
};
