export const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_CLIENT_ITEM_ACCESS_TOKEN,
  enabled:
    process.env.REACT_APP_NODE_ENV === 'production' ||
    process.env.REACT_APP_NODE_ENV === 'eksstaging' ||
    process.env.REACT_APP_NODE_ENV === 'staging' ||
    process.env.REACT_APP_NODE_ENV === 'qa' ||
    process.env.REACT_APP_NODE_ENV === 'preview',
  environment: process.env.REACT_APP_NODE_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  code_version: process.env.REACT_APP_GIT_SHA,
  nodeSourceMaps: true,
  payload: {
    client: {
      environment: process.env.REACT_APP_NODE_ENV,
      javascript: {
        source_map_enabled: true,
        code_version: process.env.REACT_APP_GIT_SHA,
        guess_uncaught_frames: true
      },
    },
  }
};
