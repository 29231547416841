import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

// This hook provides access to Google Maps services.
export const useMapsServices = () => {
  // Destructure the placesService and placesAutocompleteService from the usePlacesService hook.
  const { placesService, placesAutocompleteService } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE, // Use the Google API key from the environment variables.
    libraries: ['places'], // Specify the Google Maps libraries to load.
  });

  /**
   * Fetches predictions for a given input value using the Google Places Autocomplete service.
   *
   * @param {string} inputValue - The input value for which predictions are requested.
   * @param {function} callback - A callback function to handle the predictions and status returned by the service.
   * @returns {void}
   */
  const getPlacePredictions = (inputValue, countries, callback) => {
    // Check if the Places Autocomplete service is available before calling getPlacePredictions method
    placesAutocompleteService?.getPlacePredictions(
      {
        // Specify the input value for predictions
        input: inputValue,
        // Set to false to include results outside of strict bounds
        strictBounds: false,
        // Limit predictions based on enabled countries
        componentRestrictions: { country: countries },
        // Specify the type of predictions (in this case, addresses)
        types: ['address'],
      },
      // Callback function to handle predictions and status
      (predictions, status) => {
        // Invoke the provided callback with predictions array or an empty array if predictions are not available,
        // along with the status returned by the service
        callback(predictions || [], status);
      }
    );
  };

  /**
   * Fetches details for a place using the Google Places Service.
   *
   * @param {string} placeId - The unique identifier of the place for which details are requested.
   * @param {function} callback - A callback function to handle the details and status returned by the service.
   * @returns {void}
   */
  const getPlaceDetails = (placeId, callback) =>
    // Check if the Places Service is available before calling getDetails method
    placesService?.getDetails(
      {
        placeId,
        // Specify the fields to be returned for the place (name and address components)
        fields: ['name', 'address_components'],
      },
      // Callback function to handle the details and status
      (place, status) => {
        // Invoke the provided callback with the retrieved place and its status
        callback(place, status);
      }
    );

  // Return the getPlacePredictions and getPlaceDetails functions from the hook.
  return {
    getPlacePredictions,
    getPlaceDetails,
  };
};
