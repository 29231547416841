/**
 * MilestoneStatusPill.tsx
 * @param {string} status - 'awaiting acceptance', 'need invoice' or 'paid'
 * @param {string} className - Style classes
 *
 * This pill is used for showing milestone statuses
 *
 */

import { upperCase } from 'lodash';
import { twMerge } from 'tailwind-merge';

const milestoneStatusClassNames = {
  paid: 'success',
  need_invoice: 'bg-vartana-gold-60',
  awaiting_acceptance: 'bg-vartana-light-purple',
};

interface MilestoneStatusPillProps {
  status: string;
  className?: string;
}

export function MilestoneStatusPill({
  status,
  className = '',
}: MilestoneStatusPillProps): JSX.Element {
  return (
    <div className="flex h-6 px-3 py-1 justify-center items-center">
      <span
        className={twMerge(className, `pill ${milestoneStatusClassNames[status]}` || '')}
      >
        {upperCase(status)}
      </span>
    </div>
  );
}
