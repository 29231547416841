/**
 * This component renders the form fields for customer address in case
 * when a new customer is being created from the order form
 */
import { Typography } from '@vartanainc/design-system';
import { useFormikContext } from 'formik';

import { useReactiveVar } from '@apollo/client';
import { get } from 'lodash';
import { useEffect } from 'react';
import MultiCountryZipInput from '../../../designSystem/MultiCountryZipInput/MultiCountryZipInput';
import {
  getCountryOptions,
  getStateOptions,
  handleCountrySelect,
} from '../../../utils/helpers';
import { sessionVar } from '../../../graphql/cache';
import TextInputField from '../../../designSystem/TextInput/TextInputField';
import AddressInputField from '../../../designSystem/AddressInput/AddressInput';
import CustomDropdown from '../../../designSystem/FormikDropdownInput/FormikDropdownInput';
import { useSetAddressFields } from '../../../utils/hooks';
import { COUNTRY_TO_CURRENCY_MAP } from '../../../static';

function NewCustomerAddress(): JSX.Element {
  const formikBag = useFormikContext();
  const { setFieldValue, validateField, values } = formikBag;
  const sessionData = useReactiveVar(sessionVar);
  const multipleCountriesEnabled = get(
    sessionData,
    'session.user.company.product.multipleCountriesEnabled',
    false
  );
  const enabledCurrencies = get(
    sessionData,
    'session.user.company.product.enabledCurrencies',
    []
  );
  const enabledCountries = get(
    sessionData,
    'session.user.company.product.enabledCountries',
    []
  );

  const customerCountry = get(values, 'country', '');

  const setSelectedPlace = useSetAddressFields(
    setFieldValue,
    validateField,
    multipleCountriesEnabled
  );

  // This useEffect hook updates the 'currency' field value based on the selected 'customerCountry'
  useEffect(() => {
    const shouldSetCurrency =
      customerCountry &&
      enabledCurrencies.includes(COUNTRY_TO_CURRENCY_MAP[customerCountry]);
    if (shouldSetCurrency) {
      setFieldValue('currency', COUNTRY_TO_CURRENCY_MAP[customerCountry]);
    }
  }, [customerCountry, enabledCurrencies, setFieldValue]);

  return (
    <div className="border-b border-vartana-gray-40-v3 mb-6">
      <Typography variant="heading18" bold color="color-black-100">
        Company Information
      </Typography>
      <div className="py-6">
        <AddressInputField
          id="street"
          name="street"
          label="Company address"
          afterPlaceSelect={(tempSelectedPlace) => setSelectedPlace(tempSelectedPlace)}
          countries={enabledCountries}
        />
        <div className="flex flex-row gap-4 mt-4 mb-2">
          <div className="w-1/2">
            <TextInputField
              {...{
                name: 'city',
                label: 'City',
              }}
              ref={null}
            />
          </div>
          <div className="w-1/2 flex flex-row gap-4">
            <CustomDropdown
              id="state"
              name="state"
              label="State"
              options={getStateOptions(enabledCountries)}
              placeholder="Select"
            />
            <MultiCountryZipInput id="zip" name="zip" label="Zip" />
          </div>
        </div>
        {multipleCountriesEnabled && (
          <div className="flex flex-row gap-4 mt-4 mb-2">
            <div className="w-1/2">
              <CustomDropdown
                id="country"
                name="country"
                label="Country"
                options={getCountryOptions(enabledCountries)}
                placeholder="Select"
                onChange={(e) => handleCountrySelect(formikBag, e.value)}
              />
            </div>
            <div className="w-1/2 flex flex-row gap-4">
              <TextInputField
                {...{
                  name: 'currency',
                  label: 'Currency',
                  disabled: true,
                }}
                ref={null}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default NewCustomerAddress;
