import VartanaLoader from '../../assets/vartanaLoader.gif';
import SvgIcon from '../SvgIcon/SvgIcon';

const IconLoader = (): JSX.Element => {
  // This component helps load our SVG sprite as soon as the root is mounted.
  return (
    <>
      <SvgIcon className="hidden" name="" />
      <img className="hidden" src={VartanaLoader} alt="loader" />
    </>
  );
};

export default IconLoader;
