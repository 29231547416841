import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'twin.macro';

import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';

const Container = styled.div`
  .RSPBprogressBar {
    ${tw`mx-auto`}
  }

  .RSPBprogression {
    ${tw`bg-vartana-blue-50`}
  }

  .pending {
    ${tw`bg-vartana-blue-50 rounded-full w-4 h-4`}
    box-shadow: 0px 0px 0px 8px rgba(27, 109, 255, 0.3);
  }

  .complete {
    ${tw`shadow-none bg-vartana-blue-50 rounded-full w-4 h-4`}
  }
`;

const PERCENTAGE_PER_STEP = 33.334;

function OnboardingProgress({ currentStep }) {
  const percentCompleted = useMemo(() => {
    return currentStep * PERCENTAGE_PER_STEP;
    // switch (currentStep) {
    // case 0:
    //   return 0;
    // case 1:
    //   return 25;
    // case 2:
    //   return 50;
    // case 3:
    //   return 75;
    // case 4:
    //   return 100;
    // default:
    //   return 0;
    // }
  }, [currentStep]);

  const stepClass = useCallback(
    (target) => {
      if (percentCompleted >= target) {
        return 'complete';
      }
      return 'pending';
    },
    [percentCompleted]
  );

  return (
    <Container>
      <ProgressBar percent={percentCompleted} height={5} width={180}>
        <Step>
          {({ accomplished }) => (
            <div className={`indexed-step ${accomplished ? stepClass(25) : ''}`}></div>
          )}
        </Step>
        <Step>
          {({ accomplished }) => (
            <div className={`indexed-step ${accomplished ? stepClass(50) : ''}`}></div>
          )}
        </Step>
        <Step>
          {({ accomplished }) => (
            <div className={`indexed-step ${accomplished ? stepClass(75) : ''}`}></div>
          )}
        </Step>
        <Step>
          {({ accomplished }) => (
            <div className={`indexed-step ${accomplished ? stepClass(100) : ''}`}></div>
          )}
        </Step>
      </ProgressBar>
    </Container>
  );
}

OnboardingProgress.propTypes = {
  currentStep: PropTypes.number,
};

OnboardingProgress.defaultProps = {
  currentStep: 0,
};

export default OnboardingProgress;
