import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { twMerge } from 'tailwind-merge';

import crossIcon from '../../assets/cross.svg';
import { ReactComponent as ExclamationMarkIcon } from '../../assets/exclamation_mark.svg';
import { isRenderedFromHubspot } from '../../utils/helpers';
import './modal.scss';

function RemoveProposalModal({ open, handleClose, handleRemove }) {
  const modalClassName = isRenderedFromHubspot() ? 'hubspot-modal-content' : '';
  return (
    <Modal isOpen={open} className={twMerge(modalClassName, 'modal')} ariaHideApp={false}>
      <div className="absolute top-48 left-1/2 transform-gpu -translate-x-2/4 w-96">
        <div className="rounded-lg bg-white p-10 flex flex-col gap-4">
          <div className="absolute right-0 top-0 p-3">
            <button onClick={handleClose} type="button">
              <img alt="close" src={crossIcon} />
            </button>
          </div>
          <div className="flex gap-3">
            <ExclamationMarkIcon />
            <h3 className="page-title-small">Are you sure?</h3>
          </div>
          <p className="font-normal text-sm leading-5">
            Information will not be saved if you removed this payment option.
          </p>
          <div className="flex justify-between mt-6">
            <div className="flex items-center">
              <button onClick={handleClose} className="flex items-center gap-1">
                <span className="vp-btn-text text-vartana-blue-40">Cancel</span>
              </button>
            </div>
            <button
              type="button"
              onClick={handleRemove}
              className="py-4 px-10 bg-vartana-blue-60 rounded-md"
            >
              <p className="vp-btn-text text-white">Remove</p>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

RemoveProposalModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleRemove: PropTypes.func,
};

RemoveProposalModal.defaultProps = {
  open: false,
  handleClose: () => {},
  handleRemove: () => {},
};

export default RemoveProposalModal;
