import { Typography } from '@vartanainc/design-system';
import { twMerge } from 'tailwind-merge';
import vartanaLoaderGif from '../../assets/vartanaLoader.gif';

interface VartanaLoaderProps {
  className?: string;
  containerClassName?: string;
  textClassName?: string;
  fullscreen: boolean;
  loading: boolean;
  text?: React.ReactNode;
  textColor?: string;
}

/**
 * Component for displaying a loader animation.
 *
 * @param {string} className Additional CSS class names for styling the loader image.
 * @param {string} containerClassName Additional CSS class names for styling the loader container.
 * @param {boolean} fullscreen Indicates whether the loader should cover the entire screen.
 * @param {boolean} loading Indicates whether the loader should be displayed.
 */
function VartanaLoader({
  className,
  containerClassName,
  textClassName,
  fullscreen,
  loading,
  text,
  textColor,
}: VartanaLoaderProps): JSX.Element | null {
  if (loading) {
    return (
      <div
        className={`${twMerge(
          containerClassName,
          'w-full h-full z-10 bg-white bg-opacity-80 top-0 left-0 flex items-center justify-center flex-col gap-6'
        )} ${fullscreen ? 'fixed' : 'absolute'} `}
      >
        <img
          src={vartanaLoaderGif}
          alt="loader"
          className={twMerge(className, 'h-[6.25rem] w-[6.25rem]')}
        />
        {text && (
          <Typography
            variant="paragraph14"
            color={textColor || 'color-black-100'}
            className={twMerge(textClassName, 'text-center')}
            bold
          >
            {text}
          </Typography>
        )}
      </div>
    );
  }
  return null;
}

export default VartanaLoader;
