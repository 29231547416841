import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { isEmpty } from 'lodash';
import MultiFileUpload from '../MultiFileUpload';
import AutoLoad from '../AutoLoad';

import crossIcon from '../../assets/cross.svg';
import invoiceIcon from '../../assets/invoice-icon.svg';
import { ReactComponent as BackIcon } from '../../assets/back_icon.svg';

function uploadDocumentSchema(requiredDocuments) {
  let result = {};
  if (requiredDocuments.length) {
    result = requiredDocuments.reduce((map, obj) => {
      const schema = map;
      schema[obj.type] = yup
        .array()
        .required('Document is required')
        .test('Emptiness check', 'Document is required', (val) => !isEmpty(val));
      return schema;
    }, {});
  } else {
    result = {
      documents: yup
        .array()
        .required('Document is required')
        .test('Emptiness check', 'Document is required', (val) => !isEmpty(val)),
    };
  }

  return yup.object().shape(result);
}

const customStyles = {
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

function UploadDocumentModal({
  title,
  open,
  onClose,
  onSubmit,
  documents,
  acceptMultipleFiles,
}) {
  const [documentsAttested, setDocumentsAttested] = useState(false);
  const [documentFormSchema, setDocumentFormSchema] = useState(
    uploadDocumentSchema(documents)
  );

  useEffect(() => {
    setDocumentFormSchema(uploadDocumentSchema(documents));
  }, [documents]);

  const documentFields = useMemo(() => {
    let fields;
    if (documents.length) {
      fields = documents.map((object) => (
        <div key={`label-${object.type}`}>
          <p className="text-vartana-gray-60 mt-6 text-xs pb-2">{object.label}</p>
          <MultiFileUpload
            key={`file-${object.type}`}
            name={object.type}
            acceptMultipleFiles={acceptMultipleFiles}
            uploadText="Upload or drag and drop PDF files"
          />
        </div>
      ));
    } else {
      fields = (
        <div className="mt-6">
          <MultiFileUpload name="documents" />
        </div>
      );
    }
    return fields;
  }, [documents, acceptMultipleFiles]);

  return (
    <Modal isOpen={open} className="modal" ariaHideApp={false} style={customStyles}>
      <div className="absolute rounded-lg bg-white py-8 px-10 w-3/5">
        <Formik
          initialValues={{
            enableReinitialize: true,
            validateOnMount: true,
            documents: undefined,
          }}
          validationSchema={documentFormSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValid, dirty, values }) => {
            const isFinancialDocuments = title === 'More information needed';
            const disabled =
              isSubmitting ||
              !(isValid && dirty) ||
              (isFinancialDocuments && (!values.financial.length || !documentsAttested));

            return (
              <>
                <div className="absolute right-0 top-0 p-3">
                  <button
                    className="cursor-pointer"
                    onClick={() => {
                      if (!isSubmitting) onClose();
                    }}
                  >
                    <img alt="close" src={crossIcon} />
                  </button>
                </div>
                <div className="flex gap-2 text-vartana-black">
                  <img alt="close" src={invoiceIcon} />
                  <h1 className="vp-card-title-bold">{title}</h1>
                </div>
                {isFinancialDocuments ? (
                  <p className="pt-2 text-xs text-vartana-black">
                    Please upload the last 2 years of financial statements. Financial
                    statements should include an income statement and balance sheet. This
                    information may be available from a CPA, accounting system, or
                    business tax returns (Form 1065, 1120 or 1120-S) including Schedule L.
                    Click to see examples of&nbsp;
                    <a
                      href={process.env.REACT_APP_F1065_SAMPLE_URL}
                      className="relative cursor-pointer bg-white rounded-md font-medium text-vartana-blue-60 hover:text-blue focus-within:outline-none underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      1065
                    </a>
                    ,&nbsp;
                    <a
                      className="relative cursor-pointer bg-white rounded-md font-medium text-vartana-blue-60 hover:text-blue focus-within:outline-none underline"
                      href={process.env.REACT_APP_F1120_SAMPLE_URL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      1120
                    </a>
                    &nbsp; and{' '}
                    <a
                      className="relative cursor-pointer bg-white rounded-md font-medium text-vartana-blue-60 hover:text-blue focus-within:outline-none underline"
                      href={process.env.REACT_APP_F1120S_SAMPLE_URL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {' '}
                      1120-S
                    </a>
                    .
                  </p>
                ) : null}

                <Form>
                  <fieldset disabled={isSubmitting}>
                    {documentFields}
                    {isFinancialDocuments ? (
                      <div className="inline-flex sm:mr-6 mt-4">
                        <input
                          type="checkbox"
                          name="agreed"
                          className="mr-2 border-vartana-blue-60 click:bg-vartana-light-green"
                          checked={documentsAttested}
                          onChange={(e) => setDocumentsAttested(e.target.checked)}
                        />
                        <p className="text-xs text-vartana-gray-60">
                          I attest my customer has verified and provided consent to share
                          information associated with this credit application.
                        </p>
                      </div>
                    ) : null}

                    <div className="flex justify-between mt-6">
                      <div className="flex items-center">
                        <button
                          onClick={() => {
                            if (!isSubmitting) onClose();
                          }}
                          className="flex items-center gap-1"
                        >
                          <BackIcon />
                          <span className="vp-btn-text text-vartana-blue-60">Cancel</span>
                        </button>
                      </div>
                      <AutoLoad loading={isSubmitting}>
                        <button
                          type="submit"
                          className={`py-4 px-16 ${
                            disabled ? 'bg-vartana-gray-40' : 'bg-vartana-blue-60'
                          } rounded-md`}
                          disabled={disabled}
                        >
                          <p className="vp-btn-text text-white">Submit</p>
                        </button>
                      </AutoLoad>
                    </div>
                  </fieldset>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
}

UploadDocumentModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  acceptMultipleFiles: PropTypes.bool,
};

UploadDocumentModal.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
  title: '',
  documents: [],
  acceptMultipleFiles: false,
};

export default UploadDocumentModal;
