import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import { isVendor } from '../../../../utils/helpers';
import { labelValueField } from '../../../../constants/common.constants';
import { InviteStatusPill } from '../../../../components/Pill/InviteStatusPill';
import { GET_ORDER_OWNER } from '../../../../graphql/queries/order';
import { sessionVar } from '../../../../graphql/cache';
import AutoLoad from '../../../../components/AutoLoad';

function ResellerSection({ reseller, count, showCount }) {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const crmOpportunityId = searchParams.get('crmOpportunityId') || '';
  const orderNumber = params.orderNumber || searchParams.get('orderNumber') || '';

  const { data: orderData, loading: resellerLoading } = useQuery(GET_ORDER_OWNER, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    variables: {
      orderNumber,
      crmOpportunityId,
      companyNumber: get(reseller, 'number', ''),
    },
  });

  // if it's VD, the order number would be populated based on order route
  const isVendorDashboard = params.orderNumber;
  const order = get(orderData, 'orderBy', {});

  const secondaryOwner = order?.secondaryOwnerFor || {};
  const payoutAmount = parseFloat(order?.payoutAmount);
  const showPayoutAmount = payoutAmount && !order.hideOrderDetailsForCollaborator;

  const inviteStatus = get(reseller, 'resellerInvitationStatus');
  const inviteStatusPill = <InviteStatusPill status={inviteStatus} className="mt-1" />;

  const sessionData = useReactiveVar(sessionVar);
  const resellerLink = isVendor(get(sessionData, 'session.user.company'))
    ? `/dashboard/resellers/${get(reseller, 'number')}`
    : '';

  return (
    <AutoLoad
      loading={resellerLoading}
      containerClassName="h-full flex items-center py-10"
    >
      <section className="first:pt-0 py-5 grid grid-cols-2 gap-4">
        {labelValueField(
          `Partner ${showCount ? count : ''}`,
          get(reseller, 'name', ''),
          isVendorDashboard && resellerLink // the link should only reflect for vendor dashboard
        )}
        {labelValueField('Account', inviteStatusPill || '--')}
        {labelValueField('Order contact', get(secondaryOwner, 'firstName', ''))}
        {labelValueField('Last name', get(secondaryOwner, 'lastName', ''))}
        {labelValueField('Email', get(secondaryOwner, 'email', ''))}
        {showPayoutAmount
          ? labelValueField('Payout amount', order?.formattedPayoutAmount || '--')
          : ''}
      </section>
    </AutoLoad>
  );
}

ResellerSection.propTypes = {
  reseller: PropTypes.shape({}).isRequired,
  count: PropTypes.number.isRequired,
  showCount: PropTypes.bool.isRequired,
};

export default ResellerSection;
