/**
 * TEXT_CONSTANTS contains key-value pairs for text used throughout the application.
 * This centralization allows for easy updates and ensures consistency in UI labels,
 * tooltips, and messages. It includes terms for forms, buttons, tooltips, and error messages
 */

export const TEXT_CONSTANTS = {
  PG_ACKNOWLEDGEMENT:
    'By checking this box, I acknowledge that I am providing personal information of the individual(s) that can act as the personal guarantor. Vartana will review their credit history through a soft credit check which will not impact their credit score.',
};
