import { pick } from 'lodash';

const SUB_CATEGORIES_TO_HIDE = [
  'early_payoff_request',
  'early_payoff_declined',
  'early_payoff_approved',
  'early_payoff_completed',
];

export function formatPreferenceSets(notifPreferences, channelPreference) {
  const prefsData = {};
  notifPreferences
    .filter(
      (preference) =>
        preference.category !== 'user_related' &&
        !SUB_CATEGORIES_TO_HIDE.includes(preference.subCategory)
    )
    .map((preference) => ({
      ...preference,
      category: preference.category.replace('_related', ''),
    }))
    .forEach(({ category, subCategory, settings }) => {
      if (!prefsData[category]) prefsData[category] = {};

      prefsData[category][subCategory] = pick(settings, ['all', 'specific']);
    });

  prefsData.delivery_methods = {
    methods: pick(channelPreference?.setting, ['email', 'sms']),
  };

  return prefsData;
}
