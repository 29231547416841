import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { ButtonV2, Typography, TextPill, Card } from '@vartanainc/design-system';
import Modal from 'react-modal';
import { ACTION_TO_TEXT } from '../../../constants/permissions';
import CalendlyInvite from '../../Dashboard/MainBody/Onboarding/CalendlyInvite';
import { GET_VENDOR_BUYERS_DATA } from '../../../graphql/queries/vendor';
import usePermissions from '../../../utils/hooks/permissions';
import { getWebsiteURL } from '../../../utils/helpers';

import { SidebarContext } from '../../../context/DashboardContext';

import { ReactComponent as PriceChangeIcon } from '../../../assets/price_change.svg';
import { ReactComponent as ReceiptLongIcon } from '../../../assets/receipt_long_blue.svg';
import { ReactComponent as SupportAgentIcon } from '../../../assets/support_agent.svg';
import { ReactComponent as PersonAddIcon } from '../../../assets/person_add_blue.svg';
import { ReactComponent as PaymentsIcon } from '../../../assets/payments-gold.svg';
import { ReactComponent as CrossIcon } from '../../../assets/cross.svg';
import { ReactComponent as HamburgerIcon } from '../../../assets/hamburger.svg';

import InviteTeamModal from './InviteTeamModal/InviteTeamModal';

const MainBody = (): JSX.Element => {
  const navigate = useNavigate();
  const [openCalendly, setOpenCalendly] = useState(false);
  const [buyerExists, setBuyerExists] = useState(true);
  const [, userHasPermission] = usePermissions();
  const { menu, toggleMenu } = useContext(SidebarContext);
  const [showInviteTeamModal, setShowInviteTeamModal] = useState(false);

  useQuery(GET_VENDOR_BUYERS_DATA, {
    onCompleted: (data) => {
      setBuyerExists(data.vendorBuyersData.buyersExist);
    },
  });

  return (
    <div className="w-full flex flex-col bg-vartana-steel-20">
      <div className="py-9 px-8 bg-white w-full border-b border-vartana-gray-40-v3 flex items-center">
        <button
          onClick={() => {
            toggleMenu(!menu);
          }}
          className="md:hidden -ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
        >
          <span className="sr-only">Open sidebar</span>
          <HamburgerIcon />
        </button>
        <Typography variant="heading24" color="color-black-100">
          {`Let's get started`}
        </Typography>
      </div>
      <div className="w-full pt-10 pb-4 px-8 flex flex-col gap-10 max-w-[85.375rem] min-w-[68.75rem] mx-auto">
        <div className="w-full flex flex-col gap-4">
          <Typography variant="heading18" bold color="color-black-100">
            Quick actions
          </Typography>

          <div className="w-full flex gap-4">
            <div className="shadow-lg w-2/3 bg-white px-10 pt-8 pb-10 rounded-lg divide-x flex">
              {userHasPermission('customer', 'create') ? (
                <>
                  <div className="pr-10 flex flex-col justify-center items-start w-1/2">
                    <ReceiptLongIcon className="-mt-2 w-10 h-10" />
                    <div className="mt-4 mb-6">
                      <Typography variant="paragraph14" color="color-black-100">
                        Send a sales proposal with embedded payment options.
                      </Typography>
                    </div>
                    <ButtonV2
                      variant={{ type: 'primary', typography: 'paragraph12' }}
                      text={ACTION_TO_TEXT.order.send_proposal}
                      onClick={() =>
                        buyerExists
                          ? navigate('/dashboard/orders/new')
                          : navigate('/dashboard/orders')}
                    />
                  </div>
                  <div className="pl-10 flex flex-col justify-center items-start w-1/2">
                    <PriceChangeIcon className="-mt-2 w-10 h-10" />
                    <div className="mt-4 mb-6">
                      <Typography variant="paragraph14" color="color-black-100">
                        Check customer eligibility for flexible payment options.
                      </Typography>
                    </div>
                    <ButtonV2
                      variant={{ type: 'primary', typography: 'paragraph12' }}
                      text={ACTION_TO_TEXT.customer.add}
                      onClick={() => navigate('/dashboard/customers/new')}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="pr-10 flex flex-col justify-center items-start w-1/2">
                    <ReceiptLongIcon className="w-10 h-10 -mt-2" />
                    <div className="mt-4 mb-6">
                      <Typography variant="paragraph14" color="color-black-100">
                        Keep tabs on your incoming revenues by tracking your orders.
                      </Typography>
                    </div>
                    <ButtonV2
                      variant={{ type: 'primary', typography: 'paragraph12' }}
                      text="View orders"
                      onClick={() => navigate('/dashboard/orders')}
                    />
                  </div>
                  <div className="pl-10 flex flex-col justify-center items-start w-1/2">
                    <PaymentsIcon className="-mt-2 w-10 h-10" />
                    <div className="mt-4 mb-6">
                      <Typography variant="paragraph14" color="color-black-100">
                        Monitor payouts and customer payments all in one place.
                      </Typography>
                    </div>
                    <ButtonV2
                      variant={{ type: 'primary', typography: 'paragraph12' }}
                      text="View payments"
                      onClick={() => navigate('/dashboard/transactions')}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="shadow-lg w-1/3 bg-white px-10 pt-8 pb-10 rounded-lg flex flex-col gap-6">
              <div className="gap-2 flex flex-col items-start">
                <SupportAgentIcon className="w-8 h-8" />
                <Typography variant="paragraph14" color="color-black-100">
                  Discover the art of deal engineering and flexible payment options.
                </Typography>
                <ButtonV2
                  variant={{ type: 'text', typography: 'paragraph14' }}
                  text="Schedule demo"
                  onClick={() => setOpenCalendly(true)}
                />
              </div>
              <hr className="h-px bg-[#DFE3E9]" />
              <div className="gap-2 flex flex-col items-start">
                <PersonAddIcon className="w-8 h-8" />
                <Typography variant="paragraph14" color="color-black-100">
                  Self-service financing with your entire team
                </Typography>
                <ButtonV2
                  variant={{ type: 'text', typography: 'paragraph14' }}
                  text="Invite teammates"
                  onClick={() => setShowInviteTeamModal(true)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col gap-4">
          <Typography variant="heading18" bold color="color-black-100">
            Leverage the Vartana platform
          </Typography>

          <div className="w-full flex gap-4">
            <div className="w-1/3">
              <Card
                variant="bordered"
                borderColor="color-blue-100"
                containerClassName="h-full"
                contentClassName="h-full"
                parentContainerClassName="h-full"
                content={(
                  <>
                    <div className="flex flex-col gap-6 items-start h-full justify-between">
                      <div className="flex flex-col gap-6 items-start">
                        <TextPill
                          text="Sales"
                          color="color-steel-180"
                          bgColor="color-blue-70"
                        />

                        <div className="flex flex-col gap-4">
                          <Typography variant="heading16" bold color="color-black-100">
                            How Vartana helps B2B companies make sales
                          </Typography>
                          <Typography variant="paragraph14" color="color-black-100">
                            Using Vartana, enterprise companies increase closing deals
                            faster by offering payment flexibility to buyers.
                          </Typography>
                        </div>
                      </div>

                      <ButtonV2
                        variant={{ type: 'text', typography: 'paragraph14' }}
                        text="Read more"
                        onClick={() =>
                          window.open(
                            `${getWebsiteURL()}/blog-posts/how-vartana-helps-b2b-software-and-hardware-companies-make-sales`
                          )}
                        iconRight="chevron_right"
                      />
                    </div>
                  </>
                )}
              />
            </div>

            <div className="w-1/3">
              <Card
                variant="bordered"
                borderColor="color-gold-100"
                containerClassName="h-full"
                contentClassName="h-full"
                parentContainerClassName="h-full"
                content={(
                  <div className="flex flex-col gap-6 items-start h-full justify-between">
                    <div className="flex flex-col gap-6 items-start">
                      <TextPill
                        text="Finance"
                        color="color-steel-180"
                        bgColor="color-gold-60"
                      />

                      <div className="flex flex-col gap-4">
                        <Typography variant="heading16" bold color="color-black-100">
                          Leverage self-serve financing to unlock growth and boost sales
                        </Typography>
                        <Typography variant="paragraph14" color="color-black-100">
                          Self-serve financing allows customers to access financing
                          options directly through an online platform, facilitating
                          seamless and convenient transactions.
                        </Typography>
                      </div>
                    </div>

                    <ButtonV2
                      variant={{ type: 'text', typography: 'paragraph14' }}
                      text="Read more"
                      onClick={() =>
                        window.open(
                          `${getWebsiteURL()}/blog-posts/unlocking-growth-and-boosting-sales-the-power-of-b2b-financing-and-self-serve-financing`
                        )}
                      iconRight="chevron_right"
                    />
                  </div>
                )}
              />
            </div>

            <div className="w-1/3">
              <Card
                variant="bordered"
                borderColor="color-red-100"
                containerClassName="h-full"
                contentClassName="h-full"
                parentContainerClassName="h-full"
                content={(
                  <div className="flex flex-col gap-6 items-start h-full justify-between">
                    <div className="flex flex-col gap-6 items-start">
                      <TextPill
                        text="Trending"
                        color="color-steel-180"
                        bgColor="color-red-60"
                      />

                      <div className="flex flex-col gap-4">
                        <Typography variant="heading16" bold color="color-black-100">
                          Deal engineering: 5 tips to a successful deal close
                        </Typography>

                        <Typography variant="paragraph14" color="color-black-100">
                          Personalization breeds relevance. The key to a successful deal
                          close lies in tailored solutions that resonate with the
                          customer.
                        </Typography>
                      </div>
                    </div>

                    <ButtonV2
                      variant={{ type: 'text', typography: 'paragraph14' }}
                      text="Read more"
                      onClick={() =>
                        window.open(
                          `${getWebsiteURL()}/blog-posts/the-art-of-deal-engineering-5-tips-to-a-successful-deal-close`
                        )}
                      iconRight="chevron_right"
                    />
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={openCalendly}
        overlayClassName="modal flex justify-center items-center"
        className="flex justify-center items-center w-[75%] max-w-[60rem]"
        shouldCloseOnOverlayClick
        onRequestClose={() => setOpenCalendly(false)}
      >
        <div className="m-auto transform-gpu w-full h-full max-h-[45.875rem] shadow-lg rounded-xl bg-white flex flex-col justify-start overflow-y-auto">
          <div className="p-2 flex justify-end">
            <button onClick={() => setOpenCalendly(false)} className="w-6 h-6">
              <CrossIcon className="w-4 h-4" />
            </button>
          </div>
          <div className="px-10 pb-10 flex-1 flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <Typography variant="heading20">Welcome to Vartana!</Typography>
              <Typography variant="paragraph14">
                Book a walkthrough to get the most out of our platform.
              </Typography>
            </div>

            <div className="flex-1 flex justify-stretch flex-col -m-8">
              <CalendlyInvite onClose={() => setOpenCalendly(false)} isLaunchPadModal />
            </div>

            <div className="flex justify-end">
              <ButtonV2
                variant={{ type: 'secondary', typography: 'paragraph14' }}
                text="Cancel"
                onClick={() => setOpenCalendly(false)}
              />
            </div>
          </div>
        </div>
      </Modal>
      {showInviteTeamModal && (
        <InviteTeamModal
          isModalOpen={showInviteTeamModal}
          onClose={() => setShowInviteTeamModal(false)}
        />
      )}
    </div>
  );
};

export default MainBody;
