import React from 'react';
import PropTypes from 'prop-types';

import Loader from '../Loader';

function AutoLoad({ children, loading, className, containerClassName }) {
  return (
    <>
      {loading ? (
        <Loader
          isLoading
          className={`w-10 h-10 ${className}`}
          containerClassName={containerClassName}
        />
      ) : (
        children
      )}
    </>
  );
}

AutoLoad.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
};

AutoLoad.defaultProps = {
  children: null,
  className: '',
  containerClassName: '',
};

export default AutoLoad;
