/**
 * PatternInputV2 Component
 *
 * This is a reusable input component for formatted text input with various customization options.
 * It extends Formik's useField hook to handle form state and validation.
 * The component leverages InputFieldPatternFormatV2 for displaying formatted text with optional masking.
 *
 * Props:
 * - `id`: Unique identifier for the input field.
 * - `name`: Formik field name, required for form state management.
 * - `format`: Specifies the formatting pattern (e.g., '+1 (###) ###-####').
 * - `placeholder`: Placeholder text shown in the input field when empty.
 * - `label`: Label text to describe the input field.
 * - `value`: Default or controlled value of the input.
 * - `mask`: Optional mask character for input formatting.
 * - `tooltipElement`: Optional tooltip component for additional info.
 * - `disableFullStoryRecording`: Adds a class to prevent FullStory from recording this input.
 * - `type`: Defines the input type (e.g., 'text', 'password', or 'tel').
 */

import { useField } from 'formik';

import { InputFieldPatternFormatV2 } from '@vartanainc/design-system';
import { ReactComponent as TooltipIcon } from '../../assets/tooltip.svg';

interface PatternInputV2Props {
  id: string;
  name: string;
  format: string;
  placeholder?: string;
  label: string;
  value: string;
  mask?: string;
  tooltipElement?: React.ReactElement;
  disableFullStoryRecording?: boolean;
  type?: 'text' | 'password' | 'tel';
}

const PatternInputV2: React.FC<PatternInputV2Props> = ({
  id,
  name,
  format,
  placeholder = '',
  label,
  value,
  mask = '',
  tooltipElement,
  disableFullStoryRecording = false,
  type = 'text',
  ...rest
}) => {
  const [field, meta, { setValue }] = useField(name);

  return (
    <InputFieldPatternFormatV2
      {...field}
      {...rest}
      placeholder={placeholder}
      value={value}
      format={format}
      mask={mask}
      fullWidth
      label={label}
      showError={!!(meta.touched && meta.error)}
      helperText={meta.error}
      tooltipIcon={tooltipElement ? <TooltipIcon /> : <></>}
      tooltipElement={tooltipElement}
      className={disableFullStoryRecording ? 'fs-mask' : ''}
      onChange={({ formattedValue }: { formattedValue: string }) => {
        setValue(formattedValue);
      }}
      type={type}
    />
  );
};

export default PatternInputV2;
