/* eslint-disable react/prop-types */
import { useEffect, useRef, useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { capitalize, get, isEmpty, startCase } from 'lodash';
import { InputField, Typography } from '@vartanainc/design-system';
import { OrderStatusOptions } from '../../static';
import { GET_RESELLER_ORDERS } from '../../graphql/queries/resellers';
import AutoLoad from '../../components/AutoLoad';
import Table from '../../components/Table';

import { getStatusClass, getUSDateFormat } from '../../utils/helpers';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import DropdownInput from '../../designSystem/DropdownInput/DropdownInput';
import NoDataState from '../../components/NoDataState/NoDataState';
import { CurrencyPill } from '../../components/Pill/CurrencyPill';
import { sessionVar } from '../../graphql/cache';

const RELOAD_TIME = 500; // milliseconds

export function ResellerOrders() {
  const { number } = useParams();
  const debounceRef = useRef();
  const [orderNumberSearch, setOrderNumberSearch] = useState('');
  const [customerNameSearch, setCustomerNameSearch] = useState('');
  const [orderStatusSearch, setOrderStatusSearch] = useState('');

  const sessionData = useReactiveVar(sessionVar);
  const showCurrency = get(
    sessionData,
    'session.user.company.product.multipleCountriesEnabled',
    false
  );

  const {
    data: ordersData,
    loading: ordersLoading,
    refetch: fetchResellerOrders,
  } = useQuery(GET_RESELLER_ORDERS, {
    variables: { resellerNumber: number },
  });

  const orders = get(ordersData, 'sellerOrders.nodes', []);

  // Fetch orders list whenever filters change
  useEffect(() => {
    clearTimeout(debounceRef.current);

    debounceRef.current = setTimeout(() => {
      fetchResellerOrders({
        orderNumber: orderNumberSearch,
        customerName: customerNameSearch,
        status: orderStatusSearch,
      });
    }, [RELOAD_TIME]);
  }, [customerNameSearch, fetchResellerOrders, orderNumberSearch, orderStatusSearch]);

  const tableColumns = [
    {
      Header: 'Order #',
      accessor: 'number',
      Cell: ({ value: orderNumber }) => (
        <Link to={`/dashboard/orders/${orderNumber}`}>
          {' '}
          <Typography variant="paragraph14" bold color="color-blue-120">
            {orderNumber}
          </Typography>
        </Link>
      ),
    },
    {
      Header: 'Customer',
      accessor: 'company',
      Cell: ({ value: company }) => (
        <div className="flex flex-col gap-1">
          <Typography variant="paragraph14" bold>
            {capitalize(company?.name || '--')}
          </Typography>
          <Typography variant="paragraph12" color="color-gray-140">
            {company?.number || '--'}
          </Typography>
        </div>
      ),
    },
    {
      Header: 'Amount',
      accessor: 'formattedAmount',
      Cell: ({ row }) =>
        row.original.hideOrderDetailsForCollaborator ? (
          '--'
        ) : (
          <Typography variant="paragraph14">{row.original.formattedAmount}</Typography>
        ),
    },
    {
      Header: 'Term',
      accessor: 'formattedPaymentTerm',
      Cell: ({ value: paymentTerm }) => (
        <Typography variant="paragraph14">{paymentTerm}</Typography>
      ),
    },
    {
      Header: 'Status',
      accessor: 'vendorState',
      Cell: ({ value: vendorState }) => (
        <span className={getStatusClass(vendorState)}>{startCase(vendorState)}</span>
      ),
    },
    {
      Header: 'Owner',
      accessor: 'user',
      Cell: ({ value: user }) => (
        <Typography variant="paragraph14">{user?.fullName || '--'}</Typography>
      ),
    },
    {
      Header: 'Updated',
      accessor: 'updatedAt',
      Cell: ({ value: updatedAt }) => (
        <Typography variant="paragraph14">{getUSDateFormat(updatedAt)}</Typography>
      ),
    },
  ];

  if (showCurrency) {
    tableColumns.splice(2, 0, {
      Header: 'Currency',
      accessor: 'currency',
      Cell: (props) => {
        const currency = get(props, 'row.original.currency', {});
        return <CurrencyPill currency={currency} />;
      },
    });
  }

  return (
    <div>
      {/* Filters */}
      <div className="grid grid-cols-3 gap-4 pt-4 pb-6 px-8 border-b border-vartana-gray-40-v3 bg-white">
        <InputField
          label="Order #"
          placeholder="O-009DEF23"
          name="order-number"
          suffixIcon={<SearchIcon />}
          fullWidth
          onChange={(e) => setOrderNumberSearch(e.target.value)}
        />
        <InputField
          label="Customer"
          name="order-number"
          suffixIcon={<SearchIcon />}
          fullWidth
          onChange={(e) => setCustomerNameSearch(e.target.value)}
        />
        <DropdownInput
          label="Status"
          name="order-status"
          options={OrderStatusOptions}
          isSearchable={false}
          value={orderStatusSearch}
          onChange={({ value }) => setOrderStatusSearch(value)}
        />
      </div>

      {/*  Orders table */}
      <AutoLoad loading={ordersLoading}>
        {!isEmpty(orders) ? (
          <div className="w-full mt-8 align-middle inline-block overflow-x-auto overflow-y-hidden border-b border-gray-200">
            <Table
              columns={tableColumns}
              data={orders}
              // sortBy={sortBy}
              getHeaderProps={() => ({
                className:
                  'px-4 md:pl-8 md:pr-4 py-4 text-left text-xs font-medium text-vartana-gray-60 tracking-wider whitespace-nowrap uppercase',
              })}
              cellClassName="md:pl-8"
            />
          </div>
        ) : (
          <div className="h-full w-full flex items-center">
            <NoDataState />
          </div>
        )}
      </AutoLoad>
    </div>
  );
}
