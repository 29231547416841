import { TrackStatus } from '@vartanainc/design-system';
import { ReactElement, useEffect } from 'react';
import { ORDER_CHECKOUT_TITLES } from '../widgetV2.constants';

interface TabSummaryProps {
  title: string;
  description: string;
  lastActive: string;
  completed: boolean;
  setActive: () => void;
  step: number;
  progress: number;
  isActive: boolean;
  totalSteps: number;
}

export type trackerState = 'active' | 'active-warn' | 'completed' | 'disabled';
export type TrackerStatus =
  | 'sending-request'
  | 'request-sent'
  | 'need-info'
  | 'in-progress'
  | 'in-review'
  | 'approved'
  | 'finished'
  | 'canceled'
  | 'expired';

interface TrackerStep {
  stepNumber: number;
  stepStatus: trackerState;
}

const ACTIVE_STEP_STATUS = 'active';
const WARN_STEP_STATUS = 'active-warn';
const COMPLETE_STEP_STATUS = 'completed';
const DISABLED_STEP_STATUS = 'disabled';

const OrderStatus = ({
  title,
  description,
  lastActive,
  setActive,
  completed,
  step,
  totalSteps,
  progress,
  isActive,
}: TabSummaryProps): ReactElement => {
  useEffect(() => {
    setActive();
  }, [setActive]);

  const getTrackerSteps = (): TrackerStep[] => {
    const steps: TrackerStep[] = [];
    for (let i = 1; i <= totalSteps; i += 1) {
      steps.push({
        stepNumber: i,
        stepStatus: DISABLED_STEP_STATUS,
      });
    }
    if (completed) {
      steps.forEach((element, index) => {
        const newStep: TrackerStep = element;
        newStep.stepStatus = COMPLETE_STEP_STATUS;
        steps[index] = newStep;
      });
    } else {
      steps.forEach((element, index) => {
        if (element.stepNumber < step) {
          const newStep: TrackerStep = element;
          newStep.stepStatus = COMPLETE_STEP_STATUS;
          steps[index] = newStep;
        }
        if (element.stepNumber === step) {
          const newStep: TrackerStep = element;
          newStep.stepStatus = isActive ? ACTIVE_STEP_STATUS : WARN_STEP_STATUS;
          steps[index] = newStep;
        }
      });
    }
    return steps;
  };

  const getTrackerIcon = (): TrackerStatus => {
    // TODO: [MuhammadAhmadEjaz, mwahaj92] update this as this has become too long.
    if (step !== null && step !== undefined) {
      return completed ? 'finished' : 'in-progress';
    }
    if (completed) return 'finished';
    switch (title) {
      case ORDER_CHECKOUT_TITLES.poNeedsApproval:
      case ORDER_CHECKOUT_TITLES.awaitingInvoice:
      case ORDER_CHECKOUT_TITLES.awaitingPayoutInfo:
      case ORDER_CHECKOUT_TITLES.signaturesRequired:
      case ORDER_CHECKOUT_TITLES.needsApproval: {
        return 'need-info';
      }
      case ORDER_CHECKOUT_TITLES.corporateGuarantee:
      case ORDER_CHECKOUT_TITLES.agreementInReview:
      case ORDER_CHECKOUT_TITLES.epInReview:
      case ORDER_CHECKOUT_TITLES.poInReview:
      case ORDER_CHECKOUT_TITLES.reviewingInfo:
      case ORDER_CHECKOUT_TITLES.invoiceInReview: {
        return 'in-review';
      }
      case ORDER_CHECKOUT_TITLES.sendingOrderLink:
      case ORDER_CHECKOUT_TITLES.corporateGuaranteeSent: {
        return 'sending-request';
      }
      case ORDER_CHECKOUT_TITLES.epApproved: {
        return 'approved';
      }
      case ORDER_CHECKOUT_TITLES.epCompleted: {
        return 'finished';
      }
      case ORDER_CHECKOUT_TITLES.orderCanceled: {
        return 'canceled';
      }
      case ORDER_CHECKOUT_TITLES.orderExpired: {
        return 'expired';
      }
      case 'Payout awaiting acceptance': {
        return 'in-review';
      }
      case 'Partial payout fulfilled': {
        return 'finished';
      }
      case 'Payout complete': {
        return 'finished';
      }
      default: {
        return 'request-sent';
      }
    }
  };

  return (
    <div className="order-summary w-full flex flex-col items-center gap-4 justify-center bg-slate-100">
      <TrackStatus
        steps={getTrackerSteps()}
        trackerStatus={getTrackerIcon()}
        heading={title}
        description={description}
        timestamp={lastActive}
        isOrder={false}
      />
    </div>
  );
};

export default OrderStatus;
