import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Typography } from '@vartanainc/design-system';

import crossIcon from '../../assets/cross.svg';
import exclamationMarkIcon from '../../assets/exclamation_mark.svg';
import { isRenderedFromHubspot } from '../../utils/helpers';

function InfoModal({
  title,
  isOpen,
  description,
  confirmButtonText,
  rejectButtonText,
  onClose,
  onSubmit,
}) {
  const modalClassName = isRenderedFromHubspot() ? 'hubspot-modal-content' : '';
  return (
    <Modal
      isOpen={isOpen}
      className={twMerge(modalClassName, 'modal')}
      ariaHideApp={false}
    >
      <div className="absolute top-1/4 left-1/2 transform-gpu -translate-x-2/4">
        <div className="rounded-lg bg-white py-4 px-6">
          <div className="p-2 absolute right-0 top-0">
            <button className="cursor-pointer" onClick={onClose}>
              <img alt="close" src={crossIcon} />
            </button>
          </div>
          <div className="mx-10 my-5">
            <div className="flex gap-2 text-vartana-black my-5">
              <img alt="close" src={exclamationMarkIcon} />
              <Typography variant="heading18" bold>
                {title}
              </Typography>
            </div>
            <Typography variant="paragraph14">{description}</Typography>
            <div className="flex justify-between mt-6">
              <button onClick={onClose} className="flex items-center gap-1">
                <span className="vp-btn-text text-vartana-blue-60">
                  {rejectButtonText}
                </span>
              </button>

              <button
                type="submit"
                className="py-4 px-10 bg-vartana-blue-60 rounded-md"
                onClick={onSubmit}
              >
                <p className="vp-btn-text text-white">{confirmButtonText}</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

InfoModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  confirmButtonText: PropTypes.string,
  rejectButtonText: PropTypes.string,
  description: PropTypes.node,
};

InfoModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onSubmit: () => {},
  title: '',
  confirmButtonText: 'Submit',
  rejectButtonText: 'Cancel',
  description: null,
};

export default InfoModal;
