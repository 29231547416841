import React, { useCallback, useEffect } from 'react';

import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { get } from 'lodash';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { Typography, Button } from '@vartanainc/design-system';
import { useIntercom } from 'react-use-intercom';
import * as FullStory from '@fullstory/browser';

import { GENERATE_PROPOSAL } from '../../../../graphql/queries/customer';
import { sessionVar } from '../../../../graphql/cache';

import AutoLoad from '../../../../components/AutoLoad';
import FormInput from '../../../../components/FormInput';
import { showErrorToast } from '../../../../utils/helpers';
import MoneyInputField from '../../../../designSystem/MoneyInput/MoneyInputField';

const GenerateProposalFormSchema = yup.object().shape({
  opportunityAmount: yup
    .number()
    .positive('Amount must be greater than zero')
    .required('This field is required'),
});

function Proposal() {
  const [generateProposal, { loading: isGeneratingProposal }] =
    useMutation(GENERATE_PROPOSAL);
  const location = useLocation();
  const useQueryParams = new URLSearchParams(location.search);
  const customerNumber = useQueryParams.get('customerNumber');
  const application = useQueryParams.get('application');
  const { update: updateIntercom } = useIntercom();

  const onProposalSubmit = useCallback(
    async (values) => {
      try {
        const response = await generateProposal({
          variables: {
            ...values,
          },
        });
        const proposal = get(response, 'data.generateProposal.encodedProposal');
        const linkSource = `data:application/pdf;base64,${proposal}`;
        const downloadLink = document.createElement('a');
        const fileName = `${get(response, 'data.generateProposal.number')}-Proposal.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (e) {
        showErrorToast();
      }
    },
    [generateProposal]
  );

  const sessionData = useReactiveVar(sessionVar);

  useEffect(() => {
    const user = get(sessionData, 'session.user', null);
    const companyUrlAdmin = !customerNumber
      ? ''
      : `${process.env.REACT_APP_ADMIN_URL}/admin/companies/?q%5Bnumber_contains%5D=${customerNumber}&commit=Filter&order=id_desc`;
    if (user) {
      const { fullName } = user;
      FullStory.identify(user.email, {
        customer: fullName,
        displayName: fullName,
        email: user.email,
      });
      updateIntercom({
        name: application === 'hubspot' ? '' : fullName,
        email: user.email,
        created_at: new Date(user.createdAt).valueOf(),
        customAttributes: {
          application: 'widget-app',
          environment: process.env.REACT_APP_NODE_ENV,
          resourceId: user.id,
          resourceType: 'User',
          companyUrlAdmin,
        },
      });
    }
  }, [customerNumber, sessionData, updateIntercom, application]);

  return (
    <AutoLoad loading={isGeneratingProposal}>
      <div className="p-16">
        <div className="space-y-2">
          <Typography variant="heading24" color="color-black-100">
            Create Proposal
          </Typography>
          <Typography variant="paragraph14" color="color-black-100">
            Please enter sales order amount to create proposal.
          </Typography>
        </div>
        <div className="mt-6">
          <Formik
            initialValues={{
              validateOnMount: true,
              customerNumber,
              opportunityAmount: '',
            }}
            enableReinitialize
            validationSchema={GenerateProposalFormSchema}
            onSubmit={onProposalSubmit}
          >
            {({ isSubmitting, isValid, dirty }) => {
              const submitDisabled =
                isSubmitting || isGeneratingProposal || !(isValid && dirty);

              return (
                <Form>
                  <fieldset>
                    <FormInput
                      className="tw-hidden"
                      name="customerNumber"
                      type="hidden"
                    />

                    <div className="space-y-4">
                      <div className="flex flex-row space-x-4">
                        <MoneyInputField
                          name="opportunityAmount"
                          label="Opportunity Amount"
                          type="number"
                          // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get currency from product/credit appraisal
                          prefix="$"
                          onChange={(e, { setValue }) => {
                            const { value } = e.target;
                            if (value) setValue(Math.abs(value));
                            else setValue(value);
                          }}
                        />
                        <Button disabled={isGeneratingProposal || submitDisabled}>
                          <p className="mt-6 pt-2 sf-button-text text-white disabled:bg-sf-vartana-gray-40 bg-sf-blue px-24 py-2 rounded">
                            Download
                          </p>
                        </Button>
                      </div>
                    </div>
                  </fieldset>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </AutoLoad>
  );
}

export default Proposal;
