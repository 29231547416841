import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query getUsers($fullName: String, $first: Int, $after: String, $roleId: Int) {
    users(fullName: $fullName, first: $first, after: $after, roleId: $roleId) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          number
          firstName
          lastName
          fullName
          email
          phone
          title
          userRole {
            id
            name
          }
          updatedAt
        }
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $title: String
    $phone: String
    $roleId: Int!
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      title: $title
      phone: $phone
      roleId: $roleId
    ) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $title: String
    $phone: String
    $roleId: Int
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      title: $title
      phone: $phone
      roleId: $roleId
    ) {
      id
    }
  }
`;

export const RESET_PASSWORD_FOR_OTHER_USER = gql`
  mutation resetPasswordForOtherUser($userNumber: String!) {
    resetPasswordForOtherUser(userNumber: $userNumber) {
      id
    }
  }
`;

export const GET_FINANCE_USER_DETAILS = gql`
  query getFinanceUserDetails {
    financeUser {
      id
      firstName
      lastName
      email
      phone
    }
  }
`;
