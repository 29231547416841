import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import SvgIcon from '../SvgIcon/SvgIcon';

export function CrossButton({ onClick, className, ...rest }) {
  return (
    <button
      className={twMerge('absolute top-4 right-5', className)}
      onClick={onClick}
      {...rest}
    >
      <SvgIcon name="close" fill="color-gray-100" variant="thick" className="w-6 h-6" />
    </button>
  );
}

CrossButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

CrossButton.defaultProps = {
  className: '',
};
