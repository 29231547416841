import { ReactElement, useEffect, useImperativeHandle, useMemo } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { AlertBox, Typography } from '@vartanainc/design-system';

import TextAreaInput from '../../../designSystem/TextAreaInput/TextAreaInput';
import MultiFileUpload from '../../../components/MultiFileUpload';
import { APPLICATION_MAX_UPLOAD_FILES } from '../../../constants/common.constants';
import SvgIcon from '../../../components/SvgIcon/SvgIcon';

interface FinancialDoc {
  key: string;
  label: string;
  type: string;
  year: string;
  quarter: string;
  externalId: string;
  filename: string | null;
  documents: string[];
}
interface FinancialDocumentsFormProps {
  handleSubmit: () => void;
  financialDocs: FinancialDoc[];
  formRef: React.RefObject<unknown>;
  setSubmissionDisabled: (isDisabled: boolean) => void;
  setRemovedFiles: (files: (prev) => string[]) => void;
  notes: string;
  alertText?: string;
}

export function FinancialDocumentsForm({
  handleSubmit,
  financialDocs,
  formRef,
  setSubmissionDisabled,
  setRemovedFiles,
  notes,
  alertText,
}: FinancialDocumentsFormProps): ReactElement {
  const initialValues = useMemo(() => {
    const initial = {
      notes,
    };
    financialDocs.forEach((doc) => {
      initial[doc.externalId] = doc?.documents;
    });
    return initial;
  }, [financialDocs, notes]);

  const formikBag = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const { values, isSubmitting } = formikBag;

  useEffect(() => {
    const formKeys = Object.keys(values).filter((key) => key !== 'notes');
    const isDisabled =
      formKeys?.length < 1 ||
      !formKeys.some((value) => values[value]?.length) ||
      isSubmitting;

    setSubmissionDisabled(isDisabled);
  }, [values, isSubmitting, setSubmissionDisabled, financialDocs]);

  useImperativeHandle(formRef, () => formikBag);

  const handleFileRemove = (
    file: { id: string; size?: string },
    externalId: string
  ): void => {
    if (file.id && !file.size) setRemovedFiles((prev) => [...prev, file.id]);
  };

  return (
    <FormikProvider value={formikBag}>
      <Typography variant="heading18" bold>
        Financial documents
      </Typography>
      {alertText && (
        <div className="mt-4 max-w-[36.5rem]">
          <AlertBox
            variant="alert-box"
            backgroundColor="announcement"
            icon={<SvgIcon name="info" fill="color-blue-120" />}
            content={alertText}
          />
        </div>
      )}
      <fieldset>
        <div className="mt-4 flex flex-col gap-4">
          {financialDocs.map((doc) => (
            <div key={doc.key}>
              <div className="flex flex-row gap-1 mb-2">
                <Typography variant="paragraph14" color="color-black-100" bold>
                  {doc.year}
                </Typography>
                <Typography variant="paragraph14" color="color-black-100">
                  {doc.quarter ? `Q-${doc.quarter} ` : ''}
                  {doc.label}
                </Typography>
              </div>

              <MultiFileUpload
                uploadText="Click or drag PDF files to upload"
                name={`${doc.externalId}`}
                acceptMultipleFiles
                onRemove={(file) => handleFileRemove(file, doc.externalId)}
                maxFiles={APPLICATION_MAX_UPLOAD_FILES}
                hideOverflowScroll
                key={doc.externalId}
              />
            </div>
          ))}

          <div className="flex flex-row gap-4 more-info-text-area">
            <TextAreaInput
              id="notes"
              name="notes"
              label="Additional notes (optional)"
              tooltipElement={null}
              placeholder="Add a note for the reviewer"
            />
          </div>
        </div>
      </fieldset>
    </FormikProvider>
  );
}
