import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import { Button } from '@vartanainc/design-system';
import { TRIGGER_IMPORT_CUSTOMER_CSV_JOB } from '../../../../../graphql/queries/vendor';

import MultiFileUpload from '../../../../../components/MultiFileUpload';

import { useDirectUploadFiles } from '../../../../../utils/hooks';
import CommonFooter from '../CommonFooter';

const schema = yup.object().shape({
  csv: yup.array().min(1, 'This field is required').required('This field is required'),
});

function Import({ vendor, onBack, showSkipOption, onClose }) {
  const [triggerImportCustomerCsvJob] = useMutation(TRIGGER_IMPORT_CUSTOMER_CSV_JOB);

  const [directUploadFiles] = useDirectUploadFiles();

  const onSubmit = useCallback(
    async (values) => {
      try {
        const { csv } = values;

        if (csv) {
          const uploadResponse = await directUploadFiles(
            [...csv],
            {
              documentType: 'customers_batch',
            },
            {
              id: get(vendor, 'seller.id', null),
              type: 'Company',
            }
          );
          if (uploadResponse) {
            const blobId = get(uploadResponse, '0.metadata.blobId', '');
            if (blobId) await triggerImportCustomerCsvJob({ variables: { blobId } });
          }
          onClose();
          window.location.reload();
        }
      } catch (e) {
        console.log(e);
      }
    },
    [directUploadFiles, onClose, triggerImportCustomerCsvJob, vendor]
  );

  const formikBag = useFormik({
    initialValues: {
      csv: [],
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: schema,
    onSubmit,
  });

  const { isSubmitting, isValid, handleSubmit } = formikBag;

  const disabled = isSubmitting || !isValid;

  return (
    <>
      <FormikProvider value={formikBag}>
        <form onSubmit={handleSubmit}>
          <fieldset disabled={isSubmitting}>
            <div className="space-y-6">
              <div className="space-y-1">
                <h1 className="vp-card-title-bold text-vartana-black">
                  Import customers from file
                </h1>
                <p className="vp-body">
                  Need to create a CSV file quickly? Use our{' '}
                  <a
                    rel="noreferrer"
                    className="focus:outline-none text-vartana-blue-60"
                    target="_blank"
                    href={process.env.REACT_APP_CUSTOMER_BATCH_TEMPLATE_URL}
                  >
                    customers template.
                  </a>
                </p>
              </div>

              <div className="space-y-4">
                <MultiFileUpload
                  label="Import customers"
                  name="csv"
                  accept=".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"
                  uploadText="Upload or drag and drop CSV file"
                />
              </div>
            </div>

            <CommonFooter>
              <div className="flex justify-between items-center">
                <div>
                  {/** New Buttons added here */}
                  <Button onClick={onBack} variant="outlined">
                    &lt; Back
                  </Button>
                </div>
                <div className="flex justify-between items-center space-x-4">
                  {showSkipOption && (
                    <div>
                      <Button onClick={onClose} variant="outlined">
                        Skip for now
                      </Button>
                    </div>
                  )}
                  <div>
                    <Button onClick={() => handleSubmit()} disabled={disabled}>
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </CommonFooter>
          </fieldset>
        </form>
      </FormikProvider>
    </>
  );
}

Import.propTypes = {
  vendor: PropTypes.shape({}).isRequired,
  onBack: PropTypes.func,
  showSkipOption: PropTypes.bool,
  onClose: PropTypes.func,
};

Import.defaultProps = {
  onBack: null,
  showSkipOption: false,
  onClose: undefined,
};

export default Import;
