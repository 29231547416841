import { gql } from '@apollo/client';

export const GET_RESELLERS = gql`
  query getResellers {
    resellers {
      number
      name
    }
  }
`;

// Get resellers of currently logged-in Vendor
export const GET_VENDOR_RESELLERS = gql`
  query getVendorResellers(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $fullNameOrEmail: String
    $resellerName: String
  ) {
    vendorResellers(
      before: $before
      after: $after
      first: $first
      last: $last
      fullNameOrEmail: $fullNameOrEmail
      name: $resellerName
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        name
        number
        inviteStatus: resellerInvitationStatus
        updatedAt
        primaryUser {
          fullName
          email
        }
      }
    }
  }
`;

// Get reseller details for profile tab
export const GET_RESELLER_BY_NUMBER = gql`
  query getResellerByNumber($number: String!) {
    resellerByNumber(number: $number) {
      name
      number
      createdAt
      inviteStatus: resellerInvitationStatus
      primaryUser {
        firstName
        lastName
        email
        formattedPhone
      }
    }
  }
`;

// Get reseller orders for reseller-->orders tab
export const GET_RESELLER_ORDERS = gql`
  query getResellerOrders(
    $resellerNumber: String
    $orderNumber: String
    $customerName: String
    $status: String
    $decision: String
  ) {
    sellerOrders(
      number: $orderNumber
      company: $customerName
      status: $status
      decision: $decision
      resellerCompanyNumber: $resellerNumber
    ) {
      totalCount
      nodes {
        vendorState
        number
        state
        loanDecision
        currency
        company {
          name
          number
        }
        formattedAmount
        formattedFee
        formattedFeePercentage
        formattedPaymentTerm
        updatedAt
        hideOrderDetailsForCollaborator
        user {
          fullName
        }
      }
    }
  }
`;

// Get reseller account executive of selected customer in order creation => reseller form
export const GET_RESELLER_AE_OF_CUSTOMER = gql`
  query getResellerAEofCustomer($resellerNumber: String!, $customerNumber: String!) {
    company(number: $customerNumber) {
      resellerAccountExecutive(resellerNumber: $resellerNumber) {
        firstName
        lastName
        email
        company {
          name
        }
      }
    }
  }
`;
