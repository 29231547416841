import React, { useState, useMemo, useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useQuery, useReactiveVar, useMutation } from '@apollo/client';
import { get } from 'lodash';
import { useIntercom } from 'react-use-intercom';
import * as FullStory from '@fullstory/browser';
import { Typography, Button } from '@vartanainc/design-system';

import { GET_ORDER_BY } from '../../../../graphql/queries/order';
import { CREATE_AMENDMENT_REQUEST } from '../../../../graphql/mutations';
import { sessionVar } from '../../../../graphql/cache';
import { OrderShowContext } from '../../../../context/OrderContext';
import { titleize, showToast } from '../../../../utils/helpers';
import TrackStatus from './TrackStatus';
import chevronIcon from '../../../../assets/chevron.svg';
import OrderSummary from '../../../Orders/Summary/indexV3';
import AutoLoad from '../../../../components/AutoLoad';
import Tabs from '../../../../components/TabbedNavigation';
import Loader from '../../../../components/Loader';
import { HasPermission } from '../../../../components/HasPermission/HasPermission';
import { ACTION, RESOURCE } from '../../../../constants/permissions';

function Summary() {
  const [disabledTabs, setDisabledTabs] = useState([]);
  const [searchParams] = useSearchParams();
  const customerNumber = searchParams.get('customerNumber') || '';
  const crmOpportunityId = searchParams.get('crmOpportunityId') || '';
  const orderNumber = searchParams.get('orderNumber') || '';
  const application = searchParams.get('application') || '';
  const preSelectedTab = searchParams.get('tab') === 'track' ? 1 : 0;
  const { update: updateIntercom } = useIntercom();

  const {
    data: orderData,
    loading: orderLoading,
    refetch: refetchOrderData,
  } = useQuery(GET_ORDER_BY, {
    variables: {
      number: orderNumber,
      crmOpportunityId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const sessionData = useReactiveVar(sessionVar);

  const selectedCustomer = get(orderData, 'orderBy.company', {});
  const selectedOrder = get(orderData, 'orderBy', {});

  const orderShowContext = useMemo(
    () => ({
      refetchOrderData,
      selectedCustomer,
      selectedOrder,
    }),
    [refetchOrderData, selectedCustomer, selectedOrder]
  );

  useEffect(() => {
    if (
      !(
        get(orderData, 'orderBy.currentStepDetails.progress', 0) === 0 &&
        get(orderData, 'orderBy.currentStepDetails.step', 0) === 0
      ) &&
      get(orderData, 'orderBy.status', '') === 'canceled'
    ) {
      setDisabledTabs([1]);
    }
  }, [orderData]);

  useEffect(() => {
    const user = get(sessionData, 'session.user', null);
    const orderId = get(orderData, 'orderBy.id', 0);
    const companyId = get(orderData, 'orderBy.company.id', 0);
    const orderUrlAdmin = !orderId
      ? ''
      : `${process.env.REACT_APP_ADMIN_URL}/admin/orders/${orderId}`;
    const companyUrlAdmin = !companyId
      ? ''
      : `${process.env.REACT_APP_ADMIN_URL}/admin/companies/${companyId}`;
    if (user) {
      const { fullName } = user;
      FullStory.identify(user.email, {
        customer: fullName,
        displayName: fullName,
        email: user.email,
      });
      updateIntercom({
        name: application === 'hubspot' ? '' : fullName,
        email: user.email,
        created_at: new Date(user.createdAt).valueOf(),
        customAttributes: {
          application: 'widget-app',
          environment: process.env.REACT_APP_NODE_ENV,
          resourceId: user.id,
          resourceType: 'User',
          orderUrlAdmin,
          companyUrlAdmin,
        },
      });
    }
  }, [sessionData, orderData, updateIntercom, application]);

  const [requestBuyout, { loading: loadingBuyout }] = useMutation(
    CREATE_AMENDMENT_REQUEST,
    {
      variables: { number: get(orderData, 'orderBy.number', '') },
      onCompleted: () => {
        const message = 'We received your request for an early payoff.';
        showToast('success', message);
      },
      refetchQueries: [GET_ORDER_BY],
      onError: () => {
        const message =
          'There was an error while raising request for early payoff. Please contact Vartana helpdesk for further assistance.';
        showToast('error', message);
      },
    }
  );

  const buyoutButton = useMemo(() => {
    const canRequestBuyout = get(orderData, 'orderBy.canRequestBuyout', false);
    const customer = get(orderData, 'orderBy.company', {});

    if (canRequestBuyout) {
      return (
        <HasPermission
          resource={RESOURCE.order}
          action={ACTION.request_buyout}
          customer={customer}
        >
          <Button
            size="x-large"
            className="absolute right-3.25"
            disabled={loadingBuyout}
            onClick={() => requestBuyout()}
          >
            {loadingBuyout ? (
              <Loader className="w-5 h-5 border-l-white border-r-white" />
            ) : (
              'Request for early payoff'
            )}
          </Button>
        </HasPermission>
      );
    }
    return null;
  }, [orderData, requestBuyout, loadingBuyout]);

  if (!customerNumber && (!crmOpportunityId || !orderNumber))
    return <Navigate to="/" replace />;

  return (
    <div>
      <AutoLoad loading={orderLoading}>
        <div className="flex flex-col gap-6">
          <div className="flex gap-2">
            {get(orderData, 'orderBy.company.name', '') ? (
              <>
                <Typography variant="heading32" color="color-black-100">
                  {titleize(get(orderData, 'orderBy.company.name', ''))}
                </Typography>

                <img alt="chevron" src={chevronIcon} />
              </>
            ) : null}
            <Typography variant="heading32">
              {`Order # ${get(orderData, 'orderBy.number', '')}`}
            </Typography>
            {buyoutButton}
          </div>
          <OrderShowContext.Provider value={orderShowContext}>
            <Tabs
              variant="expanded"
              tabLabels={['Summary', 'Track']}
              disabledTabs={disabledTabs}
              preSelectedTabIndex={preSelectedTab}
            >
              <OrderSummary
                crmOpportunityId={crmOpportunityId}
                orderData={get(orderData, 'orderBy', {})}
              />
              <TrackStatus
                companyNumber={customerNumber}
                crmOpportunityId={crmOpportunityId}
                orderNumber={orderNumber}
              />
            </Tabs>
          </OrderShowContext.Provider>
        </div>
      </AutoLoad>
    </div>
  );
}

export default Summary;
