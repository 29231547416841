import { useCallback } from 'react';
import { Typography } from '@vartanainc/design-system';
import { useField } from 'formik';
import { orderType } from '../../constants/common.constants';
import { ReactComponent as DeferPaymentIcon } from '../../assets/event.svg';
import { ReactComponent as InstallmentPaymentIcon } from '../../assets/payments.svg';
import { ReactComponent as SignaturePaymentIcon } from '../../assets/signature.svg';
import colors from '../ColorMap/ColorMap';

type option = {
  value: string;
  label: string;
  isDisabled: boolean;
};

interface PaymentOptionSelectProps {
  name: string;
  options?: option[];
  value?: string;
  onChange?: () => void;
}

/**
 * Component for rendering a payment option select.
 * This component is used to select payment options on order proposal.
 * @param name - The name of the select field used by formik.
 * @param options - The available payment options for the select.
 * @param onChange - The callback function to be called when the select value changes.
 * @returns - The JSX component that is used to select payment options on order proposal.
 */
const PaymentOptionSelect: React.FC<PaymentOptionSelectProps> = ({
  name,
  options,
  onChange,
}) => {
  // second value is the meta object, which we are not using that's why we are ignoring it
  const [field, , { setValue }] = useField(name);

  // CSS class names for select tiles
  const defaultTileClassNames =
    'flex flex-col items-center justify-center w-36 gap-1 p-3 border rounded-md';
  const selectedTileClassNames = ' border-vartana-blue-100 cursor-pointer';
  const disabledTileClassNames =
    ' border-vartana-gray-80 bg-vartana-gray-30 bg-opacity-50 cursor-not-allowed';
  const unSelectedTileClassNames = ' border-vartana-gray-50 cursor-pointer';

  /**
   * Get the icon name for a given option.
   * @param optionValue - The value of the option.
   * @param selectedOption - The currently selected option.
   * @param iconName - The base icon name.
   * @returns The icon name based on the option and selection state.
   */
  const getOptionIconColor = (
    optionValue: string,
    selectedOption: string,
    isDisabled: boolean
  ): string => {
    if (isDisabled) {
      return colors['color-gray-100'];
    }
    if (optionValue === selectedOption) {
      return colors['color-blue-100'];
    }
    return colors['color-gray-110'];
  };

  /**
   * Get the icon element for a given option.
   * @param optionValue - The value of the option.
   * @param selectedOption - The currently selected option.
   * @returns The icon element based on the option and selection state.
   */
  const getOptionIcon = (
    optionValue: string,
    selectedOption: string,
    isDisabled: boolean
  ): JSX.Element => {
    if (optionValue === orderType.pay_in_full) {
      return (
        <DeferPaymentIcon
          fill={getOptionIconColor(optionValue, selectedOption, isDisabled)}
        />
      );
    }
    if (optionValue === orderType.installments) {
      return (
        <InstallmentPaymentIcon
          fill={getOptionIconColor(optionValue, selectedOption, isDisabled)}
        />
      );
    }
    return (
      <SignaturePaymentIcon
        fill={getOptionIconColor(optionValue, selectedOption, isDisabled)}
      />
    );
  };

  /**
   * Get the CSS class name for a select tile based on the option and selection state.
   * @param optionName - The name of the option.
   * @param selectedOption - The currently selected option.
   * @returns The CSS class name for the select tile.
   */
  const getTileClassName = (
    optionName: string,
    selectedOption: string,
    isDisabled
  ): string => {
    let classNames = defaultTileClassNames;
    if (optionName === selectedOption) {
      // Add border and cursor styles for selected option
      classNames += selectedTileClassNames;
      return classNames;
    }
    if (isDisabled) {
      // Add border, background, and cursor styles for disabled option
      classNames += disabledTileClassNames;
      return classNames;
    }
    // Add border and cursor styles for other options
    classNames += unSelectedTileClassNames;
    return classNames;
  };

  /**
   * Get the text color for a select tile based on the option value.
   * @param optionValue - The value of the option.
   * @returns The text color for the select tile.
   */
  const getTileTextColor = (isDisabled: boolean): string => {
    if (isDisabled) {
      return 'color-gray-110'; // Use gray color for disabled options
    }
    return 'color-black-100'; // Use black color for other options
  };

  /**
   * Handle click event for a select tile.
   * @param selectedOption - The selected option.
   */
  const handleClick = (selectedOption: option): void => {
    if (selectedOption.isDisabled) {
      return;
    }
    setValue(selectedOption.value);
    if (onChange) onChange();
  };

  /**
   * Component for rendering a select tile.
   * @param fieldLabel - The label of the select tile.
   * @param fieldValue - The value of the select tile.
   */
  const SelectTile = useCallback(
    (
      { fieldLabel, fieldValue, isDisabled },
      getClassName,
      getTextColor,
      handleOnClick,
      getIcon
    ) => {
      return (
        <button
          className={getClassName(fieldValue, field.value, isDisabled)}
          onClick={() =>
            handleOnClick({ value: fieldValue, label: fieldLabel, isDisabled })}
          type="button"
        >
          {getIcon(fieldValue, field.value, isDisabled)}
          <Typography variant="paragraph14" color={getTextColor(isDisabled)}>
            {fieldLabel}
          </Typography>
        </button>
      );
    },
    [field.value]
  );

  return (
    <div className="flex justify-start gap-4">
      {options?.map((fieldOption) =>
        SelectTile(
          {
            fieldLabel: fieldOption.label,
            fieldValue: fieldOption.value,
            isDisabled: fieldOption.isDisabled,
          },
          getTileClassName,
          getTileTextColor,
          handleClick,
          getOptionIcon
        )
      )}
    </div>
  );
};

export default PaymentOptionSelect;
