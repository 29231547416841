import { ButtonV2 } from '@vartanainc/design-system';
import { CrossButton } from '../Button/CrossButton';
import SvgIcon from '../SvgIcon/SvgIcon';

interface DocusignCloseModalProps {
  onCloseClick: () => void;
  onConfirmClick: () => void;
}

export const DocusignCloseModal = ({
  onCloseClick,
  onConfirmClick,
}: DocusignCloseModalProps): JSX.Element => {
  return (
    <div className="w-full h-full bg-vartana-gray-60/95 fixed bottom-0 left-0 top-0 right-0 flex justify-center">
      <div className="bg-white h-full w-full md:h-fit md:w-fit md:rounded-xl md:mt-40">
        <div className="w-full flex justify-end p-2">
          <div className="cursor-pointer w-6 h-6 flex items-center justify-center">
            <CrossButton onClick={onCloseClick} className="relative top-0 right-0" />
          </div>
        </div>
        <div className="px-4 md:px-10 md:pb-10">
          <div className="flex">
            <SvgIcon name="error" width="2rem" height="2rem" fill="color-blue-120" />
            <p className="text-xl font-bold ml-2">Are you sure?</p>
          </div>
          <p className="text-sm	my-6">
            Information will not be saved if you leave the page.
          </p>
          <div className="flex justify-between pt-2">
            <ButtonV2
              variant={{
                type: 'ghost',
                typography: 'paragraph14',
              }}
              text="Cancel"
              onClick={onCloseClick}
            />
            <ButtonV2
              variant={{
                type: 'primary',
                typography: 'paragraph14',
              }}
              text="Confirm"
              onClick={onConfirmClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocusignCloseModal;
