import { CompactDropdownField } from '@vartanainc/design-system';
import { useField } from 'formik';

interface option {
  value: string;
  label: string;
}
function CompactDropdownInputField({
  name,
  label,
  options,
  placeholder,
  onChange,
  showError,
  showWarn,
  disabled,
  showHoverShadow,
  ...rest
}: {
  name: string;
  label: string;
  options: option[];
  placeholder?: string;
  showError?: boolean;
  showWarn?: boolean;
  onChange?: (valueObj) => void;
  disabled?: boolean;
  showHoverShadow?: boolean;
}): JSX.Element {
  const [field, meta, { setValue }] = useField(name);
  const handleOnChange = (valueObj): void => {
    setValue(valueObj.value);
    if (onChange) onChange(valueObj);
  };

  return (
    <CompactDropdownField
      {...field}
      {...rest}
      id={name}
      name={name}
      options={options}
      label={label}
      placeholder={placeholder}
      value={options.find((selected) => selected.value === field.value) || []}
      showError={meta.touched && showError}
      showWarning={meta.touched && showWarn}
      onChange={handleOnChange}
      disabled={disabled}
      showHoverShadow={showHoverShadow}
    />
  );
}

export default CompactDropdownInputField;
