import { gql } from '@apollo/client';

export const UPDATE_ONBOARDING_STEPS = gql`
  mutation updateOnboardingSteps($name: String!, $status: String!) {
    updateOnboardingSteps(name: $name, status: $status) {
      id
      user {
        id
        company {
          onboardingSteps
        }
      }
    }
  }
`;

export const GET_VENDOR_BUYERS_DATA = gql`
  query getVendorBuyersData {
    vendorBuyersData
  }
`;

export const GET_VENDOR_ORDERS_DATA = gql`
  query getVendorOrdersData {
    vendorOrdersData
  }
`;

export const GET_VENDOR_INVOICES_DATA = gql`
  query getVendorInvoicesData {
    vendorInvoicesData
  }
`;

export const GET_VENDOR_ONBOARDING_STATUS = gql`
  query getVendorOnboardingStatus {
    seller {
      id
      name
      onboardingSteps
      vendorOnboardingStepsCompleted
    }
  }
`;

export const GET_VENDOR_DETAILS = gql`
  query getVendorDetails {
    seller {
      onboardingSteps
      vendorOnboardingStepsCompleted
      legalName
      street
      city
      state
      zip
      country
    }
  }
`;

export const GET_NOTIFICATION_PREFS = gql`
  query getNotificationPreferences($roleId: Int!) {
    seller {
      roleNotificationPreferences(roleId: $roleId) {
        category
        subCategory
        settings {
          all
          specific
        }
      }
      roleChannelPreference(roleId: $roleId) {
        setting {
          email
          sms
        }
      }
    }
  }
`;

export const TRIGGER_IMPORT_CUSTOMER_CSV_JOB = gql`
  mutation triggerImportCustomerCsvJob($blobId: String!) {
    importCustomersCsv(blobId: $blobId) {
      id
    }
  }
`;

export const GET_RESELLER_VENDORS = gql`
  query getResellerVendors(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $vendorName: String
  ) {
    resellerVendors(
      before: $before
      after: $after
      first: $first
      last: $last
      vendorName: $vendorName
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        name
        number
        resellerVendorOrdersCount
        resellerVendorPartneredOn
      }
    }
  }
`;
