/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { useFormik, FormikProvider, Form } from 'formik';
import * as yup from 'yup';

import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Button } from '@vartanainc/design-system';
import { omit, get } from 'lodash';
import { useQuery, useMutation, useReactiveVar } from '@apollo/client';
import { useBeforeunload } from 'react-beforeunload';

import AutoLoad from '../../../../components/AutoLoad';
// import Button from '../../../../components/Button';
import {
  GET_CUSTOMER_BY_NUMBER,
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER,
} from '../../../../graphql/queries/customer';
import { sessionVar, requestModelAmountVar } from '../../../../graphql/cache';

import { US_STATES } from '../../../../static';
import {
  getAllowedTerms,
  showToast,
  showErrorToast,
  titleize,
} from '../../../../utils/helpers';
import TextInputField from '../../../../designSystem/TextInput/TextInputField';
import PatternInput from '../../../../designSystem/PatternInput/PatternInput';
import AddressInputField from '../../../../designSystem/AddressInput/AddressInput';
import CustomDropdown from '../../../../designSystem/FormikDropdownInput/FormikDropdownInput';
import { useSetAddressFields } from '../../../../utils/hooks';
import MoneyInputField from '../../../../designSystem/MoneyInput/MoneyInputField';

const schema = yup.object().shape({
  name: yup
    .string()
    .min(1, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Company name is required'),
  legalName: yup.string(),
  street: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zip: yup.string().max(5, 'Too Long!').required('Zip is required'),
  dunsNumber: yup.string().max(9, 'Must be 9 digits').min(9, 'Must be 9 digits'),
  crmId: yup.string(),
});

const requestedAmountSchema = yup.object().shape({
  requestedAmount: yup
    .number()
    .required('Request amount is required')
    .positive('Should be greater than 0'),
  requestedTerm: yup.number().required('Requested term is required'),
});

const maxUnitSchema = yup.object().shape({
  maxUnits: yup
    .number()
    .positive('Should be greater than 0')
    .required('Max unit is required'),
});

function CustomerEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const useQueryParams = new URLSearchParams(location.search);
  const companyNumber = useQueryParams.get('customerNumber');
  const crmId = useQueryParams.get('crmId') || '';
  const crmAccountId = useQueryParams.get('crmAccountId');
  const crmOpportunityId = useQueryParams.get('crmOpportunityId');
  const crmLastOpportunityStage = useQueryParams.get('crmLastOpportunityStage');
  const crmLastOpportunityProbability = parseFloat(
    useQueryParams.get('crmLastOpportunityProbability')
  );
  const crmLastOpportunityCloseDate = useQueryParams.get('crmLastOpportunityCloseDate');
  const crmLastOpportunityAmount = parseFloat(
    useQueryParams.get('crmLastOpportunityAmount')
  );
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);
  const [createCustomer] = useMutation(CREATE_CUSTOMER);

  const [canSafelyExit, setCanSafelyExit] = useState(true);

  const requestModel = useReactiveVar(requestModelAmountVar);
  const session = useReactiveVar(sessionVar);

  const [initialValues, setInitialValues] = useState({
    number: null,
    name: '',
    legalName: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    maxUnits: '',
    dunsNumber: '',
    requestedAmount: '',
    requestedTerm: '',
    contactNumber: null,
    addressId: null,
    crmId,
    crmAccountId,
    crmOpportunityId,
    crmLastOpportunityStage,
    crmLastOpportunityProbability,
    crmLastOpportunityCloseDate,
    crmLastOpportunityAmount,
  });

  const { data: customerDetails, loading: customerLoading } = useQuery(
    GET_CUSTOMER_BY_NUMBER,
    {
      skip: !companyNumber,
      variables: {
        number: companyNumber,
      },
    }
  );

  useEffect(() => {
    if (companyNumber && !customerLoading && customerDetails) {
      setInitialValues((prev) => ({
        ...prev,
        name: titleize(get(customerDetails, 'company.name', '')),
        legalName: get(customerDetails, 'company.legalName', ''),
        street: get(customerDetails, 'company.street', ''),
        city: get(customerDetails, 'company.city', ''),
        state: get(customerDetails, 'company.state', ''),
        zip: get(customerDetails, 'company.zip', ''),
        maxUnits: get(customerDetails, 'company.maxUnits', ''),
        requestedAmount: get(customerDetails, 'company.maxUrequestedAmountnits', ''),
        requestedTerm: get(customerDetails, 'company.requestedTerm', ''),
        crmId: get(customerDetails, 'company.crmId', ''),
        contactNumber: get(customerDetails, 'company.primaryUser.number', null),
        addressId: get(customerDetails, 'company.defaultAddressId', null),
        number: get(customerDetails, 'company.number', ''),
        dunsNumber: get(customerDetails, 'company.dunsNumber', ''),
      }));
    }
  }, [customerDetails, customerLoading, setInitialValues, companyNumber]);

  const onSubmit = useCallback(
    async (values, { setErrors }) => {
      try {
        let responseData;
        let valuesToBeSubmitted = [];
        if (requestModel) {
          valuesToBeSubmitted = omit(values, ['maxUnits']);
        } else {
          valuesToBeSubmitted = omit(values, ['requestedAmount', 'requestedTerm']);
        }
        if (companyNumber && companyNumber !== 'null') {
          responseData = await updateCustomer({
            variables: valuesToBeSubmitted,
          });
        } else {
          responseData = await createCustomer({
            variables: omit(valuesToBeSubmitted, [
              'number',
              'contactNumber',
              'addressId',
            ]),
          });
        }

        const errors = get(responseData, 'errors', {});

        if (Object.keys(errors).length) {
          setErrors(errors);
        } else {
          setCanSafelyExit(true);
          const newCustomerNumber = get(
            responseData,
            'data.createCustomer.number',
            companyNumber
          );
          const newCustomer = newCustomerNumber !== companyNumber;
          const message = newCustomer
            ? 'New Customer successfully added!'
            : 'Customer successfully updated!';
          showToast('success', message);
          let queryParams = location.search;
          if (newCustomer) queryParams += `&customerNumber=${newCustomerNumber}`;
          navigate(`/forms/help${queryParams}`);
        }
      } catch (e) {
        showErrorToast();
      }
    },
    [
      requestModel,
      createCustomer,
      companyNumber,
      updateCustomer,
      navigate,
      location,
      setCanSafelyExit,
    ]
  );

  const formikBag = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema.concat(requestModel ? requestedAmountSchema : maxUnitSchema),
    onSubmit,
  });

  const { isSubmitting, isValid, dirty, setFieldValue, validateField, handleSubmit } =
    formikBag;
  const setSelectedPlace = useSetAddressFields(setFieldValue, validateField);
  const disabled = isSubmitting || !isValid;

  useBeforeunload((event) => {
    if (!canSafelyExit) {
      event.preventDefault();
    }
  });

  const requestedQuantity = useMemo(() => {
    let input = '';
    if (requestModel) {
      input = (
        <>
          <div className="flex flex-row space-x-4">
            <MoneyInputField
              id="requestedAmount"
              name="requestedAmount"
              label="Requested amount"
              // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get currency from product/credit appraisal
              prefix="$"
            />
            <CustomDropdown
              id="requestedTerm"
              name="requestedTerm"
              label="Requested term"
              options={getAllowedTerms(
                get(session, 'session.user.company.product.allowedDurations', [])
              )}
              placeholder="Select"
            />
          </div>
        </>
      );
    } else {
      input = (
        <div className="flex flex-row space-x-4">
          <TextInputField
            id="maxUnits"
            name="maxUnits"
            type="number"
            noDecimal
            label="Max units"
          />
        </div>
      );
    }
    return input;
  }, [requestModel, session]);

  return (
    <div className="flex flex-col gap-6 items-stretch">
      <div className="flex gap-2 border-b pb-9">
        <Typography variant="heading32">New Customer</Typography>
      </div>
      <div className="self-center">
        <div className="mt-8">
          <Typography variant="heading24" color="color-black-100">
            Tell us about your customer
          </Typography>
        </div>
        <div className="mt-2">
          <Typography variant="paragraph14">
            Please provide the following information to generate payment options for your
            customer.
          </Typography>
        </div>
        <FormikProvider value={formikBag}>
          <Form
            className="mt-6"
            onSubmit={handleSubmit}
            onKeyUp={() => {
              setCanSafelyExit(!dirty);
            }}
          >
            <fieldset disabled={isSubmitting}>
              <div className="grid grid-cols-1 divide-y divide-vartana-gray-30">
                <div className="space-y-4 pb-8">
                  <div className="flex flex-row space-x-4">
                    <TextInputField id="name" name="name" label="Company name" />
                    <TextInputField
                      id="legalName"
                      name="legalName"
                      label="Legal entity (optional)"
                      tooltipElement="The legal name of a business as registered with a Secretary of State or the IRS."
                    />
                  </div>

                  {requestedQuantity}

                  <PatternInput
                    id="dunsNumber"
                    name="dunsNumber"
                    label="D&B DUNS Number (optional)"
                    mask="X"
                    format="#########"
                    placeholder="XXXXXXXXX"
                    tooltipElement="A unique nine-digit number used to identify and access information on a business."
                  />

                  <div className="flex flex-row">
                    <AddressInputField
                      name="street"
                      label="Street address"
                      afterPlaceSelect={(tempSelectedPlace) =>
                        setSelectedPlace(tempSelectedPlace)}
                    />
                  </div>

                  <div className="flex flex-row space-x-4">
                    <TextInputField id="city" name="city" label="City" />
                    <CustomDropdown
                      id="state"
                      name="state"
                      label="State"
                      // TODO: Nuyaan95 to add states fetched from BE
                      options={US_STATES}
                      placeholder="Select"
                    />
                    <TextInputField
                      id="zip"
                      name="zip"
                      label="Zip"
                      placeholder="5 digit zipcode"
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row justify-end mt-6">
                <AutoLoad loading={isSubmitting}>
                  <Button type="submit" disabled={disabled}>
                    Submit
                  </Button>
                </AutoLoad>
              </div>
            </fieldset>
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
}

export default CustomerEdit;
