import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import { InputTextAreaField } from '@vartanainc/design-system';
import { ReactComponent as TooltipIcon } from '../../assets/tooltip.svg';

function TextAreaInput({ id, name, label, tooltipElement = null, ...rest }) {
  const [field, meta] = useField(name);

  return (
    <InputTextAreaField
      {...field}
      {...rest}
      id={id}
      name={name}
      fullWidth
      label={label}
      showError={meta.touched && meta.error}
      helperText={meta.error}
      tooltipIcon={tooltipElement ? <TooltipIcon /> : null}
      tooltipElement={tooltipElement}
    />
  );
}

TextAreaInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  tooltipElement: PropTypes.node,
};

TextAreaInput.defaultProps = {
  id: '',
  name: '',
  placeholder: '',
  label: '',
  tooltipElement: null,
};

export default TextAreaInput;
