import { upperCase } from 'lodash';
import PropTypes from 'prop-types';

const inviteStatusClass = {
  active: 'pill success',
  invitation_expired: 'pill canceled',
  awaiting_activation: 'pill secondary',
};

/**
 * Status pill for company invite status
 *
 * @param {string} status - 'active', 'invitation expired' or 'awaiting activation'
 * @param {string} className - Style classes
 */
export function InviteStatusPill({ status, className }) {
  return (
    <span className={`${className} ${inviteStatusClass[status]}`}>
      {upperCase(status)}
    </span>
  );
}

InviteStatusPill.propTypes = {
  status: PropTypes.string.isRequired,
  className: PropTypes.string,
};

InviteStatusPill.defaultProps = {
  className: '',
};
