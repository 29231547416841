import React, { useCallback, useMemo } from 'react';
import { useIntercom } from 'react-use-intercom';
import { capitalize, get } from 'lodash';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Typography } from '@vartanainc/design-system';
import Button from '../Button';
import { ACTION_TO_TEXT, PERMISSIONS } from '../../constants/permissions';
import { PermissionSet } from '../../pages/Settings/Roles/PermissionSet';
import { ReactComponent as UserGearIcon } from '../../assets/user_gear.svg';
import usePermissions from '../../utils/hooks/permissions';

export function RoleDetailCard({ rolePermissions, cardTitle, containerClassName }) {
  const { show: showIntercom } = useIntercom();
  const [, , isSuperAdmin] = usePermissions();

  /**
   * Get `PermissionSet` to render for given resource name
   *
   * @param {string} resource - Resource name for permission group e.g. order, customer
   * @return {node} `PermissionSet` to render
   */
  const getPermissionSet = useCallback(
    (resource) => {
      const permittedActions = get(rolePermissions, resource, []);
      if (permittedActions.includes('view')) {
        const actions = PERMISSIONS[resource]
          .map((action) => ({
            enabled: permittedActions.includes(action),
            text: ACTION_TO_TEXT[resource][action],
          }))
          .filter((action) => action.text);

        return (
          <PermissionSet
            group={`${capitalize(resource)} permissions`}
            actions={isSuperAdmin ? actions : actions.filter((action) => action.enabled)}
            title={
              permittedActions.includes('view_all')
                ? 'For all accounts'
                : 'For assigned accounts'
            }
          />
        );
      }
      return null;
    },
    [isSuperAdmin, rolePermissions]
  );

  const viewAllPermissions = useMemo(() => {
    const showViewAll = Object.values(rolePermissions).some((actionList) =>
      actionList.includes('view_all')
    );

    if (showViewAll) {
      const actions = Object.entries(rolePermissions)
        .filter(([resource]) => ['order', 'customer', 'payment'].includes(resource))
        .map(([resource, permittedActions]) => ({
          enabled: permittedActions.includes('view_all'),
          text: `View ${pluralize(resource)}`,
        }));

      return (
        <PermissionSet
          group="View access"
          actions={isSuperAdmin ? actions : actions.filter((action) => action.enabled)}
          title="For all accounts"
          tooltip={(
            <Typography variant="paragraph12">
              If “View access” is turned on, user will have <br />
              access to view all orders, customers, and <br />
              payments from your organization.
            </Typography>
          )}
        />
      );
    }
    return null;
  }, [isSuperAdmin, rolePermissions]);

  const subtitle = useMemo(() => {
    if (isSuperAdmin) {
      return (
        <>
          <Typography variant="paragraph14">Please contact the </Typography>
          <Button
            element="Vartana help desk"
            onClick={showIntercom}
            className="text-vartana-blue-120"
          />
          <Typography variant="paragraph14">
            {' '}
            to enable additional permissions.
          </Typography>
        </>
      );
    }

    return 'Please contact your manager or admin to modify your permissions.';
  }, [isSuperAdmin, showIntercom]);

  return (
    <div
      id="permissions-card"
      className={`flex flex-col gap-2 py-8 px-4 bg-white ${containerClassName}`}
    >
      <div id="role-detail__header" className="flex flex-col gap-2">
        <div id="role-detail__header--title" className="flex gap-2 items-center">
          <UserGearIcon />
          <Typography variant="heading20" bold>
            {capitalize(cardTitle)}
          </Typography>
        </div>
        <Typography variant="paragraph14">{subtitle}</Typography>
      </div>

      <div className="divide-y divide-vartana-gray-40-v3">
        {getPermissionSet('order')}
        {getPermissionSet('customer')}
        {getPermissionSet('user')}
        {getPermissionSet('payment')}
        {viewAllPermissions}
      </div>
    </div>
  );
}

RoleDetailCard.propTypes = {
  rolePermissions: PropTypes.shape({}).isRequired,
  cardTitle: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
};

RoleDetailCard.defaultProps = {
  containerClassName: '',
};
