import React, { useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';

import { getAPIURL, reportError } from '../../utils/helpers';

const LandingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('t');
    if (token !== null) {
      let path = getAPIURL().replace('/crm', '');
      path = path.replace('/vendor', '');

      fetch(`${path}/set-cookie`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token,
          app: 'vendor-app',
        }),
        credentials: 'include',
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.success) navigate('/dashboard');
          else reportError(`Login error: ${result.error}`);
        });
    }
  }, [location.search, navigate]);

  return <Outlet />;
};

export default LandingPage;
