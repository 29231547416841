import { ReactElement, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { get } from 'lodash';

import TextInputField from '../TextInput/TextInputField';
import InputField from '../CompactFields/InputField';
import { sessionVar } from '../../graphql/cache';
import { COUNTRY } from '../../static';
import {
  CA_POSTAL_PLACEHOLDER,
  US_ZIP_PLACEHOLDER,
} from '../../constants/common.constants';

interface MultiCountryZipInputProps {
  [key: string]: unknown;
}

interface CompactFieldProps {
  label: string;
  name: string;
  customplaceholder: string;
  placeholder: string;
  showError: boolean;
}

const MultiCountryZipInput = ({
  compact,
  customPlaceholder,
  ...zipProps
}: MultiCountryZipInputProps): ReactElement => {
  const sessionData = useReactiveVar(sessionVar);

  const multipleCountriesEnabled = get(
    sessionData,
    'session.user.company.product.multipleCountriesEnabled',
    false
  );
  const enabledCountries = get(
    sessionData,
    'session.user.company.product.enabledCountries',
    [COUNTRY.US]
  );

  const placeholder = useMemo(() => {
    const isNotMultiCountry = !multipleCountriesEnabled && enabledCountries;
    const isUsOnly = isNotMultiCountry && enabledCountries[0] === COUNTRY.US;
    const isCaOnly = isNotMultiCountry && enabledCountries[0] === COUNTRY.CA;
    if (isUsOnly) {
      return US_ZIP_PLACEHOLDER;
    }
    if (isCaOnly) {
      return CA_POSTAL_PLACEHOLDER;
    }
    return '';
  }, [multipleCountriesEnabled, enabledCountries]);

  const compactProps = {
    ...zipProps,
    placeholder,
  } as CompactFieldProps;

  if (compact) {
    return (
      <div className="zip-input-container">
        <InputField {...compactProps} />
      </div>
    );
  }
  return (
    <div className="zip-input-container">
      <TextInputField
        {...{
          ...zipProps,
          placeholder: customPlaceholder || placeholder,
        }}
        ref={null}
      />
    </div>
  );
};

export default MultiCountryZipInput;
