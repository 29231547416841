/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useFormik, FormikProvider } from 'formik';

import * as yup from 'yup';
import { useParams, useNavigate } from 'react-router-dom';

import { omit, get } from 'lodash';
import { toast } from 'react-toastify';
import { Card, Typography, ButtonV2 } from '@vartanainc/design-system';

import { useQuery, useMutation, useReactiveVar } from '@apollo/client';

import { useBeforeunload } from 'react-beforeunload';

import FormInput from '../../components/FormInput';
import AutoLoad from '../../components/AutoLoad';
import CustomNotification from '../../components/CustomNotification';
import CancelConfirmation from '../../components/Modals/CancelConfirmation';

import {
  GET_CUSTOMER_BY_NUMBER,
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER,
  VALIDATE_CUSTOMER,
} from '../../graphql/queries/customer';

import { sessionVar, requestModelAmountVar } from '../../graphql/cache';
import {
  getCountryOptions,
  getStateOptions,
  getTermOptionsForCustomerCreation,
  handleCountrySelect,
  reportError,
} from '../../utils/helpers';
import TextInputField from '../../designSystem/TextInput/TextInputField';
import MoneyInputField from '../../designSystem/MoneyInput/MoneyInputField';
import MultiCountryZipInput from '../../designSystem/MultiCountryZipInput/MultiCountryZipInput';
import CustomDropdown from '../../designSystem/FormikDropdownInput/FormikDropdownInput';
import { useDirectUploadFiles, useSetAddressFields } from '../../utils/hooks';
import AddressInputField from '../../designSystem/AddressInput/AddressInput';
import { ReactComponent as StoreIcon } from '../../assets/store-blue.svg';
import { COUNTRY, COUNTRY_TO_CURRENCY_MAP } from '../../static';
import MultiFileUpload from '../../components/MultiFileUpload';
import {
  CUSTOMER_CREATION_GUARANTY_TYPES,
  zipSchema,
} from '../../constants/common.constants';
import PersonalGuarantor from '../../macro_components/PersonalGuarantor/PersonalGuarantor';

const schema = yup.object().shape({
  name: yup
    .string()
    .min(1, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Company name is required'),
  street: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zip: zipSchema,
  maxUnits: yup.number().positive('Should be greater than 0'),
});

const salesQuoteSchema = yup.object().shape({
  docs: yup.array().test('files length', 'Sales quote is required', (value) => {
    return value?.length > 0;
  }),
});

const requestedAmountSchema = yup.object().shape({
  requestedAmount: yup
    .number()
    .required('Request amount is required')
    .positive('Should be greater than 0'),
  requestedTerm: yup.number().required('Requested term is required'),
});

const maxUnitSchema = yup.object().shape({
  maxUnits: yup
    .number()
    .positive('Should be greater than 0')
    .required('Max unit is required'),
});

export function CustomerEdit() {
  const { companyNumber } = useParams();
  const navigate = useNavigate();
  const requestModel = useReactiveVar(requestModelAmountVar);
  const session = useReactiveVar(sessionVar);
  const showCountryField = useMemo(() => {
    return get(session, 'session.user.company.product.multipleCountriesEnabled', false);
  }, [session]);
  const supportedCountries = useMemo(() => {
    return get(session, 'session.user.company.product.enabledCountries', []);
  }, [session]);
  const supportedCurrencies = useMemo(() => {
    return get(session, 'session.user.company.product.enabledCurrencies', []);
  }, [session]);
  const isSalesQuoteCompulsory = useMemo(() => {
    return get(
      session,
      'session.user.company.salesQuoteRequiredAtCustomerCreation',
      false
    );
  }, [session]);

  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);
  const [createCustomer] = useMutation(CREATE_CUSTOMER);
  const [validateCustomer, { loading: isValidatingCustomer }] =
    useMutation(VALIDATE_CUSTOMER);
  const [attachSalesQuote] = useDirectUploadFiles();

  const [canSafelyExit, setCanSafelyExit] = useState(true);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [resetPGAddressSwitch, setResetPGAddressSwitch] = useState(false);

  const [initialValues, setInitialValues] = useState({
    number: null,
    name: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    employeeCount: '',
    annualRevenue: '',
    maxUnits: '',
    requestedAmount: '',
    requestedTerm: '',
    contactNumber: null,
    addressId: null,
    country: showCountryField ? '' : supportedCountries[0],
    currency: showCountryField ? '' : supportedCurrencies[0],
  });
  const [showPersonalGuarantor, setShowPersonalGuarantor] = useState(false);

  const countrySchema = yup.object().shape({
    // country's should be one of supported countries from Product
    country: yup.string().required('Country is required'),
    currency: yup.string().required('Currency is required'),
  });

  const { data: customerDetails, loading: customerLoading } = useQuery(
    GET_CUSTOMER_BY_NUMBER,
    {
      skip: !companyNumber,
      variables: {
        number: companyNumber,
      },
    }
  );

  useEffect(() => {
    if (companyNumber && !customerLoading && customerDetails) {
      setInitialValues((prev) => ({
        ...prev,
        name: get(customerDetails, 'company.name', ''),
        entityType: get(customerDetails, 'company.entityType', ''),
        street: get(customerDetails, 'company.street', ''),
        city: get(customerDetails, 'company.city', ''),
        state: get(customerDetails, 'company.state', ''),
        zip: get(customerDetails, 'company.zip', ''),
        employeeCount: get(customerDetails, 'company.employeeCount', ''),
        annualRevenue: get(customerDetails, 'company.annualRevenue', ''),
        maxUnits: get(customerDetails, 'company.maxUnits', ''),
        requestedAmount: get(customerDetails, 'company.requestedAmount', ''),
        requestedTerm: get(customerDetails, 'company.requestedTerm', ''),
        addressId: get(customerDetails, 'company.defaultAddressId', null),
        number: get(customerDetails, 'company.number', ''),
      }));
    }
  }, [customerDetails, customerLoading, setInitialValues, companyNumber]);

  const checkCountryExists = useCallback((personalGuarantorList, country) => {
    // Ensure the `country` field is set for all guarantors if not already present
    return personalGuarantorList.map((guarantor) => {
      if (!guarantor.country) {
        return {
          ...guarantor,
          country,
        };
      }
      return guarantor;
    });
  }, []);

  const onSubmit = useCallback(
    async (values, { setErrors }) => {
      let errors;
      try {
        let responseData = {};
        let valuesToBeSubmitted = [];
        if (requestModel) {
          valuesToBeSubmitted = omit(values, ['maxUnits']);
        } else {
          valuesToBeSubmitted = omit(values, ['requestedAmount', 'requestedTerm']);
        }
        const updatedGuarantors = checkCountryExists(
          values.personalGuarantors || [],
          values.country
        );
        // Include the updated guarantors in the values
        valuesToBeSubmitted = {
          ...valuesToBeSubmitted,
          personalGuarantors: updatedGuarantors,
        };
        if (companyNumber) {
          responseData = await updateCustomer({
            variables: valuesToBeSubmitted,
          });
        } else {
          responseData = await createCustomer({
            variables: omit(valuesToBeSubmitted, [
              'number',
              'contactNumber',
              'addressId',
              'docs',
            ]),
          });
          const { id: newCustomerId } = get(responseData, 'data.createCustomer', '');
          const { id: creditCheckId } = get(
            responseData,
            'data.createCustomer.creditCheck',
            ''
          );

          const hasErrors = Object.keys(responseData.errors || {}).length;
          if (!hasErrors) {
            const { docs } = values;
            // attach sales quote with customer only when credit check is not returned
            const attachedResource = {
              id: creditCheckId || newCustomerId,
              type: creditCheckId ? 'CreditEngine::CreditCheck' : 'Company',
            };
            const documentType = {
              documentType: 'sales_quote',
            };
            if (docs?.length)
              await attachSalesQuote(docs, documentType, attachedResource);
          }
        }

        errors = get(responseData, 'errors', {});
        if (Object.keys(errors).length) {
          await setErrors(errors);
        } else {
          const newCustomerNumber = get(
            responseData,
            'data.createCustomer.number',
            companyNumber
          );
          setCanSafelyExit(true);
          const message = companyNumber
            ? 'Customer updated! Payment plans will be generated automatically upon reviewing customer’s information. Check back for updates.'
            : 'Your customer was added successfully, updates on their credit review will be available on the current page.';
          setTimeout(() => {
            toast.success(({ toastProps }) => {
              return <CustomNotification type={toastProps.type} message={message} />;
            });
            navigate(`/dashboard/customers/${newCustomerNumber}/summary`);
          }, 0);
        }
      } catch (e) {
        console.log(e);
      }
      return errors;
    },
    [
      requestModel,
      checkCountryExists,
      companyNumber,
      updateCustomer,
      createCustomer,
      attachSalesQuote,
      navigate,
    ]
  );

  const formikSchema = useMemo(() => {
    let schemaToUse = schema;
    if (requestModel) {
      schemaToUse = schemaToUse.concat(requestedAmountSchema);
    } else {
      schemaToUse = schemaToUse.concat(maxUnitSchema);
    }
    if (showCountryField) {
      schemaToUse = schemaToUse.concat(countrySchema);
    }
    if (isSalesQuoteCompulsory) {
      schemaToUse = schemaToUse.concat(salesQuoteSchema);
    }
    return schemaToUse;
  }, [requestModel, showCountryField, countrySchema, isSalesQuoteCompulsory]);

  const formikBag = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formikSchema,
    onSubmit,
  });

  const handleCustomerValidation = useCallback(async () => {
    let hasErrors;
    const { values, setErrors } = formikBag;
    try {
      let valuesToBeSubmitted = [];
      // omit maxUnits OR requestedAmount and requestedTerm based on Vendor's configuration
      if (requestModel) {
        valuesToBeSubmitted = omit(values, ['maxUnits']);
      } else {
        valuesToBeSubmitted = omit(values, ['requestedAmount', 'requestedTerm']);
      }

      // place validation call while omitting unwated fields
      const responseData = await validateCustomer({
        variables: omit(valuesToBeSubmitted, [
          'number',
          'contactNumber',
          'addressId',
          'docs',
        ]),
      });

      // check if the validation response has errors
      hasErrors = Object.keys(responseData.errors || {}).length;
      const errors = get(responseData, 'errors', {});
      if (hasErrors) {
        setErrors(errors);
      }
    } catch (e) {
      reportError(`While validating customer: ${e}`);
    }

    // return true if no errors, false otherwise
    return !hasErrors;
  }, [requestModel, formikBag, validateCustomer]);

  const requestedTermOptions = getTermOptionsForCustomerCreation(
    session,
    formikBag.values.country
  );

  const handleCountryChange = useCallback(async () => {
    const { country, requestedTerm } = formikBag.values;
    if (country === COUNTRY.CA && requestedTerm === '0') {
      await formikBag.setFieldValue('requestedTerm', '');
      await formikBag.validateForm();
    }
    if (country) setResetPGAddressSwitch((prev) => !prev);
  }, [formikBag]);

  useEffect(() => {
    // whenever canada is selected as country, remove pay in full option from requested terms
    handleCountryChange();

    // we should run this effect only when the country is toggled
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikBag.values.country]);

  useEffect(() => {
    if (showCountryField) {
      const countryCode = formikBag.values.country;
      const currency = COUNTRY_TO_CURRENCY_MAP[countryCode];
      if (currency) formikBag.setFieldValue('currency', currency);
    }
    // only run this effect when country changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikBag.values.country]);

  const { isSubmitting, isValid, dirty, setFieldValue, validateField, handleSubmit } =
    formikBag;
  const setSelectedPlace = useSetAddressFields(
    setFieldValue,
    validateField,
    showCountryField
  );

  const disabled = isSubmitting || !(isValid && dirty);

  useBeforeunload((event) => {
    if (!canSafelyExit) {
      event.preventDefault();
    }
  });

  const requestedQuantity = useMemo(() => {
    let input = '';
    if (requestModel) {
      input = (
        <>
          <div className="flex flex-row gap-4">
            <MoneyInputField
              id="requestedAmount"
              name="requestedAmount"
              label="Requested amount"
              // TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get currency from product/credit appraisal
              prefix="$"
            />

            <CustomDropdown
              id="requestedTerm"
              name="requestedTerm"
              label="Requested term"
              options={requestedTermOptions}
              placeholder="Select option"
            />
          </div>
        </>
      );
    } else {
      input = (
        <div className="flex flex-row">
          <TextInputField
            id="maxUnits"
            name="maxUnits"
            type="number"
            noDecimal
            label="Max units"
          />
        </div>
      );
    }
    return input;
  }, [requestModel, requestedTermOptions]);

  const handleCancel = () => {
    if (dirty) {
      setOpenConfirmationModal(true);
    } else {
      navigate(-1);
    }
  };

  const nextBtnDisabled = isValidatingCustomer || !isValid || !dirty;

  const handleNext = async () => {
    const validationPass = await handleCustomerValidation();

    if (validationPass) setShowPersonalGuarantor(true);
  };

  const guarantyRequiredOnCustomerCreation = useMemo(() => {
    return (
      get(session, 'session.user.company.guaranteeTypeOnCustomerCreation', null) ===
      CUSTOMER_CREATION_GUARANTY_TYPES.PG
    );
  }, [session]);

  const handleSubmissionWithGuarantors = async (guarantors) => {
    await setFieldValue('personalGuarantors', guarantors);
    const backendErrors = await formikBag.submitForm();
    return backendErrors;
  };

  return (
    <AutoLoad
      containerClassName="flex justify-center"
      className="absolute text-center top-2/4 transform-gpu -translate-y-2/4"
    >
      <CancelConfirmation
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        onConfirm={() => navigate(-1)}
      />
      <div className="flex flex-col h-full">
        <div className="px-8 border-b border-gray-200 bg-white flex items-center min-h-[6.375rem]">
          <Typography variant="heading24" color="color-black-100">
            {companyNumber ? 'Edit customer info' : 'Add customer'}
          </Typography>
        </div>
        <div className="gap-x-4 h-full w-full bg-vartana-steel-20 ">
          <div className="py-8 rounded-lg w-7/12 mx-auto small-card-width-range">
            {/* Using `display` instead of unmounting to retain component state and avoid resetting form data. */}
            <div className={`${showPersonalGuarantor ? 'hidden' : 'block'}`}>
              <Card
                tabindex="0"
                variant="fullWidth"
                title={(
                  <div className="grid gap-2.5">
                    <div className="flex gap-2 items-center">
                      <StoreIcon />
                      <Typography variant="heading20" bold color="color-black-100">
                        Tell us about your customer
                      </Typography>
                    </div>
                    <Typography variant="paragraph14" color="color-black-100">
                      Please provide the following information to start financing review
                      for your customer.
                    </Typography>
                  </div>
                )}
                content={(
                  <FormikProvider value={formikBag}>
                    <form
                      onSubmit={handleSubmit}
                      onKeyUp={() => {
                        setCanSafelyExit(!dirty);
                      }}
                    >
                      <fieldset disabled={isSubmitting}>
                        <div className="grid grid-cols-1 divide-y divide-vartana-gray-30">
                          <div className="flex flex-col gap-4">
                            <FormInput className="hidden" name="number" type="hidden" />
                            <div className="flex flex-row">
                              <TextInputField
                                id="name"
                                name="name"
                                label="Company name"
                              />
                            </div>

                            <FormInput
                              className="hidden"
                              name="addressId"
                              type="hidden"
                            />

                            <AddressInputField
                              name="street"
                              label="Address"
                              afterPlaceSelect={(tempSelectedPlace) =>
                                setSelectedPlace(tempSelectedPlace)}
                              countries={supportedCountries}
                            />

                            <div className="flex flex-row gap-4">
                              <div className="w-1/2">
                                <TextInputField id="city" name="city" label="City" />
                              </div>
                              <div className="w-1/2 flex flex-row gap-4">
                                <CustomDropdown
                                  id="state"
                                  name="state"
                                  label="State"
                                  options={getStateOptions(supportedCountries) || []}
                                  placeholder="Select"
                                />
                                <MultiCountryZipInput id="zip" name="zip" label="Zip" />
                              </div>
                            </div>
                            {showCountryField && (
                              <div className="flex flex-row gap-4">
                                <CustomDropdown
                                  id="country"
                                  name="country"
                                  label="Country"
                                  options={getCountryOptions(supportedCountries) || []}
                                  placeholder="Select"
                                  onChange={(e) =>
                                    handleCountrySelect(formikBag, e.value)}
                                />
                                <TextInputField
                                  id="currency"
                                  name="currency"
                                  label="Currency"
                                  disabled
                                />
                              </div>
                            )}
                            {requestedQuantity}
                            <div className="flex flex-col gap-1">
                              <Typography variant="paragraph12" color="color-gray-140">
                                Sales quote or other documents
                                {!isSalesQuoteCompulsory && ' (optional)'}
                              </Typography>
                              <MultiFileUpload
                                uploadText="Click or drag PDF files to upload"
                                name="docs"
                                acceptMultipleFiles
                              />
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-row justify-between mt-8">
                          <ButtonV2
                            variant={{ type: 'ghost', typography: 'paragraph14' }}
                            type="button"
                            text="Cancel"
                            iconLeft="chevron_left"
                            onClick={handleCancel}
                          />
                          <AutoLoad loading={isSubmitting || isValidatingCustomer}>
                            {guarantyRequiredOnCustomerCreation ? (
                              <ButtonV2
                                iconRight="chevron_right"
                                type="button"
                                onClick={handleNext}
                                text="Next"
                                disabled={nextBtnDisabled}
                                variant={{ type: 'primary', typography: 'paragraph14' }}
                              />
                            ) : (
                              <ButtonV2
                                type="submit"
                                disabled={disabled}
                                text="Submit"
                                variant={{ type: 'primary', typography: 'paragraph14' }}
                              />
                            )}
                          </AutoLoad>
                        </div>
                      </fieldset>
                    </form>
                  </FormikProvider>
                )}
              >
              </Card>
            </div>
            {/* Using `display` instead of unmounting to retain component state and avoid resetting form data. */}
            <div className={`${showPersonalGuarantor ? 'block' : 'hidden'}`}>
              <PersonalGuarantor
                handleBackButtonClick={() => {
                  setShowPersonalGuarantor(false);
                }}
                handleSkipButtonClick={async () => {
                  await handleSubmissionWithGuarantors([]);
                }}
                setGuarantorsInForm={handleSubmissionWithGuarantors}
                showSinField={formikBag.values.country === COUNTRY.CA}
                selectedCountry={formikBag.values.country}
                resetPGAddressSwitch={resetPGAddressSwitch}
              />
            </div>
          </div>
        </div>
      </div>
    </AutoLoad>
  );
}
