import PropTypes from 'prop-types';
import { Typography, Button } from '@vartanainc/design-system';
import greenCheckIcon from '../../../assets/green_check.svg';

/**
 * Modal box shown at end when Integration is successful.
 * Shown after Tray Config Wizard closes.
 */
function SyncSuccessful({ onClose, activatedIntegration, iconKey }) {
  const integrationName = activatedIntegration.title;
  const iconUrl = activatedIntegration.customFields.find(
    (field) => field.key === iconKey
  )?.value;

  return (
    <div className="mx-5 px-5 mt-5 pt-5">
      <div className="mb-5">
        <Typography variant="heading18" bold>
          {integrationName} synced successfully!
        </Typography>
        <Typography variant="heading14">
          Your customers are up to date through {integrationName}
        </Typography>
      </div>
      <div className="w-full border-t border-vartana-gray-30"></div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row space-x-1 py-4">
          <div>
            <img src={iconUrl} alt="Logo" className="h-10 w-auto" />
          </div>
          <div className="flex flex-col flex-start">
            <Typography variant="heading18" bold>
              {integrationName}{' '}
            </Typography>
            <p className="vp-field-text">Integrated</p>
          </div>
        </div>
        <div className="self-center">
          <img src={greenCheckIcon} alt="Green Check Icon" />
        </div>
      </div>
      <div className="w-full border-t border-vartana-gray-30 mb-40"></div>
      <div className="flex flex-row justify-end">
        <Button onClick={onClose}>Great! Close window</Button>
      </div>
    </div>
  );
}

SyncSuccessful.propTypes = {
  onClose: PropTypes.func.isRequired,
  iconKey: PropTypes.string.isRequired,
  activatedIntegration: PropTypes.shape({
    title: PropTypes.string.isRequired,
    customFields: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      })
    ),
  }).isRequired,
};

export default SyncSuccessful;
