import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import FormLabel from '../FormLabel';
import { TOGGLE_LABEL_POSITION } from '../../constants/common.constants';

function FormToggle({
  className,
  name,
  tooltipElement,
  label,
  disabled,
  labelPosition = TOGGLE_LABEL_POSITION.DEFAULT,
  onChange,
}) {
  const [field, , { setValue }] = useField(name);

  function formToggleLabel() {
    return (
      <FormLabel
        containerClassName="mb-2"
        labelClassName="text-vartana-gray-60"
        name={field.name}
        label={label}
        tooltipElement={tooltipElement}
      />
    );
  }

  // Define handleToggleChange function
  function handleToggleChange() {
    if (onChange) {
      onChange();
    }
    setValue(!field.value);
  }

  return (
    <div className={`${className}`}>
      {labelPosition === TOGGLE_LABEL_POSITION.DEFAULT && formToggleLabel()}
      <button
        type="button"
        className={`${
          field.value ? 'bg-vartana-blue-60' : 'bg-vartana-gray-40'
        } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue disabled:opacity-50`}
        onClick={handleToggleChange}
        disabled={disabled}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={`${
            field.value ? 'translate-x-5' : 'translate-x-0'
          } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
        >
        </span>
      </button>
      {labelPosition === TOGGLE_LABEL_POSITION.RIGHT && formToggleLabel()}
    </div>
  );
}

FormToggle.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  tooltipElement: PropTypes.node,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  labelPosition: PropTypes.string,
  onChange: PropTypes.func,
};

FormToggle.defaultProps = {
  className: '',
  name: '',
  tooltipElement: null,
  label: '',
  disabled: false,
  labelPosition: TOGGLE_LABEL_POSITION.DEFAULT,
  onChange: () => {},
};

export default FormToggle;
