import { ReactElement } from 'react';
import { Typography } from '@vartanainc/design-system';
import { ReactComponent as Completed } from '../../../../assets/submission_complete.svg';

export default function SubmissionComplete(): ReactElement {
  return (
    <div className="flex flex-col items-center submission-complete-container gap-4 pt-[7.5rem] min-h-[40rem]">
      <Completed />
      <Typography variant="heading32" className="text-center">
        We received your submission.
      </Typography>
      <Typography variant="paragraph14">
        <div className="text-center">Hang tight while we review the information.</div>
      </Typography>
    </div>
  );
}
