import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { Card } from '@vartanainc/design-system';
import { GET_ROLE_DETAIL } from '../../../graphql/queries/roles';
import AutoLoad from '../../../components/AutoLoad';
import { RoleDetailCard } from '../../../components/Role/RoleDetailCard';
import { humanizeName } from '../../../utils/helpers';
import { BackButton } from '../../../components/Button/BackButton';
import Tabs from '../../../components/TabbedNavigation';
import {
  ViewNotificationPreferences,
  EditNotificationPreferences,
} from '../NotificationPreferences';
import usePermissions from '../../../utils/hooks/permissions';
import { GET_PROFILE_DETAILS } from '../../../graphql/queries/profile';
import { GET_NOTIFICATION_PREFS } from '../../../graphql/queries/vendor';
import { formatPreferenceSets } from '../helpers';

const MODE = {
  permissions: 'permissions',
  viewNotifications: 'view',
  editNotifications: 'edit',
};

function RoleDetail() {
  const { id: roleId } = useParams();
  const navigate = useNavigate();
  const [, hasPermission] = usePermissions();

  const [mode, setMode] = useState(MODE.permissions);

  const { data: roleData, loading: roleLoading } = useQuery(GET_ROLE_DETAIL, {
    variables: { id: +roleId },
  });

  const { data: profileData, loading: profileLoading } = useQuery(GET_PROFILE_DETAILS);

  const { data: notifPrefsData, loading: prefsLoading } = useQuery(
    GET_NOTIFICATION_PREFS,
    {
      variables: { roleId: +roleId },
    }
  );

  const notifPreferences = get(notifPrefsData, 'seller.roleNotificationPreferences', []);
  const channelPreferences = get(notifPrefsData, 'seller.roleChannelPreference', {});
  const preferenceSets = formatPreferenceSets(notifPreferences, channelPreferences);

  const rolePermissions = get(roleData, 'role.rolePermissions', {});
  const roleName = humanizeName(get(roleData, 'role.name', ''));
  const selfRole = get(profileData, 'session.user.userRole.id') === roleId;

  const mainContent = useMemo(() => {
    if (mode === MODE.editNotifications) {
      return (
        <EditNotificationPreferences
          roleName={roleName}
          onClose={() => setMode(MODE.viewNotifications)}
          preferenceSets={preferenceSets}
        />
      );
    }

    if (
      hasPermission('notification', 'view') &&
      (selfRole || hasPermission('notification', 'view_all'))
    ) {
      return (
        <Card
          variant="fullWidth"
          tabindex="0"
          content={(
            <Tabs
              tabLabels={['Permissions', 'Notifications']}
              variant="card"
              preSelectedTabIndex={mode === MODE.permissions ? 0 : 1}
            >
              <RoleDetailCard
                rolePermissions={rolePermissions}
                cardTitle={`${roleName} permissions`}
              />
              <ViewNotificationPreferences
                roleName={roleName}
                onEditClick={() => setMode(MODE.editNotifications)}
                preferenceSets={preferenceSets}
              />
            </Tabs>
          )}
        >
        </Card>
      );
    }

    return (
      <RoleDetailCard
        rolePermissions={rolePermissions}
        cardTitle={`${roleName} permissions`}
        containerClassName="bg-white"
      />
    );
  }, [hasPermission, mode, preferenceSets, roleName, rolePermissions, selfRole]);

  return (
    <AutoLoad
      loading={roleLoading || profileLoading || prefsLoading}
      containerClassName="h-full flex"
    >
      <div className="gap-x-4 h-full w-full bg-vartana-steel-20 ">
        <div className="w-7/12 mx-auto small-card-width-range">
          <div className="flex flex-col gap-10  dashboard-single-card py-6 w-full">
            {mainContent}
            {[MODE.permissions, MODE.viewNotifications].includes(mode) && (
              <BackButton btnText="Back to roles" onClick={() => navigate(-1)} />
            )}
          </div>
        </div>
      </div>
    </AutoLoad>
  );
}

export default RoleDetail;
