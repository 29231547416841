import React from 'react';
import PropTypes from 'prop-types';
import { ButtonV2, Card, Typography } from '@vartanainc/design-system';
import { labelValueField } from '../../../constants/common.constants';
import { HasPermission } from '../../../components/HasPermission/HasPermission';
import { ACTION } from '../../../constants/permissions';
import SvgIcon from '../../../components/SvgIcon/SvgIcon';

const RESOURCES = ['order', 'customer'];

function ViewAccountOwner({
  resource,
  customer,
  user,
  openReassignAccountOwnerSection,
  allowReassignAccount,
}) {
  const { firstName, lastName, email, phone, title } = user || {};

  return (
    <Card
      variant="fullWidth"
      title={(
        <div className="flex items-center justify-between w-full">
          <Typography variant="heading18" bold color="color-black-100">
            Account owner
          </Typography>
          {allowReassignAccount && (
            <HasPermission
              resource={resource}
              action={ACTION.update_owner}
              customer={customer}
            >
              <ButtonV2
                variant={{ type: 'ghost', typography: 'paragraph12' }}
                onClick={openReassignAccountOwnerSection}
                text="Reassign owner"
              />
            </HasPermission>
          )}
        </div>
      )}
      icon={<SvgIcon name="account-owner" width="2rem" height="2rem" />}
      content={(
        <div className="grid grid-cols-2 gap-6">
          {labelValueField('First name', firstName || '--')}
          {labelValueField('Last name', lastName || '--')}
          {labelValueField('Email', email || '--')}
          {labelValueField('Phone', phone || '--')}
          {labelValueField('Job title', title || '--')}
        </div>
      )}
    >
    </Card>
  );
}

ViewAccountOwner.propTypes = {
  resource: PropTypes.oneOf(RESOURCES).isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  customer: PropTypes.shape({
    buyerRelationToVendor: PropTypes.string,
  }).isRequired,
  openReassignAccountOwnerSection: PropTypes.func.isRequired,
  allowReassignAccount: PropTypes.bool,
};

ViewAccountOwner.defaultProps = {
  allowReassignAccount: true,
};

export default ViewAccountOwner;
