import { TrackStatus } from '@vartanainc/design-system';
import { ReactElement, useContext } from 'react';
import { get } from 'lodash';
import { WidgetContext } from '../../../../context/WidgetContext';
import { MoreInfoStepTracker } from '../StepsTracker/StepsTracker';

export interface stepDetailsProps {
  lastActiveAt: string;
  name: string;
  status: string;
  text: string;
}
interface TrackRequestStatusProps {
  title: string;
  description: string;
  lastActive: string;
  completed: boolean;
  step: number;
  progress: number;
  isActive: boolean;
  stepsCount: number;
  stepDetails: stepDetailsProps[];
}

export type trackerState = 'active' | 'active-warn' | 'completed' | 'disabled';
export type TrackerStatus =
  | 'sending-request'
  | 'request-sent'
  | 'in-progress'
  | 'finished';

interface TrackerStep {
  stepNumber: number;
  stepStatus: trackerState;
}

const TrackRequestStatus = ({
  title,
  description,
  lastActive,
  completed,
  step,
  progress,
  isActive,
  stepsCount,
  stepDetails,
}: TrackRequestStatusProps): ReactElement => {
  const widgetContext = useContext(WidgetContext);
  const screen = get(widgetContext, 'screen');

  const getTrackerSteps = (): TrackerStep[] => {
    let steps: TrackerStep[] = [];
    if (completed) {
      steps = [
        {
          stepNumber: 1,
          stepStatus: 'completed',
        },
        {
          stepNumber: 2,
          stepStatus: 'completed',
        },
      ];
    } else if (step === 1) {
      steps = [
        {
          stepNumber: 1,
          stepStatus: isActive ? 'active' : 'active-warn',
        },
        {
          stepNumber: 2,
          stepStatus: 'disabled',
        },
      ];
    } else if (step === 2) {
      steps = [
        {
          stepNumber: 1,
          stepStatus: 'completed',
        },
        {
          stepNumber: 2,
          stepStatus: isActive ? 'active' : 'active-warn',
        },
      ];
    }
    return steps;
  };

  const getTrackerIcon = (): TrackerStatus => {
    if (step !== null && step !== undefined) {
      return completed ? 'finished' : 'in-progress';
    }
    switch (title) {
      case 'Sending order link': {
        return 'sending-request';
      }
      default: {
        return 'request-sent';
      }
    }
  };

  const showSteps = stepDetails.length > 0 && getTrackerIcon() !== 'finished';
  return (
    <div
      className={`w-full h-full flex flex-col items-center gap-4 justify-center ${
        screen !== 'track' ? 'bg-slate-100' : ''
      }`}
    >
      {showSteps ? (
        <MoreInfoStepTracker stepDetails={stepDetails} />
      ) : (
        <TrackStatus
          steps={getTrackerSteps()}
          trackerStatus={getTrackerIcon()}
          heading={title}
          description={description}
          timestamp={lastActive}
          isOrder={false}
          stepsCount={stepsCount}
        />
      )}
    </div>
  );
};

export default TrackRequestStatus;
