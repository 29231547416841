import { useCallback, useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { get } from 'lodash';
import { availableTerms } from '../../api/utils';
import { BUYER_TYPE, orderScreens, orderType } from '../../constants/common.constants';
import {
  authSignFormSchema,
  getNewCustomerAddressFormSchema,
  getOrderFormSchema,
  resellerFormSchema,
} from '../../pages/Orders/order.constants';
import { sessionVar } from '../../graphql/cache';

export function useOrderCreation(selectedCustomer) {
  const [resellerMode, setResellerMode] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(orderScreens.proposals);
  const [availableTermsLoading, setAvailableTermsLoading] = useState(false);
  const [validationSchema, setValidationSchema] = useState(getOrderFormSchema());
  const [isDirectOrder, setIsDirectOrder] = useState(false);

  const sessionData = useReactiveVar(sessionVar);
  const multipleCountriesEnabled = get(
    sessionData,
    'session.user.company.product.multipleCountriesEnabled',
    false
  );
  const isDirectCustomer = selectedCustomer?.buyerRelationToVendor === BUYER_TYPE.DIRECT;
  const isIndirectCustomer =
    selectedCustomer?.buyerRelationToVendor === BUYER_TYPE.INDIRECT;

  const resellerOrderAllowed =
    isIndirectCustomer || get(sessionData, 'session.user.company.useResellerWorkflow');

  const checkOrderFinancingStatus = (proposals) => {
    return (
      proposals &&
      proposals.some(
        (proposal) =>
          proposal.orderType === orderType.installments ||
          proposal.orderType === orderType.pay_in_full
      )
    );
  };

  // On VD, turn it ON by default if vendor flag is ON.
  // On RD, keep it ON always.
  useEffect(() => {
    if (isIndirectCustomer) setResellerMode(true);
    else if (isDirectCustomer)
      setResellerMode(
        !!(
          get(sessionData, 'session.user.company.useResellerWorkflow') &&
          get(sessionData, 'session.user.company.turnOnResellerMode')
        )
      );
  }, [isDirectCustomer, isIndirectCustomer, sessionData]);

  /**
   * Call API to fetch available terms for selected customer
   * @param {string} startDate
   * @param {string} customerNumber
   * @param {function(object[]): void} setTerms
   */
  const fetchAvailableTerms = useCallback((customerNumber, setTerms, setStartDate) => {
    if (customerNumber) {
      setAvailableTermsLoading(true);
      availableTerms(customerNumber)
        .then((val) => {
          const formattedTermOptions = val.terms.map(
            (availableTerm, termOptionIndex) => ({
              id: termOptionIndex,
              value: availableTerm.value,
              label: availableTerm.label,
            })
          );
          setTerms(formattedTermOptions);
          setStartDate(new Date(val.start_date));
        })
        .catch((error) =>
          reportError(
            `While filling new Order Proposal for customer# ${customerNumber}: ${error.message}`
          )
        )
        .finally(() => setAvailableTermsLoading(false));
    }
  }, []);

  const handleNext = (proposals) => {
    // Check if any of the proposals has vartana financing
    const isFinancedOrder = checkOrderFinancingStatus(proposals);
    // Set isDirectOrder based on the presence of vartana financing
    setIsDirectOrder(!isFinancedOrder);
    switch (currentScreen) {
    case orderScreens.proposals:
      // If reseller order is allowed and order is vartana financed, show reseller screen
      if (resellerOrderAllowed && isFinancedOrder) {
        setCurrentScreen(orderScreens.reseller);
        setValidationSchema(getOrderFormSchema().concat(resellerFormSchema));
      } else {
        setCurrentScreen(orderScreens.authorizedSigner);
        setValidationSchema(
          getOrderFormSchema()
            .concat(authSignFormSchema)
            .concat(authSignFormSchema)
            .concat(
              getNewCustomerAddressFormSchema(
                selectedCustomer,
                multipleCountriesEnabled
              )
            )
        );
      }
      break;
    case orderScreens.reseller:
      setCurrentScreen(orderScreens.authorizedSigner);
      setValidationSchema(
        getOrderFormSchema()
          .concat(resellerFormSchema)
          .concat(authSignFormSchema)
          .concat(
            getNewCustomerAddressFormSchema(selectedCustomer, multipleCountriesEnabled)
          )
      );
      break;
    default:
    }
  };

  const handleBack = (proposals, setCompanyList, newCompanyName, updatedCompanyName) => {
    if (setCompanyList && newCompanyName && updatedCompanyName) {
      setCompanyList((prev) => {
        const updatedList = prev.map((company) => {
          if (company.name === newCompanyName) {
            return { ...company, name: updatedCompanyName, number: updatedCompanyName };
          }
          return company;
        });
        return updatedList;
      });
    } else if (updatedCompanyName === '') {
      return;
    }
    // Check if any of the proposals has vartana financing
    const isFinancedOrder = checkOrderFinancingStatus(proposals);
    // Set isDirectOrder based on the presence of vartana financing
    setIsDirectOrder(!isFinancedOrder);
    // If reseller order is allowed and order is vartana financed, show reseller screen
    if (
      currentScreen === orderScreens.authorizedSigner &&
      resellerOrderAllowed &&
      isFinancedOrder
    ) {
      setCurrentScreen(orderScreens.reseller);
      setValidationSchema(getOrderFormSchema().concat(resellerFormSchema));
    } else {
      setCurrentScreen(orderScreens.proposals);
      setValidationSchema(getOrderFormSchema());
    }
  };

  /**
   * Filter frequency options based on contract length.
   * @param {Array} frequencyOptions - Array of frequency options.
   * @param {number} contractLength - Length of the contract in months.
   * @returns {Array} - Filtered frequency options based on contract length.
   */
  const filterFrequencies = (
    frequencyOptions,
    contractLength,
    isCustomSchedule = false
  ) => {
    // Check if frequencyOptions array is not empty
    if (frequencyOptions.length > 0) {
      if (isCustomSchedule) {
        return frequencyOptions;
      }
      // Check if contract length is between 1 and 3 months and returns monthly
      if (contractLength >= 1 && contractLength < 4) {
        return typeof frequencyOptions[0] === 'string'
          ? frequencyOptions.filter((frequency) => frequency === 'monthly')
          : frequencyOptions.filter((frequency) => frequency.value === 'monthly');
      }
      // Check if contract length is between 4 and 12 months returns monthly and quaterly
      if (contractLength >= 4 && contractLength < 13) {
        return typeof frequencyOptions[0] === 'string'
          ? frequencyOptions.filter(
            (frequency) => frequency === 'monthly' || frequency === 'quarterly'
          )
          : frequencyOptions.filter(
            (frequency) =>
              frequency.value === 'monthly' || frequency.value === 'quarterly'
          );
      }
      // If contract length is 13 months or longer, return all frequencyOptions
      if (contractLength >= 13) {
        return frequencyOptions;
      }
    }
    // Return an empty array if frequencyOptions is empty or contract length is invalid
    return [];
  };

  /**
   * Get the spiff rate for a proposal.
   * @param {object} proposal - The proposal object.
   * @returns {number} - The spiff rate.
   */
  const getProposalSpiffRate = (proposal) => {
    // If the proposal does not have vartanaFinancing, return 0.
    if (!proposal?.vartanaFinancing) {
      return 0;
    }
    // Parse the spiffRate as a float, or return 0 if it is not a valid number.
    return parseFloat(proposal.spiffRate) || 0;
  };

  return {
    resellerMode,
    toggleResellerMode: setResellerMode,
    resellerOrderAllowed,
    availableTermsLoading,
    fetchAvailableTerms,
    currentScreen,
    validationSchema,
    handleNext,
    handleBack,
    setValidationSchema,
    filterFrequencies,
    getProposalSpiffRate,
    isDirectOrder,
  };
}
