import PropTypes from 'prop-types';
import { useField } from 'formik';
import { DropdownField } from '@vartanainc/design-system';
import { twMerge } from 'tailwind-merge';

import './FormikDropdownInput.scss';
import {
  ADD_CUSTOMER_OPTION_LABEL,
  ADD_CUSTOMER_OPTION_VALUE,
} from '../../pages/Orders/order.constants';

function FormikDropdownInput({
  name,
  label,
  options,
  isLoading = false,
  onChange = (e) => {
    return e;
  },
  autoFocus = false,
  openMenuOnFocus = false,
  maxMenuHeight = null,
  preFillFirstOption = false,
  className = '',
  isCreateAndSelect = false,
  createOption = { label: ADD_CUSTOMER_OPTION_LABEL, value: ADD_CUSTOMER_OPTION_VALUE },
  onBlur = () => {},
  ...rest
}) {
  const [field, meta, { setValue, setTouched }] = useField(name);

  // Function to handle the onChange event of the dropdown input
  const handleOnChange = (valueObj, inputValue) => {
    setValue(valueObj.value);
    // Call the onChange callback with the selected value and input value
    if (onChange) {
      onChange(valueObj, inputValue);
    }
  };

  const getFieldValue = () => {
    if (preFillFirstOption && options.length === 1) {
      return options[0];
    }
    // Find the selected option from the list of options
    return options.find((option) => option.value === field.value);
  };

  const handleOnBlur = () => {
    setTouched(true);
    onBlur();
  };

  return (
    <DropdownField
      {...field}
      {...rest}
      id={name}
      name={name}
      options={options}
      value={getFieldValue() || ''}
      fullWidth
      label={label}
      showError={meta.touched && meta.error}
      className={twMerge(className, 'dropdown-menu-container')}
      helperText={meta.error}
      onBlur={handleOnBlur}
      isLoading={isLoading}
      hideSelectedOptions
      onChange={handleOnChange}
      openMenuOnFocus={openMenuOnFocus}
      autoFocus={autoFocus}
      maxMenuHeight={maxMenuHeight || 296}
      isCreateAndSelect={isCreateAndSelect}
      createOption={createOption}
    />
  );
}

FormikDropdownInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    })
  ),
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  openMenuOnFocus: PropTypes.bool,
  autoFocus: PropTypes.bool,
  maxMenuHeight: PropTypes.number,
  preFillFirstOption: PropTypes.bool,
  className: PropTypes.string,
  isCreateAndSelect: PropTypes.bool,
  createOption: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  onBlur: PropTypes.func,
};

FormikDropdownInput.defaultProps = {
  label: '',
  options: [
    {
      label: 'label',
      value: 'value',
    },
  ],
  isLoading: false,
  onChange: () => {},
  openMenuOnFocus: false,
  autoFocus: false,
  maxMenuHeight: null,
  preFillFirstOption: false,
  className: '',
  isCreateAndSelect: false,
  createOption: {},
  onBlur: () => {},
};

export default FormikDropdownInput;
