import { ReactElement, FC } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Typography } from '@vartanainc/design-system';

import { ReactComponent as TooltipIcon } from '../../assets/tooltip.svg';

interface TooltipProps {
  tooltipClass?: string;
  iconWidth?: string;
  iconHeight?: string;
  text: string;
  tooltipId?: string;
}

const Tooltip: FC<TooltipProps> = ({
  text,
  tooltipClass = '',
  iconWidth = '1.25rem',
  iconHeight = '1.25rem',
  tooltipId = 'tooltip-icon',
}): ReactElement => {
  return (
    <>
      <TooltipIcon
        data-tooltip-id={tooltipId}
        data-tooltip-place="top"
        className="cursor-pointer"
        height={iconHeight}
        width={iconWidth}
      />
      <ReactTooltip id={tooltipId} className={tooltipClass} opacity={1}>
        <Typography color="#fff" variant="paragraph12">
          {text}
        </Typography>
      </ReactTooltip>
    </>
  );
};

export default Tooltip;
