/* eslint-disable react/prop-types */
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { InputField, Typography } from '@vartanainc/design-system';
import Loader from '../../components/Loader';
import { GET_RESELLER_VENDORS } from '../../graphql/queries/vendor';
import Table from '../../components/Table';
import { getUSDateFormat } from '../../utils/helpers';
import { BlueLink } from '../../components/Link';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import ZeroState from '../../components/ZeroState/ZeroState';
import NoDataState from '../../components/NoDataState/NoDataState';

const PAGE_TITLE = 'Partners';
const RELOAD_TIME = 500;

export function Vendors() {
  const [companyNameSearch, setCompanyNameSearch] = useState('');
  const [vendorExists, setVendorExists] = useState(false);
  const debounceRef = useRef(null);

  const [fetchVendors, { data: vendorsData, loading: vendorsLoading }] =
    useLazyQuery(GET_RESELLER_VENDORS);

  const vendors = get(vendorsData, 'resellerVendors.nodes', []);

  useEffect(() => {
    async function setVendorExistence() {
      const { data: allVendors } = await fetchVendors();
      if (allVendors.resellerVendors.nodes.length > 0) setVendorExists(true);
      else setVendorExists(false);
    }
    setVendorExistence();
  }, [fetchVendors]);

  // Fetch vendor list whenever filters change
  useEffect(() => {
    clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => {
      fetchVendors({
        variables: { vendorName: companyNameSearch },
      });
    }, [RELOAD_TIME]);

    return () => clearTimeout(debounceRef.current);
  }, [companyNameSearch, fetchVendors]);

  const tableData = vendors.map((vendor) => ({
    company: {
      name: get(vendor, 'name', ''),
      ordersCount: get(vendor, 'resellerVendorOrdersCount', null),
      partneredOn: getUSDateFormat(get(vendor, 'resellerVendorPartneredOn', '')) || '',
      number: get(vendor, 'number', ''),
    },
  }));

  const tableColumns = [
    {
      Header: 'Partner name',
      accessor: 'company.name',
      Cell: ({ value: name }) => (
        <span className="vp-body text-vartana-black">{name}</span>
      ),
    },
    {
      Header: '# of orders',
      accessor: 'company.ordersCount',
      Cell: ({ row, value: ordersCount }) => (
        <BlueLink to={`/dashboard/orders?vendorName=${row.original.company.name}`}>
          {ordersCount}
        </BlueLink>
      ),
    },
    {
      Header: 'Customers',
      Cell: ({ row }) => (
        <BlueLink to={`/dashboard/customers?vendorName=${row.original.company.name}`}>
          View
        </BlueLink>
      ),
    },
    {
      Header: 'Partnered on',
      accessor: 'company.partneredOn',
      Cell: ({ value: partneredOn }) => (
        <span className="vp-body text-vartana-black">{partneredOn}</span>
      ),
    },
  ];

  const sortBy = [{ id: 'company.partneredOn', desc: true }];

  return (
    <>
      <header className="w-full p-8 flex justify-between items-center border-b border-gray-200 h-[6.375rem] bg-white">
        <Typography variant="heading24" color="color-black-100">
          {PAGE_TITLE}
        </Typography>
      </header>

      {/* Filters */}
      {vendorExists && (
        <div className="flex gap-6 px-8 pt-4 pb-6 bg-white items-center border-b border-vartana-gray-40-v3">
          <div className="w-full max-w-[23.75rem]">
            <InputField
              label="Partner"
              suffixIcon={<SearchIcon />}
              fullWidth
              name="companyFilter"
              onChange={(event) => setCompanyNameSearch(event.target.value)}
            />
          </div>
        </div>
      )}

      {/* Listing Table */}
      {vendorsLoading && (
        <Loader
          isLoading={vendorsLoading}
          className="w-12 h-12 tw-mt-6"
          containerClassName="pt-52"
        />
      )}

      {!vendorsLoading && !vendorExists && tableData.length === 0 && (
        <ZeroState page="vendors" cta="none"></ZeroState>
      )}

      {!vendorsLoading && vendorExists && tableData.length === 0 && (
        <NoDataState page="vendors" cta="none"></NoDataState>
      )}

      {!vendorsLoading && tableData.length !== 0 && (
        <div className="w-full align-middle inline-block overflow-x-auto overflow-y-hidden border-b border-gray-200">
          <Table
            columns={tableColumns}
            data={tableData}
            sortBy={sortBy}
            getHeaderProps={() => ({
              className:
                'px-4 md:pl-8 md:pr-4 py-4 text-left text-xs font-medium text-vartana-gray-60 tracking-wider whitespace-nowrap uppercase',
            })}
            cellClassName="md:pl-8"
          />
        </div>
      )}
    </>
  );
}
