/* eslint-disable jsx-a11y/no-onchange */

import React, { useEffect, useState, useMemo } from 'react';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { Typography, Card, ReactDatePicker } from '@vartanainc/design-system';
import { get } from 'lodash';

import {
  GET_ORDERS_BY_STATUS,
  GET_ORDERS_AMOUNT_AND_FEES,
  GET_ORDERS_AMOUNT_AND_FEES_BY_SIZE,
  GET_ORDERS_STATS_BY_SIZE,
} from '../../graphql/queries/insights';

import { metabaseUrlVar, sessionVar } from '../../graphql/cache';

import { ReactComponent as CalenderIcon } from '../../assets/calender_month.svg';
import DealAmountAndFees from './Charts/DealAmountAndFees';
import DealFunnel from './Charts/DealFunnel';
import DealAmountAndFeesBySize from './Charts/DealAmountAndFeesBySize';
import DealStatsBySize from './Charts/DealStatsBySize';

import Loader from '../../components/Loader';
import { pstMoment } from '../../utils/helpers';
import DropdownInput from '../../designSystem/DropdownInput/DropdownInput';
import { DisplayByOptions } from '../../static';
import ZeroState, { getCtaText } from '../../components/ZeroState/ZeroState';
import {
  GET_VENDOR_BUYERS_DATA,
  GET_VENDOR_ORDERS_DATA,
} from '../../graphql/queries/vendor';
import '../searchFilter.scss';

function Insights() {
  const metabaseInfo = useReactiveVar(metabaseUrlVar);
  const session = useReactiveVar(sessionVar);

  const showCurrency = useMemo(() => {
    return get(session, 'session.user.company.product.multipleCountriesEnabled', false);
  }, [session]);
  const supportedCurrencies = useMemo(() => {
    return get(session, 'session.user.company.product.enabledCurrencies', []);
  }, [session]);

  // filters
  const [startDate, setStartDate] = useState(pstMoment().subtract(1, 'years').toDate());
  const [endDate, setEndDate] = useState(pstMoment().subtract(1, 'days').toDate());
  const [group, setGroup] = useState('month');
  const [currency, setCurrency] = useState('USD');

  const [customerExists, setCustomerExists] = useState(false);
  const [orderExists, setOrderExists] = useState(false);

  const { loading: buyerCountLoading } = useQuery(GET_VENDOR_BUYERS_DATA, {
    onCompleted: (data) => {
      setCustomerExists(data.vendorBuyersData.buyersExist);
    },
  });
  const { loading: orderCountLoading } = useQuery(GET_VENDOR_ORDERS_DATA, {
    onCompleted: (data) => {
      setOrderExists(data.vendorOrdersData.ordersExist);
    },
  });

  const [
    getOrdersByStatus,
    { data: ordersByStatusData, loading: ordersByStatusLoading },
  ] = useLazyQuery(GET_ORDERS_BY_STATUS);

  const [
    getOrdersAmountAndFee,
    { data: ordersAmountAndFeesData, loading: ordersAmountAndFeesLoading },
  ] = useLazyQuery(GET_ORDERS_AMOUNT_AND_FEES, {
    variables: {
      group,
    },
  });

  const [
    getOrdersAmountAndFeesBySize,
    { data: ordersAmountAndFeesBySizeData, loading: ordersAmountAndFeesBySizeLoading },
  ] = useLazyQuery(GET_ORDERS_AMOUNT_AND_FEES_BY_SIZE);

  const [
    getOrderStatsBySize,
    { data: ordersStatsBySizeData, loading: ordersStatsBySizeLoading },
  ] = useLazyQuery(GET_ORDERS_STATS_BY_SIZE);

  // Only fetch data from server if not fetching from metabase and if buyerCount > 0 and orderCount > 0
  useEffect(() => {
    if (!buyerCountLoading || !orderCountLoading) {
      if (customerExists && orderExists) {
        if (metabaseInfo.flag === undefined) return;

        if (!metabaseInfo.flag && startDate && endDate) {
          getOrdersByStatus({
            variables: {
              startDate,
              endDate,
              currency,
            },
          });
          getOrdersAmountAndFee({
            variables: {
              startDate,
              endDate,
              currency,
            },
          });
          getOrdersAmountAndFeesBySize({
            variables: {
              startDate,
              endDate,
              currency,
            },
          });
          getOrderStatsBySize({
            variables: {
              startDate,
              endDate,
              currency,
            },
          });
        }
      }
    }
  }, [
    getOrderStatsBySize,
    getOrdersAmountAndFeesBySize,
    getOrdersByStatus,
    getOrdersAmountAndFee,
    metabaseInfo.flag,
    startDate,
    endDate,
    currency,
    buyerCountLoading,
    orderCountLoading,
    customerExists,
    orderExists,
  ]);

  const ordersByStatus = get(ordersByStatusData, 'seller.ordersByStatus', []);
  const ordersAmountAndFees = get(
    ordersAmountAndFeesData,
    'seller.ordersAmountAndFees',
    []
  );
  const ordersAmountAndFeesBySize = get(
    ordersAmountAndFeesBySizeData,
    'seller.ordersAmountAndFeesBySize',
    []
  );
  const ordersStatsBySize = get(ordersStatsBySizeData, 'seller.ordersStatsBySize', []);

  const currencyOptions = useMemo(() => {
    if (showCurrency) {
      const options = supportedCurrencies.map((option) => ({
        value: option,
        label: option,
      }));
      return options;
    }
    return [];
  }, [showCurrency, supportedCurrencies]);

  const mainBody = useMemo(() => {
    if (buyerCountLoading || orderCountLoading)
      return (
        <Loader
          isLoading={buyerCountLoading || orderCountLoading}
          containerClassName="h-full flex bg-vartana-steel-20"
        />
      );
    if (!customerExists || !orderExists) {
      return <ZeroState page="analytics" cta={getCtaText(customerExists, orderExists)} />;
    }
    return (
      <>
        {get(metabaseInfo, 'flag', false) ? (
          <div className="w-full px-4 py-6 bg-vartana-steel-20">
            <iframe
              src={get(metabaseInfo, 'url', '')}
              title="Dashboard"
              className="w-full h-screen"
            />
          </div>
        ) : (
          <>
            <div className="border-b border-vartana-gray-40-v3 pt-4 pb-6 px-8 bg-white w-full">
              <div className="filter-container-responsive">
                <div className="filter-responsive">
                  <ReactDatePicker
                    label="Start date"
                    placeHolder="MM/DD/YY"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    id="startDate"
                    name="startDate"
                    autoComplete="off"
                    postFixIcon={<CalenderIcon />}
                  />
                </div>
                <div className="filter-responsive">
                  <ReactDatePicker
                    label="End date"
                    placeHolder="MM/DD/YY"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    id="endDate"
                    name="endDate"
                    autoComplete="off"
                    postFixIcon={<CalenderIcon />}
                  />
                </div>
                <div className="filter-responsive">
                  <DropdownInput
                    label="Display by"
                    name="display-by"
                    options={DisplayByOptions}
                    value={group}
                    onChange={({ value }) => {
                      setGroup(value);
                    }}
                  />
                </div>
                {showCurrency && (
                  <div className="filter-responsive">
                    <DropdownInput // handle currency here
                      label="Currency"
                      name="currency"
                      options={currencyOptions}
                      value={currency}
                      onChange={({ value }) => {
                        setCurrency(value);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="bg-vartana-steel-20 grid auto-rows-fr grid-cols-1 lg:grid-cols-2 rounded-none gap-6 p-6 w-full max-w-[85.375rem] min-w-[68.75rem] mx-auto ">
              <Card
                variant="variable"
                title="GMV volume and fees"
                contentClassName="h-[85%]"
                containerClassName="!h-auto min-h-[20rem] flex flex-col"
                content={(
                  <>
                    {ordersAmountAndFeesLoading ? (
                      <Loader
                        isLoading={ordersByStatusLoading}
                        containerClassName="flex h-full"
                      />
                    ) : (
                      <div className="mt-6 h-full">
                        <DealAmountAndFees
                          ordersAmountAndFees={ordersAmountAndFees}
                          xLabel={group}
                        />
                      </div>
                    )}
                  </>
                )}
              >
              </Card>
              <Card
                variant="variable"
                title="Order funnel"
                containerClassName="!h-auto min-h-[20rem] flex flex-col"
                contentClassName="h-[85%]"
                content={(
                  <>
                    {ordersByStatusLoading ? (
                      <Loader
                        isLoading={ordersByStatusLoading}
                        containerClassName="flex h-full"
                      />
                    ) : (
                      <div className="mt-6 h-full">
                        <DealFunnel ordersByStatus={ordersByStatus} />
                      </div>
                    )}
                  </>
                )}
              >
              </Card>
              <Card
                variant="variable"
                title=" GMV volume and fees by order size"
                containerClassName="!h-auto min-h-[20rem] flex flex-col"
                contentClassName="h-[85%]"
                content={(
                  <>
                    {ordersAmountAndFeesBySizeLoading ? (
                      <Loader
                        isLoading={ordersAmountAndFeesBySizeLoading}
                        containerClassName="flex h-full"
                      />
                    ) : (
                      <div className="mt-6 h-full">
                        <DealAmountAndFeesBySize
                          ordersAmountAndFeesBySize={ordersAmountAndFeesBySize}
                        />
                      </div>
                    )}
                  </>
                )}
              >
              </Card>
              <Card
                variant="variable"
                title=" Order count by stage and size"
                containerClassName="!h-auto min-h-[20rem] flex flex-col"
                contentClassName="h-[85%]"
                content={(
                  <>
                    {ordersStatsBySizeLoading ? (
                      <Loader
                        isLoading={ordersStatsBySizeLoading}
                        containerClassName="flex h-full"
                      />
                    ) : (
                      <div className="mt-6 h-full">
                        <DealStatsBySize ordersStatsBySize={ordersStatsBySize} />
                      </div>
                    )}
                  </>
                )}
              >
              </Card>
            </div>
          </>
        )}
      </>
    );
  }, [
    buyerCountLoading,
    customerExists,
    endDate,
    group,
    metabaseInfo,
    orderCountLoading,
    orderExists,
    ordersAmountAndFees,
    ordersAmountAndFeesBySize,
    ordersAmountAndFeesBySizeLoading,
    ordersAmountAndFeesLoading,
    ordersByStatus,
    ordersByStatusLoading,
    ordersStatsBySize,
    ordersStatsBySizeLoading,
    startDate,
    currency,
    currencyOptions,
    showCurrency,
  ]);

  return (
    <>
      <div className="bg-vartana-steel-20 min-h-screen flex flex-col items-center">
        <div className="bg-white border-b border-gray-200 w-full flex items-center justify-between flex-wrap sm:flex-nowrap px-8 h-[6.375rem] shrink-0">
          <Typography variant="heading24" color="color-black-100">
            Analytics
          </Typography>
        </div>
        {mainBody}
      </div>
    </>
  );
}

export default Insights;
