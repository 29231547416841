import { get } from 'lodash';
import { useReactiveVar } from '@apollo/client';
import { Typography } from '@vartanainc/design-system';
import { ReactComponent as PartnershipWithVartana } from '../../../../assets/partnership_with_vartana.svg';
import { sessionVar } from '../../../../graphql/cache';

import Nav from '../Nav/Nav';
import '../../../Customers/Summary/CardSummary.scss';

const DesktopSidenav = (): JSX.Element => {
  const sessionData = useReactiveVar(sessionVar);
  const loggedCompanyName = get(sessionData, 'session.user.company.name', '');

  const vendorLogo = get(sessionData, 'session.user.company.vendorLogoUrl', null);

  return (
    <>
      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0 border-r-2 border-vartana-gray-30 shadow-lg">
        <div className="flex flex-col w-64 h-screen sticky top-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow pb-6 bg-white overflow-y-auto justify-between">
            <div>
              <div className="flex items-center p-6">
                {vendorLogo ? (
                  <img
                    src={vendorLogo}
                    alt={loggedCompanyName}
                    className="w-full max-h-6 object-contain object-left"
                  />
                ) : (
                  <div className="w-[21ch] flex flex-col gap-0.5 relative">
                    <Typography
                      variant="heading18"
                      bold
                      color="color-black-100"
                      className="capitalize truncated-text"
                    >
                      {loggedCompanyName}
                    </Typography>
                    {loggedCompanyName.length > 21 ? (
                      <span className="truncated-tooltip">{loggedCompanyName}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </div>
              <Nav desktop />
            </div>
            <div className="flex justify-center align-center">
              <PartnershipWithVartana className="h-4" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DesktopSidenav;
