/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import FormLabel from '../FormLabel';

function FormFieldMsg({ show, msg, className = '' }) {
  if (show) {
    return <p className={`text-xs ${className}`}>{msg}</p>;
  }
  return null;
}
function FormInput({
  name,
  placeholder,
  label,
  value,
  className,
  disabled,
  disableFullstoryRecording,
  tooltipElement,
  warningIf,
  rows,
}) {
  const [field, meta] = useField(name);
  const warningMsg = warningIf(field.value);

  const defaultClasses = useMemo(() => 'border border-solid rounded', []);
  const disabledClasses = useMemo(
    () => 'cursor-not-allowed bg-vartana-gray-20 border-none',
    []
  );

  const styleClasses = useMemo(
    () => ({
      default: {
        borders:
          'border-vartana-gray-30 focus:outline-none focus:ring-0 focus:border-vartana-blue-60',
        text: 'placeholder-vartana-gray-40',
        label: 'text-vartana-gray-60',
      },
      error: {
        borders:
          'border-vartana-bright-red focus:outline-none focus:ring-0 focus:border-current',
        text: 'text-vartana-dark-red placeholder-vartana-dark-red',
        label: 'text-vartana-dark-red',
      },
      warning: {
        borders:
          'border-vartana-dark-mustard focus:outline-none focus:ring-0 focus:border-current',
        text: 'text-vartana-dark-mustard placeholder-vartana-dark-mustard',
        label: 'text-vartana-dark-mustard',
      },
    }),
    []
  );

  const formStateClasses = useMemo(() => {
    let classes = styleClasses.default;
    if (meta.touched && meta.error) {
      classes = styleClasses.error;
    } else if (warningMsg) {
      classes = styleClasses.warning;
    }
    return classes;
  }, [styleClasses, meta, warningMsg]);

  const inputProps = {
    ...field,
    name: field.name,
    id: field.name,
    value: value || field.value,
    placeholder,
    disabled,
    autoComplete: 'off',
    rows,
    className: `
      py-2
      w-full
      vp-field-text
      ${defaultClasses}
      ${disabled ? disabledClasses : ''}
      ${disableFullstoryRecording ? 'fs-mask' : ''}
      ${formStateClasses.borders}
      ${formStateClasses.text}
    `,
  };
  return (
    <div className={`${className}`}>
      <FormLabel
        containerClassName="mb-2"
        labelClassName={formStateClasses.label}
        name={field.name}
        label={label}
        tooltipElement={tooltipElement}
      />
      <textarea {...inputProps} />
      <FormFieldMsg
        show={meta.touched}
        msg={meta.error || warningMsg}
        className={`${formStateClasses.text} mt-2`}
      />
    </div>
  );
}

FormInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  disableFullstoryRecording: PropTypes.bool,
  tooltipElement: PropTypes.node,
  warningIf: PropTypes.func,
  rows: PropTypes.string,
};

FormInput.defaultProps = {
  name: '',
  placeholder: '',
  label: '',
  value: '',
  disabled: false,
  className: '',
  disableFullstoryRecording: false,
  tooltipElement: null,
  rows: '3',
  warningIf: () => '',
};

export default FormInput;
