import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Bar, defaults } from 'react-chartjs-2';

function DealAmountAndFees({ ordersAmountAndFees }) {
  const [deals, setDeals] = useState([]);

  useEffect(() => {
    const inp = ordersAmountAndFees;
    const data = {
      labels: inp.map((order) => order.label),
      datasets: [
        {
          type: 'line',
          label: 'Fee %',
          yAxisID: 'Fee',
          fill: false,
          data: inp.map((order) => order.fee * 100),
          backgroundColor: 'rgb(255, 187, 35)',
          borderColor: 'rgb(255, 187, 35)',
          borderWidth: 1,
          tension: 0.0,
        },
        {
          type: 'bar',
          label: 'Amount',
          yAxisID: 'Amount',
          data: inp.map((order) => order.amount.cents / 100),
          backgroundColor: 'rgb(59, 137, 254)',
          borderColor: 'rgb(59, 137, 254)',
          borderWidth: 1,
        },
      ],
    };

    setDeals(data);
  }, [ordersAmountAndFees]);

  const options = {
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: defaults.global.defaultFontSize,
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
            tickColor: 'rgba(0, 0, 0, 0)',
            drawTicks: false,
            color: 'rgb(102, 102, 102)',
          },
          ticks: {
            padding: 10,
          },
        },
      ],
      yAxes: [
        {
          id: 'Amount',
          display: true,
          position: 'left',
          type: 'linear',
          gridLines: {
            drawOnChartArea: false,
            drawTicks: false,
            color: 'rgb(102, 102, 102)',
          },
          ticks: {
            beginAtZero: true,
            padding: 10,
            autoSkip: false,
            maxTicksLimit: 6,
            callback: (value) => {
              return `$${value}`;
            },
          },
        },
        {
          id: 'Fee',
          position: 'right',
          type: 'linear',
          display: true,
          gridLines: {
            drawOnChartArea: false,
            drawTicks: false,
            color: 'rgb(102, 102, 102)',
          },
          ticks: {
            beginAtZero: true,
            padding: 10,
            maxTicksLimit: 6,
            stepSize: 1,
            callback: (value) => {
              return `${value}%`;
            },
          },
        },
      ],
    },
  };

  return <Bar data={deals} options={options} width={100} height={50} />;
}

DealAmountAndFees.propTypes = {
  ordersAmountAndFees: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      amount: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({}),
      ]),
      fee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

DealAmountAndFees.defaultProps = {
  ordersAmountAndFees: [],
};

export default DealAmountAndFees;
