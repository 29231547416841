import { Typography, Tabs } from '@vartanainc/design-system';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useState, useContext, useRef, useEffect, useMemo } from 'react';
import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useIntercom } from 'react-use-intercom';
import { useRollbar } from '@rollbar/react';
import { useMediaQuery } from 'react-responsive';
import { getAPIURL, getPageUrl, removeEmptyCustomerNumber } from '../../utils/helpers';
import { sessionVar } from '../../graphql/cache';
import { CANCEL_CREDIT_CHECK_CHANGE_REQUEST } from '../../graphql/queries/creditCheckChangeRequest';
import { CREATE_CREDIT_CHECK } from '../../graphql/queries/creditCheck';
import {
  HUBSPOT_QUERY_PARAM,
  LOADER_RESET_SPAN,
  PROGEN_CTA,
  PROGEN_TAB,
  commonRegex,
  downloadStatuses,
  WIDGET_WIDTH_VARIANTS,
  CUSTOMER_CREATION_GUARANTY_TYPES,
} from '../../constants/common.constants';
import {
  GENERATE_PROPOSAL,
  REFRESH_PRICING,
  CREATE_CREDIT_CHECK_CHANGE_REQUEST,
  GENERATE_PRE_QUALIFIED_AGREEMENT,
} from '../../graphql/queries/customer';
import {
  APPROVE_CHECKOUT,
  PREVIEW_PAYOFF_STATEMENT,
  RESEND_CORPORATE_GUARANTOR_SIGNATURE_LINK,
} from '../../graphql/queries/order';
import { GET_COMPANY_BY } from '../../graphql/queries/offers';
import CreditAppraisalAndChangeRequestModalV2 from '../../components/Modals/CreditAppraisalAndChangeRequestStatusModalV2';
import NeedMoreInfoModal from '../../components/Modals/NeedMoreInfo';
import Loader from '../../components/Loader';
import { INIT_PROGEN } from '../../graphql/queries/progen';
import { ReactComponent as VartanaLogo } from '../../assets/vartana_logo.svg';
import InstallmentTab from '../CRM/WidgetV2/InstallmentTab/installmentTab';
import DeferPaymentTab from '../CRM/WidgetV2/DeferPaymentTab/deferPayementTab';
import SignaturesTab from '../CRM/WidgetV2/SignaturesTab/SignaturesTab';
import { WidgetMetaContext } from '../../context/WidgetMetaContext/WidgetMetaContext';
import { WidgetContext } from '../../context/WidgetContext';
import ProgenFooter from '../CRM/WidgetV2/Footer/Footer';
import OrderStatus from '../CRM/WidgetV2/OrderStatus/OrderStatus';
import {
  CUSTOMER_FORM_STEPS,
  getActions,
  getCustomerFormActions,
  handlePageNavigate,
  isCompactScreen,
  isSmallScreen,
} from '../CRM/WidgetV2/widgetUtils';
import { CustomerForm } from '../CRM/WidgetV2/CutomerForm/CustomerForm';
import { progenDefaultTexts } from '../../constants/progenTexts';
import UploadDocumentModal from '../../components/Modals/UploadDocumentModal';
import { PaymentInfoModal } from '../Orders/PaymentAndFundingInvoice/PaymentInformation';
import { SuccessModal } from '../Orders/PaymentAndFundingInvoice/PaymentInformation/SuccessModal';
import './CommonWidget.scss';
import { useDirectUploadFiles } from '../../utils/hooks';
import TabSummary from '../CRM/WidgetV2/TabsSummary/TabsSummary';
import { PurchaseOrdersModal } from '../Orders/Summary/ReviewPurchaseOrder/PurchaseOrdersModal';
import { GET_PURCHASE_ORDERS } from '../Orders/Summary/ReviewPurchaseOrder/queries';
import { handleDocumentDownload } from '../../api/utils';
import {
  APPRAISAL_STATES,
  APP_NAMES,
  STATUS_PILLS_COMPACT_TEXT,
  TAB_NAMES_MAP,
  TAB_NAME_LABELS_MAP,
} from './commonWidget.constants';
import SvgIcon from '../../components/SvgIcon/SvgIcon';
import { CalculatorFormValues } from '../Orders/OrderTypes';

interface CustomerFormRef {
  submitCustomerForm: () => Promise<void>;
}

interface TabType {
  label: string;
  disabled: boolean;
}

// Modal types
const MODAL = {
  success: 'success',
  paymentInfo: 'info',
};

const CommonWidgetV2 = (): JSX.Element => {
  const { companyNumber: customerNumber, orderNumber: orderNumberFromParams } =
    useParams();

  const location = useLocation();
  const useQueryParams = new URLSearchParams(location.search);
  const crmOpportunityId = useQueryParams.get('crmOpportunityId');
  const crmAccountId = useQueryParams.get('crmAccountId');
  const orderNumber = useQueryParams.get('orderNumber') || orderNumberFromParams;
  const appName = useQueryParams.get('appName');
  const showHubspotCustomerForm = useQueryParams.get('hubspotCustomerForm');
  const defaultWidgetTab = useQueryParams.get('activeTab');
  const appraisalStateInParams = useQueryParams.get('state');
  const navigate = useNavigate();
  const { show: showIntercom } = useIntercom();

  const widgetContext = useContext(WidgetContext);
  const isCrm = !customerNumber && !get(widgetContext, 'screen', '');
  const selectedCompany = get(widgetContext, 'selectedCompany', {});
  const selectedOrder = get(widgetContext, 'selectedOrder', {});
  const companyNumber = get(selectedCompany, 'number', '') || customerNumber;
  const firstMilestone = get(selectedOrder, 'milestones[0]', {});

  const screen = get(widgetContext, 'screen');
  const setFormFocus = get(widgetContext, 'setFormFocus', (bool) => null);
  const refetchOrderData = get(widgetContext, 'refetchOrderData', () => null);

  const [activeTab, setActiveTab] = useState('');
  const [subPage, setSubPage] = useState('');
  const [showDeclinedModal, setShowDeclinedModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showCustomerForm, setShowCustomerForm] = useState(false);
  const [initialTabSetFlag, setInitialTabSetFlag] = useState(true);
  const [canSubmit, setCanSubmit] = useState(true);
  const [canDownloadProposal, setCanDownloadProposal] = useState(false);
  const [showDocumentUploadModal, toggleDocumentUploadModal] = useState(false);
  const [showVendorInvoiceModal, setShowVendorInvoiceModal] = useState(false);
  const [showSubPage, setShowSubPage] = useState(true);
  const [hasCalculatorError, setHasCalculatorError] = useState(false);
  const [initialCustomerValues, setInitialCustomerValues] = useState({});
  const [creatingChangeRequest, setCreatingChangeRequest] = useState(false);
  const [directUploadFiles] = useDirectUploadFiles();
  const [paymentInfoModal, setPaymentInfoModel] = useState('');
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [downloadProposalStatus, setDownloadProposalStatus] = useState(
    downloadStatuses.DEFAULT
  );
  const [downloadAgreementStatus, setDownloadAgreementStatus] = useState(
    downloadStatuses.DEFAULT
  );
  const [defaultTabFlag, setDefaultTabFlag] = useState<string>(defaultWidgetTab || '');
  const [customerFormStep, setCustomerFormStep] = useState(
    CUSTOMER_FORM_STEPS.customerInfo
  );
  const isLowerMdBreakpoint = useMediaQuery({
    query: '(min-width: 320px) and (max-width: 344px)',
  }); // adding a footer specific breakpoint between 320px and 344px to show only one footer button

  const customerFormRef = useRef<CustomerFormRef>();
  const calculatorRef = useRef();
  const tabsRef = useRef();

  const { loading: initializingProgen, data: widgetData } = useQuery(INIT_PROGEN, {
    variables: {
      customerNumber: companyNumber,
      crmOpportunityId: isCrm ? crmOpportunityId : '',
      crmAccountId,
      orderNumber: orderNumber || get(selectedOrder, 'number', ''),
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data: companyData } = useQuery(GET_COMPANY_BY, {
    variables: { number: companyNumber, crmAccountId: '' },
  });

  // widthVariant is used to decide what styles to apply to the widget based on provided width
  const widthVariant = useMemo(() => {
    return get(widgetContext, 'widthVariant', '');
  }, [widgetContext]);

  const { data: purchaseOrderData } = useQuery(GET_PURCHASE_ORDERS, {
    skip: !get(selectedOrder, 'number', ''),
    variables: { orderNumber: get(selectedOrder, 'number', '') },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
  });

  const purchaseOrders = get(purchaseOrderData, 'order.purchaseOrders', []);
  const purchaseOrderDocs = purchaseOrders.map(
    ({ formattedDocument }) => formattedDocument?.document
  );
  const isExpanded = location.pathname.includes('/forms/summary');
  const currency = get(companyData, 'companyBy.creditCheck.currency', '');

  const [resendCCGSignatureLink] = useMutation(RESEND_CORPORATE_GUARANTOR_SIGNATURE_LINK);

  const [generateProposal] = useMutation(GENERATE_PROPOSAL, {
    onError: () => {
      setDownloadProposalStatus(downloadStatuses.DEFAULT);
    },
  });
  const [generateAgreement] = useMutation(GENERATE_PRE_QUALIFIED_AGREEMENT, {
    onError: () => {
      setDownloadAgreementStatus(downloadStatuses.DEFAULT);
    },
  });
  const rollbar = useRollbar();
  const sessionData = useReactiveVar(sessionVar);

  const guarantyRequiredOnCustomerCreation = useMemo(() => {
    return (
      get(sessionData, 'session.user.company.guaranteeTypeOnCustomerCreation', null) ===
      CUSTOMER_CREATION_GUARANTY_TYPES.PG
    );
  }, [sessionData]);

  const [refreshPricing] = useMutation(REFRESH_PRICING);
  const [createCreditCheck] = useMutation(CREATE_CREDIT_CHECK);
  const [cancelCreditCheckChangeRequest] = useMutation(
    CANCEL_CREDIT_CHECK_CHANGE_REQUEST
  );
  const [createCreditCheckChangeRequest] = useMutation(
    CREATE_CREDIT_CHECK_CHANGE_REQUEST,
    {
      onCompleted: () => {
        window.location.reload();
      },
      onError: () => {
        setCreatingChangeRequest(false);
      },
    }
  );

  const [approveOrder] = useMutation(APPROVE_CHECKOUT);
  const [previewPayoffStatement] = useMutation(PREVIEW_PAYOFF_STATEMENT);
  const isRenderedFromHubspot = useMemo(() => appName === APP_NAMES.HUBSPOT, [appName]);
  const installmentTabWidgetData = widgetData?.proposalGenerator?.offerInstallment;
  const deferTabWidgetData = widgetData?.proposalGenerator?.deferPayment;
  const appraisalState = useMemo(() => {
    return (
      get(installmentTabWidgetData, 'appraisalState', '') ||
      get(deferTabWidgetData, 'appraisalState', '')
    );
  }, [installmentTabWidgetData, deferTabWidgetData]);
  const widgetTabList = get(tabsRef.current, 'tabitems', []) as string[];
  const preQualifiedTerms = !!widgetData?.proposalGenerator?.meta?.preQualified;
  const isCustomSchedule = !!widgetData?.proposalGenerator?.meta?.isCustomSchedule;
  const getStatusPillText = (state: string): string => {
    return state === APPRAISAL_STATES.REQUEST_IN_REVIEW &&
      isSmallScreen(widthVariant, isCrm)
      ? STATUS_PILLS_COMPACT_TEXT.REQUEST_IN_REVIEW
      : state || '';
  };

  const setTab = (tab): void => {
    // set active tab in the widget
    const tabsSetActiveTab = get(tabsRef, 'current.setActiveTab', (number) => null);
    const tabsItemsList = get(tabsRef, 'current.tabitems', []) as string[];
    const tabIndex = tabsItemsList.findIndex((itemTab) => itemTab === tab);

    if (tabIndex !== -1) tabsSetActiveTab(tabIndex);
  };

  const setDefaultTab = (defaultTab): void => {
    switch (defaultTab) {
      case PROGEN_TAB.signatures:
        setTab(progenDefaultTexts.signature_tab_label);
        break;
      case PROGEN_TAB.installment:
        setTab(progenDefaultTexts.installment_tab_label);
        break;
      case PROGEN_TAB.defer:
        setTab(progenDefaultTexts.defer_tab_label);
        break;
      default:
        break;
    }
  };

  const syncTabToParams = (): void => {
    if (activeTab === '') return;
    useQueryParams.set('activeTab', activeTab);
    useQueryParams.set('state', appraisalState);
    const newSearch = useQueryParams.toString();
    const newLocation = `${location.pathname}?${newSearch}`;

    // Push the new location to the history
    navigate(newLocation, { replace: true });
  };

  const debouncedSyncTabToParams = (): (() => void) => {
    const debounce = setTimeout(() => {
      syncTabToParams();
    }, 100);
    return () => clearTimeout(debounce);
  };

  // Check if a tab name is present in the list of tab items
  const isTabNameInList = useCallback(
    (tabsItemsList: string[], tab: string): boolean => {
      const tabNames = TAB_NAME_LABELS_MAP[tab];
      // For compact screens, check if the small version of the tab name is in the list
      // For regular screens, check if the default version of the tab name is in the list
      return isCompactScreen(widthVariant, isCrm)
        ? tabsItemsList.includes(tabNames.small)
        : tabsItemsList.includes(tabNames.default);
    },
    [widthVariant, isCrm]
  );

  useEffect(() => {
    // do not retain the default tab if the appraisal state was changed
    const shouldSkipDefaultTab =
      defaultTabFlag && appraisalState !== appraisalStateInParams && tabsRef.current;
    if (shouldSkipDefaultTab) {
      setDefaultTabFlag('');
      return;
    }
    if (defaultTabFlag && tabsRef.current) {
      setDefaultTab(defaultTabFlag);
      setDefaultTabFlag('');
    }
    // this side effect should only run when tabsRef.current is mounted and defaultTabFlag is present
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsRef.current]);

  useEffect(() => {
    debouncedSyncTabToParams();

    // this side effect should only run when activeTab changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    const showDeferTab =
      (installmentTabWidgetData?.appraisalState === APPRAISAL_STATES.DECLINED &&
        deferTabWidgetData?.appraisalState === APPRAISAL_STATES.APPROVED) ||
      deferTabWidgetData?.appraisalState === APPRAISAL_STATES.NEED_INFORMATION;

    const showInstallmentTab =
      installmentTabWidgetData?.appraisalState === APPRAISAL_STATES.APPROVED ||
      installmentTabWidgetData?.appraisalState === APPRAISAL_STATES.NEED_INFORMATION;
    const tabsItemsList = get(tabsRef, 'current.tabitems', []) as string[];

    if (showInstallmentTab) {
      if (
        tabsItemsList.length &&
        isTabNameInList(tabsItemsList, PROGEN_TAB.installment)
      ) {
        setTab(
          isCompactScreen(widthVariant, isCrm)
            ? TAB_NAME_LABELS_MAP.offerInstallment.small
            : TAB_NAME_LABELS_MAP.offerInstallment.default
        );
      }
    } else if (showDeferTab) {
      if (tabsItemsList.length && isTabNameInList(tabsItemsList, PROGEN_TAB.defer)) {
        setTab(
          isCompactScreen(widthVariant, isCrm)
            ? TAB_NAME_LABELS_MAP.deferPayment.small
            : TAB_NAME_LABELS_MAP.deferPayment.default
        );
      }
    }
    // adding this as we want to prevent tab change on breakpoint change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deferTabWidgetData?.appraisalState,
    installmentTabWidgetData?.appraisalState,
    widgetData,
  ]);

  useEffect(() => {
    const isMoreInfo = !!get(
      widgetData,
      'proposalGenerator.meta.moreInformationDetails',
      ''
    );
    const isTrackRequest = !!get(
      widgetData,
      'proposalGenerator.meta.trackRequestDetails',
      ''
    );
    const subPageChanged = subPage === 'moreInformationDetails' && isTrackRequest;
    // The defaultSubPage query param is used to determine if the calculator should be shown when credit check is in more-info state or not
    // As this is a flag coming from query-params, the value for this would be a string 'true' or 'false'
    const defaultSubPage = useQueryParams.get('defaultSubPage');
    const shouldHandleSubpageAssignment =
      (((widgetTabList.length || screen === 'track') &&
        initialTabSetFlag &&
        widgetData) ||
        subPageChanged) &&
      defaultSubPage !== 'false';

    if (shouldHandleSubpageAssignment) {
      // Open the more-info or track-request subpages instead of showing the calculator for applicable cases
      if (isMoreInfo) {
        setSubPage('moreInformationDetails');
        setShowSubPage(true);
      }
      if (isTrackRequest) {
        setSubPage('trackRequestDetails');
        setShowSubPage(true);
      }
      if (screen === 'track') {
        setSubPage(isMoreInfo ? 'moreInformationDetails' : 'trackRequestDetails');
        setShowSubPage(true);
      }
      setInitialTabSetFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetData, screen, widgetTabList, initialTabSetFlag]);

  const handleCancelCreditCheckChangeRequest = useCallback(async () => {
    cancelCreditCheckChangeRequest({
      variables: {
        companyNumber,
      },
    }).then(() => {
      window.location.reload();
    });
  }, [cancelCreditCheckChangeRequest, companyNumber]);

  const handleDownload = (file: string, fileName: string): void => {
    const downloadLink = document.createElement('a');
    const linkSource = `data:application/pdf;base64,${file}`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const extractCalculatorValues = (): CalculatorFormValues => {
    const offeredBuyerInterestRate = get(
      calculatorRef,
      'current.formRef.values.interestRate'
    );

    return {
      billingFrequency: get(calculatorRef, 'current.formRef.values.billingFrequency', ''),
      totalContractValue: get(
        calculatorRef,
        'current.formRef.values.totalContractValue',
        ''
      ),
      term: get(calculatorRef, 'current.formRef.values.term', '0'),
      netTerm: get(calculatorRef, 'current.formRef.values.netTerm', '0'),
      subsidy: get(calculatorRef, 'current.formRef.values.subsidy', '0'),
      spiffRate: get(calculatorRef, 'current.formRef.values.spiffRate', '0'),
      offeredBuyerInterestRate: offeredBuyerInterestRate || '0',
      applyOfferedBuyerInterestRate: !!offeredBuyerInterestRate,
    };
  };

  const handleGenerateProposal = useCallback(async () => {
    if (!isCustomSchedule && !canDownloadProposal) {
      get(calculatorRef, 'current.validateTcv', () => null)();
      // return false to signfiy that the download proposal action was not successful
      return false;
    }
    const values = extractCalculatorValues();
    setDownloadProposalStatus(downloadStatuses.DOWNLOADING);
    const { data } = await generateProposal({
      variables: {
        customerNumber: companyNumber,
        opportunityAmount: +parseFloat(
          values.totalContractValue?.replace(/,/g, '')
        ).toFixed(2),
        billingFrequency: values.billingFrequency,
        term: parseFloat(values.term),
        blindDiscount: values.applyOfferedBuyerInterestRate
          ? 0
          : parseFloat(values.subsidy),
        paymentTerm: values.netTerm,
        isDollar: get(calculatorRef, 'current.formRef.values.isDollar'),
        spiffRate: parseFloat(values.spiffRate),
        applyOfferedBuyerInterestRate: values.applyOfferedBuyerInterestRate,
        offeredBuyerInterestRate: parseFloat(values.offeredBuyerInterestRate),
      },
    });

    setDownloadProposalStatus(downloadStatuses.DOWNLOADED);
    setTimeout(() => {
      setDownloadProposalStatus(downloadStatuses.DEFAULT);
    }, LOADER_RESET_SPAN);
    const proposal = get(data, 'generateProposal.encodedProposal');
    const fileName = `${get(data, 'generateProposal.number')}-Proposal.pdf`;
    handleDownload(proposal, fileName);

    // returning undefined here as the function expects a return statement
    return undefined;
  }, [isCustomSchedule, canDownloadProposal, companyNumber, generateProposal]);

  // Handles the download agreement button click by taking form values and calling the generate agreement mutation
  const handleGenerateAgreement = useCallback(async () => {
    if (!isCustomSchedule && !canDownloadProposal) {
      get(calculatorRef, 'current.validateTcv', () => null)();
      return;
    }
    const values = extractCalculatorValues();
    setDownloadAgreementStatus(downloadStatuses.DOWNLOADING);
    const { data } = await generateAgreement({
      variables: {
        customerNumber: companyNumber,
        opportunityAmount: +parseFloat(
          values.totalContractValue?.replace(commonRegex.digitsSansCommas, '')
        ).toFixed(2),
        billingFrequency: values.billingFrequency,
        term: parseFloat(values.term),
        blindDiscount:
          values.applyOfferedBuyerInterestRate || !parseFloat(values.subsidy)
            ? 0
            : parseFloat(values.subsidy),
        paymentTerm: values.netTerm,
        isDollar: get(calculatorRef, 'current.formRef.values.isDollar'),
        spiffRate: parseFloat(values.spiffRate) || 0,
        applyOfferedBuyerInterestRate: values.applyOfferedBuyerInterestRate,
        offeredBuyerInterestRate: parseFloat(values.offeredBuyerInterestRate),
      },
    });

    setDownloadAgreementStatus(downloadStatuses.DOWNLOADED);
    setTimeout(() => {
      setDownloadAgreementStatus(downloadStatuses.DEFAULT);
    }, LOADER_RESET_SPAN);
    const proposal = get(data, 'generatePreQualifiedAgreement');
    const fileName = `${get(selectedCompany, 'name')}-pre-qualified-agreement.pdf`;
    handleDownload(proposal, fileName);
  }, [
    isCustomSchedule,
    canDownloadProposal,
    companyNumber,
    selectedCompany,
    generateAgreement,
  ]);

  // this removes calculator error when user switches tabs
  useEffect(() => {
    if (hasCalculatorError) setHasCalculatorError(false);
  }, [activeTab]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleViewOrder = useCallback(
    (showHelp = false) => {
      if (isCrm) {
        const url = getPageUrl({
          page: 'orderSummary',
          orderNumber: get(selectedOrder, 'number', ''),
          customerNumber: companyNumber,
          crmOpportunityId: crmOpportunityId || '',
          expandedApp: true,
          showHelp,
        });
        handlePageNavigate(url, isRenderedFromHubspot, navigate);
      } else if (isExpanded) {
        window.location.reload();
      } else navigate(getPageUrl({ page: 'orderSummary', orderNumber }));
    },
    [
      isCrm,
      isExpanded,
      navigate,
      orderNumber,
      selectedOrder,
      companyNumber,
      crmOpportunityId,
      isRenderedFromHubspot,
    ]
  );

  const handleApproveOrder = useCallback(async () => {
    if (isCrm) {
      handleViewOrder();
      return;
    }
    await approveOrder({
      variables: {
        number: orderNumber || get(selectedOrder, 'number', ''),
      },
    });
    window.location.reload();
  }, [approveOrder, handleViewOrder, isCrm, orderNumber, selectedOrder]);

  const handleRefreshPricing = useCallback(async () => {
    await refreshPricing({
      variables: {
        companyNumber,
      },
    });
    window.location.reload();
  }, [companyNumber, refreshPricing]);

  const handleCreateCreditCheck = useCallback(async () => {
    await createCreditCheck({
      variables: {
        companyNumber,
      },
    });
    window.location.reload();
  }, [companyNumber, createCreditCheck]);

  const toggleModal = (action): void => {
    if (action.slug === 'learn_more') setShowDetailsModal(true);
    else setShowDeclinedModal(true);
  };

  // returns payment type based on selected tab
  const getPaymentType = (selectedTab: string): string => {
    if (selectedTab === 'offerInstallment') {
      return 'installment_plan';
    }
    if (selectedTab === 'deferPayment') {
      return 'full_payment';
    }
    return 'direct';
  };

  const isCustomerFormEnabled =
    widgetData?.proposalGenerator?.sendForSignature?.isCustomerFormEnabled;

  const handleCreateOrder = useCallback(
    (newCustomerNumber = '') => {
      const values = get(calculatorRef, 'current.formRef.values', {});
      const paymentType = getPaymentType(activeTab);
      const paymentTerm =
        activeTab === PROGEN_TAB.signatures ? 0 : get(values, 'netTerm', null);
      const proposalValues = {
        paymentType,
        paymentTerm,
        term:
          activeTab === PROGEN_TAB.signatures
            ? null
            : parseFloat(get(values, 'term', '0')),
        billingFrequency: get(values, 'billingFrequency', ''),
        spiffRate: get(values, 'spiffRate', null),
        totalContractValue:
          parseFloat(get(values, 'totalContractValue', '').replace(',', '')) || null,
        subsidy: get(values, 'subsidy', null),
        isDollar: get(values, 'isDollar'),
        interestRate: get(values, 'interestRate', null),
      };
      if (isCrm) {
        let proposalParams = '';
        Object.entries(proposalValues).forEach(([key, value]) => {
          proposalParams += `&${key}=${value}`;
        });
        const url = getPageUrl({
          page: 'createOrder',
          customerNumber: newCustomerNumber || companyNumber,
          otherParams: `${location.search}${proposalParams}`,
          isNonVartanaOrder: activeTab === PROGEN_TAB.signatures,
          expandedApp: true,
        });
        handlePageNavigate(
          removeEmptyCustomerNumber(url),
          appName === APP_NAMES.HUBSPOT,
          navigate
        );
      } else {
        const createOrderPath = getPageUrl({
          page: 'createOrder',
          otherParams: `${
            activeTab === PROGEN_TAB.signatures ? '?isSignatureOnly=true' : ''
          }`,
        });
        navigate(createOrderPath, {
          state: {
            selectedCompany,
            selectedProposal: proposalValues,
          },
        });
      }
    },
    [activeTab, isCrm, location.search, companyNumber, appName, navigate, selectedCompany]
  );

  const handleModifyTerms = useCallback(() => {
    if (isCrm) {
      const url = getPageUrl({
        page: 'modifyTerms',
        customerNumber: companyNumber,
        otherParams: location.search,
        expandedApp: true,
      });
      handlePageNavigate(url, appName === APP_NAMES.HUBSPOT, navigate);
    } else {
      navigate(
        getPageUrl({
          page: 'modifyTerms',
          customerNumber: companyNumber,
        })
      );
    }
  }, [isCrm, location.search, companyNumber, appName, navigate]);

  const handleRequestFromCustomer = useCallback(() => {
    if (isCrm) {
      handlePageNavigate(
        getPageUrl({
          page: 'shareLink',
          customerNumber: companyNumber,
          crmOpportunityId: crmOpportunityId || '',
        }),
        isRenderedFromHubspot,
        navigate
      );
    } else if (screen === 'track') {
      setFormFocus();
    } else {
      navigate(`/dashboard/customers/${companyNumber}/track`);
    }
  }, [
    isCrm,
    companyNumber,
    navigate,
    crmOpportunityId,
    screen,
    setFormFocus,
    isRenderedFromHubspot,
  ]);

  const handleHelpDesk = (): void => {
    if (isCrm) {
      const url = getPageUrl({
        page: 'getHelp',
        customerNumber: companyNumber,
      });
      handlePageNavigate(url, appName === APP_NAMES.HUBSPOT, navigate);
    } else showIntercom();
  };

  const onDocumentSubmit = useCallback(
    async (values) => {
      const id = get(companyData, 'companyBy.creditCheck.id', null);
      const type = get(companyData, 'companyBy.creditCheck.className', null);

      (directUploadFiles as (files, documentBlobs, resource) => Promise<void>)(
        [...values.financial],
        {
          documentType: 'financial',
        },
        {
          id,
          type,
        }
      ).then(() => {
        window.location.reload();
      });
    },
    [directUploadFiles, companyData]
  );

  const onInvoiceSubmit = useCallback(
    async (values) => {
      await (directUploadFiles as (files, documentBlobs, resource) => Promise<void>)(
        [...values.documents],
        { documentType: 'funding_invoice' },
        { id: get(selectedOrder, 'id'), type: 'Order' }
      );
      window.location.reload();
    },
    [directUploadFiles, selectedOrder]
  );

  const handleUploadInfo = useCallback(() => {
    const otherParams = isRenderedFromHubspot ? `&${HUBSPOT_QUERY_PARAM}` : '';
    const isExpandedTrack = location.pathname.includes('share-link');
    const expandedAppMoreInfoUrl = getPageUrl({
      page: 'moreInfo',
      customerNumber: companyNumber,
      expandedApp: true,
      otherParams,
    });
    if (!isCrm) {
      if (isExpandedTrack) {
        navigate(expandedAppMoreInfoUrl);
        return;
      }
      navigate(getPageUrl({ page: 'moreInfo', customerNumber: companyNumber }));
    } else handlePageNavigate(expandedAppMoreInfoUrl, isRenderedFromHubspot, navigate);
  }, [isCrm, companyNumber, navigate, location.pathname, isRenderedFromHubspot]);

  const handleResendRequest = useCallback(
    async (isOrder = false) => {
      // For ccg orders, call the resend request mutation right away
      const isCCGOrder = get(selectedOrder, 'company.creditCheck.conditionalToCcg');
      if (isCCGOrder) {
        await resendCCGSignatureLink({
          variables: {
            number: orderNumber || get(selectedOrder, 'number', ''),
          },
        });
        // return undefined for eslint consistent returns
        return undefined;
      }

      // Redirect to the customer/order track page, or if already at track page then focus the form field when resend request CTA is clicked
      if (screen === 'track' || screen === 'order-track') setFormFocus();
      else if (!isCrm) navigate(`/dashboard/customers/${companyNumber}/track`);
      else if (isOrder) {
        const url = getPageUrl({
          page: 'trackOrder',
          customerNumber: companyNumber,
          crmOpportunityId: crmOpportunityId || '',
          orderNumber: get(selectedOrder, 'number', ''),
        });
        handlePageNavigate(url, appName === APP_NAMES.HUBSPOT, navigate);
      } else {
        const url = getPageUrl({
          page: 'shareLink',
          customerNumber: companyNumber,
          crmOpportunityId: crmOpportunityId || '',
        });
        handlePageNavigate(url, appName === APP_NAMES.HUBSPOT, navigate);
      }
      return false;
    },
    [
      appName,
      companyNumber,
      crmOpportunityId,
      isCrm,
      navigate,
      screen,
      selectedOrder,
      setFormFocus,
      orderNumber,
      resendCCGSignatureLink,
    ]
  );

  const handleGetHigherLimit = async (): Promise<void> => {
    setCreatingChangeRequest(true);
    const values = get(calculatorRef, 'current.formRef.values', {}) as {
      billingFrequency: string;
      netTerm: number;
      paymentType: string;
      term: string;
      totalContractValue: string;
    };
    const tcv = parseFloat(get(values, 'totalContractValue', '0'));

    const creditChangeRequestPayload = {
      totalContractAmount: tcv,
      billingFrequency:
        values.paymentType === 'net_terms' ? 'balloon' : values.billingFrequency,
      companyNumber,
      modificationReason: 'Change request from progen v4',
      paymentTerm: values.netTerm,
      term: values.paymentType === 'net_terms' ? 0 : parseFloat(values.term),
      paymentOption:
        activeTab === 'offerInstallment' ? 'installment_plan' : 'full_payment',
    };
    createCreditCheckChangeRequest({
      variables: creditChangeRequestPayload,
    });
  };

  const handleResellerInvite = useCallback(() => {
    const companyName = get(selectedCompany, 'name', '');
    if (isCrm) {
      const url = getPageUrl({
        page: 'resellerInvite',
        customerNumber: companyNumber,
        expandedApp: true,
        otherParams: location.search,
        customerName: companyName,
      });
      handlePageNavigate(url, appName === APP_NAMES.HUBSPOT, navigate);
    } else
      navigate(
        getPageUrl({
          page: 'resellerInvite',
          customerNumber: companyNumber,
          customerName: companyName,
        })
      );
  }, [appName, companyNumber, isCrm, location.search, navigate, selectedCompany]);

  const handleCustomerAutoPopulate = async (response): Promise<void> => {
    let data = {} as {
      body: {
        city?: string;
        street?: string;
        state?: string;
        zip?: string;
        name?: string;
        country?: string;
      };
    };

    try {
      data = await response.json();
    } catch (error) {
      console.error('No valid response in tray request', error);
    }

    if (Object.keys(data).length !== 0) {
      const customerData = get(data, 'body');
      if (customerData?.country) delete customerData.country;
      setInitialCustomerValues(customerData);
    }
    setShowCustomerForm(true);
  };

  const triggerCreateCustomerWorkflow = useCallback(async () => {
    let path = `${getAPIURL()}/integrations/tray/trigger_create_customer_workflow`;
    path += `?crmOpportunityId=${crmOpportunityId}&userId=${get(
      sessionData,
      'session.user.id',
      ''
    )}`;
    await fetch(path, { credentials: 'include' })
      .then((response) => {
        if (response.ok) window.location.reload();
        else handleCustomerAutoPopulate(response);
      })
      .catch((error) => {
        rollbar.error(`[Get started now - triggerCreateCustomerWorkflow] ${error}`);
      });
  }, [crmOpportunityId, rollbar, sessionData]);

  const downloadPayoffStatement = async (): Promise<void> => {
    const response = await previewPayoffStatement({
      variables: { number: get(selectedOrder, 'number', '') },
    });
    const doc = get(response, 'data.previewPayoffStatement.payoffStatementDocument');
    handleDocumentDownload(doc, get(selectedOrder, 'number', ''));
    return undefined;
  };

  const handleLearnMoreModal = (): void => {
    const navigateToMoreDetails = isCrm && !isRenderedFromHubspot; // only navigate to more details page if it's CRM and not rendered from Hubspot
    if (navigateToMoreDetails) {
      handlePageNavigate(
        getPageUrl({ page: 'moreDetails', customerNumber: companyNumber }),
        isRenderedFromHubspot,
        navigate
      );
      return;
    }
    toggleModal({ slug: PROGEN_CTA.learnMore });
  };

  const showSignatureCustomerForm = useMemo(() => {
    return (showCustomerForm || appName === APP_NAMES.HUBSPOT) && isCustomerFormEnabled;
  }, [showCustomerForm, isCustomerFormEnabled, appName]);

  const allowedWidgetTabs =
    widgetData?.proposalGenerator && // check to make sure widgetData.proposalGenerator exists
    Object.keys(widgetData?.proposalGenerator).filter(
      (
        tabName // filtering to remove meta key
      ) => Object.values(TAB_NAMES_MAP).includes(tabName)
    );

  // TODO: add helpers to a separate file in refactor
  const getTabTextVariant = (): string => {
    if (widthVariant === WIDGET_WIDTH_VARIANTS.xl) {
      return 'default';
    }
    return 'small';
  };

  const handleGetStarted = async (): Promise<void> => {
    // If the customer form is enabled, show the customer form
    if (
      installmentTabWidgetData?.isCustomerFormEnabled ||
      deferTabWidgetData?.isCustomerFormEnabled
    ) {
      if (guarantyRequiredOnCustomerCreation) {
        // If guaranty flow is enabled
        const customerCreationUrl = getPageUrl({
          page: 'createCustomer',
          customerNumber: companyNumber,
          otherParams: location.search,
        });
        const customerFormWindow = handlePageNavigate(
          customerCreationUrl,
          isRenderedFromHubspot,
          navigate
        );
        if (customerFormWindow) {
          customerFormWindow.onFormSubmit = async () => {
            window.location.reload();
          };
        }
      }
      // If guaranty flow is not enabled, trigger create customer workflow as before
      else await triggerCreateCustomerWorkflow();
    }
    // If customer form is not enabled, create credit check instead
    else await handleCreateCreditCheck();
  };

  const handleCustomerFormNextStep = async (): Promise<void> => {
    // validate the customer here and update form state
    setCustomerFormStep(customerFormStep + 1);
  };

  const onClickHandler = async (slug: string): Promise<void | boolean> => {
    switch (slug) {
      case PROGEN_CTA.createOrder:
        handleCreateOrder();
        break;
      case PROGEN_CTA.customerCheckout:
        if (
          installmentTabWidgetData?.isCustomerFormEnabled ||
          deferTabWidgetData?.isCustomerFormEnabled
        )
          setShowCustomerForm(true);
        else await handleCreateCreditCheck();
        break;
      case PROGEN_CTA.getStarted:
        await handleGetStarted();
        break;
      case PROGEN_CTA.modifyTerms:
        handleModifyTerms();
        break;
      case PROGEN_CTA.contactHelpDesk:
        handleHelpDesk();
        break;
      case PROGEN_CTA.requestFromCustomer:
        handleRequestFromCustomer();
        break;
      case PROGEN_CTA.submitForm:
        await customerFormRef?.current?.submitCustomerForm();
        break;
      case PROGEN_CTA.viewOrder:
        handleViewOrder();
        break;
      case PROGEN_CTA.viewDocuments:
        handleViewOrder();
        break;
      case PROGEN_CTA.downloadProposal:
        return handleGenerateProposal();
      case PROGEN_CTA.getAgreement:
        handleGenerateAgreement();
        break;
      case PROGEN_CTA.updateRates:
        await handleRefreshPricing();
        break;
      case PROGEN_CTA.uploadInfo:
        handleUploadInfo();
        break;
      case PROGEN_CTA.resendRequest:
        handleResendRequest();
        break;
      case PROGEN_CTA.resendOrder:
        return handleResendRequest(true);
      case PROGEN_CTA.inviteReseller:
        handleResellerInvite();
        break;
      case PROGEN_CTA.generateNewTerms:
        await handleCreateCreditCheck();
        break;
      case PROGEN_CTA.approveOrder:
        await handleApproveOrder();
        break;
      case PROGEN_CTA.uploadInvoice:
        setShowVendorInvoiceModal(true);
        break;
      case PROGEN_CTA.provideInformation:
        setPaymentInfoModel(MODAL.paymentInfo);
        break;
      case PROGEN_CTA.reviewAndApprove:
        setReviewModalOpen(true);
        break;
      case PROGEN_CTA.downloadStatement:
        await downloadPayoffStatement();
        break;
      case PROGEN_CTA.viewPaymentSchedule:
        navigate(
          `/dashboard/customers/${get(selectedCompany, 'number', '')}/payment-schedule`
        );
        break;
      case PROGEN_CTA.learnMore:
        handleLearnMoreModal();
        break;
      default:
    }
    // returning undefined here as the function expects a return statement
    return undefined;
  };

  const isTabDisabled = (tabName: string): boolean => {
    return !widgetData?.proposalGenerator[tabName].enabled;
  };

  const getSupportedTabLabels = (): TabType[] => {
    const supportedTabs: TabType[] = [];
    if (!showHubspotCustomerForm) {
      allowedWidgetTabs.forEach((tabName: string) => {
        const isDisabled = isTabDisabled(tabName); // Check if the tab should be disabled
        supportedTabs.push({
          label: isCrm
            ? TAB_NAME_LABELS_MAP[tabName][getTabTextVariant()]
            : TAB_NAME_LABELS_MAP[tabName]?.default,
          disabled: isDisabled, // Include disabled state
        });
      });
    }
    return supportedTabs;
  };

  const getSupportedTabCustomerForm = (tabName): JSX.Element => {
    return (
      <CustomerForm
        setCanSubmit={setCanSubmit}
        customerFormRef={customerFormRef}
        crmOpportunityId={crmOpportunityId || ''}
        crmAccountId={crmAccountId || ''}
        isCrm={isCrm}
        initialCustomerValues={initialCustomerValues}
        isSignature={tabName === TAB_NAMES_MAP.signature && appName !== APP_NAMES.HUBSPOT}
        activeStep={customerFormStep}
        handleNextStep={handleCustomerFormNextStep}
      />
    );
  };

  const shouldShowTabLoader =
    widthVariant === WIDGET_WIDTH_VARIANTS.sm || isLowerMdBreakpoint;

  const isTabLoading =
    downloadProposalStatus === downloadStatuses.DOWNLOADING && shouldShowTabLoader;

  const getSupportedTabContent = (): JSX.Element[] => {
    const supportedTabs: JSX.Element[] = [];

    allowedWidgetTabs.forEach((tabName: string) => {
      if (tabName === TAB_NAMES_MAP.signature) {
        supportedTabs.push(
          showSignatureCustomerForm ? (
            getSupportedTabCustomerForm(tabName)
          ) : (
            <SignaturesTab
              setActive={() => setActiveTab(PROGEN_TAB.signatures)}
              setSubPage={setSubPage}
              isCrm={isCrm}
            />
          )
        );
      }
      if (tabName === TAB_NAMES_MAP.installment) {
        const renderCustomerForm =
          installmentTabWidgetData?.isCustomerFormEnabled &&
          (showCustomerForm || appName === APP_NAMES.HUBSPOT);
        supportedTabs.push(
          renderCustomerForm ? (
            getSupportedTabCustomerForm(tabName)
          ) : (
            <InstallmentTab
              status={getStatusPillText(installmentTabWidgetData?.appraisalState || '')}
              currency={currency}
              tertiaryActions={installmentTabWidgetData?.actions?.filter(
                (action) => action.type === 'tertiary'
              )}
              isTabLoading={isTabLoading}
              setActive={setActiveTab}
              setSubPage={setSubPage}
              toggleModal={toggleModal}
              isCrm={isCrm}
              companyNumber={companyNumber}
              crmOpportunityId={crmOpportunityId || ''}
              handleCancelCreditCheckChangeRequest={handleCancelCreditCheckChangeRequest}
              calculatorRef={calculatorRef}
              setCanDownloadProposal={setCanDownloadProposal}
              showSubPage={showSubPage}
              setShowSubPage={setShowSubPage}
              setHasCalculatorError={setHasCalculatorError}
              handleGetHigherLimit={handleGetHigherLimit}
              creatingChangeRequest={creatingChangeRequest}
              appName={appName}
              isPreQualifiedTerms={preQualifiedTerms}
              isCustomSchedule={isCustomSchedule}
            />
          )
        );
      }
      if (tabName === TAB_NAMES_MAP.defer) {
        const renderCustomerForm =
          deferTabWidgetData?.isCustomerFormEnabled &&
          (showCustomerForm || appName === APP_NAMES.HUBSPOT);

        supportedTabs.push(
          renderCustomerForm ? (
            getSupportedTabCustomerForm(tabName)
          ) : (
            <DeferPaymentTab
              status={getStatusPillText(deferTabWidgetData?.appraisalState) || ''}
              currency={currency}
              tertiaryActions={deferTabWidgetData?.actions?.filter(
                (action) => action.type === 'tertiary'
              )}
              setActive={setActiveTab}
              setSubPage={setSubPage}
              toggleModal={toggleModal}
              isCrm={isCrm}
              isTabLoading={isTabLoading}
              companyNumber={companyNumber}
              crmOpportunityId={crmOpportunityId || ''}
              handleCancelCreditCheckChangeRequest={handleCancelCreditCheckChangeRequest}
              calculatorRef={calculatorRef}
              setCanDownloadProposal={setCanDownloadProposal}
              showSubPage={showSubPage}
              setShowSubPage={setShowSubPage}
              setHasCalculatorError={setHasCalculatorError}
              handleGetHigherLimit={handleGetHigherLimit}
              creatingChangeRequest={creatingChangeRequest}
              appName={appName}
            />
          )
        );
      }
    });
    return supportedTabs;
  };

  return (
    // complete progen
    <div
      className={`progen-v4 flex flex-col text-center m-auto width-variant-${widthVariant}  ${
        isCrm
          ? 'crm-widget-container h-full w-full'
          : 'h-[29.9rem] w-[29.2rem] overflow-y-hidden'
      }
      ${isRenderedFromHubspot ? 'hubspot-widget-container' : ''}`}
    >
      {/* // progen navigation */}
      {isCrm && (
        <div className="header-container">
          <VartanaLogo className="widget-vartana-logo" />
          <div
            className="help-desk-container"
            onClick={handleHelpDesk}
            role="button"
            tabIndex={0}
          >
            <SvgIcon name="support" height="1rem" width="1rem" fill="gray" />
            <Typography variant="paragraph12" color="color-gray-110">
              Help desk
            </Typography>
          </div>
        </div>
      )}
      {!(isCrm || screen === 'track' || screen === 'order-track') && (
        <div className="p-3.5"></div>
      )}
      <div className="h-full w-full overflow-auto">
        {initializingProgen ? (
          <Loader
            containerClassName="flex justify-center"
            className="w-10 h-10 absolute text-center top-2/4 transform-gpu -translate-y-2/4"
          />
        ) : (
          <>
            <WidgetMetaContext.Provider
              value={{ ...widgetData?.proposalGenerator, activeTab }}
            >
              {widgetData?.proposalGenerator?.order ? (
                <div className="h-full">
                  <OrderStatus
                    title={widgetData?.proposalGenerator?.order?.title || ''}
                    description={widgetData?.proposalGenerator?.order?.description || ''}
                    lastActive={
                      widgetData?.proposalGenerator?.order?.lastActiveAt?.replace(
                        ' @',
                        ''
                      ) || ''
                    }
                    setActive={() => setActiveTab('order')}
                    completed={widgetData?.proposalGenerator?.order?.completed}
                    step={widgetData?.proposalGenerator?.order?.step}
                    totalSteps={widgetData?.proposalGenerator?.order?.totalSteps}
                    progress={widgetData?.proposalGenerator?.order?.progress}
                    isActive={widgetData?.proposalGenerator?.order?.isActive}
                  />
                  <ProgenFooter
                    isCrm={isCrm}
                    actions={getActions(
                      get(widgetData, 'proposalGenerator.order.actions', [])
                    )}
                    handleClick={onClickHandler}
                    isOrderFooter
                    isLowerMdBreakpoint={isLowerMdBreakpoint}
                  />
                </div>
              ) : (
                <>
                  {screen === 'track' ? (
                    <>
                      <TabSummary
                        status={
                          installmentTabWidgetData?.appraisalState ||
                          deferTabWidgetData?.appraisalState
                        }
                        onButtonClick={toggleModal}
                        isCrm={isCrm}
                        companyNumber={companyNumber}
                        isTrackScreen={screen === 'track'}
                      />
                      <ProgenFooter
                        isCrm={isCrm}
                        actions={getActions(
                          get(
                            widgetData,
                            `proposalGenerator.${
                              subPage ? `meta.${subPage}` : activeTab
                            }.actions`,
                            []
                          )
                        )}
                        handleClick={onClickHandler}
                        downloadProposalStatus={downloadProposalStatus}
                        createOrderDisabled={hasCalculatorError}
                        isLowerMdBreakpoint={isLowerMdBreakpoint}
                      />
                    </>
                  ) : (
                    <Tabs
                      tabsRef={tabsRef}
                      tabitems={getSupportedTabLabels().map((tab) => tab && tab.label)}
                      tabDisabledStates={getSupportedTabLabels().map(
                        (tab) => tab.disabled
                      )}
                      tabcontent={getSupportedTabContent()}
                      tabfooter={(
                        <ProgenFooter
                          isCrm={isCrm}
                          calculatorHasErrors={hasCalculatorError}
                          isLowerMdBreakpoint={isLowerMdBreakpoint}
                          actions={
                            showCustomerForm || showHubspotCustomerForm
                              ? getCustomerFormActions(canSubmit, customerFormStep)
                              : getActions(
                                  get(
                                    widgetData,
                                    `proposalGenerator.${
                                      subPage ? `meta.${subPage}` : activeTab
                                    }.actions`,
                                    []
                                  )
                                )
                          }
                          handleClick={onClickHandler}
                          downloadProposalStatus={downloadProposalStatus}
                          downloadAgreementStatus={downloadAgreementStatus}
                        />
                      )}
                    />
                  )}
                </>
              )}
            </WidgetMetaContext.Provider>
          </>
        )}
      </div>
      <CreditAppraisalAndChangeRequestModalV2
        open={showDeclinedModal}
        standardReasons={get(
          companyData,
          'companyBy.creditCheck.declineReasonsWithStatus.standard_reasons',
          []
        ).map((reason) => ({ reason, key: uuidv4() }))}
        customReason={get(
          companyData,
          'companyBy.creditCheck.declineReasonsWithStatus.custom_reason',
          ''
        )}
        message={get(
          companyData,
          'companyBy.creditCheck.declineReasonsWithStatus.modal_message',
          null
        )}
        title={get(
          companyData,
          'companyBy.creditCheck.declineReasonsWithStatus.modal_title',
          null
        )}
        status={get(
          companyData,
          'companyBy.creditCheck.declineReasonsWithStatus.credit_appraisal_status',
          null
        )}
        onClose={() => setShowDeclinedModal(false)}
      />
      <NeedMoreInfoModal
        open={showDetailsModal}
        title={get(
          companyData,
          'companyBy.creditCheck.currentStepDetails.modal_title',
          null
        )}
        onClose={() => setShowDetailsModal(false)}
        message={get(
          companyData,
          'companyBy.creditCheck.currentStepDetails.step_details',
          null
        )}
      />
      <UploadDocumentModal
        open={showDocumentUploadModal}
        onClose={() => toggleDocumentUploadModal(false)}
        title="More information needed"
        onSubmit={onDocumentSubmit}
        documents={[{ label: '2 years of financial statements', type: 'financial' }]}
        acceptMultipleFiles
      />
      <PaymentInfoModal
        isOpen={paymentInfoModal === MODAL.paymentInfo}
        onSuccess={() => setPaymentInfoModel(MODAL.success)}
        onClose={() => setPaymentInfoModel('')}
        orderId={get(selectedOrder, 'id', '')}
        milestoneNumber={get(firstMilestone, 'number', '')}
      />
      <SuccessModal
        isOpen={paymentInfoModal === MODAL.success}
        onClose={async () => {
          setPaymentInfoModel('');
          refetchOrderData();
        }}
      />
      <UploadDocumentModal
        open={showVendorInvoiceModal}
        onClose={() => setShowVendorInvoiceModal(false)}
        title="Upload vendor invoice"
        onSubmit={onInvoiceSubmit}
      />
      <PurchaseOrdersModal
        isOpen={reviewModalOpen}
        purchaseOrderDocs={purchaseOrderDocs}
        onClose={() => setReviewModalOpen(false)}
        orderNumber={get(selectedOrder, 'number', '')}
      />
    </div>
  );
};

export default CommonWidgetV2;
