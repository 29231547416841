import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import UploadDocumentModal from '../../../components/Modals/UploadDocumentModal';
import ActionRequired from '../Summary/ActionRequired';
import { ORDER_COMMENCED_STATES } from '../../../constants/order';

function FundingInvoice({
  orderMeta,
  showInvoiceModal,
  onInvoiceSubmit,
  toggleDocumentUploadModal,
}) {
  const orderCommenced = useMemo(
    () => ORDER_COMMENCED_STATES.includes(get(orderMeta, 'status', '')),
    [orderMeta]
  );

  return (
    <>
      <UploadDocumentModal
        open={showInvoiceModal}
        onClose={() => toggleDocumentUploadModal(false)}
        title="Upload vendor invoice"
        onSubmit={onInvoiceSubmit}
      />
      <ActionRequired
        title="Awaiting invoice!"
        message={`Please upload invoice to receive ${
          orderCommenced ? 'remaining' : ''
        } payout.`}
        secondaryMessage={orderCommenced ? null : orderMeta.updatedAt}
        onPrimaryClick={() => toggleDocumentUploadModal(true)}
        primaryButtonText="Upload invoice"
      />
    </>
  );
}

FundingInvoice.propTypes = {
  orderMeta: PropTypes.shape({
    id: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    milestoneNumber: PropTypes.string.isRequired,
    updatedAt: PropTypes.string,
  }).isRequired,
  onInvoiceSubmit: PropTypes.func.isRequired,
  toggleDocumentUploadModal: PropTypes.func.isRequired,
  showInvoiceModal: PropTypes.bool.isRequired,
};

export default FundingInvoice;
