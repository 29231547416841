import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { useMutation } from '@apollo/client';
import { omit, get } from 'lodash';
import { Button, Card } from '@vartanainc/design-system';
import { SEND_CHECKOUT } from '../../../graphql/queries/order';

import FormInput from '../../../components/FormInput';
import { reportError, showToast } from '../../../utils/helpers';
import {
  commonRegex,
  FIELD_INVALID_MESSAGES,
  requiredPhoneFieldValidation,
} from '../../../constants/common.constants';
import TextInputField from '../../../designSystem/TextInput/TextInputField';
import TextAreaInput from '../../../designSystem/TextAreaInput/TextAreaInput';
import PatternInput from '../../../designSystem/PatternInput/PatternInput';

const SendCheckoutLinkFormSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required')
    .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.firstName),
  lastName: yup
    .string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required')
    .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.lastName),
  phone: requiredPhoneFieldValidation,
  email: yup
    .string()
    .typeError('Invalid email')
    .email('Invalid email')
    .required('This field is required'),
  message: yup.string(),
});

function SendCheckoutLinkForm({ orderNumber, disabled, signer, formFocusSwitch }) {
  const [initialFocusFlag, setInitialFocusFlag] = useState(false);
  const [sendCheckout, { loading: checkoutSending }] = useMutation(SEND_CHECKOUT);

  const firstNameRef = useRef();
  const handleFormFocus = () => {
    firstNameRef.current?.focus();
  };

  useEffect(() => {
    if (initialFocusFlag) handleFormFocus();
    else setInitialFocusFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFocusSwitch]);

  const handleForwardCheckout = useCallback(
    async (values, { setErrors, resetForm }) => {
      try {
        const responseData = await sendCheckout({
          variables: {
            ...omit(values, ['validateOnMount']),
          },
        });

        const errors = get(responseData, 'errors', {});

        if (Object.keys(errors).length) {
          setErrors(errors);
        } else {
          showToast(
            'success',
            'Checkout link sent! Recipient will receive their checkout link shortly.'
          );
          resetForm();
        }
      } catch (e) {
        reportError(`[SEND_CHECKOUT] error: ${e}`);
      }
    },
    [sendCheckout]
  );

  const prefilled = Object.keys(signer).length;

  return (
    <div className="w-7/12">
      <Card
        variant="fullWidth"
        tabindex={0}
        title="Resend or forward checkout link to a new signer"
        content={(
          <Formik
            initialValues={{
              validateOnMount: true,
              number: orderNumber,
              firstName: get(signer, 'firstName', ''),
              lastName: get(signer, 'lastName', ''),
              email: get(signer, 'email', ''),
              phone: get(signer, 'phone', ''),
              message: '',
            }}
            enableReinitialize
            validationSchema={SendCheckoutLinkFormSchema}
            onSubmit={handleForwardCheckout}
          >
            {({ isSubmitting, isValid, dirty, values }) => {
              const submitDisabled =
                disabled || isSubmitting || checkoutSending || !(isValid && dirty);

              return (
                <Form>
                  <fieldset disabled={disabled}>
                    <FormInput className="tw-hidden" name="number" type="hidden" />
                    <div className="space-y-4">
                      <div className="flex flex-row space-x-4">
                        <TextInputField
                          name="firstName"
                          label="Legal first name"
                          disabled={disabled || prefilled || isSubmitting}
                          ref={firstNameRef}
                        />

                        <TextInputField
                          name="lastName"
                          label="Legal last name"
                          disabled={disabled || prefilled || isSubmitting}
                        />
                      </div>

                      <div className="flex flex-row space-x-4">
                        <TextInputField
                          name="email"
                          label="Signer's email"
                          disabled={disabled || prefilled || isSubmitting}
                        />
                        {/* Todo: Phone number should be in this format
                          phone : "+1 (212) 666-6666" */}
                        <PatternInput
                          name="phone"
                          label="Phone"
                          mask="_"
                          value={values.phone}
                          disableFullStoryRecording
                          disabled={disabled || prefilled || isSubmitting}
                        />
                      </div>

                      <div className="flex flex-row">
                        <TextAreaInput
                          name="message"
                          label="Include a message"
                          placeholder="Hello..."
                          disabled={disabled || isSubmitting}
                        />
                      </div>
                    </div>

                    <div className="flex flex-row justify-center mt-6">
                      <Button disabled={submitDisabled}>Send</Button>
                    </div>
                  </fieldset>
                </Form>
              );
            }}
          </Formik>
        )}
      >
      </Card>
    </div>
  );
}

SendCheckoutLinkForm.propTypes = {
  orderNumber: PropTypes.string,
  disabled: PropTypes.bool,
  signer: PropTypes.node,
  formFocusSwitch: PropTypes.bool,
};

SendCheckoutLinkForm.defaultProps = {
  orderNumber: '',
  disabled: false,
  signer: null,
  formFocusSwitch: false,
};

export default SendCheckoutLinkForm;
