import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';

import tooltipIcon from '../../assets/tooltip.svg';

// @param {node} content - the content of the tooltip
// @param {node} icon - the icon to which the tooltip is attached
// @param {node} defaultIconClassName - the className for the default icon
function DangerousTooltip({ content, icon, defaultIconClassName, position }) {
  const uniqueId = uuidv4();
  return (
    <>
      {icon ? (
        <div data-tooltip-id={uniqueId} className="cursor-pointer">
          {icon}
        </div>
      ) : (
        <img
          alt="tooltip"
          data-tooltip-id={uniqueId}
          data-tooltip-place={position}
          className={`cursor-pointer ${defaultIconClassName}`}
          src={tooltipIcon}
        />
      )}

      <ReactTooltip
        id={uniqueId}
        effect="solid"
        className="bg-vartana-gray-70 text-center"
      >
        {content}
      </ReactTooltip>
    </>
  );
}

DangerousTooltip.propTypes = {
  content: PropTypes.node.isRequired,
  icon: PropTypes.node,
  defaultIconClassName: PropTypes.string,
  position: PropTypes.string,
};

DangerousTooltip.defaultProps = {
  icon: null,
  defaultIconClassName: '',
  position: 'top',
};

export default DangerousTooltip;
