import React, { useContext, useMemo, useState } from 'react';
import { get, upperCase } from 'lodash';
import { twMerge } from 'tailwind-merge';

import { Typography, Card } from '@vartanainc/design-system';

import { useReactiveVar } from '@apollo/client';
import { sessionVar } from '../../../graphql/cache';
import TruncatedText from '../../../components/TruncatedText/TruncatedText';
import {
  accountExecutiveTypes,
  appVariants,
  billingMethodsLabels,
  BUYER_TYPE,
  PLACEHOLDER_STRING,
} from '../../../constants/common.constants';
import PaymentSchedule from '../../Insights/Charts/PaymentSchedule';
import { ReactComponent as InvoiceIcon } from '../../../assets/mail-icon-blue.svg';
import { ReactComponent as InvoiceIconDisabled } from '../../../assets/mail-icon-gray.svg';
import { ReactComponent as AccountBalanceIcon } from '../../../assets/bank-icon.svg';
import { ReactComponent as CreditCardIcon } from '../../../assets/credit-card-icon.svg';
import { ReactComponent as StoreIcon } from '../../../assets/store-blue.svg';
import { ReactComponent as PriceCheckIcon } from '../../../assets/price_check.svg';
import { ReactComponent as ReceiptLongIcon } from '../../../assets/receipt_long_blue-32.svg';

import {
  getStatusClass,
  getUSDateFormat,
  removeTrailingZeros,
  titleize,
} from '../../../utils/helpers';

import CommonWidgetV2 from '../../CommonWidgetV2/commonWidget';
import { WidgetContext } from '../../../context/WidgetContext';
import ReassignAccountOwner from '../../Common/AccountOwner/reassign';
import { CustomerShowContext } from '../../../context/CustomerContext';
import { ACTION, RESOURCE } from '../../../constants/permissions';
import ViewAccountOwner from '../../Common/AccountOwner/ViewAccountOwner';
import usePermissions from '../../../utils/hooks/permissions';
import { RequestToPayByInvoiceButton } from '../../../components/Button/RequestPayByInvoiceButton';
import { OnlyCustomerContext } from '../../../context/OnlyCustomerContext';
import { HasPermission } from '../../../components/HasPermission/HasPermission';
import { CurrencyPill } from '../../../components/Pill/CurrencyPill';

import './CardSummary.scss';
import { ORDER_POST_FINANCED_STATES } from '../../../constants/order';

const billingMethodIconMapping = {
  ach: <AccountBalanceIcon className="w-6 h-6" />,
  invoice: <InvoiceIcon />,
  credit_card: <CreditCardIcon className="w-fit h-5" />,
};

const sections = ['summary', 'reassignAccountExecutive'];

function CustomerSummary() {
  const customerData = useContext(OnlyCustomerContext);
  const [, userHasPermission] = usePermissions();
  const { refetchCustomerData } = useContext(CustomerShowContext);
  const [widgetBorderStyle, setWidgetBorderStyle] = useState('');
  const [selectedSection, setSelectedSection] = useState(sections[0]);
  const customer = get(customerData, 'company', {});
  const [loanDecision, companyNumber] = useMemo(() => {
    const loanStatus = get(customer, 'creditCheck.loanDecision', '-');
    const companyNo = get(customer, 'number', '');
    return [loanStatus, companyNo];
  }, [customer]);

  const sessionData = useReactiveVar(sessionVar);
  const showCurrencyPill = get(
    sessionData,
    'session.user.company.product.multipleCountriesEnabled'
  );

  const paymentSchedulerGraph = useMemo(() => {
    const data = get(customer, 'buyerPaymentsCollectedOverPredictedPayments', []);
    if (data.length) {
      return (
        <Card
          variant="variable"
          title="Payment schedule"
          content={(
            <div className="border border-gray-300 rounded-md shadow-sm py-4 flex-grow flex flex-col justify-center">
              <PaymentSchedule paymentsCollectedOverPredictedPayments={data} />
            </div>
          )}
        >
        </Card>
      );
    }
    return null;
  }, [customer]);

  const [selectedTerm, setSelectedTerm] = useState(0);
  const [selectedFrequency, setSelectedFrequency] = useState('');

  const loanDecisionPill = useMemo(() => {
    return (
      <p>
        <span className={getStatusClass(loanDecision)}>{upperCase(loanDecision)}</span>
      </p>
    );
  }, [loanDecision]);

  const [creditConditionsRequired] = useMemo(() => {
    let conditions = get(customer, 'creditCheck.creditConditionsForApproval', []);

    conditions = conditions.map((condition) => {
      if (condition === 'Financial Statements') {
        return 'Financials';
      }
      return condition;
    });

    return [conditions];
  }, [customer]);

  const renderRequirementSection = useMemo(() => {
    if (
      loanDecision === 'approved' &&
      !creditConditionsRequired.includes('Personal Guarantee')
    )
      return null;

    let heading = '';
    if (creditConditionsRequired.length > 1) {
      heading = 'Requirements';
    } else if (creditConditionsRequired.length === 1) {
      heading = 'Requirement';
    } else {
      return null;
    }

    const isCreditConditionsFullwidth =
      showCurrencyPill && creditConditionsRequired.length > 1;

    return (
      creditConditionsRequired.length > 0 && (
        <div
          className={`${
            isCreditConditionsFullwidth ? 'w-full' : 'w-[28ch]'
          } flex flex-col gap-0.5`}
        >
          <Typography variant="paragraph12" bold color="color-gray-110">
            {heading}
          </Typography>
          <div className="flex flex-row gap-2">
            {creditConditionsRequired.map((condition) => {
              return (
                <p>
                  <span className={getStatusClass('checkout')}>{condition}</span>
                </p>
              );
            })}
          </div>
        </div>
      )
    );
  }, [creditConditionsRequired, loanDecision, showCurrencyPill]);

  const renderPricingValidUntilSection = useMemo(() => {
    if (loanDecision !== 'approved') return null;

    return (
      <div className="w-[28ch] flex flex-col gap-0.5">
        <Typography variant="paragraph12" bold color="color-gray-110">
          Pricing valid until
        </Typography>
        <Typography variant="paragraph14" color="color-black-100">
          {getUSDateFormat(get(customer, 'pricingExpiryDate', ''))}
        </Typography>
      </div>
    );
  }, [customer, loanDecision]);

  const renderApprovedUntilSection = useMemo(() => {
    if (loanDecision !== 'approved') return null;

    return (
      <div className="w-[28ch] flex flex-col gap-0.5">
        <Typography variant="paragraph12" bold color="color-gray-110">
          Approval valid until
        </Typography>
        <Typography variant="paragraph14" color="color-black-100">
          {getUSDateFormat(get(customer, 'creditCheck.formattedExpireAt', ''))}
        </Typography>
      </div>
    );
  }, [customer, loanDecision]);

  const widgetContext = useMemo(
    () => ({
      selectedOrder: {},
      selectedCompany: customer,
      product: get(customer, 'seller.product', {}),
      variant: appVariants.vendor,
      selectedTerm,
      setSelectedTerm,
      selectedFrequency,
      setSelectedFrequency,
      setWidgetBorderStyle,
    }),
    [customer, selectedTerm, setSelectedTerm, selectedFrequency, setSelectedFrequency]
  );

  const legalName = get(customer, 'businessName', '');

  const showProposalCalculator = useMemo(() => {
    if (customer?.buyerRelationToVendor === BUYER_TYPE.INDIRECT)
      return userHasPermission(RESOURCE.order, ACTION.create, customer);

    return true;
  }, [userHasPermission, customer]);

  const orders = get(customer, 'buyerOrders', []);
  const hasPreFinancedOrders =
    orders.length > 0
      ? orders.filter((order) => {
        return (
          order.paymentTerm === 0 && !ORDER_POST_FINANCED_STATES.includes(order.status)
        );
      }).length > 0
      : false;

  const formattedBalances = useMemo(() => {
    // convert formattedTotalBalance object to array for dynamic rendering
    let totalBalances = Object.keys(get(customer, 'formattedTotalBalance', {})).map(
      (key) => get(customer, `formattedTotalBalance.${key}`, '')
    );

    // filter out balances with falsy values for cents
    totalBalances = totalBalances.filter((balance) => balance.cents);

    if (totalBalances.length === 0) {
      totalBalances = [{ formatted: PLACEHOLDER_STRING }];
    }

    return totalBalances;
  }, [customer]);

  return (
    <>
      {selectedSection === sections[0] ? (
        <div className="flex p-6 bg-vartana-steel-20 gap-6 w-full max-w-[85.375rem] min-w-[68.75rem] mx-auto ">
          <div className="flex flex-col gap-y-4 auto-rows-max lg:w-7/12 ">
            <Card
              variant="fullWidth"
              title={(
                <Typography variant="heading18" color="color-black-100" bold>
                  Customer details
                </Typography>
              )}
              icon={<StoreIcon />}
              content={(
                <div className="flex flex-col gap-4">
                  <div className="flex flex-row gap-4">
                    <div className="w-[28ch] flex flex-col gap-0.5">
                      <Typography variant="paragraph12" bold color="color-gray-110">
                        Name
                      </Typography>
                      <TruncatedText
                        variant="paragraph14"
                        color="color-black-100"
                        text={titleize(get(customer, 'name', '-'))}
                        maxChar={30}
                        multilineTooltip
                      />
                    </div>
                    <div className="w-[28ch] flex flex-col gap-0.5">
                      <Typography variant="paragraph12" bold color="color-gray-110">
                        Legal name
                      </Typography>
                      <TruncatedText
                        variant="paragraph14"
                        color="color-black-100"
                        text={titleize(legalName)}
                        maxChar={30}
                        multilineTooltip
                      />
                    </div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="w-[28ch] flex flex-col gap-0.5">
                      <Typography variant="paragraph12" bold color="color-gray-110">
                        Customer ID
                      </Typography>
                      <Typography variant="paragraph14" color="color-black-100">
                        {get(customer, 'number', '')}
                      </Typography>
                    </div>
                    <div className="w-[28ch] flex flex-col gap-0.5">
                      <Typography variant="paragraph12" bold color="color-gray-110">
                        Updated on
                      </Typography>
                      <Typography variant="paragraph14" color="color-black-100">
                        {getUSDateFormat(get(customer, 'updatedAt', ''))}
                      </Typography>
                    </div>
                  </div>
                </div>
              )}
            />
            {get(customer, 'currentBuyerOrdersCount', '') !== 0 && (
              <Card
                variant="fullWidth"
                title="Order summary"
                icon={<ReceiptLongIcon />}
                content={(
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-row gap-4">
                      <div className="w-[28ch] flex flex-col gap-0.5">
                        <Typography variant="paragraph12" bold color="color-gray-110">
                          Number of orders
                        </Typography>
                        <Typography variant="paragraph14" color="color-black-100">
                          {get(customer, 'currentBuyerOrdersCount', '')}
                        </Typography>
                      </div>
                      <div className="w-[28ch] flex flex-col gap-0.5">
                        <Typography variant="paragraph12" bold color="color-gray-110">
                          Total amount
                        </Typography>
                        <div className="flex flex-row divide-x divide-vartana-gray-40-v3 gap-1">
                          {formattedBalances.map((balance, index) => (
                            <Typography
                              key={balance}
                              className={index === 0 ? '' : 'pl-1'}
                              variant="paragraph14"
                              color="color-black-100"
                            >
                              {removeTrailingZeros(balance.formatted)}
                            </Typography>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row gap-4">
                      <div className="w-[28ch] flex flex-col gap-0.5">
                        <Typography variant="paragraph12" bold color="color-gray-110">
                          Payments collected
                        </Typography>
                        <Typography variant="paragraph14" color="color-black-100">
                          {get(customer, 'totalPaymentsCollectedCount', '')}
                        </Typography>
                      </div>
                      <div className="w-[28ch] flex flex-col gap-0.5">
                        <Typography variant="paragraph12" bold color="color-gray-110">
                          Remaining balance
                        </Typography>
                        <Typography variant="paragraph14" color="color-black-100">
                          {get(customer, 'formattedTotalRemainingBalance', '')}
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}
              />
            )}
            <Card
              variant="fullWidth"
              title={(
                <Typography variant="heading18" color="color-black-100" bold>
                  Credit decision
                </Typography>
              )}
              icon={<PriceCheckIcon />}
              content={(
                <div className="flex flex-col gap-4">
                  <div className="flex flex-row gap-4 flex-wrap">
                    <div className="w-[28ch] flex flex-col gap-0.5">
                      <Typography variant="paragraph12" bold color="color-gray-110">
                        Credit decision
                      </Typography>
                      {loanDecisionPill}
                    </div>
                    {showCurrencyPill && (
                      <div className="w-[28ch] flex flex-col gap-0.5">
                        <Typography variant="paragraph12" bold color="color-gray-110">
                          Currency
                        </Typography>
                        <CurrencyPill
                          currency={get(customer, 'creditCheck.currency', '')}
                        />
                      </div>
                    )}
                    {renderRequirementSection}
                    {renderApprovedUntilSection}
                    {renderPricingValidUntilSection}
                  </div>
                </div>
              )}
            />

            <ViewAccountOwner
              resource="customer"
              customer={customer}
              user={get(customer, 'accountExecutive', {})}
              openReassignAccountOwnerSection={() => setSelectedSection(sections[1])}
            />
          </div>
          <div className="flex flex-col auto-rows-max gap-y-6">
            <div
              className={twMerge(
                'relative bg-white rounded-lg shadow-lg',
                widgetBorderStyle
              )}
            >
              <WidgetContext.Provider value={widgetContext}>
                <CommonWidgetV2 />
              </WidgetContext.Provider>
            </div>
            <Card
              variant="fullWidth"
              title={(
                <Typography variant="heading18" color="color-black-100" bold>
                  Billing methods
                </Typography>
              )}
              content={(
                <div className="grid grid-cols-2 gap-4">
                  {get(customer, 'availablePaymentMethods', []).map((billingMethod) => {
                    return (
                      <div key={billingMethod} className="flex gap-1 items-center">
                        {billingMethodIconMapping[billingMethod]}
                        <Typography variant="paragraph14">
                          {billingMethodsLabels[billingMethod]}{' '}
                        </Typography>
                      </div>
                    );
                  })}
                  {!get(customer, 'invoiceEnabled', false) ? (
                    <div
                      key="apply-invoice"
                      className="flex gap-2 items-center w-full justify-end"
                    >
                      <div className="flex gap-1 items-center">
                        <div className="w-6 h-6">
                          <InvoiceIconDisabled />
                        </div>
                        <Typography variant="paragraph14" color="color-gray-110">
                          Invoice
                        </Typography>
                      </div>
                      {/* Todo: Replace from design system small button when it's developed */}
                      <HasPermission
                        resource="order"
                        action="request_pay_by_invoice"
                        customer={customer}
                      >
                        <RequestToPayByInvoiceButton
                          companyName={get(customer, 'name', '')}
                          companyNumber={companyNumber}
                          hasPreFinancedOrders={hasPreFinancedOrders}
                        />
                      </HasPermission>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              )}
            >
            </Card>
            {showProposalCalculator && paymentSchedulerGraph}
          </div>
        </div>
      ) : (
        <div className="p-6">
          <ReassignAccountOwner
            type={accountExecutiveTypes.customer}
            resource={{
              name: get(customer, 'businessName'),
              number: get(customer, 'number'),
            }}
            assignedUser={get(customer, 'accountExecutive', {})}
            handleClose={async () => {
              setSelectedSection(sections[0]);
              await refetchCustomerData();
            }}
          />
        </div>
      )}
    </>
  );
}

export default CustomerSummary;
