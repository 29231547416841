import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_ROLES } from '../../../graphql/queries/roles';

export function Roles() {
  const [roles, setRoles] = useState([]);
  const { loading: rolesLoading } = useQuery(GET_ROLES, {
    onCompleted: (response) => setRoles(get(response, 'roles', [])),
  });

  return <Outlet context={{ roles, rolesLoading }} />;
}
