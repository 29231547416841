import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useContext, useState } from 'react';
import { OrderShowContext } from '../../../../context/OrderContext';
import { EditPurchaseOrdersForm } from './EditPurchaseOrdersForm';
import { ViewPurchaseOrders } from './ViewPurchaseOrders';
import { isRenderedFromHubspot } from '../../../../utils/helpers';

const MODAL = {
  edit: 'edit request for POs',
  view: 'view POs in PDF viewer',
};

export const PurchaseOrdersModal = ({
  isOpen,
  onClose,
  purchaseOrderDocs,
  orderNumber,
}) => {
  const [currentModal, setCurrentModal] = useState(MODAL.view);
  const { refetchOrderData } = useContext(OrderShowContext);

  const modalClassName = isRenderedFromHubspot()
    ? 'hubspot-purchase-order-request-modal'
    : '';
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName="flex justify-center items-center modal"
      className={twMerge(modalClassName, 'w-full h-full overflow-y-auto outline-0')}
    >
      {currentModal === MODAL.view && (
        <ViewPurchaseOrders
          purchaseOrderDocs={purchaseOrderDocs}
          orderNumber={orderNumber}
          onClose={onClose}
          onEdit={() => setCurrentModal(MODAL.edit)}
        />
      )}
      {currentModal === MODAL.edit && (
        <div className="request-edit-modal flex justify-center mt-40">
          <EditPurchaseOrdersForm
            orderNumber={orderNumber}
            onCancel={() => setCurrentModal(MODAL.view)}
            onSuccess={() => {
              refetchOrderData();
              onClose();
            }}
          />
        </div>
      )}
    </Modal>
  );
};

PurchaseOrdersModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  orderNumber: PropTypes.string.isRequired,
  purchaseOrderDocs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

PurchaseOrdersModal.defaultProps = {
  isOpen: true,
};

export default PurchaseOrdersModal;
