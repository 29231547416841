import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

/*
  Use this when you need to switch route URLs with tabs
*/
export function TabsWithRouter({ tabs, containerClassName }) {
  return (
    <nav
      className={`flex space-x-8 border-b border-gray-200 shadow rounded-none px-8 h-16 bg-white ${containerClassName}`}
      aria-label="Tabs"
    >
      {tabs.map((tab) => (
        <NavLink
          key={tab.path}
          to={tab.path}
          className={({ isActive }) => `tab default ${isActive ? 'selected' : ''}`}
        >
          {tab.name}
        </NavLink>
      ))}
    </nav>
  );
}

TabsWithRouter.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
  containerClassName: PropTypes.string,
};

TabsWithRouter.defaultProps = {
  containerClassName: '',
};
