import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useFormik, FormikProvider, Form } from 'formik';
import * as yup from 'yup';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Typography } from '@vartanainc/design-system';
import { get, isEmpty } from 'lodash';
import { useOutletContext } from 'react-router-dom';
import FormInput from '../../../../components/FormInput';
import AutoLoad from '../../../../components/AutoLoad';
import CommonFooter from './CommonFooter';
import { GET_PROFILE_DETAILS } from '../../../../graphql/queries/profile';
import { UPDATE_PROFILE_DETAILS } from '../../../../graphql/mutations';
import { reportError } from '../../../../utils/helpers';
import CommonHeader from './CommonHeader';
import TextInputField from '../../../../designSystem/TextInput/TextInputField';
import PatternInput from '../../../../designSystem/PatternInput/PatternInput';
import { ReactComponent as EyeIcon } from '../../../../assets/eye-icon.svg';
import { ReactComponent as EyeIconSlash } from '../../../../assets/eye-icon-slash.svg';
import {
  commonRegex,
  FIELD_INVALID_MESSAGES,
  PASSWORD_ERRORS,
} from '../../../../constants/common.constants';
import { passwordFieldValidation } from '../../../../utils/validators';
import {
  getConditionIcon,
  getTextColor,
  handlePasswordValidation,
} from '../../../../utils/formUtils';

const schema = yup.object().shape({
  firstName: yup
    .string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required')
    .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.firstName),
  lastName: yup
    .string()
    .min(1, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required')
    .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.lastName),
  email: yup
    .string()
    .email('Enter a valid email address')
    .required('This field is required'),
  phone: yup.string(),
  password: passwordFieldValidation,
});

function UserDetails() {
  const { goForward, isOnboarding } = useOutletContext();

  const { loading: userProfileLoading, data: userProfile } =
    useQuery(GET_PROFILE_DETAILS);
  const [updateUserProfile] = useMutation(UPDATE_PROFILE_DETAILS);

  const [initialValues, setInitialValues] = useState({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
  });
  const [revealPassword, setRevealPassword] = useState(false);
  const [passwordConditions, setPasswordConditions] = useState({
    lengthCondition: false,
    numberCondition: false,
    letterCaseCondition: false,
  });
  const [showPasswordHints, setShowPasswordHints] = useState(false);
  const [showRedHints, setShowRedHints] = useState(false);

  const passwordRef = useRef();

  const onSubmit = useCallback(
    async (values, { setErrors }) => {
      try {
        const responseData = await updateUserProfile({
          variables: {
            ...values,
          },
        });
        const errors = get(responseData, 'errors', {});
        if (!isEmpty(errors)) {
          setErrors(errors);
          return;
        }

        goForward();
      } catch (e) {
        reportError(`Onboarding: While updating user details: ${e.message}`);
      }
    },
    [goForward, updateUserProfile]
  );

  // Pre-fill form fields if user details already exist
  useEffect(() => {
    if (!userProfileLoading && userProfile) {
      setInitialValues({
        id: get(userProfile, 'session.user.id', ''),
        firstName: get(userProfile, 'session.user.firstName', ''),
        lastName: get(userProfile, 'session.user.lastName', ''),
        email: get(userProfile, 'session.user.email', ''),
        phone: get(userProfile, 'session.user.phone', '') || '',
      });
    }
  }, [userProfileLoading, userProfile]);

  function getSchemaBasedOnState() {
    let newSchema;
    if (get(userProfile, 'session.user.isSsoUser') || !isOnboarding) {
      newSchema = schema.shape({
        password: null,
      });
    } else {
      newSchema = schema;
    }
    return newSchema;
  }

  const formikBag = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: getSchemaBasedOnState(),
    onSubmit,
  });

  const { isSubmitting, isValid, dirty, handleSubmit } = formikBag;

  const isPasswordValid =
    passwordConditions.lengthCondition &&
    passwordConditions.letterCaseCondition &&
    passwordConditions.numberCondition;

  const passwordError =
    !get(userProfile, 'session.user.isSsoUser') && isOnboarding && !isPasswordValid;

  const formValid = isValid && dirty && !passwordError;
  const disabled = isSubmitting || !formValid;

  useEffect(() => {
    handlePasswordValidation(
      formikBag.errors?.password,
      formikBag.values?.password,
      setPasswordConditions
    );
  }, [formikBag.errors?.password, formikBag.values?.password]);

  const onBlurPassword = () => {
    if (isPasswordValid) {
      setShowPasswordHints(false);
    } else {
      setShowRedHints(true);
    }
  };

  const handleSubmitClick = () => {
    if (passwordError) {
      passwordRef.current?.focus();
    } else handleSubmit();
  };

  return (
    <>
      <AutoLoad
        loading={userProfileLoading}
        containerClassName="flex justify-center"
        className="absolute text-center top-2/4 transform-gpu -translate-y-2/4 mt-8"
      >
        <FormikProvider value={formikBag}>
          <Form onSubmit={handleSubmit}>
            <FormInput containerClassName="hidden" name="id" type="hidden" />

            <fieldset disabled={isSubmitting}>
              <div className="space-y-6">
                <CommonHeader
                  title="Let’s get started"
                  message="Join the Vartana platform in a few simple steps."
                />

                <div className="space-y-4">
                  <div className="flex flex-row space-x-4">
                    <TextInputField name="firstName" label="First Name" />
                    <TextInputField name="lastName" label="Last Name" />
                  </div>

                  <div className="flex flex-row space-x-4">
                    <TextInputField name="email" label="Email" />
                    <PatternInput
                      name="phone"
                      label="Phone"
                      mask="_"
                      disableFullStoryRecording
                    />
                  </div>
                  {get(userProfile, 'session.user.isSsoUser') || !isOnboarding ? null : (
                    <>
                      <TextInputField
                        name="password"
                        label="Create password"
                        type={revealPassword ? 'text' : 'password'}
                        disableFullStoryRecording
                        ref={passwordRef}
                        suffixIcon={
                          revealPassword ? (
                            <EyeIconSlash className="h-5 w-auto object-contain" />
                          ) : (
                            <EyeIcon className="h-5 w-auto object-contain" />
                          )
                        }
                        suffixIconFunc={() => setRevealPassword(!revealPassword)}
                        onFocus={() => setShowPasswordHints(true)}
                        onBlur={onBlurPassword}
                      />
                      <div
                        className={`transition-all ease-in delay-200 duration-500 ${
                          showPasswordHints
                            ? 'opacity-100 h-12 mt-2'
                            : 'opacity-0 h-0 mt-0'
                        }`}
                      >
                        <div className="flex gap-1 items-center">
                          {getConditionIcon(
                            passwordConditions.lengthCondition,
                            showRedHints
                          )}
                          <Typography
                            variant="paragraph10"
                            color={getTextColor(
                              'isLengthConditionMet',
                              passwordConditions,
                              showRedHints
                            )}
                          >
                            {PASSWORD_ERRORS.lengthError}
                          </Typography>
                        </div>
                        <div className="flex gap-1 items-center">
                          {getConditionIcon(
                            passwordConditions.letterCaseCondition,
                            showRedHints
                          )}
                          <Typography
                            variant="paragraph10"
                            color={getTextColor(
                              'isLetterCaseConditionMet',
                              passwordConditions,
                              showRedHints
                            )}
                          >
                            {PASSWORD_ERRORS.casingError}
                          </Typography>
                        </div>
                        <div className="flex gap-1 items-center">
                          {getConditionIcon(
                            passwordConditions.numberCondition,
                            showRedHints
                          )}
                          <Typography
                            variant="paragraph10"
                            color={getTextColor(
                              'isNumberConditionMet',
                              passwordConditions,
                              showRedHints
                            )}
                          >
                            {PASSWORD_ERRORS.numberError}
                          </Typography>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <CommonFooter>
                <div className="flex justify-between items-center">
                  <div />
                  <div>
                    {/** New Button  */}
                    <Button
                      onClick={handleSubmitClick}
                      disabled={disabled}
                      variant="outlined"
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </CommonFooter>
            </fieldset>
          </Form>
        </FormikProvider>
      </AutoLoad>
    </>
  );
}

export default UserDetails;
