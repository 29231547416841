import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography } from '@vartanainc/design-system';

import { BUMP_PRIORITY_TO_HIGH } from '../../../../graphql/queries/customer';
import AutoLoad from '../../../../components/AutoLoad';
import InfoModal from '../../../../components/Modals/InfoModal';

export function ExtendedAppDialog() {
  const location = useLocation();
  const navigate = useNavigate();
  const useQueryParams = new URLSearchParams(location.search);
  const companyNumber = useQueryParams.get('customerNumber') || '';

  const [bumpPriority, { loading: bumpingPriority }] = useMutation(
    BUMP_PRIORITY_TO_HIGH,
    {
      onError: (error) => console.error(`[BUMP_PRIORITY_TO_HIGH], ${error.message}`),
    }
  );

  const bumpPriorityToHigh = useCallback(async () => {
    try {
      await bumpPriority({ variables: { number: companyNumber } });
    } catch (e) {
      console.error(e);
    }
    window.close();
  }, [bumpPriority, companyNumber]);

  return (
    <div className="flex flex-col divide-y gap-6">
      <AutoLoad
        loading={bumpingPriority}
        containerClassName="flex justify-center"
        className="absolute text-center top-2/4 transform-gpu -translate-y-2/4"
      >
        <InfoModal
          title="Payment plans are almost ready"
          confirmButtonText="Yes, continue without financing"
          rejectButtonText="No, go back"
          description={(
            <Typography variant="paragraph14">
              We are working diligently to approve your customer for Vartana financing.
              <br />
              Would you like to proceed without Vartana financing for this order?
            </Typography>
          )}
          isOpen
          onClose={() => bumpPriorityToHigh()}
          onSubmit={() => navigate(`/forms/order${location.search}`)}
        >
        </InfoModal>
      </AutoLoad>
    </div>
  );
}
