import { Typography } from '@vartanainc/design-system';
import { capitalize } from 'lodash';
import { useLocation } from 'react-router-dom';
import colors from '../../../../components/ColorMap/ColorMap';

import { ReactComponent as Confirmation } from '../../../../assets/confirmation.svg';

function ConfirmationScreen(): JSX.Element {
  const location = useLocation();
  const { customerName, variant } = location.state;

  const confirmationTextDescription = {
    change_request: 'Hang tight while we review the information.',
    signature_order_direct: 'We have sent the documents to your customer for signature.',
    signature_order_review:
      'We are processing your order and will send the documents to your customer shortly.',
  };

  const confirmationTextTitle = {
    change_request: 'We received your submission.',
    signature_order_direct: 'Thank you.',
    signature_order_review: 'Thank you.',
  };

  return (
    <div className="flex flex-col bg-vartana-steel-20">
      <div className="py-6 px-14 flex flex-col gap-2 border-b border-vartana-gray-40-v3">
        <Typography variant="heading24" color="color-black-100">
          {capitalize(customerName)}
        </Typography>
      </div>
      {/* 9.25rem adds up to height of header and footer */}
      <div className="w-full min-h-[calc(100vh-9.25rem)] h-[calc(100vh-9.25rem)] flex justify-center items-center">
        <div className="flex flex-col items-center">
          <Confirmation className="h-[clamp(9.375rem,25vh,12.5rem)]" />
          {/* using p tag here because we need to have variable font size */}
          <p
            className={`text-[${colors['color-black-100']}] mt-[clamp(1.125rem,2.5vh,2.25rem)] text-[clamp(1.5rem,4vh,2.25rem)] font-medium`}
          >
            {confirmationTextTitle[variant]}
          </p>
          <p
            className={`text-[${colors['color-black-100']}] mt-[clamp(0.5rem,1.5vh,1rem)] text-[clamp(0.625rem,2vh,0.875rem)] text-center max-w-[22.125rem]`}
          >
            {confirmationTextDescription[variant]}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationScreen;
