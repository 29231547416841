import { getAPIURL } from '../utils/helpers';

export const setVendor = (values) => {
  let path = getAPIURL().replace('/crm', '');
  path = path.replace('/vendor', '');

  return fetch(`${path}/hubspot/set_vendor`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(values),
    credentials: 'include',
  });
};
