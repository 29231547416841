/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { capitalize, get } from 'lodash';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { Link, useLocation } from 'react-router-dom';

import { InputField, Typography } from '@vartanainc/design-system';
import Table from '../../components/Table';
import Loader from '../../components/Loader';
import { formatCapital, getUSDateFormat, humanizeName } from '../../utils/helpers';
import { GET_USERS } from '../../graphql/queries/user';
import ResetUserPasswordModal from '../../components/Modals/ResetUserPasswordModal';
import { sessionVar } from '../../graphql/cache';
import { GET_ROLES } from '../../graphql/queries/roles';
import { HasPermission } from '../../components/HasPermission/HasPermission';
import AutoLoad from '../../components/AutoLoad';
import DropdownInput from '../../designSystem/DropdownInput/DropdownInput';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import NoDataState from '../../components/NoDataState/NoDataState';

function Users() {
  const session = useReactiveVar(sessionVar);
  const location = useLocation();

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [fetchingFreshUsers, setFetchingFreshUsers] = useState(false);
  const [fetchingMoreUsers, setFetchingMoreUsers] = useState(false);
  const [afterCursor, setAfterCursor] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [searchUserName, setSearchUserName] = useState('');
  const [roles, setRoles] = useState([]);
  const [userRoleFilter, setUserRoleFilter] = useState(get(location, 'state.roleId', ''));

  const [debounceId, setDebounceId] = useState(null);
  const [isResetUserPasswordDialogOpen, setIsResetUserPasswordDialogOpenOpen] =
    useState(false);

  const [fetchMoreUsers, { loading: userListLoading }] = useLazyQuery(GET_USERS, {
    notifyOnNetworkStatusChange: true,
  });

  const { loading: rolesLoading } = useQuery(GET_ROLES, {
    onCompleted: (response) => {
      const fetchedRoles = get(response, 'roles', []).map((role) => ({
        ...role,
        value: role.id,
        label: capitalize(role.name),
      }));
      setRoles([
        ...[
          {
            value: '',
            label: 'All',
          },
        ],
        ...fetchedRoles,
      ]);
    },
  });

  const loader = useRef(null);

  const handleResetPassword = (selectedUserParam) => {
    setSelectedUser(selectedUserParam);
    setIsResetUserPasswordDialogOpenOpen(true);
  };

  const fetchMoreUsersWithFilters = useCallback(
    (variables, concat) => {
      const defaultVariables = {
        first: null,
        after: null,
        fullName: null,
        roleId: null,
        ...variables,
      };

      setFetchingMoreUsers(true);
      fetchMoreUsers({ variables: defaultVariables })
        .then((fetchMoreResult) => {
          const newEdges = get(fetchMoreResult, 'data.users.edges', []);
          const pageInfo = get(fetchMoreResult, 'data.users.pageInfo', {});
          if (pageInfo) {
            setAfterCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);
          }
          if (concat) {
            setUsers((prev) => [...prev, ...newEdges]);
          } else {
            setUsers(newEdges);
          }
        })
        .catch((error) => {
          throw new Error(`[GET_USERS]: ${error}`);
        })
        .finally(() => {
          setFetchingFreshUsers(false);
          setFetchingMoreUsers(false);
        });
    },
    [fetchMoreUsers, setUsers, setAfterCursor, setHasNextPage]
  );

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (
        target.isIntersecting &&
        !userListLoading &&
        !fetchingMoreUsers &&
        hasNextPage &&
        afterCursor
      ) {
        fetchMoreUsersWithFilters(
          {
            first: 10,
            after: afterCursor,
            fullName: searchUserName,
            roleId: userRoleFilter ? +userRoleFilter : null,
          },
          true
        );
      }
    },
    [
      userListLoading,
      fetchingMoreUsers,
      hasNextPage,
      afterCursor,
      fetchMoreUsersWithFilters,
      searchUserName,
      userRoleFilter,
    ]
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver, loader]);

  // Fetch fresh users whenever filters change
  useEffect(() => {
    setFetchingFreshUsers(true);
    setUsers([]);
    if (searchUserName) {
      setDebounceId(
        setTimeout(
          () =>
            fetchMoreUsersWithFilters(
              {
                first: 10,
                fullName: searchUserName,
                roleId: +userRoleFilter || null,
              },
              false
            ),
          1000
        )
      );
    } else {
      fetchMoreUsersWithFilters({ first: 10, roleId: +userRoleFilter || null }, false);
    }
  }, [searchUserName, fetchMoreUsersWithFilters, userRoleFilter]);

  const columns = useMemo(
    () => [
      {
        Header: 'User',
        accessor: 'name.name',
        Cell: (props) => (
          <div className="flex items-start flex-col gap-1">
            <div>
              <Link
                to="/dashboard/settings/users/view"
                className="vp-text-link-bold text-vartana-blue-60"
                state={{
                  selectedUser: props.row.original,
                }}
              >
                <Typography variant="paragraph14" bold>
                  {' '}
                  {formatCapital(props.row.original.name.name)}
                </Typography>
              </Link>
            </div>
            <Typography variant="paragraph12">{props.row.original.name.email}</Typography>
          </div>
        ),
      },
      {
        Header: 'Job title',
        accessor: 'jobTitle',
        Cell: (props) => (
          <Typography variant="paragraph14">
            {props.row.original.jobTitle || '--'}
          </Typography>
        ),
      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: (props) => (
          <Typography variant="paragraph14">
            {humanizeName(props.row.original.userRole.name) || '--'}
          </Typography>
        ),
      },
      {
        Header: 'Updated on',
        accessor: 'updateOn.formatted',
        Cell: (props) => <Typography variant="paragraph14"> {props.value}</Typography>,
      },
      {
        Header: '',
        accessor: 'resetPassword',
        Cell: (props) =>
          get(session, 'session.user.email', null) &&
          get(session, 'session.user.email', null) !== props.row.original.name.email && (
            <HasPermission resource="user" action="reset_password">
              <button onClick={() => handleResetPassword(props.row.original)}>
                <span className="vp-text-link-bold text-vartana-blue-60">
                  Reset password
                </span>
              </button>
            </HasPermission>
          ),
      },
    ],
    [session]
  );

  const data = useMemo(() => {
    return users
      .filter((edge) => edge.node)
      .map((edge) => {
        const user = edge.node;
        return {
          ...user,
          cursor: edge.cursor,
          name: {
            name: get(user, 'fullName', '--'),
            email: get(user, 'email', ''),
          },
          jobTitle: get(user, 'title', '--'),
          role: get(user, 'role', '--'),
          updateOn: {
            formatted: getUSDateFormat(get(user, 'updatedAt', '')),
            raw: Date.parse(get(user, 'updatedAt', '')) || '',
          },
        };
      });
  }, [users]);

  const sortBy = useMemo(
    () => [{ id: 'name.name' }, { id: 'role' }, { id: 'updateOn.raw' }],
    []
  );

  const infiniteLoaders = useCallback(() => {
    if (fetchingMoreUsers) return <Loader containerClassName="mt-10" />;
    if (hasNextPage) return <div ref={loader} />;
    return null;
  }, [fetchingMoreUsers, hasNextPage]);

  const mainBody = useMemo(() => {
    if (data.length) {
      return (
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-ml-6 lg:-ml-8">
            <div className="py-2 align-middle inline-block min-w-full sm:pl-6 lg:pl-8">
              <div className="overflow-hidden border-b border-gray-200 sm:rounded-none">
                <Table
                  cellClassName="px-8"
                  columns={columns}
                  data={data}
                  sortBy={sortBy}
                  getHeaderProps={() => ({
                    className:
                      'px-8 py-4 text-left text-xs font-medium text-vartana-gray-60 tracking-wider uppercase',
                  })}
                />
                {infiniteLoaders()}
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (fetchingFreshUsers)
      return <Loader containerClassName="absolute left-1/2 top-1/2" />;
    return (
      <div className="flex justify-center items-center w-full h-[calc(100%-10rem)]">
        <NoDataState />
      </div>
    );
  }, [columns, data, fetchingFreshUsers, infiniteLoaders, sortBy]);

  return (
    <AutoLoad loading={rolesLoading} containerClassName="absolute left-1/2 top-1/2">
      <div className="bg-white shadow rounded-none">
        <div className="px-4 py-5 sm:p-6">
          <div className="grid grid-cols-3 gap-6">
            <div>
              <InputField
                label="Search user"
                name="user-name"
                fullWidth
                prefixIcon={<SearchIcon />}
                onChange={(e) => {
                  clearTimeout(debounceId);
                  setSearchUserName(e.target.value);
                }}
              />
            </div>
            <HasPermission resource="role" action="view_all">
              <div>
                <DropdownInput
                  label="Filter by roles"
                  name="user-role"
                  value={userRoleFilter}
                  options={roles}
                  onChange={({ value }) => {
                    clearTimeout(debounceId);
                    setUserRoleFilter(+value);
                  }}
                />
              </div>
            </HasPermission>
          </div>
        </div>
      </div>
      {mainBody}
      <ResetUserPasswordModal
        open={isResetUserPasswordDialogOpen}
        selectedUser={{
          email: selectedUser.email,
          number: selectedUser.number,
        }}
        handleClose={() => setIsResetUserPasswordDialogOpenOpen(false)}
      />
    </AutoLoad>
  );
}

export default Users;
