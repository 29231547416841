import React from 'react';
import ReactDom from 'react-dom';

import PropTypes from 'prop-types';

function CommonFooter({ children }) {
  const commonFooterRoot = document.getElementById('common-footer-root');

  if (commonFooterRoot) {
    return ReactDom.createPortal(<>{children}</>, commonFooterRoot);
  }
  return null;
}

CommonFooter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
};

export default CommonFooter;
