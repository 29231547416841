export const UI_TEXT = {
  order: {
    order_completed: 'Notify user when an order is completed',
    order_countersignature: 'Notify user when a signature is required',
    order_approval_requested: 'Notify user when order approval is requested',
    order_approval_required: 'Notify user when order approval is required',
    order_query_forwarded: 'Notify user when an order query is raised',
    order_forwarded_confirmation: 'Notify user when an order is forwarded',
    order_canceled: 'Notify user when an order is canceled',
    order_ready: 'Notify user when an order link is sent',
    order_expired: 'Notify user when an order is expired',
    purchase_order_approval: 'Notify user when a PO approval is required',
  },
  credit_approval: {
    included_in_credit_decision: 'Notify user of credit decisions',
    included_in_need_more_information: 'Notify user when more information is needed',
    included_in_term_request: 'Notify user of Term request decisions',
  },
  payment: {
    payout: 'Notify user of payouts',
    payment_method: 'Notify user of payment method updates',
  },
  delivery_methods: {
    methods: 'Notify user by',
  },
};
