import PropTypes from 'prop-types';
import { Typography } from '@vartanainc/design-system';
import FormCheckbox from '../../../components/FormCheckboxV1';
import { UI_TEXT } from './constants';
import { LockedCheck } from '../../../components/FormCheckboxV1/LockedCheck';

const columnHeaders = {
  order: ['Order notifications', 'Assigned accounts', 'All accounts'],
  credit_approval: ['Credit approval notifications', 'Assigned accounts', 'All accounts'],
  payment: ['Payment notifications', 'Assigned accounts', 'All accounts'],
  delivery_methods: ['Deliver notifications by', 'Email', 'SMS'],
};

const deliveryMethods = 'delivery_methods';

export function NotificationSet({ category, items, editMode }) {
  let optionKeys;
  if (category === deliveryMethods) optionKeys = ['email', 'sms'];
  else optionKeys = ['specific', 'all'];

  // Gray out "Email" column header to match disabled checkbox
  const grayStyle = category === deliveryMethods && editMode ? 'color-gray-110' : '';

  return (
    <div className="flex flex-col gap-4 py-6">
      <header className="grid grid-cols-8 items-center">
        <b className="col-span-4">
          {' '}
          <Typography variant="heading16" bold color="color-black-100">
            {columnHeaders[category][0]}
          </Typography>
        </b>
        <b className="col-span-2 justify-self-center">
          <Typography variant="paragraph14" bold color={`${grayStyle}`}>
            {' '}
            {columnHeaders[category][1]}
          </Typography>
        </b>
        <b className="col-span-2 justify-self-center">
          <Typography variant="paragraph14" bold color="color-black-100">
            {' '}
            {columnHeaders[category][2]}{' '}
          </Typography>
        </b>
      </header>
      {Object.entries(items).map(([subcat, options]) => {
        return (
          <main key={`${category}-${subcat}`} className="grid grid-cols-8 items-center">
            <div className="col-span-4">
              <Typography variant="paragraph14" color="color-gray-160">
                {UI_TEXT[category][subcat]}
              </Typography>
            </div>

            {editMode ? (
              <>
                <FormCheckbox
                  name={`${category}.${subcat}.${optionKeys[0]}`}
                  containerClassName="col-span-2 justify-self-center"
                  disabled={Object.keys(options).includes('email')}
                />
                <FormCheckbox
                  name={`${category}.${subcat}.${optionKeys[1]}`}
                  containerClassName="col-span-2 justify-self-center"
                />
              </>
            ) : (
              <>
                <LockedCheck
                  isChecked={options[optionKeys[0]]}
                  containerClassName="col-span-2 justify-self-center"
                />
                <LockedCheck
                  isChecked={options[optionKeys[1]]}
                  containerClassName="col-span-2 justify-self-center"
                />
              </>
            )}
          </main>
        );
      })}
    </div>
  );
}

NotificationSet.propTypes = {
  category: PropTypes.string.isRequired,
  items: PropTypes.shape({}).isRequired,
  editMode: PropTypes.bool,
};

NotificationSet.defaultProps = {
  editMode: false,
};
