import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import { InputField } from '@vartanainc/design-system';
import { ReactComponent as TooltipIcon } from '../../assets/tooltip.svg';

const TextInputField = forwardRef(
  (
    {
      id,
      name,
      label,
      tooltipElement,
      noDecimal,
      type,
      disableFullStoryRecording,
      ...rest
    },
    ref
  ) => {
    const [field, meta] = useField(name);

    const handleKeyDown = (e) => {
      if (noDecimal && e.key === '.') {
        e.stopPropagation();
        e.preventDefault();
      }
    };

    return (
      <InputField
        {...field}
        {...rest}
        id={id}
        name={name}
        fullWidth
        label={label}
        onKeyDown={handleKeyDown}
        type={type}
        showError={meta.touched && meta.error}
        helperText={meta.error}
        tooltipIcon={tooltipElement ? <TooltipIcon /> : null}
        tooltipElement={tooltipElement}
        className={`${disableFullStoryRecording ? 'fs-mask' : ''}`}
        ref={ref}
      />
    );
  }
);

TextInputField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  tooltipElement: PropTypes.node,
  noDecimal: PropTypes.bool,
  type: PropTypes.string,
  disableFullStoryRecording: PropTypes.bool,
};

TextInputField.defaultProps = {
  id: '',
  name: '',
  placeholder: '',
  label: '',
  tooltipElement: null,
  noDecimal: false,
  type: 'text',
  disableFullStoryRecording: false,
};

export default TextInputField;
