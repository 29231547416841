import { cloneElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { twMerge } from 'tailwind-merge';

const Switch = ({ disabledTabs, selectedTabIndex, children }) => {
  const returnedChild = children.find((child, index) => {
    return index === selectedTabIndex && !disabledTabs.includes(selectedTabIndex);
  });
  return returnedChild || children[0];
};
Switch.propTypes = {
  selectedTabIndex: PropTypes.number.isRequired,
  disabledTabs: PropTypes.arrayOf(PropTypes.number).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
};

const tabClasses = (variant, mode) => {
  // Common to all
  const common = `text-vartana-black focus:outline-none cursor-pointer
    border-b-2 border-transparent hover:border-vartana-blue-60
    disabled:text-vartana-gray-40 disabled:border-0
  `;

  // Card
  const cardHeight = 'h-9 pb-4';

  // Widget
  const widgetHeight = 'h-9';

  // Expanded App
  const expandedHeight = 'py-6';

  const styles = {
    card: {
      container: 'border-b',
      default: twMerge(common, cardHeight, 'vp-body'),
      selected: twMerge(
        common,
        cardHeight,
        'vp-body-bold border-b-[3px] border-vartana-blue-60'
      ),
    },
    widget: {
      container: 'border-b',
      default: twMerge(common, widgetHeight, 'vp-p-small'),
      selected: twMerge(
        common,
        widgetHeight,
        'vartana-p-small-bold border-b-[3px] border-vartana-blue-60'
      ),
    },
    expanded: {
      container: 'border-y',
      default: twMerge(common, expandedHeight, 'vp-text-link'),
      selected: twMerge(
        common,
        expandedHeight,
        'vp-text-link-bold border-b-[3px] border-vartana-blue-60'
      ),
    },
  };
  return styles[variant][mode];
};

/*
  Use this when you need to switch tabs without changing route URL
*/
function Tabs({ children: tabs, tabLabels, disabledTabs, variant, preSelectedTabIndex }) {
  const [currentTabIndex, setCurrentTabIndex] = useState(preSelectedTabIndex);

  useEffect(() => {
    setCurrentTabIndex(preSelectedTabIndex);
  }, [preSelectedTabIndex]);

  useEffect(() => {
    if (disabledTabs.includes(currentTabIndex)) setCurrentTabIndex(0);
  }, [disabledTabs, currentTabIndex]);

  const getTabClass = (tabId) => {
    if (currentTabIndex === tabId) return tabClasses(variant, 'selected');
    return tabClasses(variant, 'default');
  };

  const setLastTabIndexActive = () => {
    setCurrentTabIndex(tabLabels.length - 1);
  };

  return (
    <>
      <div className={`flex items-center gap-6 mx-4 ${tabClasses(variant, 'container')}`}>
        {tabLabels.map((label, index) => (
          <button
            key={label}
            disabled={disabledTabs.includes(index)}
            onClick={() => setCurrentTabIndex(index)}
            className={getTabClass(index)}
          >
            {label}
          </button>
        ))}
      </div>

      <Switch selectedTabIndex={currentTabIndex} disabledTabs={disabledTabs}>
        {tabs.map((tab, idx) =>
          cloneElement(tab, {
            key: tabLabels[idx],
            setLastTabActive: setLastTabIndexActive,
          })
        )}
      </Switch>
    </>
  );
}

Tabs.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
    .isRequired,
  tabLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabledTabs: PropTypes.arrayOf(PropTypes.number),
  variant: PropTypes.oneOf(['vendor', 'widget', 'expanded', 'card']).isRequired,
  preSelectedTabIndex: PropTypes.number,
};

Tabs.defaultProps = {
  disabledTabs: [],
  preSelectedTabIndex: 0,
};

export default Tabs;
