import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Bar, defaults } from 'react-chartjs-2';

import { capitalize } from 'lodash';

function PaymentSchedule({ paymentsCollectedOverPredictedPayments }) {
  const data = useMemo(() => {
    const inp = paymentsCollectedOverPredictedPayments;
    return {
      labels: inp.map((i) => capitalize(i.month)),
      datasets: [
        {
          type: 'line',
          label: 'Predicted Payment',
          yAxisID: 'predicted_payment',
          fill: false,
          data: inp.map((i) => i.predicted_payment),
          backgroundColor: 'rgb(255, 187, 35)',
          borderColor: 'rgb(255, 187, 35)',
          borderWidth: 1,
          tension: 0.0,
        },
        {
          type: 'bar',
          label: 'Collected Payment',
          // yAxisID: 'collected_payment',
          data: inp.map((i) => i.collected_payment),
          backgroundColor: 'rgb(59, 137, 254)',
          borderColor: 'rgb(59, 137, 254)',
          borderWidth: 1,
        },
      ],
    };
  }, [paymentsCollectedOverPredictedPayments]);

  const options = {
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: defaults.global.defaultFontSize,
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem) => {
          return `$${tooltipItem.yLabel}`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
            tickColor: 'rgba(0, 0, 0, 0)',
            drawTicks: false,
            color: 'rgb(102, 102, 102)',
          },
          ticks: {
            padding: 10,
          },
        },
      ],
      yAxes: [
        {
          id: 'predicted_payment',
          display: true,
          position: 'left',
          type: 'linear',
          gridLines: {
            drawOnChartArea: false,
            drawTicks: false,
            color: 'rgb(102, 102, 102)',
          },
          ticks: {
            beginAtZero: true,
            padding: 10,
            autoSkip: false,
            maxTicksLimit: 6,
            callback: (value) => {
              return `$${value}`;
            },
          },
        },
      ],
    },
  };

  return (
    <>
      <Bar data={data} options={options} width={100} height={50} />
      <p className="vp-p-small font-bold text-center pt-4">Current Calendar Year</p>
    </>
  );
}

PaymentSchedule.propTypes = {
  paymentsCollectedOverPredictedPayments: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

PaymentSchedule.defaultProps = {
  paymentsCollectedOverPredictedPayments: [],
};

export default PaymentSchedule;
