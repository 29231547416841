import { gql } from '@apollo/client';

export const GET_PURCHASE_ORDERS = gql`
  query getPurchaseOrders($orderNumber: String!) {
    order(number: $orderNumber) {
      id
      purchaseOrders {
        recipient {
          name
        }
        formattedDocument {
          document
        }
      }
    }
  }
`;

export const REVIEW_PURCHASE_ORDERS = gql`
  mutation reviewPurchaseOrders(
    $orderNumber: String!
    $approved: Boolean!
    $purchaseOrderEditRequest: String
  ) {
    reviewPurchaseOrders(
      number: $orderNumber
      approved: $approved
      purchaseOrderEditRequest: $purchaseOrderEditRequest
    ) {
      state
    }
  }
`;
