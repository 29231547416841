import moment from 'moment';
import PropTypes from 'prop-types';
import { Button } from '@vartanainc/design-system';
import { humanTimeFormat } from '../../utils/helpers';

function AfterDeactivate({ integration, goForward }) {
  return (
    <div className="flex flex-col items-center p-4 bg-white rounded-lg">
      {/* Heading & Subheading */}
      <div className="h-24 px-10 py-6 self-stretch">
        <h1 className="text-vartana-black vp-card-title-bold">
          {integration.title} integration deactivated
        </h1>
        <p className="text-sm">
          Activate with {integration.title} integration again to import customers
          automatically.
        </p>
      </div>

      <div className="flex flex-col justify-between px-10 pt-6 h-96 self-stretch">
        {/* Logo & Title */}
        <div
          className={`
            flex flex-row justify-start items-center gap-5 py-7 
            border-t border-b border-vartana-gray-30
          `}
        >
          <div>
            <img src={integration.iconUrl} alt="Logo" width="50" height="50" />
          </div>
          <div className="flex flex-col">
            <div className="vp-card-title text-vartana-black">{integration.title}</div>
            <div className="italic font-normal text-xs leading-4 text-right text-gray-600">
              Deactivated: {humanTimeFormat(moment())}
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="flex flex-row justify-end items-center mt-20">
          <div>
            <Button onClick={goForward}>Great! Close window</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

AfterDeactivate.propTypes = {
  integration: PropTypes.shape({
    title: PropTypes.string.isRequired,
    iconUrl: PropTypes.string.isRequired,
  }).isRequired,
  goForward: PropTypes.func.isRequired,
};

export default AfterDeactivate;
