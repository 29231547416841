import { gql } from '@apollo/client';

export const INIT_PROGEN = gql`
  query initProgen(
    $crmOpportunityId: String
    $crmAccountId: String
    $customerNumber: String
    $orderNumber: String
  ) {
    proposalGenerator(
      crmOpportunityId: $crmOpportunityId
      orderNumber: $orderNumber
      crmAccountId: $crmAccountId
      customerNumber: $customerNumber
    )
  }
`;

export const CALCULATE_PROPOSED_PAYMENTS = gql`
  query calculateProposedPayments(
    $amount: Float!
    $paymentType: String!
    $companyNumber: String!
    $paymentTerm: Int
    $term: Int
    $billingFrequency: String
    $isDollar: Boolean
    $percentageBlindDiscount: Float
    $dollarBlindDiscount: Float
    $spiffRate: Float!
    $offeredBuyerInterestRate: Float
    $applyOfferedBuyerInterestRate: Boolean
  ) {
    calculateProposedPayments(
      amount: $amount
      paymentType: $paymentType
      companyNumber: $companyNumber
      paymentTerm: $paymentTerm
      term: $term
      billingFrequency: $billingFrequency
      isDollar: $isDollar
      percentageBlindDiscount: $percentageBlindDiscount
      dollarBlindDiscount: $dollarBlindDiscount
      spiffRate: $spiffRate
      offeredBuyerInterestRate: $offeredBuyerInterestRate
      applyOfferedBuyerInterestRate: $applyOfferedBuyerInterestRate
    )
  }
`;
