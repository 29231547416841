import { useCallback, useContext } from 'react';
import { get } from 'lodash';
import { useReactiveVar } from '@apollo/client';
import { PermissionsContext } from '../../context/DashboardContext';
import { humanizeName } from '../helpers';
import { sessionVar } from '../../graphql/cache';
import { BUYER_TYPE, SELLER_TYPE } from '../../constants/common.constants';

/**
 * Allow permission based on role & reseller permissions
 *
 * @param {object[array]} rolePermissions - Permissions coming from role
 * @param {object[array]} globalResellerPermissions - Permissions coming from reseller config
 * @param {string} sellerType - Either pure vendor, reseller or hybrid reseller
 * @param {string} resource - E.g. order, customer
 * @param {string} action - E.g. create, update, view, view_all
 * @param {object} vendor - To check vendor provided permissions for resellers
 * @returns {boolean} True if resource[action] is allowed
 */
export const combinedPermissionAllowed = (
  rolePermissions,
  globalResellerPermissions,
  sellerType,
  resource,
  action,
  vendor = null
) => {
  const isPermittedBy = (permissions) => get(permissions, resource, []).includes(action);
  let allowed;

  if (vendor) {
    allowed =
      isPermittedBy(rolePermissions) && isPermittedBy(vendor?.resellerPermissions);
  } else if (sellerType === SELLER_TYPE.RESELLER) {
    allowed = isPermittedBy(rolePermissions) && isPermittedBy(globalResellerPermissions);
  } else allowed = isPermittedBy(rolePermissions);

  return allowed;
};

/**
 * Returns helper methods & attributes regarding user permissions
 *
 * @returns {[object, function (string, string, object=): boolean, boolean, function(object, string, string): boolean]} [rolePermissions, hasRolePermission, isSuperAdmin, hasVendorPermission]
 */
function usePermissions() {
  const { rolePermissions, userRole, globalResellerPermissions } =
    useContext(PermissionsContext);

  const sessionData = useReactiveVar(sessionVar);
  const sellerType = get(sessionData, 'session.user.company.sellerType');

  /**
   * Check if current user has permission for given resource:action
   *
   * @param {string} resource - Singular name e.g. order, customer, user
   * @param {string} action - Action name e.g. create, update, view, view_all
   * @param {object=} customer - The selected customer
   * @param {string} customer.buyerRelationToVendor - Direct or indirect relation
   * @param {object} customer.seller - Customer's Vendor
   * @param {object} customer.seller.resellerPermissions - Reseller permissions by vendor
   * @return {boolean}
   */
  const hasPermission = useCallback(
    (resource, action, customer = null) => {
      return combinedPermissionAllowed(
        rolePermissions,
        globalResellerPermissions,
        sellerType,
        resource,
        action,
        customer?.buyerRelationToVendor === BUYER_TYPE.INDIRECT && customer?.seller
      );
    },
    [rolePermissions, globalResellerPermissions, sellerType]
  );

  /**
   * Check whether user this vendor has enabled reseller permission for resource[action]
   *
   * @param {Object} vendor - Company object
   * @param {string} resource - E.g. order, customer
   * @param {string} action - E.g. create, view
   * @returns {boolean}
   */
  const hasVendorPermission = (vendor, resource, action) => {
    const vendorPermissions = get(vendor, 'resellerPermissions', {});

    return vendorPermissions[resource]?.includes(action);
  };

  const isSuperAdmin = humanizeName(userRole?.name) === 'Super admin';

  return [rolePermissions, hasPermission, isSuperAdmin, hasVendorPermission];
}

export default usePermissions;
