import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { useMutation } from '@apollo/client';
import * as yup from 'yup';
import { get, isEmpty } from 'lodash';
import { FormikProvider, useFormik } from 'formik';
import { Typography, Button } from '@vartanainc/design-system';

import { ReactComponent as LogoIcon } from '../../assets/logo.svg';
import loginBg from '../../assets/desktop-sign-in.svg';
import { ReactComponent as EyeIcon } from '../../assets/eye-icon.svg';
import { ReactComponent as EyeIconSlash } from '../../assets/eye-icon-slash.svg';

import { RESET_PASSWORD } from '../../graphql/queries/profile';
import { useQueryParams } from '../../utils/hooks';
import { showToast } from '../../utils/helpers';
import AutoLoad from '../../components/AutoLoad';
import { handleSignIn } from '../../api/utils';
import TextInputField from '../../designSystem/TextInput/TextInputField';
import MarketingText from './MarketingText';
import {
  commonRegex,
  FIELD_INVALID_MESSAGES,
  FIELD_REQUIRED_MSG,
  PASSWORD_ERRORS,
} from '../../constants/common.constants';
import { passwordFieldValidation } from '../../utils/validators';
import {
  getConditionIcon,
  getTextColor,
  handlePasswordValidation,
} from '../../utils/formUtils';

const ResetPasswordFormSchema = yup.object().shape({
  newPassword: passwordFieldValidation,
  confirmPassword: yup
    .string()
    .required(FIELD_REQUIRED_MSG)
    .oneOf([yup.ref('newPassword'), null], 'Password does not match'),
});

const ActivateAccountFormSchema = yup.object().shape({
  firstName: yup
    .string()
    .required(FIELD_REQUIRED_MSG)
    .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.firstName),
  lastName: yup
    .string()
    .required(FIELD_REQUIRED_MSG)
    .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.lastName),
});

const MODE = {
  reset: 'reset',
  activate: 'activate',
};

const ResetPassword = ({ mode }) => {
  const token = useQueryParams().get('token');
  const userFirstName = useQueryParams().get('user_first_name');
  const userLastName = useQueryParams().get('user_last_name');
  const isInviteTeamFlow = userFirstName === '' && userLastName === '';

  const newPassRef = useRef();
  const confirmPassRef = useRef();

  const [resetPassword, { loading: resettingPassword }] = useMutation(RESET_PASSWORD);
  const [revealNewPassword, setRevealNewPassword] = useState(false);
  const [revealConfirmPassword, setRevealConfirmPassword] = useState(false);
  const [serverTimer, setServerTimer] = useState(false);

  const [passwordConditions, setPasswordConditions] = useState({
    lengthCondition: false,
    numberCondition: false,
    letterCaseCondition: false,
  });
  const [showPasswordHints, setShowPasswordHints] = useState(false);
  const [showRedHints, setShowRedHints] = useState(false);

  const onSubmit = async ({ firstName, lastName, newPassword, confirmPassword }) => {
    if (
      passwordConditions.lengthCondition &&
      passwordConditions.letterCaseCondition &&
      passwordConditions.numberCondition
    )
      try {
        const variables = {
          newPassword,
          confirmPassword,
          token,
          mode,
        };

        if (isInviteTeamFlow) {
          variables.firstName = firstName;
          variables.lastName = lastName;
        }

        const responseData = await resetPassword({
          variables,
        });

        setServerTimer(true);
        setTimeout(() => {
          if (serverTimer)
            showToast(
              'error',
              'Something went wrong. Please try again in a few moments.'
            );
        }, 3000);

        const errors = get(responseData, 'errors', {});
        if (isEmpty(errors)) {
          setServerTimer(false);
          const email = get(responseData, 'data.resetPasswordConfirm.email', '');
          const result = await handleSignIn({ email, password: newPassword });
          showToast('success', 'Your account password has been updated.');
          if (result.success) window.location.href = '/dashboard';
        } else {
          showToast(
            'error',
            'Something went wrong. Please check your internet connection and try again.'
          );
        }
      } catch (e) {
        console.error('Reset Password Error: ', e);
      }
  };

  const formikBag = useFormik({
    initialValues: { firstName: '', lastName: '', newPassword: '', confirmPassword: '' },
    validationSchema: isInviteTeamFlow
      ? ResetPasswordFormSchema.concat(ActivateAccountFormSchema)
      : ResetPasswordFormSchema,
    onSubmit: async (...args) => {
      const [values] = args;
      await onSubmit(values);
    },
  });
  const { isSubmitting, handleSubmit } = formikBag;

  const handleSubmitClick = () => {
    if (
      !passwordConditions.lengthCondition ||
      !passwordConditions.letterCaseCondition ||
      !passwordConditions.numberCondition
    ) {
      newPassRef.current.focus();
    } else if (
      (formikBag.errors && formikBag.errors?.confirmPassword) ||
      formikBag.values?.confirmPassword === ''
    ) {
      confirmPassRef.current.focus();
    }
  };

  useEffect(() => {
    handlePasswordValidation(
      formikBag.errors?.newPassword,
      formikBag.values?.newPassword,
      setPasswordConditions
    );
  }, [formikBag.errors?.newPassword, formikBag.values?.newPassword]);

  const onBlurPassword = () => {
    if (
      passwordConditions.lengthCondition &&
      passwordConditions.letterCaseCondition &&
      passwordConditions.numberCondition
    ) {
      setShowPasswordHints(false);
    } else {
      setShowRedHints(true);
    }
  };

  const getCardheading = () => {
    if (isInviteTeamFlow) {
      return 'Get started with Vartana';
    }
    if (mode === MODE.reset) {
      return 'Reset account password';
    }
    return 'Create account password';
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      <img src={loginBg} alt="" className="absolute inset-0 h-full w-full object-cover" />
      <div className="w-full absolute flex justify-center lg:inset-0">
        <div className="px-8 py-8 flex flex-1 flex-col max-w-7xl gap-14 lg:py-16 lg:px-24 md:px-16">
          <LogoIcon className="h-auto w-40" />
          <div className="flex flex-col h-full justify-center gap-8 items-center lg:flex-row ">
            <div className="flex grow-0 w-full justify-center">
              <MarketingText />
            </div>
            <div className="w-full bg-vartana-blue-100 rounded-b-lg rounded-t pt-1 max-w-lg h-auto grow-0 shadow-2xl lg:w-auto">
              <div className="w-full h-full bg-white rounded-b-lg ">
                <div className="flex justify-center p-10">
                  <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div className="">
                      <Typography variant="heading24" bold color="color-blue-180">
                        {getCardheading()}
                      </Typography>
                      <div className="mt-10">
                        <FormikProvider value={formikBag}>
                          <form className="" onSubmit={handleSubmit}>
                            <div className="flex flex-col">
                              {isInviteTeamFlow && (
                                <div className="flex gap-2 mb-4">
                                  <TextInputField
                                    name="firstName"
                                    label="First name"
                                    type="text"
                                    fullWidth
                                  />
                                  <TextInputField
                                    name="lastName"
                                    label="Last name"
                                    type="text"
                                    fullWidth
                                  />
                                </div>
                              )}
                              <TextInputField
                                name="newPassword"
                                label="New password"
                                type={revealNewPassword ? 'text' : 'password'}
                                disableFullStoryRecording
                                autoComplete="new-password"
                                id="newPassword"
                                ref={newPassRef}
                                suffixIcon={
                                  revealNewPassword ? (
                                    <EyeIconSlash className="h-5 w-auto object-contain" />
                                  ) : (
                                    <EyeIcon className="h-5 w-auto object-contain" />
                                  )
                                }
                                onFocus={() => setShowPasswordHints(true)}
                                onBlur={() => onBlurPassword()}
                                suffixIconFunc={() =>
                                  setRevealNewPassword(!revealNewPassword)}
                                fullWidth
                              />

                              <div
                                className={`transition-all ease-in delay-200 duration-500 ${
                                  showPasswordHints
                                    ? 'opacity-100 h-12 mt-2'
                                    : 'opacity-0 h-0 mt-0'
                                }`}
                              >
                                <div className="flex gap-1 items-center">
                                  {getConditionIcon(
                                    passwordConditions.lengthCondition,
                                    showRedHints
                                  )}
                                  <Typography
                                    variant="paragraph10"
                                    color={getTextColor(
                                      'isLengthConditionMet',
                                      passwordConditions,
                                      showRedHints
                                    )}
                                  >
                                    {PASSWORD_ERRORS.lengthError}
                                  </Typography>
                                </div>
                                <div className="flex gap-1 items-center">
                                  {getConditionIcon(
                                    passwordConditions.letterCaseCondition,
                                    showRedHints
                                  )}
                                  <Typography
                                    variant="paragraph10"
                                    color={getTextColor(
                                      'isLetterCaseConditionMet',
                                      passwordConditions,
                                      showRedHints
                                    )}
                                  >
                                    {PASSWORD_ERRORS.casingError}
                                  </Typography>
                                </div>
                                <div className="flex gap-1 items-center">
                                  {getConditionIcon(
                                    passwordConditions.numberCondition,
                                    showRedHints
                                  )}
                                  <Typography
                                    variant="paragraph10"
                                    color={getTextColor(
                                      'isNumberConditionMet',
                                      passwordConditions,
                                      showRedHints
                                    )}
                                  >
                                    {PASSWORD_ERRORS.numberError}
                                  </Typography>
                                </div>
                              </div>

                              <div className="mt-4">
                                <TextInputField
                                  name="confirmPassword"
                                  label="Confirm password"
                                  type={revealConfirmPassword ? 'text' : 'password'}
                                  disableFullStoryRecording
                                  autoComplete="new-password"
                                  id="confirmPassword"
                                  ref={confirmPassRef}
                                  suffixIcon={
                                    revealConfirmPassword ? (
                                      <EyeIconSlash className="h-5 w-auto object-contain" />
                                    ) : (
                                      <EyeIcon className="h-5 w-auto object-contain" />
                                    )
                                  }
                                  suffixIconFunc={() =>
                                    setRevealConfirmPassword(!revealConfirmPassword)}
                                  fullWidth
                                  onPaste={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              </div>

                              <div className="flex flex-row justify-center mt-6">
                                <AutoLoad loading={isSubmitting}>
                                  <Button
                                    type="submit"
                                    disabled={resettingPassword}
                                    onClick={handleSubmitClick}
                                    size="x-small"
                                    BackgroundColor="primary"
                                  >
                                    {mode === MODE.activate
                                      ? 'Activate account'
                                      : 'Reset password'}
                                  </Button>
                                </AutoLoad>
                              </div>
                            </div>
                          </form>
                        </FormikProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  mode: PropTypes.oneOf(Object.values(MODE)),
};

ResetPassword.defaultProps = {
  mode: MODE.reset,
};

export default ResetPassword;
