import PropTypes from 'prop-types';
import { Form, useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { get } from 'lodash';

import { Card, ButtonV2 } from '@vartanainc/design-system';
import { ReactComponent as UserBadgeIcon } from '../../assets/user_badge.svg';
import { ReactComponent as EyeIcon } from '../../assets/eye-icon.svg';
import { ReactComponent as EyeIconSlash } from '../../assets/eye-icon-slash.svg';
import AutoLoad from '../../components/AutoLoad';
import {
  commonRegex,
  FIELD_INVALID_MESSAGES,
  PASSWORD_ERRORS,
  phoneFieldValidation,
} from '../../constants/common.constants';
import CustomNotification from '../../components/CustomNotification';
import { UPDATE_PROFILE_DETAILS } from '../../graphql/mutations';
import TextInputField from '../../designSystem/TextInput/TextInputField';
import PatternInput from '../../designSystem/PatternInput/PatternInput';
import { ConfirmPasswordSchema } from '../../formikSchema/commonSchema';
import { passwordFieldValidation } from '../../utils/validators';
import {
  handlePasswordValidation,
  getConditionIcon,
  getConditionText,
} from '../../utils/formUtils';

const ProfileDetailsFormSchema = yup.object().shape({
  id: yup.number().nullable(),
  firstName: yup
    .string()
    .nullable()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required')
    .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.firstName),
  lastName: yup
    .string()
    .nullable()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('This field is required')
    .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.lastName),
  email: yup
    .string()
    .nullable()
    .typeError('Invalid email')
    .email('Invalid email')
    .required('This field is required'),
  title: yup.string().nullable(),
  role: yup.string().nullable(),
  phone: phoneFieldValidation,
  password: yup.lazy(() =>
    yup
      .string()
      .nullable()
      .when(['password'], {
        is: (val) => val !== undefined,
        then: passwordFieldValidation,
      })
  ),
  confirmPassword: yup.lazy(() =>
    yup
      .string()
      .nullable()
      .when(['password'], {
        is: (val) => val && val.length > 0,
        then: ConfirmPasswordSchema,
      })
  ),
});

export const initialProfileFormValues = {
  id: '',
  firstName: '',
  lastName: '',
  title: '',
  email: '',
  phone: '',
  role: '',
  password: '',
  confirmPassword: '',
};

function EditUserProfile({ onSuccess, onCancelClicked, selectedUser }) {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState(initialProfileFormValues);
  const [passwordConditions, setPasswordConditions] = useState({
    lengthCondition: false,
    numberCondition: false,
    letterCaseCondition: false,
  });
  const [showPasswordHints, setShowPasswordHints] = useState(false);
  const [showRedHints, setShowRedHints] = useState(false);
  const [revealPassword, setRevealPassword] = useState(false);
  const [revealConfirmPassword, setRevealConfirmPassword] = useState(false);

  const passwordRef = useRef();

  const [updateProfileDetails] = useMutation(UPDATE_PROFILE_DETAILS);

  useEffect(() => {
    setInitialFormValues({
      ...initialProfileFormValues,
      ...selectedUser,
      role: get(selectedUser, 'userRole.name') || '',
    });
  }, [selectedUser]);

  const handleSubmit = (values, { setErrors }) => {
    setIsFormSubmitting(true);
    updateProfileDetails({
      variables: {
        ...values,
        password: values.password || null,
      },
    })
      .then((response) => {
        const errors = get(response, 'errors', {});
        if (Object.keys(errors).length) {
          setErrors(errors);
        } else {
          toast.success(({ toastProps }) => {
            return (
              <CustomNotification
                type={toastProps.type}
                message="User details are updated successfully."
              />
            );
          });
          onSuccess();
        }
      })
      .catch((error) => {
        toast.warning(({ toastProps }) => {
          return (
            <CustomNotification type={toastProps.type} message="Something went wrong." />
          );
        });
        throw new Error(`Mutation: UPDATE_PROFILE_DETAILS, Error: ${error}`);
      })
      .finally(() => setIsFormSubmitting(false));
  };

  const formikBag = useFormik({
    initialValues: initialFormValues,
    validationSchema: ProfileDetailsFormSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const onBlurPassword = () => {
    if (
      passwordConditions.lengthCondition &&
      passwordConditions.letterCaseCondition &&
      passwordConditions.numberCondition
    ) {
      setShowPasswordHints(false);
    } else {
      setShowRedHints(true);
    }
  };

  useEffect(() => {
    handlePasswordValidation(
      formikBag.errors?.password,
      formikBag.values?.password,
      setPasswordConditions
    );
  }, [formikBag.errors?.password, formikBag.values?.password]);

  return (
    <FormikProvider value={formikBag}>
      <Form>
        <Card
          tabIndex={0}
          variant="fullWidth"
          title=" Edit user details"
          icon={<UserBadgeIcon />}
          content={(
            <>
              <div className="grid grid-cols-2 gap-4">
                <TextInputField name="firstName" label="First name" />
                <TextInputField name="lastName" label="Last name" />
                <TextInputField name="email" label="Email / User ID" disabled />
                <PatternInput name="phone" label="Phone" mask="_" />
                <TextInputField name="title" label="Job title" />
                <TextInputField name="role" label="Role" disabled />
                <div>
                  <TextInputField
                    name="password"
                    type={revealPassword ? 'text' : 'password'}
                    label="Reset password"
                    ref={passwordRef}
                    suffixIcon={
                      revealPassword ? (
                        <EyeIconSlash className="h-5 w-auto object-contain" />
                      ) : (
                        <EyeIcon className="h-5 w-auto object-contain" />
                      )
                    }
                    onFocus={() => setShowPasswordHints(true)}
                    onBlur={() => onBlurPassword()}
                    suffixIconFunc={() => setRevealPassword(!revealPassword)}
                    disableFullStoryRecording
                  />
                  <div
                    className={`transition-all ease-in delay-200 duration-500 ${
                      showPasswordHints ? 'opacity-100 h-12 mt-2' : 'opacity-0 h-0 mt-0'
                    }`}
                  >
                    <div className="flex gap-1 items-center">
                      {getConditionIcon(passwordConditions.lengthCondition, showRedHints)}
                      {getConditionText(
                        'isLengthConditionMet',
                        passwordConditions,
                        showRedHints,
                        PASSWORD_ERRORS.lengthError
                      )}
                    </div>
                    <div className="flex gap-1 items-center">
                      {getConditionIcon(
                        passwordConditions.letterCaseCondition,
                        showRedHints
                      )}
                      {getConditionText(
                        'isLetterCaseConditionMet',
                        passwordConditions,
                        showRedHints,
                        PASSWORD_ERRORS.casingError
                      )}
                    </div>
                    <div className="flex gap-1 items-center">
                      {getConditionIcon(passwordConditions.numberCondition, showRedHints)}
                      {getConditionText(
                        'isNumberConditionMet',
                        passwordConditions,
                        showRedHints,
                        PASSWORD_ERRORS.numberError
                      )}
                    </div>
                  </div>
                </div>
                <TextInputField
                  name="confirmPassword"
                  type={revealConfirmPassword ? 'text' : 'password'}
                  label="Confirm password"
                  suffixIcon={
                    revealConfirmPassword ? (
                      <EyeIconSlash className="h-5 w-auto object-contain" />
                    ) : (
                      <EyeIcon className="h-5 w-auto object-contain" />
                    )
                  }
                  suffixIconFunc={() => setRevealConfirmPassword(!revealConfirmPassword)}
                  disableFullStoryRecording
                  onPaste={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
              <div className="flex flex-row justify-between mt-8">
                <ButtonV2
                  variant={{ type: 'ghost', typography: 'paragraph14' }}
                  type="button"
                  text="Cancel"
                  iconLeft="chevron_left"
                  onClick={() => onCancelClicked()}
                />
                <AutoLoad loading={isFormSubmitting}>
                  <ButtonV2
                    type="submit"
                    disabled={!formikBag.isValid || !formikBag.dirty}
                    text="Submit"
                    variant={{ type: 'primary', typography: 'paragraph14' }}
                  />
                </AutoLoad>
              </div>
            </>
          )}
        >
        </Card>
      </Form>
    </FormikProvider>
  );
}

EditUserProfile.propTypes = {
  onSuccess: PropTypes.func,
  onCancelClicked: PropTypes.func,
  selectedUser: PropTypes.shape({}),
};

EditUserProfile.defaultProps = {
  onCancelClicked: () => {},
  onSuccess: () => {},
  selectedUser: {},
};

export default EditUserProfile;
