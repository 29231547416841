import PropTypes from 'prop-types';
import { useOutletContext } from 'react-router-dom';

import Summary from '../Summary/indexV3';
import TrackStatus from '../TrackStatus/TrackStatus';

/*
  For Vendor Dashboard
*/
export const OrderTabs = ({ tab }) => {
  const { orderShowData } = useOutletContext();

  if (tab === 'summary') {
    return <Summary orderData={orderShowData} />;
  }
  if (tab === 'track') {
    return <TrackStatus orderData={orderShowData} />;
  }
  return null;
};

OrderTabs.propTypes = {
  tab: PropTypes.oneOf(['summary', 'track']).isRequired,
};
