import { useEffect, useState } from 'react';
import { Button, Typography } from '@vartanainc/design-system';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { REVIEW_PURCHASE_ORDERS } from './queries';
import { CrossButton } from '../../../../components/Button/CrossButton';
import Loader from '../../../../components/Loader';
import ErrorPage from '../../../Error';
import { mergePDFs, reportError, showToast } from '../../../../utils/helpers';

export function ViewPurchaseOrders({ purchaseOrderDocs, orderNumber, onClose, onEdit }) {
  const [mergedPDF, setMergedPDF] = useState('');
  const [error, setError] = useState('');

  const [approvePurchaseOrders, { loading }] = useMutation(REVIEW_PURCHASE_ORDERS, {
    variables: { orderNumber, approved: true },
    refetchQueries: ['getOrderByNumber', 'getOrderBy'],
    onCompleted: () => {
      showToast(
        'success',
        'Purchase orders are approved and will be issued to companies receiving payouts.'
      );
      onClose();
    },
  });

  // Merge all Purchase Orders into single PDF
  useEffect(() => {
    mergePDFs(purchaseOrderDocs, 'Purchase Orders')
      .then((combinedPDF) => setMergedPDF(combinedPDF))
      .catch((e) => {
        reportError(`Invalid POs for order# ${orderNumber}: ${e.message}`);
        setError('Error in parsing documents');
      });
  }, [orderNumber, purchaseOrderDocs]);

  return (
    <>
      <header className="sticky px-10 py-8 w-full h-28 bg-white flex justify-between">
        <CrossButton onClick={onClose} />
        <div className="flex flex-col gap-2">
          <Typography variant="heading24" color="color-black-100">
            Review purchase orders
          </Typography>
          <Typography variant="paragraph10" color="color-black-100" bold>
            Order# {orderNumber}
          </Typography>
        </div>

        <div className="flex gap-4 mr-6">
          <Button variant="outlined" backgroundColor="secondary" onClick={onEdit}>
            Request to edit
          </Button>
          <Button disabled={loading} onClick={approvePurchaseOrders}>
            {loading ? <Loader className="w-8 h-8" /> : 'Approve order'}
          </Button>
        </div>
      </header>
      <main className="relative h-full bg-vartana-gray-70 flex justify-center">
        {error ? (
          <ErrorPage className="w-5/6" />
        ) : (
          <iframe title="PO Docs" src={mergedPDF} className="w-full h-full" />
        )}
      </main>
    </>
  );
}

ViewPurchaseOrders.propTypes = {
  purchaseOrderDocs: PropTypes.arrayOf(PropTypes.string).isRequired,
  orderNumber: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};
