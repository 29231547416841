import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { isEmpty, pick } from 'lodash';
import { Card, Typography } from '@vartanainc/design-system';
import { useFormikContext } from 'formik';
import { FIRST_NAME_PREFILL_FIELD_PLACEHOLDER } from '../../../constants/common.constants';
import DangerousTooltip from '../../../components/DangerousTooltip';
import TextInputField from '../../../designSystem/TextInput/TextInputField';
import PatternInput from '../../../designSystem/PatternInput/PatternInput';
import TextAreaInput from '../../../designSystem/TextAreaInput/TextAreaInput';
import FreeTextDropdownInput from '../../../designSystem/FreeTextDropdownInput/FreeTextDropdownInput';
import { fetchJobTitles, titleize, reportError } from '../../../utils/helpers';
import { ReactComponent as InfoIcon } from '../../../assets/info_icon.svg';
import NewCustomerAddress from '../NewCustomerAddress/NewCustomerAddress';
import AuthUserSearchField from '../../../components/AuthUserSearchField/AuthUserSearchField';

function AuthorizedSigner({
  disableEdit,
  customerName,
  phone,
  showCustomerAddressForm,
  customerNumber = '',
}) {
  const [jobTitles, setJobTitles] = useState([]);
  const formikBag = useFormikContext();
  const fieldNamesPrefix = 'authorizedSigner';

  // Job titles for authorized signer form - avoid refetching when going back & forth
  useEffect(() => {
    const titles = JSON.parse(sessionStorage.getItem('job-titles'));

    if (isEmpty(titles)) {
      fetchJobTitles()
        .then((response) => {
          setJobTitles(response.titles);
          sessionStorage.setItem('job-titles', JSON.stringify(response.titles));
        })
        .catch((error) => reportError(error.message, error));
    } else {
      setJobTitles(titles);
    }
  }, []);

  const setContactValues = (formattedContact) => {
    const { title, ...rest } = formattedContact;
    const updatedValues = {
      ...formikBag.values.authorizedSigner,
      ...rest,
      ...((title || title === '') && { jobTitle: title }),
    };
    formikBag
      .setFieldValue(
        fieldNamesPrefix,
        pick(updatedValues, ['firstName', 'lastName', 'jobTitle', 'email', 'phone'])
      )
      .then(() => formikBag.validateForm()); // Set the field value without title
  };

  const signerFirstName = useMemo(() => {
    return formikBag?.values?.authorizedSigner?.firstName;
  }, [formikBag?.values?.authorizedSigner?.firstName]);

  return (
    <div className="grid grid-cols w-full">
      <Card
        tabindex={0}
        variant="fullWidth"
        content={(
          <>
            {showCustomerAddressForm && <NewCustomerAddress />}
            <div className="flex gap-1 items-center pb-2">
              <Typography variant="heading18" bold>
                Authorized signer
              </Typography>
              <DangerousTooltip
                content={(
                  <Typography variant="paragraph12">
                    A person who can make financial decisions on behalf of the company
                  </Typography>
                )}
                icon={<InfoIcon />}
              />
            </div>
            <Typography variant="paragraph14" color="color-black-100" className="vp-body">
              {`The person below is responsible for signing agreements on behalf of ${titleize(
                customerName
              )}.`}
            </Typography>

            <div className="flex flex-col pt-6">
              <div className="space-y-4">
                <div className="flex flex-row space-x-4">
                  <AuthUserSearchField
                    name="authorizedSigner.firstName"
                    label="First name"
                    onSelectOption={setContactValues}
                    value={signerFirstName}
                    placeHolder={FIRST_NAME_PREFILL_FIELD_PLACEHOLDER.default}
                    companyNumber={customerNumber}
                    disabled={disableEdit}
                  />

                  <TextInputField
                    id="authorizedSigner.lastName"
                    name="authorizedSigner.lastName"
                    label="Last name"
                    disabled={disableEdit}
                  />
                </div>
                <div className="flex flex-row space-x-4">
                  <FreeTextDropdownInput
                    disabled={disableEdit}
                    id="authorizedSigner.jobTitle"
                    name="authorizedSigner.jobTitle"
                    label="Job title"
                    options={jobTitles}
                  />
                </div>
                <div className="flex flex-row space-x-4">
                  <TextInputField
                    id="authorizedSigner.email"
                    name="authorizedSigner.email"
                    label="Email"
                    type="email"
                    placeholder="example@email.com"
                    disabled={disableEdit}
                  />
                  <PatternInput
                    id="authorizedSigner.phone"
                    name="authorizedSigner.phone"
                    label="Phone"
                    mask="_"
                    disableFullStoryRecording
                    disabled={disableEdit}
                    value={phone || formikBag.values.authorizedSigner.phone}
                  />
                </div>
                <div className="flex flex-row space-x-4">
                  <TextAreaInput
                    id="message"
                    name="message"
                    label="Message for signer (optional)"
                    placeholder="Include a note for the contract recipient..."
                  />
                </div>
              </div>
            </div>
          </>
        )}
      >
      </Card>
    </div>
  );
}

AuthorizedSigner.defaultProps = {
  disableEdit: false,
  showCustomerAddressForm: false,
  customerNumber: '',
};

AuthorizedSigner.propTypes = {
  disableEdit: PropTypes.bool,
  customerName: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  showCustomerAddressForm: PropTypes.bool,
  customerNumber: PropTypes.string,
};

export default AuthorizedSigner;
