import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { get, isEmpty } from 'lodash';

import { Typography } from '@vartanainc/design-system';

import { sessionVar } from '../../graphql/cache';
import LoginBg from '../../assets/desktop-sign-in.svg';
import { ReactComponent as LogoIcon } from '../../assets/logo.svg';

const TalkToUs = (): JSX.Element => {
  const session = useReactiveVar(sessionVar);

  const { show: showIntercom, boot: bootIntercom } = useIntercom();
  const [queryParams] = useSearchParams();
  const showIntercomWindow = queryParams.get('help') === 'true';

  useEffect(() => {
    const userSession = get(session, 'session.user', {});
    if (!isEmpty(userSession)) {
      bootIntercom({
        name: get(userSession, 'fullName', ''),
        email: get(userSession, 'email', ''),
        customAttributes: {
          application: 'vendor-app',
          resourceId: get(userSession, 'id', -1),
          resourceType: 'User',
        },
      });
      if (showIntercomWindow) showIntercom();
    }
  }, [session, showIntercomWindow, bootIntercom, showIntercom]);

  return (
    <div className="min-h-screen bg-gray-50 flex">
      <img src={LoginBg} alt="" className="absolute inset-0 h-full w-full object-cover" />
      <div className="absolute flex justify-center lg:inset-0">
        <div className="px-8 py-8 flex flex-1 flex-col max-w-7xl gap-14 lg:py-16 lg:px-24 md:px-16">
          <LogoIcon className="h-auto w-40" />
          <div className="flex flex-col h-full justify-start gap-8 items-center lg:flex-row">
            <div className="flex grow-0 w-full max-w-[33.25rem]">
              <div className="align-middle flex flex-col justify-center mb-9">
                <div className="mb-9 max-w-[52ch]">
                  <Typography variant="heading55" family="Merriweather">
                    Talk to us!
                  </Typography>
                </div>
                <div className="flex gap-4 flex-col">
                  <Typography variant="paragraph18">
                    Welcome to the Vartana Help Desk. Message us in the chat window with
                    your inquiries, and a team member will be in touch shortly.
                  </Typography>
                  <Typography variant="paragraph18">
                    You can also contact us at{' '}
                    <Typography variant="paragraph18" color="color-blue-120">
                      <a href="mailto:support@vartana.com">support@vartana.com</a>
                    </Typography>
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TalkToUs;
