import PropTypes from 'prop-types';
import InfoModal from './InfoModal';

function PayByInvoiceModal({ isOpen, onClose, onSubmit }) {
  return (
    <InfoModal
      title="Additional fees may apply"
      confirmButtonText="I accept"
      description={(
        <>
          <p>
            If the buyer is approved to pay by invoice, they will default to Net-30
            payment terms. This change may result in a higher processing fee for you or
            the buyer. Do you accept the additional processing fee?
          </p>
          <br />
          <p>If you have any questions, please reach out to support@vartana.com.</p>
        </>
      )}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
    >
    </InfoModal>
  );
}

PayByInvoiceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PayByInvoiceModal;
