import { Typography } from '@vartanainc/design-system';
import { ReactComponent as CartIcon } from '../../assets/cart.svg';

export function OrderHeading() {
  return (
    <div className="rounded-lg flex gap-2 items-center">
      <CartIcon />
      <Typography variant="heading20" color="color-black-100">
        This order is for:
      </Typography>
    </div>
  );
}
