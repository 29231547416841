import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Card, Typography } from '@vartanainc/design-system';
import React from 'react';
import ResellerSection from './ResellerSection';
import { ReactComponent as HomeWorkIcon } from '../../../../assets/home_work-blue.svg';

function ResellersCard({ resellers }) {
  if (isEmpty(resellers)) return null;

  return (
    <Card
      variant="fullWidth"
      icon={<HomeWorkIcon />}
      title={(
        <Typography variant="heading18" bold color="color-black-100">
          Partner details
        </Typography>
      )}
      content={(
        <main className="divide-y divide-vartana-gray-40-v3">
          {resellers.map((reseller, index) => (
            <ResellerSection
              key={reseller.number}
              reseller={reseller}
              count={index + 1}
              showCount={resellers.length !== 1}
            />
          ))}
        </main>
      )}
    />
  );
}

ResellersCard.propTypes = {
  resellers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ResellersCard;
