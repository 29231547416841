import React from 'react';
import PropTypes from 'prop-types';

import ActionRequired from './index';

function ApprovalRequired({
  onPrimaryClick,
  onSecondaryClick,
  secondaryClickAllowed,
  timeStamp,
  isLoading,
}) {
  return (
    <ActionRequired
      title="Order approval"
      message="Your approval is needed before we send order link. If you see a problem, you can edit an order or cancel it."
      onPrimaryClick={onPrimaryClick}
      onSecondaryClick={secondaryClickAllowed ? onSecondaryClick : () => {}}
      primaryButtonText="Approve order"
      secondaryButtonText={secondaryClickAllowed ? 'Edit order' : ''}
      secondaryMessage={timeStamp}
      isLoading={isLoading}
    />
  );
}

ApprovalRequired.propTypes = {
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func,
  secondaryClickAllowed: PropTypes.bool.isRequired,
  timeStamp: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

ApprovalRequired.defaultProps = {
  onSecondaryClick: null,
  timeStamp: null,
};

export default ApprovalRequired;
