import { useOutletContext } from 'react-router-dom';
import { Card } from '@vartanainc/design-system';
import { get } from 'lodash';
import { ReactComponent as StoreFrontIcon } from '../../assets/storefront-32.svg';
import { labelValueField } from '../../constants/common.constants';
import { InviteStatusPill } from '../../components/Pill/InviteStatusPill';
import { titleize, getUSDateFormat } from '../../utils/helpers';

export function ResellerProfile() {
  const { reseller } = useOutletContext();

  return (
    <div className="h-full bg-vartana-gray-20 p-6">
      <Card
        variant="large"
        title={reseller.name}
        icon={<StoreFrontIcon />}
        content={(
          <>
            <main className="flex flex-col divide-y divide-vartana-gray-40-v3">
              <content className="grid grid-cols-2 pb-5">
                {labelValueField('Company', titleize(reseller.name) || '--')}
                {labelValueField(
                  'Account',
                  <InviteStatusPill status={reseller.inviteStatus} />
                )}
              </content>

              <content className="grid grid-cols-2 gap-y-8 py-5">
                {labelValueField(
                  'Primary contact first name',
                  get(reseller, 'primaryUser.firstName', '--')
                )}
                {labelValueField(
                  'Last name',
                  get(reseller, 'primaryUser.lastName', '--')
                )}
                {labelValueField('Email', get(reseller, 'primaryUser.email', '--'))}
                {labelValueField(
                  'Phone',
                  get(reseller, 'primaryUser.formattedPhone', '--')
                )}
              </content>

              <content className="grid grid-cols-2 pt-5">
                {labelValueField('Company ID', reseller.number || '--')}
                {labelValueField(
                  'Created on',
                  getUSDateFormat(reseller.createdAt || '--')
                )}
              </content>
            </main>
          </>
        )}
      >
      </Card>
    </div>
  );
}
