import { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import {
  SidebarContext,
  AppContext,
  MainbodyContext,
} from '../../../context/DashboardContext';

function MainBody() {
  const { toggleMenu } = useContext(SidebarContext);
  const { vendor } = useContext(AppContext);

  return (
    <MainbodyContext.Provider value={{ vendor }}>
      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            onClick={() => {
              toggleMenu((prev) => !prev);
            }}
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          >
            <span className="sr-only">Open sidebar</span>
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <main
          className="flex flex-col flex-1 relative z-0 overflow-y-auto focus:outline-none bg-vartana-steel-20"
          tabIndex={0}
        >
          <Outlet />
        </main>
      </div>
    </MainbodyContext.Provider>
  );
}

export default MainBody;
