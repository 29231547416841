/*
  Common helper utils for graphQL clients: vendor & widget
*/

// GraphQL fields to ignore when reporting errors
export const HIDDEN_FIELDS = [
  'email',
  'password',
  'newPassword',
  'confirmPassword',
  'token',
  'phone',
  'ein',
];

export const IGNORED_QUERIES = ['checkResellers'];
