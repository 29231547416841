export const ORDER_CANCELLATION_REASONS = [
  { value: 1, label: 'Deal is now closed-lost' },
  { value: 2, label: 'Need to re-do the order' },
  { value: 3, label: 'Unable to ship product' },
  { value: 4, label: 'Went with Vartana competitor' },
  { value: 5, label: 'Other(s)' },
];

export const ACCEPTANCE_MODE = {
  CUSTOMER_MILESTONES: 'customer_milestones',
  DEEMED: 'deemed',
  PRE_AUTH: 'pre_auth',
  CUSTOMER_FULL: 'customer_full',
  ANNUAL_FUNDING: 'annual_funding',
};

export const MILESTONES_STATES = {
  NEED_INVOICE: 'need_invoice',
  AWAITING_ACCEPTANCE: 'awaiting_acceptance',
  PAID: 'paid',
};

export const ORDER_STATES_TO_SHOW_MILESTONES_PAYOUT = [
  'need_invoice',
  'ready_to_accept',
  'ready_to_commence',
  'ready_to_fund',
  'partially_paid',
];

export const ORDER_COMMENCED_STATES = ['ready_to_fund', 'partially_paid', 'fully_paid'];
export const ORDER_FUNDED_STATES = ['partially_paid', 'fully_paid'];
export const textOnInvoiceSubmitWhenFinanced =
  'Vartana will shortly process remaining payout as per invoice amount.';
export const textOnInvoiceSubmit = 'Vartana is processing your order.';

export const FUNDING_INVOICE = 'funding_invoice';
export const ORDER_STATES_WHEN_INVOICE_CAN_BE_UPLOADED = [
  'invoice',
  'need_invoice',
  'awaiting_acceptance',
  'ready_to_accept',
  'ready_to_commence',
  'ready_to_fund',
  'partially_paid',
];

export const ORDER_POST_FINANCED_STATES = ['canceled', ...ORDER_COMMENCED_STATES];
