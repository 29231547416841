import React, { useCallback, useEffect, useState } from 'react';

import { get } from 'lodash';
import { Typography, InputField } from '@vartanainc/design-system';
import { useQuery, useLazyQuery as useApolloLazyQuery } from '@apollo/client';
import { TransactionStatusOptions } from '../../static';

import AutoLoad from '../../components/AutoLoad';

import {
  GET_ACCOUNT_BALANCE,
  GET_VENDOR_INVOICES,
} from '../../graphql/queries/transaction';
import {
  GET_VENDOR_BUYERS_DATA,
  GET_VENDOR_ORDERS_DATA,
  GET_VENDOR_INVOICES_DATA,
} from '../../graphql/queries/vendor';

import TransactionsTable from '../Tables/TransactionsTable';
import { useLazyQuery } from '../../utils/hooks';
import { HasPermission } from '../../components/HasPermission/HasPermission';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import DropdownInput from '../../designSystem/DropdownInput/DropdownInput';
import '../searchFilter.scss';
import ZeroState, { getCtaText } from '../../components/ZeroState/ZeroState';
import NoDataState from '../../components/NoDataState/NoDataState';

function Transactions() {
  const [transactionNumber, setTransactionNumber] = useState();
  const [transactionStatus, setTransactionStatus] = useState();

  const [payments, setPayments] = useState([]);

  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);

  const [afterCursor, setAfterCursor] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [debounceId, setDebounceId] = useState(null);

  const [availableBalance, setAvailableBalance] = useState('');
  const [totalBalance, setTotalBalance] = useState('');
  const [params, setParams] = useState({});

  const [customerExists, setCustomerExists] = useState(false);
  const [orderExists, setOrderExists] = useState(false);
  const [paymentExists, setPaymentExists] = useState(false);

  const { loading: buyerCountLoading } = useQuery(GET_VENDOR_BUYERS_DATA, {
    onCompleted: (data) => {
      setCustomerExists(data.vendorBuyersData.buyersExist);
    },
  });
  const { loading: orderCountLoading } = useQuery(GET_VENDOR_ORDERS_DATA, {
    onCompleted: (data) => {
      setOrderExists(data.vendorOrdersData.ordersExist);
    },
  });
  const { loading: invoicesCountLoading } = useQuery(GET_VENDOR_INVOICES_DATA, {
    onCompleted: (data) => {
      setPaymentExists(data.vendorInvoicesData.invoicesExist);
    },
  });

  const { loading: transactionListLoading, executer: fetchMoreTractions } = useLazyQuery(
    GET_VENDOR_INVOICES,
    {
      variables: params,
    }
  );

  const [getAccountBalance, { data: accountBalance, loading: balancesLoading }] =
    useApolloLazyQuery(GET_ACCOUNT_BALANCE, {
      onCompleted: () => {
        const accountBalanceData = get(accountBalance, 'seller.accountBalance');
        setAvailableBalance(accountBalanceData[0][1]);
        setTotalBalance(accountBalanceData[1][1]);
      },
    });

  useEffect(() => {
    if (!buyerCountLoading || !orderCountLoading || !invoicesCountLoading) {
      if (paymentExists) {
        getAccountBalance();
      }
    }
  }, [
    buyerCountLoading,
    getAccountBalance,
    invoicesCountLoading,
    orderCountLoading,
    paymentExists,
  ]);

  const fetchMorePaymentsWithFilters = useCallback(
    (variables, concat) => {
      const defaultVariables = {
        first: null,
        after: null,
        externalInvoiceNumber: null,
        status: null,
        ...variables,
      };
      setParams(defaultVariables);
      return new Promise((resolve, reject) => {
        setFetchMoreLoading(true);
        if (!buyerCountLoading || !orderCountLoading || !invoicesCountLoading) {
          if (paymentExists) {
            return fetchMoreTractions(defaultVariables)
              .then((fetchMoreResult) => {
                const newEdges = get(fetchMoreResult, 'data.vendorInvoices.edges', []);
                const pageInfo = get(fetchMoreResult, 'data.vendorInvoices.pageInfo', {});
                if (pageInfo) {
                  setAfterCursor(pageInfo.endCursor);
                  setHasNextPage(pageInfo.hasNextPage);
                }
                if (concat) {
                  setPayments((prev) => [...prev, ...newEdges]);
                } else {
                  setPayments(newEdges);
                }
                setFetchMoreLoading(false);
                resolve();
              })
              .catch((error) => {
                setFetchMoreLoading(false);
                reject(error);
              });
          }
        }
        return null;
      });
    },
    [
      buyerCountLoading,
      orderCountLoading,
      invoicesCountLoading,
      paymentExists,
      fetchMoreTractions,
    ]
  );

  useEffect(() => {
    if (!transactionNumber) {
      fetchMorePaymentsWithFilters({ first: 10, status: transactionStatus }, false);
    } else {
      // while transactionNumber is being typed
      setDebounceId(
        setTimeout(
          () =>
            fetchMorePaymentsWithFilters(
              {
                first: 10,
                number: transactionNumber,
                status: transactionStatus,
              },
              false
            ),
          1000
        )
      );
    }
  }, [transactionNumber, transactionStatus, fetchMorePaymentsWithFilters]);

  const fetchMore = useCallback(() => {
    fetchMorePaymentsWithFilters(
      {
        first: 10,
        after: afterCursor,
        number: transactionNumber,
        status: transactionStatus,
      },
      true
    );
  }, [afterCursor, fetchMorePaymentsWithFilters, transactionNumber, transactionStatus]);

  return (
    <AutoLoad
      loading={
        transactionListLoading ||
        buyerCountLoading ||
        orderCountLoading ||
        invoicesCountLoading
      }
      containerClassName="h-full flex"
    >
      <div className="flex flex-row px-8 py-9 border-b border-gray-200 justify-between bg-white">
        <Typography variant="heading24" color="color-black-100">
          Payments
        </Typography>
      </div>
      {!paymentExists ? (
        <ZeroState page="payments" cta={getCtaText(customerExists, orderExists)} />
      ) : (
        <>
          {/* Balance stats */}
          <HasPermission resource="payment" action="view_all">
            <div className="grid grid-cols-2 gap-2 border-b border-gray-200 divide-x bg-white">
              <div className="flex flex-col gap-2 px-8 py-4">
                <Typography variant="heading16">Total balance</Typography>
                <AutoLoad loading={balancesLoading} className="w-7 h-7 !m-0">
                  <Typography variant="heading20" bold>
                    {get(availableBalance, 'formatted', 0)}
                  </Typography>
                </AutoLoad>
              </div>

              <div className="flex flex-col gap-2 px-8 py-4">
                <Typography variant="heading16">Available balance</Typography>
                <AutoLoad loading={balancesLoading} className="w-7 h-7 !m-0">
                  <Typography variant="heading20" bold>
                    {get(totalBalance, 'formatted', 0)}
                  </Typography>
                </AutoLoad>
              </div>
            </div>
          </HasPermission>

          {/* Invoice filters */}
          <div className="filter-container-responsive pt-4 pb-6 px-8 border-b border-vartana-gray-40-v3 bg-white">
            <div className="filter-responsive">
              <InputField
                label="Order form #"
                name="transaction-number"
                suffixIcon={<SearchIcon />}
                fullWidth
                value={transactionNumber}
                onChange={(e) => {
                  clearTimeout(debounceId);
                  setTransactionNumber(e.target.value);
                }}
              />
            </div>
            <div className="filter-responsive">
              <DropdownInput
                label="Status"
                name="credit-condition"
                options={TransactionStatusOptions}
                value={transactionStatus}
                isSearchable={false}
                onChange={({ value }) => {
                  setTransactionStatus(value);
                }}
              />
            </div>
          </div>

          {/* Table of invoices */}
          <AutoLoad
            loading={transactionListLoading}
            containerClassName="h-full flex items-center"
          >
            {payments.length === 0 ? (
              <NoDataState />
            ) : (
              <TransactionsTable
                transactionsList={payments}
                isLoading={fetchMoreLoading}
                transactionListLoading={transactionListLoading}
                hasNextPage={hasNextPage}
                afterCursor={afterCursor}
                fetchMore={fetchMore}
                filterOptions={{
                  order_form_number: [transactionNumber, setTransactionNumber],
                  status: [transactionStatus, setTransactionStatus],
                }}
              />
            )}
          </AutoLoad>
        </>
      )}
    </AutoLoad>
  );
}

export default Transactions;
