import { Typography } from '@vartanainc/design-system';
import { ReactComponent as IllustrationEmptyBoxIcon } from '../../assets/illustration-empty-box.svg';

export const getCtaText = (customerExists: boolean, orderExists: boolean): string => {
  if (!customerExists) return 'add_customer';
  if (!orderExists) return 'add_order';
  return 'payments';
};

const NoDataState = (): JSX.Element => {
  return (
    <>
      <div className="flex flex-1 justify-center w-full items-center pb-[5%]">
        <div className="flex flex-col items-center gap-6">
          <div>
            <IllustrationEmptyBoxIcon className="ml-auto w-auto h-[13.625rem]" />
          </div>

          <div className="flex flex-col items-center gap-2">
            <Typography variant="heading20" color="color-black-100">
              No results found
            </Typography>
            <Typography variant="heading16" color="color-steel-180">
              There is no data matching your filter or search inquiry.
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoDataState;
