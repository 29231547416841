import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@vartanainc/design-system';
import { useVartanaFeatureFlags } from '../../../../utils/helpers';
import { ReactComponent as CopyIcon } from '../../../../assets/copy.svg';

function TrackRecipients({ emailHistory }) {
  const { vendorCheckoutUrl: showVendorCheckoutUrl } = useVartanaFeatureFlags();
  return (
    <div className="space-y-4">
      <div className="inline-block text-vartana-black">
        <Typography variant="heading18" bold>
          Activities and updates
        </Typography>
      </div>
      <div>
        {emailHistory.map((email) => {
          return (
            <div>
              <div className="flex items-center flex-row space-x-3 mt-5">
                <Typography variant="paragraph14" bold>
                  {email.email}
                </Typography>
                {showVendorCheckoutUrl && email?.url && (
                  <Button
                    size="x-small"
                    backgroundColor="none"
                    startIcon={<CopyIcon />}
                    onClick={() => {
                      navigator.clipboard.writeText(email.url);
                    }}
                  >
                    Copy link
                  </Button>
                )}
              </div>
              <div className="border-l-2 border-vartana-blue-120 border-opacity-25 pl-2 mt-2">
                {email.events.map((emailEvent) => {
                  return (
                    <div className="flex flex-col space-y-1 vp-p-small pt-3 pb-1.5 pl-4 relative">
                      <p className="absolute -left-3.25 top-2.5 text-vartana-blue-120 text-base">
                        &#x2022;
                      </p>
                      <div className="text-vartana-gray-70">
                        <Typography variant="paragraph12" color="color-gray-70">
                          {emailEvent.message}
                        </Typography>
                      </div>
                      <div className="text-vartana-gray-50">
                        <Typography variant="paragraph12" color="color-gray-50">
                          {emailEvent.timestamp}
                        </Typography>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

TrackRecipients.propTypes = {
  emailHistory: PropTypes.arrayOf(PropTypes.node),
};

TrackRecipients.defaultProps = {
  emailHistory: [],
};

export default TrackRecipients;
