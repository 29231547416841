import { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { get } from 'lodash';
import { sessionVar } from '../graphql/cache';

/**
 * Custom hook to extract and memoize values from the session variable
 * @param path The deep path in the session object to the desired value
 * @param defaultValue A fallback value if the path doesn't exist in the session
 */
export const useSessionValue = <T>(path: string, defaultValue: T): T => {
  const session = useReactiveVar(sessionVar);

  return useMemo(() => get(session, path, defaultValue), [session, path, defaultValue]);
};
