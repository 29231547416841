/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import TextInputField from '../../../../designSystem/TextInput/TextInputField';
import FormikDropdownInput from '../../../../designSystem/FormikDropdownInput/FormikDropdownInput';

export const ManualBankForm = ({ formik, accountTypes, isEft }) => {
  const { errors, touched } = formik;

  if (isEft) {
    return (
      <div className="flex flex-col gap-4 pt-8 md:grid md:grid-cols-6">
        <div className="col-span-6 md:col-span-3">
          <TextInputField
            name="bankName"
            type="text"
            label="Name of bank"
            errorMsg={errors.bankName}
            touched={touched.bankName}
          />
        </div>

        <div className="col-span-6 md:col-span-3">
          <TextInputField
            name="institutionNumber"
            type="text"
            label="Institution number"
            errorMsg={errors.institutionNumber}
            touched={touched.institutionNumber}
          />
        </div>

        <div className="col-span-6 md:col-span-3">
          <TextInputField
            name="transitNumber"
            type="text"
            label="Transit number"
            errorMsg={errors.branch}
            touched={touched.branch}
          />
        </div>

        <div className="col-span-6 md:col-span-3">
          <TextInputField
            name="accountName"
            type="text"
            label="Account nickname"
            errorMsg={errors.accountName}
            touched={touched.accountName}
          />
        </div>

        <div className="col-span-6 md:col-span-3">
          <TextInputField
            name="accountNumber"
            type="text"
            label="Account number"
            errorMsg={errors.accountNumber}
            touched={touched.accountNumber}
          />
        </div>

        <div className="col-span-6 md:col-span-3">
          <TextInputField
            name="confirmAccountNumber"
            type="text"
            label="Confirm account number"
            errorMsg={errors.confirmAccountNumber}
            touched={touched.confirmAccountNumber}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-4 pt-8 md:grid md:grid-cols-6">
      <div className="col-span-6 md:col-span-3">
        <TextInputField
          name="bankName"
          type="text"
          label="Name of bank"
          errorMsg={errors.bankName}
          touched={touched.bankName}
        />
      </div>

      <div className="col-span-6 md:col-span-3">
        <TextInputField
          name="accountName"
          type="text"
          label="Account nickname"
          errorMsg={errors.accountName}
          touched={touched.accountName}
        />
      </div>

      <div className="col-span-6 md:col-span-3">
        <FormikDropdownInput
          name="accountType"
          label="Account type"
          options={accountTypes}
        />
      </div>

      <div className="col-span-6 md:col-span-3">
        <TextInputField
          name="routingNumber"
          type="text"
          label="Routing number"
          errorMsg={errors.routingNumber}
          touched={touched.routingNumber}
        />
      </div>

      <div className="col-span-6 md:col-span-3">
        <TextInputField
          name="accountNumber"
          type="text"
          label="Account number"
          errorMsg={errors.accountNumber}
          touched={touched.accountNumber}
        />
      </div>

      <div className="col-span-6 md:col-span-3">
        <TextInputField
          name="confirmAccountNumber"
          type="text"
          label="Confirm account number"
          errorMsg={errors.confirmAccountNumber}
          touched={touched.confirmAccountNumber}
        />
      </div>
    </div>
  );
};

ManualBankForm.propTypes = {
  formik: PropTypes.shape({}).isRequired,
  accountTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

ManualBankForm.defaultProps = {
  accountTypes: [],
};
