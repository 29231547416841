import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { HorizontalBar, defaults } from 'react-chartjs-2';
import { capitalize } from 'lodash';

function getBarColor(label) {
  switch (capitalize(label)) {
  case 'Submitted':
    return '#FF0000';
  case 'Complete':
    return '#ffbb23';
  case 'Approved':
    return '#3b89fe';
  case 'Financed':
    return '#2dc26c';
  case 'Commenced':
    return '#2dc26c';
  default:
    return '#2dc26c';
  }
}

function DealFunnel({ ordersByStatus }) {
  const [dealFunnel, setDealFunnel] = useState([]);

  useEffect(() => {
    const data = {
      datasets: ordersByStatus.map((val) => {
        return {
          type: 'horizontalBar',
          label: [capitalize(val.label)],
          axis: 'y',
          data: [val.value],
          backgroundColor: getBarColor(val.label),
          borderColor: getBarColor(val.label),
          borderWidth: 1,
        };
      }),
    };

    setDealFunnel(data);
  }, [ordersByStatus]);

  const options = {
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: defaults.global.defaultFontSize,
      },
    },
    tooltips: {
      callbacks: {
        title: () => '',
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            drawOnChartArea: false,
            drawTicks: false,
            color: 'rgb(102, 102, 102)',
          },
          ticks: {
            maxTicksLimit: 6,
            stepSize: 1,
            padding: 10,
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          display: true,
          gridLines: {
            drawOnChartArea: false,
            drawTicks: false,
            color: 'rgb(102, 102, 102)',
          },
        },
      ],
    },
  };

  return <HorizontalBar data={dealFunnel} options={options} width={100} height={50} />;
}

DealFunnel.propTypes = {
  ordersByStatus: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
};

DealFunnel.defaultProps = {
  ordersByStatus: [],
};

export default DealFunnel;
