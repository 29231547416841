import { Outlet } from 'react-router-dom';
import { useMemo } from 'react';

import { PermissionsContext, AppContext } from './context/DashboardContext';
import AutoLoad from './components/AutoLoad';
import { ResellerPermissions, UserPermissions, Vendor } from './utils/commonInterfaces';

interface AppProps {
  vendor: Vendor;
  userRole: { name: string };
  rolePermissions: UserPermissions;
  globalResellerPermissions: ResellerPermissions;
  setPermissions: () => void;
  loading: boolean;
}

function App({
  vendor,
  userRole,
  rolePermissions,
  globalResellerPermissions,
  setPermissions,
  loading,
}: AppProps): JSX.Element {
  const permissionCxt = useMemo(
    () => ({
      rolePermissions,
      globalResellerPermissions,
      setPermissions,
      userRole,
    }),
    [rolePermissions, globalResellerPermissions, setPermissions, userRole]
  );

  return (
    <PermissionsContext.Provider value={permissionCxt}>
      <AppContext.Provider value={{ vendor }}>
        <AutoLoad
          loading={loading}
          containerClassName="flex justify-center"
          className="absolute text-center top-1/2 transform-gpu -translate-y-1/2"
        >
          <Outlet />
        </AutoLoad>
      </AppContext.Provider>
    </PermissionsContext.Provider>
  );
}

export default App;
