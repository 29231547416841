import { useState, useMemo, useCallback } from 'react';
import { ButtonV2, InputField, Typography } from '@vartanainc/design-system';
import { get } from 'lodash';
import { useMutation, useReactiveVar } from '@apollo/client';
import Modal from 'react-modal';
import { v4 as uuidv4 } from 'uuid';

import Loader from '../../../../components/Loader';
import { showToast } from '../../../../utils/helpers';
import { ReactComponent as CrossIcon } from '../../../../assets/cross.svg';
import { ReactComponent as WhiteCrossIcon } from '../../../../assets/cross_white.svg';
import { ReactComponent as SendIcon } from '../../../../assets/send_bold.svg';
import { commonRegex } from '../../../../constants/common.constants';
import { sessionVar } from '../../../../graphql/cache';
import { INVITE_USERS } from '../../../../graphql/mutations';

interface InviteTeamModalProps {
  isModalOpen: boolean;
  onClose: () => void;
}

interface EmailPill {
  email: string;
  isValid: boolean;
  id: number;
}

const InviteTeamModal = ({ isModalOpen, onClose }: InviteTeamModalProps): JSX.Element => {
  const sessionData = useReactiveVar(sessionVar);
  const sessionUserEmail = get(sessionData, 'session.user.email');

  const [emails, setEmails] = useState<EmailPill[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [showInputError, setShowInputError] = useState(false);
  const [inviteTeam, { loading }] = useMutation(INVITE_USERS);
  const [emailError, setEmailError] = useState(
    'Please enter a valid email address: example@email.com'
  );

  const companyDomain = get(sessionData, 'session.user.company.domain', '');

  const handleInputChange = (event): void => {
    if (event.target.value === '' && emails.length >= 1) {
      setShowInputError(false);
    }
    setInputValue(event.target.value);
  };

  const isInList = (email): boolean => {
    const emailFound = emails.find(
      (e) => e.email === email || email === sessionUserEmail
    );
    return !!emailFound;
  };

  const handleKeyDown = (event): void => {
    if (['Enter', 'Tab', ',', ' '].includes(event.key)) {
      event.preventDefault();

      const emailToAdd = inputValue.trim();
      const isValidEmail = !!emailToAdd.match(commonRegex.email);
      const uniqueId = uuidv4();

      if (emailToAdd && isValidEmail && !isInList(emailToAdd)) {
        setShowInputError(false);
        const emailDomain = inputValue.split('@')[1];

        const isValidDomain = emailDomain === companyDomain;
        if (companyDomain === '' && isValidEmail) {
          setEmails((prevState) => [
            ...prevState,
            {
              email: emailToAdd,
              isValid: isValidEmail,
              id: uniqueId,
            },
          ]);
          setInputValue('');
        } else if (isValidDomain) {
          setEmails((prevState) => [
            ...prevState,
            {
              email: emailToAdd,
              isValid: isValidEmail && isValidDomain,
              id: uniqueId,
            },
          ]);
          setInputValue('');
        } else {
          setEmailError('Email domain does not match company domain.');
          setShowInputError(true);
        }
      } else if (isInList(emailToAdd)) {
        setEmailError('This email already exists');
        setShowInputError(true);
      } else {
        setShowInputError(true);
        setEmailError('Please enter a valid email address: example@email.com');
      }
    }
  };

  const handlePaste = (event): void => {
    event.preventDefault();

    const paste = event.clipboardData.getData('text');
    const emailsMatched = paste.match(commonRegex.groupedEmail);

    if (emailsMatched) {
      const newEmails = emailsMatched.filter((email) => !isInList(email));
      const newEmailsMatched = newEmails.filter((email, index, self) => {
        // Keep only the first occurrence of each email
        return self.indexOf(email) === index;
      });

      const emailsToBeAdded = newEmailsMatched.map((e) => {
        const emailDomain = e.split('@')[1];
        const isValidDomain = emailDomain === companyDomain;

        if (companyDomain === '') {
          return {
            email: e,
            isValid: !!e.match(commonRegex.email),
            id: uuidv4(),
          };
        }
        return {
          email: e,
          isValid: !!e.match(commonRegex.email) && isValidDomain,
          id: uuidv4(),
        };
      });

      setEmails((prevState) => [...prevState, ...emailsToBeAdded]);
      setInputValue('');
    }
  };

  const handleDelete = useCallback(
    (emailToDelete) => {
      setEmails(emails.filter((email) => email !== emailToDelete));
    },
    [emails]
  );

  const renderEmails = useMemo(() => {
    if (emails.length === 0) return <></>;

    emails.sort((a, b) => {
      if (a.isValid && !b.isValid) {
        return 1;
      }
      return -1;
    });

    return (
      <div className="flex gap-2 flex-wrap max-h-[7rem] overflow-y-auto">
        {emails.map((email) => {
          return (
            <div
              className={`py-1 rounded pr-1 pl-2 text-white flex gap-1 items-center ${
                email.isValid ? 'bg-vartana-blue-40 ' : 'bg-vartana-dark-red'
              }`}
            >
              <Typography variant="paragraph12">{email.email}</Typography>
              <button
                onClick={() => handleDelete(email)}
                className="w-4 h-4 flex items-center justify-center"
              >
                <WhiteCrossIcon
                  className={`w-4 h-4 ${
                    email.isValid ? 'fill-white' : 'fill-vartana-dark-red'
                  }`}
                />
              </button>
            </div>
          );
        })}
      </div>
    );
  }, [emails, handleDelete]);

  const handleSubmit = async (): Promise<void> => {
    const formattedEmails = emails
      .filter((email) => email.isValid)
      .map((email) => email.email);
    try {
      const { data } = await inviteTeam({
        variables: { emails: formattedEmails },
      });
      if (get(data, 'inviteUsers', false)) {
        showToast('success', 'Invitation sent successfully.');
        onClose();
      }
    } catch (error) {
      showToast('error', 'Something went wrong.');
    }
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        overlayClassName="modal flex justify-center items-center z-50"
        className="flex justify-center items-center w-[75%] max-w-[60rem] focus:outline-none"
        shouldCloseOnOverlayClick
        onRequestClose={onClose}
      >
        <div className="m-auto transform-gpu w-full h-full max-w-[43.75rem] shadow-lg rounded-xl bg-white flex flex-col justify-start overflow-y-auto">
          <div className="p-2 flex justify-end">
            <button onClick={onClose} className="w-6 h-6">
              <CrossIcon className="w-4 h-4" />
            </button>
          </div>
          <div className="px-10 pb-10 flex-1 flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                <SendIcon className="w-8 h-8" />
                <Typography variant="heading24" color="color-black-100">
                  Invite teammates
                </Typography>
              </div>
              <Typography variant="paragraph14">
                Self-service financing with the best rates for your entire team
              </Typography>
            </div>

            <div className="flex gap-1 flex-col email-pill-parent">
              <InputField
                label="Team emails (enter multiple)"
                className="w-full email-pill-input"
                placeholder="example@email.com"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                showError={showInputError}
                helperText={emailError}
                fullWidth
                onPaste={handlePaste}
              />
            </div>

            {renderEmails}

            <div className="flex justify-between mt-4">
              <ButtonV2
                variant={{ type: 'text', typography: 'paragraph14' }}
                text="Cancel"
                iconLeft="chevron_left"
                onClick={onClose}
              />
              {loading ? (
                <Loader />
              ) : (
                <ButtonV2
                  variant={{ type: 'primary', typography: 'paragraph14' }}
                  text="Send"
                  onClick={handleSubmit}
                  disabled={emails.filter((email) => email.isValid).length === 0}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InviteTeamModal;
