import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@vartanainc/design-system';
import { ReactComponent as CopyIcon } from '../../../../assets/copy_icon.svg';
import { useVartanaFeatureFlags } from '../../../../utils/helpers';

function TrackRecipients({ emailHistory }) {
  const { vendorCheckoutUrl: showVendorCheckoutUrl } = useVartanaFeatureFlags();
  return (
    <div className="space-y-4">
      <div className="text-vartana-black">
        <Typography variant="heading18" bold>
          Activities and updates
        </Typography>
      </div>
      <div>
        {emailHistory.map((email) => {
          return (
            <div key={email.id}>
              <div className="flex flex-row items-center space-x-3 mt-5">
                <Typography variant="paragraph14" bold>
                  {email.email}
                </Typography>
                {showVendorCheckoutUrl && email?.url && (
                  <Button
                    size="x-small"
                    backgroundColor="none"
                    startIcon={<CopyIcon />}
                    onClick={() => {
                      navigator.clipboard.writeText(email.url);
                    }}
                  >
                    Copy link
                  </Button>
                )}
              </div>
              <div className="border-l-2 border-vartana-blue-120 border-opacity-25 pl-2 mt-2">
                {email.events.map((emailEvent) => {
                  return (
                    <div
                      key={emailEvent.actual_timestamp}
                      className="flex flex-col space-y-1 vp-p-small pt-3 pb-1.5 pl-4 relative"
                    >
                      <p className="absolute -left-3.25 top-2.5 text-vartana-blue-120 text-base">
                        &#x2022;
                      </p>
                      <p className="text-vartana-gray-70">{emailEvent.message}</p>
                      <p className="text-vartana-gray-50">{emailEvent.timestamp}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

TrackRecipients.propTypes = {
  emailHistory: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      events: PropTypes.arrayOf(PropTypes.shape({})),
      url: PropTypes.string,
    })
  ),
};

TrackRecipients.defaultProps = {
  emailHistory: [],
};

export default TrackRecipients;
