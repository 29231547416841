import { twMerge } from 'tailwind-merge';
import { ButtonV2, Typography } from '@vartanainc/design-system';
import { ReactComponent as NeedInfo } from '../../assets/need_info_blue.svg';
import { CrossButton } from '../Button/CrossButton';
import { Modal } from './Modal';
import { isRenderedFromHubspot } from '../../utils/helpers';
import './modal.scss';

function CancelConfirmationModal({
  open,
  onClose,
  onConfirm,
}: {
  open?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}): JSX.Element {
  const modalClassName = isRenderedFromHubspot() ? 'hubspot-modal' : '';
  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      onRequestClose={onClose}
      className={twMerge(
        'max-w-[26rem] -translate-y-24 focus:outline-none',
        modalClassName
      )}
    >
      <div>
        <div className="h-10 relative">
          <CrossButton onClick={onClose} className="top-2 right-2" />
        </div>
        <div className="px-10 pb-10 flex flex-col gap-6">
          <div className="flex items-center gap-2">
            <NeedInfo />
            <Typography variant="heading20" color="color-black-100">
              Are you sure?
            </Typography>
          </div>
          <Typography variant="paragraph14" color="color-black-100">
            Information will not be saved if you leave the page.
          </Typography>
          <div className="flex justify-between">
            <ButtonV2
              onClick={onClose}
              text="Close"
              variant={{ type: 'ghost', typography: 'paragraph14' }}
            />
            <ButtonV2
              onClick={onConfirm}
              text="Confirm"
              variant={{ type: 'primary', typography: 'paragraph14' }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
export default CancelConfirmationModal;
