// PersonalGuarantorFormFooter.tsx

/**
 * PersonalGuarantorFormFooter Component
 *
 * Renders "Back", "Skip", and "Submit" buttons for navigating the form or submitting it.
 * Utilizes `ButtonV2` from '@vartanainc/design-system' for styling and integrates with Formik
 * to handle form submission.
 *
 * Props:
 * - `onBackClick`: Handler for the "Back" button click event.
 * - `onSkipClick`: Handler for the "Skip" button click event.
 *
 * The "Submit" button:
 * - Uses Formik's `handleSubmit` to trigger form submission.
 * - Is automatically disabled if the form is invalid.
 *
 * Usage:
 * Include this component as the footer in forms that require "Back," "Skip," and "Submit" navigation actions.
 */

import { ReactElement, useState } from 'react';
import { ButtonV2 } from '@vartanainc/design-system';
import { useFormikContext } from 'formik';
import { PgFormValues } from './PersonalGuarantorTypes';
import AutoLoad from '../../components/AutoLoad';

interface PersonalGuarantorFormFooterProps {
  handleBackButtonClick: () => void;
  handleSkipButtonClick: () => Promise<void>;
}

export function PersonalGuarantorFormFooter({
  handleBackButtonClick,
  handleSkipButtonClick,
}: PersonalGuarantorFormFooterProps): ReactElement {
  const [isCustomerCreating, setIsCustomerCreating] = useState<boolean>(false);

  const formikBag = useFormikContext<PgFormValues>();

  // This method is use to wrap handle skip button click and provide loading support when customer creating is in progress
  const handleSkip = async (): Promise<void> => {
    setIsCustomerCreating(true);
    await handleSkipButtonClick();
    setIsCustomerCreating(false);
  };

  return (
    <div className="flex flex-row justify-between mt-8">
      <ButtonV2
        variant={{ type: 'ghost', typography: 'paragraph14' }}
        type="button"
        text="Back"
        iconLeft="chevron_left"
        onClick={handleBackButtonClick}
      />
      <AutoLoad loading={formikBag.isSubmitting || isCustomerCreating}>
        <div className="flex flex-row gap-4">
          <ButtonV2
            type="button"
            text="Skip"
            variant={{ type: 'ghost', typography: 'paragraph14' }}
            onClick={handleSkip}
          />
          <ButtonV2
            type="submit"
            text="Submit"
            variant={{ type: 'primary', typography: 'paragraph14' }}
            disabled={!formikBag.dirty}
            onClick={formikBag.handleSubmit}
          />
        </div>
      </AutoLoad>
    </div>
  );
}
