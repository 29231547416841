import DesktopSidenav from './DesktopSidenav/DesktopSidebar';
import MobileSidenav from './MobileSidenav/MobileSidenav';

const Sidenav = (): JSX.Element => {
  return (
    <>
      <MobileSidenav />
      <DesktopSidenav />
    </>
  );
};

export default Sidenav;
