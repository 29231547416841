/* eslint-disable no-underscore-dangle */
class NavigationService {
  constructor() {
    this._navigate = null;
    this._rollbar = null;
  }

  set navigate(nav) {
    this._navigate = nav;
  }

  get navigate() {
    return this._navigate;
  }

  set rollbar(rollbar) {
    this._rollbar = rollbar;
  }

  get rollbar() {
    return this._rollbar;
  }
}

export const navigationService = new NavigationService();
