import Modal from 'react-modal';
import PropTypes from 'prop-types';

/**
 * Wrapper component to house [Tray Config Wizard](https://tray.io/documentation/embedded/building-integrations/the-config-wizard/)
 */
function TrayWizard({ isOpen, srcUrl, onClose, className, show }) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="modal" ariaHideApp={false}>
      <iframe
        id="Tray iFrame"
        title="Authentication Wizard"
        src={srcUrl}
        style={{ width: '50%', height: '100%', opacity: `${show ? 1 : 0}` }}
        className={`${className} p-10`}
      />
    </Modal>
  );
}

TrayWizard.propTypes = {
  isOpen: PropTypes.bool,
  srcUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string,
  show: PropTypes.bool,
};

TrayWizard.defaultProps = {
  className: '',
  onClose: () => {},
  isOpen: true,
  show: true,
};

export default TrayWizard;
