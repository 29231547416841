import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import UpsertUser from './UpsertUser';
import UserCard from './UserCard';
import { BackButton } from '../../components/Button/BackButton';

const modes = ['edit', 'view'];

function ViewUser() {
  const location = useLocation();
  const [selectedMode, setSelectedMode] = useState(modes[0]);
  const [selectedUser, setSelectedUser] = useState({
    fullName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    title: '',
    role: '',
  });

  useEffect(() => {
    if (location.state && location.state.selectedUser) {
      setSelectedUser({ ...location.state.selectedUser });
    }
  }, [location.state]);

  const handleEditUser = () => {
    setSelectedMode(modes[1]);
  };

  const handleClose = (updatedValues) => {
    setSelectedMode(modes[0]);
    if (updatedValues) {
      setSelectedUser({
        ...updatedValues,
      });
    }
  };

  return (
    <div className="py-8 rounded-lg w-7/12 mx-auto small-card-width-range">
      {selectedMode === modes[0] ? (
        <div className="flex flex-col gap-10">
          <UserCard
            cardTitle={selectedUser.fullName}
            showResetPassword
            selectedUser={{
              ...selectedUser,
              role: get(selectedUser, 'userRole.name'),
            }}
            onEditClicked={() => handleEditUser()}
          />
          <BackButton />
        </div>
      ) : (
        <UpsertUser
          selectedUser={selectedUser}
          mode="edit"
          onClose={(updatedUser) => handleClose(updatedUser)}
        />
      )}
    </div>
  );
}

export default ViewUser;
