import { Typography } from '@vartanainc/design-system';
import { useFormikContext } from 'formik';
import { ReactElement, useCallback, useMemo } from 'react';
import AddressInputField from '../../designSystem/AddressInput/AddressInput';
import CustomDropdown from '../../designSystem/FormikDropdownInput/FormikDropdownInput';
import MultiCountryZipInput from '../../designSystem/MultiCountryZipInput/MultiCountryZipInput';
import { getStateOptions, isWidget } from '../../utils/helpers';
import PatternInput from '../../designSystem/PatternInput/PatternInput';
import PatternInputV2 from '../../designSystem/PatternInputV2/PatternInputV2';
import TextInputField from '../../designSystem/TextInput/TextInputField';
import FreeTextDropdownInput from '../../designSystem/FreeTextDropdownInput/FreeTextDropdownInput';
import AuthUserSearchField from '../../components/AuthUserSearchField/AuthUserSearchField';
import {
  PERCENTAGE_OF_OWNERSHIP_OPTIONS,
  PHONE_NUMBER_FORMAT,
  TOGGLE_LABEL_POSITION,
  SSN_SIN_FIELD_CONFIG,
  DATE_FORMAT_SHORT_MDY,
  FIRST_NAME_PREFILL_FIELD_PLACEHOLDER,
} from '../../constants/common.constants';
import { useSetAddressFields } from '../../utils/hooks';
import './PersonalGuarantorForm.scss';

import SvgIcon from '../../components/SvgIcon/SvgIcon';
import { useJobTitles } from '../../hooks/useJobTitles';
import { DateOfBirthComponent } from './DateOfBirthComponent';
import { PgFormValues } from './PersonalGuarantorTypes';
import FormToggle from '../../components/FormToggle';
import Tooltip from '../../components/Tooltip/Tooltip';

interface AddressType {
  street: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}
interface PersonalGuarantorFormProps {
  pg?: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    title: string;
    dob: Date | null;
    defaultAddress: AddressType;
    ssn: string;
    sin: string;
    percentageOfOwnership: string;
  };
  showGuarantorNumber: boolean;
  guarantorIndex: number;
  showSinField: boolean;
  multipleCountriesEnabled: boolean;
  enabledCountries: string[];
  selectedCountry: string;
  showRemoveGuarantorButton: boolean;
  resetAddressSwitch: boolean;
}

export function PersonalGuarantorForm({
  pg,
  showGuarantorNumber,
  guarantorIndex,
  showSinField,
  multipleCountriesEnabled,
  enabledCountries,
  selectedCountry,
  showRemoveGuarantorButton,
  resetAddressSwitch,
}: PersonalGuarantorFormProps): ReactElement {
  const formikBag = useFormikContext<PgFormValues>();
  const { setFieldValue, values, validateField } = formikBag;
  const jobTitles = useJobTitles();

  /**
   * handleToggleChange
   *
   * Sets `primary` to true for the selected guarantor (identified by `guarantorIndex`),
   * and false for all others, ensuring only one guarantor is marked as the default.
   */

  const handleToggleChange = (): void => {
    // Set `primary` to true only for the current guarantor, false for others
    const updatedGuarantors = values.personalGuarantors.map((guarantor, index) => ({
      ...guarantor,
      primary: index === guarantorIndex, // Mark only the current guarantor as primary
    }));

    setFieldValue('personalGuarantors', updatedGuarantors);
  };

  /**
   * isToggleDisabled
   *
   * Determines if the primary toggle for the current guarantor should be disabled.
   * - If this guarantor is the only primary in the list, the toggle should be disabled to prevent turning off the last active primary.
   * - When `isToggleDisabled` is true, the toggle will have a disabled appearance and no interaction.
   */
  const isToggleDisabled =
    values.personalGuarantors?.[guarantorIndex]?.primary &&
    values.personalGuarantors?.filter((guarantor) => guarantor?.primary).length === 1;

  const setSelectedPlace = useSetAddressFields(
    setFieldValue,
    validateField,
    true,
    `personalGuarantors[${guarantorIndex}].`
  );

  const getFieldName = useCallback((fieldName: string, index: number): string => {
    return `personalGuarantors[${index}].${fieldName}`;
  }, []);

  const handleRemoveGuarantor = useCallback((): void => {
    const isPrimaryPgRemoved = values.personalGuarantors[guarantorIndex].primary;

    const updatedGuarantors = values.personalGuarantors.filter(
      (_, index) => index !== guarantorIndex
    );

    if (isPrimaryPgRemoved) {
      // Set first guarantor as primary if primary was removed
      updatedGuarantors[0].primary = true;
    }

    setFieldValue('personalGuarantors', updatedGuarantors);
    formikBag.setFieldTouched('personalGuarantors', false, false);
  }, [formikBag, guarantorIndex, setFieldValue, values.personalGuarantors]);

  const renderTextInputField = useCallback(
    (name, label, placeholder = ''): ReactElement => {
      return (
        <TextInputField
          {...{
            name: getFieldName(name, guarantorIndex),
            label,
            placeholder,
            fullWidth: 'true',
          }}
          ref={null}
        />
      );
    },
    [getFieldName, guarantorIndex]
  );

  // Set state options based on `selectedCountry`, or use an empty array if selected country is not provided.
  const stateOptions = useMemo(
    () => (selectedCountry ? getStateOptions([selectedCountry]) : []),
    [selectedCountry]
  );

  const isFirstGuarantor = useMemo(() => guarantorIndex === 0, [guarantorIndex]);
  const getToggleClassNames = (isPrimary: boolean): string => {
    let classnames = 'flex gap-2 items-center form-toggle-label my-2';
    if (isToggleDisabled) {
      classnames += ' form-toggle-disabled';
    }
    classnames += isPrimary ? ' bg-vartana-blue-110' : ' bg-vartana-gray-30-50';
    return classnames;
  };

  const showOptionalPgTitle = useMemo(
    () => isWidget && formikBag.values.personalGuarantors.length === 1,
    [formikBag.values.personalGuarantors.length]
  );

  const signerFirstName = useMemo(() => {
    return formikBag?.values?.personalGuarantors[guarantorIndex]?.firstName || '';
  }, [formikBag?.values?.personalGuarantors, guarantorIndex]);

  const setContactValues = (selectedOption): void => {
    Object.keys(selectedOption).forEach((key) => {
      if (selectedOption[key] || selectedOption[key] === '') {
        formikBag.setFieldValue(getFieldName(key, guarantorIndex), selectedOption[key]);
        // Set touched for the field to show if any errors occur
        formikBag.setTouched({
          ...formikBag.touched,
          [getFieldName(key, guarantorIndex)]: true,
        });
      }
    });
    formikBag.validateForm();
  };

  return (
    <div className="pg-form-container">
      <fieldset>
        <div className="mb-4">
          {showGuarantorNumber && (
            <div
              className={`${
                isFirstGuarantor ? 'first-guarantor' : 'followup-guarantor'
              } flex flex-row justify-between items-center`}
            >
              <Typography
                variant="paragraph18"
                bold
                color="color-black-100"
                className="flex flex-row align-middle items-center gap-1"
              >
                Personal guarantor{' '}
                {showOptionalPgTitle ? <>(optional)</> : guarantorIndex + 1}
                {isWidget && (
                  <Tooltip
                    tooltipClass="max-w-[17rem]"
                    text="An individual with ownership or executive responsibilities in the company who
                    can guarantee the payment agreement with personal credit in case the business
                    defaults."
                  />
                )}
              </Typography>
              {showRemoveGuarantorButton && (
                <div onClick={handleRemoveGuarantor} tabIndex={0} role="button">
                  <SvgIcon
                    name="close"
                    fill="color-gray-100"
                    height="1.5rem"
                    width="1.5rem"
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-4">
            {isWidget ? (
              <AuthUserSearchField
                name={getFieldName('firstName', guarantorIndex)}
                label="First name"
                onSelectOption={setContactValues}
                value={signerFirstName}
                placeHolder={FIRST_NAME_PREFILL_FIELD_PLACEHOLDER.default}
              />
            ) : (
              renderTextInputField('firstName', 'First name')
            )}
            {renderTextInputField('lastName', 'Last name')}
          </div>
          <div
            className={`flex flex-row gap-4 ${
              pg?.phone ? 'filled-auth-signer-fields' : ''
            }`}
          >
            {renderTextInputField('email', 'Email', 'example@email.com')}
            <PatternInput
              id={getFieldName('phone', guarantorIndex)}
              name={getFieldName('phone', guarantorIndex)}
              label="Phone (optional)"
              mask="_"
              disableFullStoryRecording
              format={PHONE_NUMBER_FORMAT}
              placeholder="(123) 456-7890"
              value={values.personalGuarantors[guarantorIndex].phone}
            />
          </div>
          <div className="flex flex-row gap-4 py-4">
            <DateOfBirthComponent
              name={getFieldName('dob', guarantorIndex)}
              label="Date of birth"
              placeholder="MM/DD/YYYY"
              dateFormat={DATE_FORMAT_SHORT_MDY}
            />
            {showSinField ? (
              <PatternInputV2
                id={getFieldName('sin', guarantorIndex)}
                name={getFieldName('sin', guarantorIndex)}
                label="Social insurance number (optional)"
                format={SSN_SIN_FIELD_CONFIG.FORMAT}
                placeholder="123-45-6789"
                value={values.personalGuarantors[guarantorIndex].sin || ''}
                disableFullStoryRecording
                type="password"
              />
            ) : (
              <PatternInputV2
                id={getFieldName('ssn', guarantorIndex)}
                name={getFieldName('ssn', guarantorIndex)}
                label="Social security number"
                format={SSN_SIN_FIELD_CONFIG.FORMAT}
                placeholder="123-45-6789"
                value={values.personalGuarantors[guarantorIndex].ssn || ''}
                disableFullStoryRecording
                type="password"
              />
            )}
          </div>
        </div>
        <div className="test-test">
          <AddressInputField
            id={getFieldName('street', guarantorIndex)}
            name={getFieldName('street', guarantorIndex)}
            label="Primary address"
            afterPlaceSelect={(tempSelectedPlace) => setSelectedPlace(tempSelectedPlace)}
            countries={[selectedCountry]}
            resetAddressSwitch={resetAddressSwitch}
          />
          <div className="flex flex-row gap-4 mt-4 mb-2">
            <div className="w-1/2">{renderTextInputField('city', 'City')}</div>
            <div className="w-1/2 flex flex-row gap-4">
              <CustomDropdown
                id={getFieldName('state', guarantorIndex)}
                name={getFieldName('state', guarantorIndex)}
                label="State"
                options={stateOptions}
                placeholder="Select"
              />
              <MultiCountryZipInput
                id={getFieldName('zip', guarantorIndex)}
                name={getFieldName('zip', guarantorIndex)}
                label="Zip"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-4 my-4">
          <div className="w-1/2">
            <FreeTextDropdownInput
              id={getFieldName('title', guarantorIndex)}
              name={getFieldName('title', guarantorIndex)}
              label="Job title"
              options={jobTitles}
              onSelectOption={(e: { value: unknown }) => {
                setFieldValue('title', e.value);
              }}
            />
          </div>
          <div className="w-1/2">
            <CustomDropdown
              id={getFieldName('percentageOfOwnership', guarantorIndex)}
              name={getFieldName('percentageOfOwnership', guarantorIndex)}
              label="Percentage of ownership"
              options={PERCENTAGE_OF_OWNERSHIP_OPTIONS}
              placeholder="Select"
            />
          </div>
        </div>
        <div className="overflow-hidden">
          {values.personalGuarantors.length > 1 && (
            <FormToggle
              name={`personalGuarantors.${guarantorIndex}.primary`}
              label="Default signer for all orders"
              labelPosition={TOGGLE_LABEL_POSITION.RIGHT}
              onChange={handleToggleChange}
              disabled={isToggleDisabled}
              className={getToggleClassNames(
                values.personalGuarantors[guarantorIndex].primary
              )}
            />
          )}
        </div>
      </fieldset>
    </div>
  );
}
