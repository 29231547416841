import { ReactElement } from 'react';
import './LoaderMd.scss';

const spinnerColor = {
  white: 'white',
};

interface LoaderMdProps {
  color?: 'white' | '';
  containerClass?: string;
}

const LoaderMd = ({ color = '', containerClass = '' }: LoaderMdProps): ReactElement => {
  return (
    <>
      <div
        className={`loader spin ${
          color === spinnerColor.white ? 'white-spinner' : ''
        } ${containerClass}`}
      >
        <div className="spin__blocker"></div>
        <div className="spin__bottom-left"></div>
        <div className="spin__bottom-right"></div>
        <div className="spin__top-right"></div>
        <div className="spin__top-left"></div>
      </div>
    </>
  );
};

export default LoaderMd;
