import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';

import ActionRequired from './Summary/ActionRequired/index';
import usePermissions from '../../utils/hooks/permissions';
import SvgIcon from '../../components/SvgIcon/SvgIcon';

function OrderExpired({ company, variant }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [, hasPermission] = usePermissions();

  const handleCreateOrder = useCallback(() => {
    const link =
      variant === 'vendor'
        ? '/dashboard/orders/new'
        : `/forms/order${location.search}&customerNumber=${company?.number}`;
    navigate(link, {
      state: {
        selectedCompany: company,
      },
    });
  }, [navigate, company, variant, location.search]);

  const showCreateOrderBtn = useMemo(() => {
    return hasPermission('order', 'create', company);
  }, [company, hasPermission]);

  return (
    <ActionRequired
      title="Order expired"
      icon={<SvgIcon name="schedule" fill="gray" className="w-8 h-8 self-center" />}
      message={(
        <p>
          This order is no longer active.
          {hasPermission('order', 'create', company) && (
            <>
              Create a new order <br />
              by clicking the button below.
            </>
          )}
        </p>
      )}
      onPrimaryClick={handleCreateOrder}
      primaryButtonText="Create order"
      showPrimaryButton={showCreateOrderBtn}
    />
  );
}

OrderExpired.propTypes = {
  company: PropTypes.shape({
    number: PropTypes.string,
    buyerRelationToVendor: PropTypes.string,
    seller: PropTypes.shape({
      resellerPermissions: PropTypes.shape({}),
    }),
  }).isRequired,
  variant: PropTypes.oneOf(['vendor', 'widget']),
};

OrderExpired.defaultProps = {
  variant: 'vendor',
};
export default OrderExpired;
