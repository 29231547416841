import { useState } from 'react';
import { Typography } from '@vartanainc/design-system';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import AutoLoad from '../../../../components/AutoLoad';
import { GET_PURCHASE_ORDERS } from './queries';
import ActionRequired from '../ActionRequired';
import { PurchaseOrdersModal } from './PurchaseOrdersModal';

export function POReviewRequired({ orderNumber }) {
  const [reviewModalOpen, setReviewModalOpen] = useState(false);

  const { data: purchaseOrderData, loading: purchaseOrdersLoading } = useQuery(
    GET_PURCHASE_ORDERS,
    {
      skip: !orderNumber,
      variables: { orderNumber },
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-first',
    }
  );
  const purchaseOrders = get(purchaseOrderData, 'order.purchaseOrders', []);

  const purchaseOrderDocs = purchaseOrders.map(
    ({ formattedDocument }) => formattedDocument?.document
  );

  return (
    <AutoLoad loading={purchaseOrdersLoading}>
      <PurchaseOrdersModal
        isOpen={reviewModalOpen}
        purchaseOrderDocs={purchaseOrderDocs}
        onSuccess={() => setReviewModalOpen(true)}
        onClose={() => setReviewModalOpen(false)}
        orderNumber={orderNumber}
      />
      <ActionRequired
        title="Purchase orders need approval"
        message={(
          <div className="flex flex-col gap-4 vp-body text-vartana-black">
            <Typography variant="paragraph14">
              Please review and approve payouts for the following companies:
            </Typography>
            <div className="flex justify-center">
              <p className="text-left">
                {purchaseOrders.map(({ recipient }) => (
                  <Typography key={recipient.name} variant="paragraph14">
                    &bull; {recipient.name}
                    <br />
                  </Typography>
                ))}
              </p>
            </div>
          </div>
        )}
        primaryButtonText="Review"
        onPrimaryClick={() => setReviewModalOpen(true)}
      />
    </AutoLoad>
  );
}

POReviewRequired.propTypes = {
  orderNumber: PropTypes.string.isRequired,
};
