import { Link } from 'react-router-dom';
import { Typography } from '@vartanainc/design-system';
import { useState } from 'react';

interface NavItemProps {
  title: string;
  active: boolean;
  desktop?: boolean;
  icon: string;
  iconComponent: string;
  iconColor: string;
  to: string;
  onClick?: () => void;
}

const NavItem = ({
  title,
  active,
  desktop = false,
  icon,
  iconColor,
  iconComponent,
  to,
  onClick,
}: NavItemProps): JSX.Element => {
  const styles = active ? 'bg-vartana-steel-20' : '';
  const [linkHovered, setLinkHovered] = useState(false);

  // let fillStyle = active || linkHovered ? iconColor : '#A5ACB8';

  return (
    <div>
      <Link
        to={to}
        onClick={onClick}
        className={`rounded vp-body-bold flex items-center px-4 py-2 gap-4 ${styles}
        `}
        onMouseEnter={() => setLinkHovered(true)}
        onMouseLeave={() => setLinkHovered(false)}
      >
        <div
          className="w-6 h-6 bg-contain bg-center bg-no-repeat transition ease-in-out delay-150 duration-300"
          style={{
            maskImage: `url(${iconComponent})`,
            maskSize: '100%',
            maskRepeat: 'no-repeat',
            maskPosition: 'center',
            WebkitMaskImage: `url(${iconComponent})`,
            WebkitMaskSize: '100%',
            WebkitMaskRepeat: 'no-repeat',
            WebkitMaskPosition: 'center',
            backgroundColor: active || linkHovered ? iconColor : '#A5ACB8',
          }}
        />
        <Typography
          variant="paragraph12"
          bold
          color={active || linkHovered ? 'color-blue-180' : 'color-steel-120'}
          className="transition ease-in-out delay-150 duration-300"
        >
          {title}{' '}
        </Typography>
      </Link>
    </div>
  );
};

export default NavItem;
