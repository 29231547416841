import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get, kebabCase, snakeCase } from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_VENDOR_ONBOARDING_STATUS,
  UPDATE_ONBOARDING_STEPS,
} from '../../graphql/queries/vendor';

/**
 * Custom hook for Onboarding workflow. Returns an array with 2 objects.
 *
 * First object contains data:
 *
 * openOnboarding {bool} Is onboarding modal open?
 * vendor {object} Current vendor data from GQL server call
 * vendorLoading {boolean} Is GQL call still fetching vendor data?
 * currentStepIndex {number} Used to show current step in progress bar
 * onboardingStepRoutes {array} List of routes in Onboardings
 *
 * Second object contains methods:
 *
 * toggleOnboarding {func}  Turn onboarding mode on or off
 * goForward {func}
 * goBack {func}
 *
 * @returns {[dataObject: Object, methodObject: Object]}
 */
export function useOnboarding(cookieCompleted = true) {
  const navigate = useNavigate();

  const [openOnboarding, toggleOnboarding] = useState(false);
  const [vendor, setVendor] = useState({});
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [onboardingStepRoutes, setOnboardingStepsRoutes] = useState([]);

  const [previousStep, currentStep, nextStep] = useMemo(
    () => [
      onboardingStepRoutes[currentStepIndex - 1],
      onboardingStepRoutes[currentStepIndex],
      onboardingStepRoutes[currentStepIndex + 1],
    ],
    [currentStepIndex, onboardingStepRoutes]
  );

  const updateCurrentStep = useCallback((steps = []) => {
    if (!steps.length) return;

    let stepIndex = steps.findIndex((step) => step.status === 'pending');
    if (stepIndex === -1) stepIndex = steps.length - 1;

    setCurrentStepIndex(stepIndex);
  }, []);

  const [getVendorOnboardingSteps, { loading: vendorLoading }] = useLazyQuery(
    GET_VENDOR_ONBOARDING_STATUS,
    {
      onCompleted: (responseData) => {
        setVendor(responseData);
        const onboardingCompleted = get(
          responseData,
          'seller.vendorOnboardingStepsCompleted',
          false
        );
        const steps = get(responseData, 'seller.onboardingSteps', []);

        toggleOnboarding(!onboardingCompleted);
        updateCurrentStep(steps);

        // ['/user-details', '/vendor-details', '/finance-user-details']
        const stepRoutes = steps.map((step) => `${kebabCase(step.name)}`);
        stepRoutes.push('calendly');
        setOnboardingStepsRoutes(stepRoutes);
      },
    }
  );

  const [updateOnboardingSteps] = useMutation(UPDATE_ONBOARDING_STEPS);

  useEffect(() => {
    if (cookieCompleted) getVendorOnboardingSteps();
  }, [cookieCompleted, getVendorOnboardingSteps]);

  const goForward = useCallback(async () => {
    await updateOnboardingSteps({
      variables: { name: snakeCase(currentStep), status: 'complete' },
    });
    setCurrentStepIndex((stepIndex) => stepIndex + 1);
    navigate(`/onboarding/${nextStep}`);
  }, [updateOnboardingSteps, currentStep, navigate, nextStep]);

  const goBack = useCallback(async () => {
    await updateOnboardingSteps({
      variables: { name: snakeCase(previousStep), status: 'pending' },
    });
    setCurrentStepIndex((stepIndex) => stepIndex - 1);
    navigate(`/onboarding/${previousStep}`);
  }, [navigate, previousStep, updateOnboardingSteps]);

  return [
    {
      openOnboarding,
      vendorLoading,
      currentStepIndex,
      onboardingStepRoutes,
      vendor,
    },
    {
      toggleOnboarding,
      goForward,
      goBack,
    },
  ];
}
