/* eslint-disable react/prop-types */
import { useQuery, useReactiveVar } from '@apollo/client';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { InputField, Typography } from '@vartanainc/design-system';
import { GET_VENDOR_RESELLERS } from '../../graphql/queries/resellers';
import Table from '../../components/Table';
import { titleize, getUSDateFormat } from '../../utils/helpers';
import { InviteStatusPill } from '../../components/Pill/InviteStatusPill';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import Loader from '../../components/Loader';
import ZeroState from '../../components/ZeroState/ZeroState';
import NoDataState from '../../components/NoDataState/NoDataState';
import '../searchFilter.scss';
import { sessionVar } from '../../graphql/cache';
import { SELLER_TYPE } from '../../constants/common.constants';

const RELOAD_TIME = 500;

export function Resellers() {
  const [companyNameSearch, setCompanyNameSearch] = useState('');
  const [contactSearch, setContactSearch] = useState('');
  const [resellerExists, setResellerExists] = useState(false);
  const debounceRef = useRef(null);

  const sessionData = useReactiveVar(sessionVar);
  const loggedCompany = get(sessionData, 'session.user.company', {});

  // For Distributor, we show both Vendors & Resellers side tabs.
  // Since we can't name both tabs as "Partners", we've decided to retain "Resellers"
  // wording for distributor case only
  const isDistributor =
    loggedCompany.sellerType === SELLER_TYPE.VENDOR_RESELLER &&
    loggedCompany.useResellerWorkflow;

  const PARTNER_LABEL = isDistributor ? 'Reseller' : 'Partner';
  const PAGE_TITLE = `${PARTNER_LABEL}s`;

  const {
    data: resellersData,
    loading: resellersLoading,
    refetch: fetchResellers,
  } = useQuery(GET_VENDOR_RESELLERS);
  const resellers = get(resellersData, 'vendorResellers.nodes', []);

  useEffect(() => {
    async function setResellerExistence() {
      const { data: allResellers } = await fetchResellers();
      if (allResellers?.vendorResellers?.nodes?.length > 0) setResellerExists(true);
      else setResellerExists(false);
    }
    setResellerExistence();
  }, [fetchResellers]);

  // Fetch reseller list whenever filters change
  useEffect(() => {
    clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => {
      fetchResellers({
        resellerName: companyNameSearch,
        fullNameOrEmail: contactSearch,
      });
    }, [RELOAD_TIME]);

    return () => clearTimeout(debounceRef.current);
  }, [companyNameSearch, contactSearch, fetchResellers]);

  const tableColumns = [
    {
      Header: PARTNER_LABEL,
      accessor: 'company.name',
      Cell: ({ row, value: companyName }) => (
        <Link
          to={get(row, 'original.company.number', '')}
          className="vp-text-link-bold text-vartana-blue-60 px-1"
        >
          {titleize(companyName)}
        </Link>
      ),
    },
    {
      Header: 'Primary contact',
      accessor: 'primaryUser',
      Cell: ({ value: user }) => (
        <div className="flex flex-col gap-1">
          <Typography variant="paragraph14">{user.fullName}</Typography>
        </div>
      ),
    },
    {
      Header: 'Account',
      accessor: 'company.inviteStatus',
      Cell: ({ value: inviteStatus }) => <InviteStatusPill status={inviteStatus} />,
    },
    {
      Header: 'Updated on',
      accessor: 'company.updatedOn',
      Cell: ({ value: updatedOn }) => (
        <Typography variant="paragraph14">{updatedOn}</Typography>
      ),
    },
  ];

  const tableData = resellers.map((reseller) => ({
    company: {
      name: get(reseller, 'name', ''),
      number: get(reseller, 'number', ''),
      inviteStatus: get(reseller, 'inviteStatus', ''),
      updatedOn: getUSDateFormat(get(reseller, 'updatedAt', '')),
    },
    primaryUser: {
      fullName: get(reseller, 'primaryUser.fullName', ''),
      email: get(reseller, 'primaryUser.email', ''),
    },
  }));

  return (
    <div className="overflow-y-auto h-full">
      <header className="w-full px-8 flex items-center border-b border-gray-200 bg-white h-[6.375rem]">
        <Typography variant="heading24" color="color-black-100">
          {PAGE_TITLE}
        </Typography>
      </header>

      {/* Filters */}
      {resellerExists && (
        <div className="filter-container-responsive px-8 pt-4 pb-6 bg-white items-center border-b border-vartana-gray-40-v3">
          <div className="filter-responsive">
            <InputField
              label={PARTNER_LABEL}
              suffixIcon={<SearchIcon />}
              fullWidth
              name="companyFilter"
              onChange={(event) => setCompanyNameSearch(event.target.value)}
            />
          </div>
          <div className="filter-responsive">
            <InputField
              label="Primary contact"
              suffixIcon={<SearchIcon />}
              fullWidth
              name="contactFilter"
              onChange={(event) => setContactSearch(event.target.value)}
            />
          </div>
        </div>
      )}

      {/* Listing Table */}
      {resellersLoading && (
        <Loader
          isLoading={resellersLoading}
          className="w-12 h-12 tw-mt-6"
          containerClassName="pt-52"
        />
      )}

      {!resellersLoading &&
        resellers.length === 0 &&
        get(resellersData, 'resellers.totalCount', 0) === 0 && (
        <div className="flex items-center h-[75%]">
          {resellerExists ? (
            <NoDataState></NoDataState>
          ) : (
            <ZeroState page="resellers" cta="invite_reseller"></ZeroState>
          )}
        </div>
      )}

      {!resellersLoading && tableData.length !== 0 && (
        <div className="w-full align-middle inline-block overflow-x-auto overflow-y-hidden border-b border-gray-200">
          <div className="w-full align-middle inline-block overflow-x-auto overflow-y-hidden border-b border-gray-200">
            <Table
              columns={tableColumns}
              data={tableData}
              getHeaderProps={() => ({
                className:
                  'px-4 md:pl-8 md:pr-4 py-4 text-left text-xs font-medium text-vartana-gray-60 tracking-wider whitespace-nowrap uppercase',
              })}
              cellClassName="md:pl-8"
            />
          </div>
        </div>
      )}
    </div>
  );
}
