import React from 'react';

import CustomerForm from '../../page_components/CustomerForm';

function Customer() {
  return (
    <div>
      <CustomerForm />
    </div>
  );
}

export default Customer;
