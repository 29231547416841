import { get } from 'lodash';

import { fetchUrl } from './utils';
import { getAPIURL, showToast } from '../utils/helpers';
import { navigationService } from '../graphql/navigation.service';

export const TRAY_ICON_KEY = 'icon-url';
export const TRAY_INITIAL_WORKFLOW_KEY = 'onboardingWorkflowId';
export const TRAY_EMBEDDED_ID = 'vartana';
export const TRAY_EMBEDDED_BASE_URL = 'https://embedded.tray.io';

/*
  Following are server API endpoints related to fetching or mutating data from tray.io
  Tray Docs: https://tray.io/documentation/embedded/getting-started/overview/
*/

// TODO: Review paths for /vendor or /crm
export const getTrayUserId = async () => {
  let path = '/vendor/integrations/tray/current-user';
  path += `?isTestUser=${process.env.REACT_APP_NODE_ENV !== 'production'}`;
  const user = await fetchUrl(path, { credentials: 'include' });
  return get(user, 'id');
};

export const getAllIntegrations = async () => {
  let path = '/vendor/integrations/tray/solutions';
  path += `?tag=${process.env.REACT_APP_NODE_ENV}`;
  return fetchUrl(path, { credentials: 'include' });
};

export const getPullIntegrations = async () => {
  let path = '/vendor/integrations/tray/pull_solutions';
  path += `?tag=${process.env.REACT_APP_NODE_ENV}`;
  return fetchUrl(path, { credentials: 'include' });
};

export const getActivatedInstances = async (trayUserId) => {
  let path = '/vendor/integrations/tray/solution-instances';
  path += `?trayUserId=${trayUserId}`;
  return fetchUrl(path, { credentials: 'include' });
};

export const getIntegrationInstance = async (trayUserId, solution) => {
  const instanceName = `Instance: ${get(solution, 'title')}`;
  let path = '/vendor/integrations/tray/solution-instance';
  path += `?trayUserId=${trayUserId}&solutionId=${get(
    solution,
    'id'
  )}&instanceName=${instanceName}`;
  return fetchUrl(path, { credentials: 'include' });
};

export const generateConfigAuthCode = async (trayUserId) => {
  const path = `/vendor/integrations/tray/auth-code?trayUserId=${trayUserId}`;
  const result = await fetchUrl(path, { credentials: 'include' });
  return get(result, 'authorizationCode');
};

export const updateTrayInstance = async ({ instance, trayUserId, enable }) => {
  let path = '/vendor/integrations/tray/update-solution-instance';
  path += `?instanceName=${get(instance, 'name')}&instanceId=${get(
    instance,
    'id'
  )}&trayUserId=${trayUserId}&enable=${enable}`;
  return fetchUrl(path, { method: 'PUT', credentials: 'include' });
};

const showMessage = (success) => {
  if (success) {
    showToast(
      'success',
      'Customers are being imported! Once they are approved for payment options, you can start creating orders.'
    );
  } else {
    showToast('error', 'There was an error in importing customers.');
  }
};

export const triggerOnboardingWorkflow = (workflowUrl) => {
  let path = `${getAPIURL()}/integrations/tray/trigger-workflow`;
  path += `?workflowUrl=${workflowUrl}`;
  fetch(path, { credentials: 'include' })
    .then((response) => (response.ok ? showMessage(true) : showMessage(false)))
    .catch((error) => {
      showMessage(false);
      navigationService.rollbar.error(`[triggerOnboardingWorkflow]: ${error}`);
    });
};

/*
  Other Tray-related utility functions
*/

export const initializeTrayData = async () => {
  const userId = await getTrayUserId();
  const solutions = await getAllIntegrations();
  const activeInstances = await getActivatedInstances(userId);

  const newIntegrations = [];
  solutions?.forEach?.((solution) => {
    newIntegrations.push({
      id: get(solution, 'id'),
      title: get(solution, 'title'),
      iconUrl: get(solution, 'customFields', []).find(
        (field) => field.key === TRAY_ICON_KEY
      )?.value,
      initWorkflowId: get(solution, 'customFields', []).find(
        (field) => field.key === TRAY_INITIAL_WORKFLOW_KEY
      )?.value,
      isActive: activeInstances?.some?.(
        (instance) => get(instance, 'solution.id') === get(solution, 'id')
      ),
    });
  });
  return { newIntegrations, userId };
};

export const initializeCustomerPullSolutions = async () => {
  const userId = await getTrayUserId();
  const solutions = await getPullIntegrations();
  const activeInstances = await getActivatedInstances(userId);

  const newIntegrations = [];
  solutions?.forEach?.((solution) => {
    newIntegrations.push({
      id: get(solution, 'id'),
      title: get(solution, 'title'),
      iconUrl: get(solution, 'customFields', []).find(
        (field) => field.key === TRAY_ICON_KEY
      )?.value,
      initWorkflowId: get(solution, 'customFields', []).find(
        (field) => field.key === TRAY_INITIAL_WORKFLOW_KEY
      )?.value,
      isActive: activeInstances?.some?.(
        (instance) => get(instance, 'solution.id') === get(solution, 'id')
      ),
    });
  });
  return { newIntegrations, userId };
};

export const configFinished = async ({
  selectedInstance,
  trayUserId,
  initialWorkflowUrl,
  setIntegrations,
  onSuccess,
  onFailure,
}) => {
  const instance = await updateTrayInstance({
    instance: selectedInstance,
    trayUserId,
    enable: true,
  });
  if (instance?.enabled) {
    const activatedId = instance.solution.id;
    setIntegrations((prevIntegrations) =>
      prevIntegrations.map((integration) =>
        integration.id === activatedId ? { ...integration, isActive: true } : integration
      )
    );
    // adding a timeout here because Tray solution gets enabled
    // but the workflows don't get enabled until a few seconds later.
    setTimeout(() => {
      triggerOnboardingWorkflow(
        initialWorkflowUrl,
        instance.solution.id,
        setIntegrations
      );
    }, 2000); // adding a 2000ms delay
    onSuccess();
  } else {
    const message = `Error: Couldn't activate ${get(
      selectedInstance,
      'solution.title',
      'integration'
    )}`;
    showToast('error', message);
    onFailure();
  }
};

export const validateConfigData = (trayWindow) => {
  trayWindow.postMessage(
    {
      type: 'tray.configPopup.client.validation',
      data: { inProgress: true },
    },
    TRAY_EMBEDDED_BASE_URL
  );

  // TODO: Validate user input

  trayWindow.postMessage(
    {
      type: 'tray.configPopup.client.validation',
      data: { inProgress: false, errors: {} },
    },
    TRAY_EMBEDDED_BASE_URL
  );
};

export const composeWizardUrl = (instanceId, authCode) =>
  `https://embedded.tray.io/external/solutions/${TRAY_EMBEDDED_ID}/configure/${instanceId}?code=${authCode}&customValidation=true`;
