import { ReactElement, useState } from 'react';
import { Typography } from '@vartanainc/design-system';
import CreateCustomerWrapper from './CreateCustomerWrapper';
import './CreateCustomer.scss';
import SubmissionComplete from '../RequestHigherLimitV2/SubmissionComplete';

export default function CreateCustomer(): ReactElement {
  const [customerCreated, setCustomerCreated] = useState(false);
  const handleCustomerCreated = (): void => {
    setCustomerCreated(true);
    window.opener?.onFormSubmit?.();
  };

  return (
    <div className="create-customer-container flex flex-col h-full bg-vartana-steel-20 min-h-[calc(100vh-4rem)]">
      <div className="px-8 border-b border-gray-200 flex items-center min-h-[6.375rem] justify-between">
        <div className="flex flex-col gap-2 pt-6 border-b border-gray-200 min-h-[6.375rem] align-middle">
          <Typography className="capitalize" variant="heading24" color="color-black-100">
            Add customer
          </Typography>
          <Typography variant="paragraph10" bold color="color-gray-160">
            Customer application
          </Typography>
        </div>
      </div>
      <div className="flex flex-row justify-center p-10">
        {customerCreated ? (
          <SubmissionComplete />
        ) : (
          <CreateCustomerWrapper handleCustomerCreated={handleCustomerCreated} />
        )}
      </div>
    </div>
  );
}
