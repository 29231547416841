import React from 'react';
import PropTypes from 'prop-types';

import descending from '../../../assets/sort_descending.svg';
import ascending from '../../../assets/sort_ascending.svg';

function SortableHeader({ props: { column }, title }) {
  const isSorted = column.isSorted && column.isSortedDesc !== undefined;
  const desc = !!column.isSortedDesc;

  return (
    <div className="flex flex-row space-x-2 cursor-pointer">
      <p>{title}</p>
      {isSorted ? <img alt="sort" src={desc ? descending : ascending} /> : null}
    </div>
  );
}

SortableHeader.propTypes = {
  props: PropTypes.shape({
    column: PropTypes.shape({
      isSorted: PropTypes.bool,
      isSortedDesc: Promise.bool,
    }),
  }).isRequired,
  title: PropTypes.string.isRequired,
};

export default SortableHeader;
