import { useContext, useState, useCallback } from 'react';
import { gql, useQuery } from '@apollo/client';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { humanTimeFormat } from '../../../utils/helpers';
import Loader from '../../../components/Loader';
import { HasPermission } from '../../../components/HasPermission/HasPermission';
import { ACTION, RESOURCE } from '../../../constants/permissions';
import FundingInvoice from './FundingInvoice';
import MilestonesFundingInvoice from './MilestonesFundingInvoice';
import { PaymentInfoRequired } from './PaymentInformation';
import { OrderShowContext } from '../../../context/OrderContext';
import { PaymentInfoContext } from './context';
import {
  FUNDING_INVOICE,
  ORDER_FUNDED_STATES,
  ORDER_STATES_TO_SHOW_MILESTONES_PAYOUT,
  textOnInvoiceSubmit,
  textOnInvoiceSubmitWhenFinanced,
} from '../../../constants/order';
import { useDirectUploadFiles } from '../../../utils/hooks';
import CustomNotification from '../../../components/CustomNotification';
import {
  orderStates,
  vendorInvoiceSubmittedText,
  MODAL_CONSTANTS,
} from '../../../constants/common.constants';

const GET_PAYMENT_INFO = gql`
  query getPaymentInfo($orderNumber: String!) {
    seller {
      defaultPaymentMethod {
        id
      }
    }
    order(number: $orderNumber) {
      id
      number
      status
      updatedAt
      canUploadFundingInvoice
      acceptanceMode
      state
      milestones {
        number
        sequenceNumber
        formattedPayoutAmount
        formattedPayoutRate
        vendorMilestoneStatus
        enableInvoiceUpload
        milestoneInvoice
        isSpiff
      }
    }
  }
`;

export function PaymentAndFundingInfoRequired({ orderNumber, stepDetails }) {
  const { refetchOrderData, selectedCustomer } = useContext(OrderShowContext);
  const [showDocumentUploadModal, toggleDocumentUploadModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState('');
  const [selectedMilestone, setSelectedMilestone] = useState(null);
  const [directUploadFiles] = useDirectUploadFiles();
  const { data: paymentData, loading: paymentInfoLoading } = useQuery(GET_PAYMENT_INFO, {
    skip: !orderNumber,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    variables: { orderNumber },
    notifyOnNetworkStatusChange: true,
  });

  const orderData = paymentData?.order;
  const { milestones: milestonesData = [] } = orderData || {};
  const hasMultipleFundingInvoice = milestonesData.length > 1;
  const hasPaymentMethod = !!get(paymentData, 'seller.defaultPaymentMethod.id');

  // this will be used when order has acceptance mode which can have 1 milestone.
  const firstMilestone = milestonesData.find(
    (milestone) => milestone.sequenceNumber === 1
  );

  const allFundingInvoiceUploaded =
    !orderData?.canUploadFundingInvoice &&
    !(
      ORDER_STATES_TO_SHOW_MILESTONES_PAYOUT.includes(orderData?.state) &&
      hasMultipleFundingInvoice
    );

  const missingPaymentMethodWhenSingleUpload =
    !hasPaymentMethod && !hasMultipleFundingInvoice;

  const onInvoiceSubmit = useCallback(
    async (values) => {
      await directUploadFiles(
        [...values.documents],
        {
          documentType: FUNDING_INVOICE,
        },
        {
          id: get(orderData, 'id', ''),
          type: 'Order',
          milestoneNumber: selectedMilestone || firstMilestone?.number,
        }
      );
      await refetchOrderData();
      toggleDocumentUploadModal(false);
      toast.success(({ toastProps }) => (
        <CustomNotification
          type={toastProps.type}
          message={`${vendorInvoiceSubmittedText} ${
            ORDER_FUNDED_STATES.includes(orderData?.state)
              ? textOnInvoiceSubmitWhenFinanced
              : textOnInvoiceSubmit
          }`}
        />
      ));
    },
    [
      toggleDocumentUploadModal,
      refetchOrderData,
      directUploadFiles,
      orderData,
      selectedMilestone,
      firstMilestone,
    ]
  );

  if (paymentInfoLoading) return <Loader />;
  if (allFundingInvoiceUploaded) return null;

  let returnVal = null;
  let updatedAt = get(paymentData, 'order.updatedAt', null);
  if (updatedAt) updatedAt = humanTimeFormat(updatedAt);

  const onInvoiceUploadButton = (milestoneNumber) => {
    setSelectedMilestone(milestoneNumber);
    if (hasPaymentMethod) {
      toggleDocumentUploadModal(true);
    } else {
      setPaymentModal(MODAL_CONSTANTS.paymentInfo);
    }
  };

  if (missingPaymentMethodWhenSingleUpload) {
    returnVal = (
      <HasPermission
        resource={RESOURCE.payment}
        action={ACTION.upload_funding_invoice}
        customer={selectedCustomer}
      >
        <PaymentInfoRequired
          onSuccess={refetchOrderData}
          orderMeta={{
            id: orderData?.id,
            status: orderData?.status || orderStates.pending,
            milestoneNumber: firstMilestone?.number,
            updatedAt,
          }}
        />
      </HasPermission>
    );
  } else {
    returnVal = (
      <HasPermission
        resource={RESOURCE.payment}
        action={ACTION.upload_funding_invoice}
        customer={selectedCustomer}
      >
        {hasMultipleFundingInvoice ? (
          <MilestonesFundingInvoice
            orderMeta={{
              id: orderData?.id,
              number: orderNumber,
              status: orderData?.status || orderStates.pending,
              isLoading: paymentInfoLoading,
              updatedAt,
            }}
            milestonesData={milestonesData}
            showInvoiceModal={showDocumentUploadModal}
            onInvoiceSubmit={onInvoiceSubmit}
            toggleDocumentUploadModal={toggleDocumentUploadModal}
            onInvoiceUploadButton={onInvoiceUploadButton}
            selectedMilestone={selectedMilestone}
            paymentModal={paymentModal}
            setPaymentModal={setPaymentModal}
            onSuccess={refetchOrderData}
          />
        ) : (
          <FundingInvoice
            orderMeta={{
              id: orderData?.id,
              number: orderNumber,
              status: orderData?.status || orderStates.pending,
              milestoneNumber: firstMilestone?.number,
              updatedAt,
            }}
            showInvoiceModal={showDocumentUploadModal}
            onInvoiceSubmit={onInvoiceSubmit}
            toggleDocumentUploadModal={toggleDocumentUploadModal}
          />
        )}
      </HasPermission>
    );
  }

  return (
    <PaymentInfoContext.Provider value={{ stepDetails, needFundingInvoice: true }}>
      {returnVal}
    </PaymentInfoContext.Provider>
  );
}

PaymentAndFundingInfoRequired.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  stepDetails: PropTypes.shape({}),
};

PaymentAndFundingInfoRequired.defaultProps = {
  stepDetails: {},
};
