import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import { get } from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import { ButtonV2, Card, Typography } from '@vartanainc/design-system';

import { ReactComponent as UserHeartIcon } from '../../../assets/person-heart-blue.svg';
import AutoLoad from '../../../components/AutoLoad';
import { GET_USERS } from '../../../graphql/queries/user';
import { REASSIGN_CUSTOMER_ACCOUNT_OWNER } from '../../../graphql/queries/customer';
import CustomNotification from '../../../components/CustomNotification';
import {
  accountExecutiveTypes,
  commonRegex,
  FIELD_INVALID_MESSAGES,
} from '../../../constants/common.constants';
import { REASSIGN_ORDER_ACCOUNT_OWNER } from '../../../graphql/queries/order';
import TextInputField from '../../../designSystem/TextInput/TextInputField';
import PatternInput from '../../../designSystem/PatternInput/PatternInput';
import CustomDropdown from '../../../designSystem/FormikDropdownInput/FormikDropdownInput';

const AccountOwnerSchema = yup.object().shape({
  number: yup.string(),
  fullName: yup.string(),
  firstName: yup
    .string()
    .required('This field is required')
    .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.firstName),
  lastName: yup
    .string()
    .matches(commonRegex.ALPHABETS_AND_SINGLE_SPACE, FIELD_INVALID_MESSAGES.lastName),
  email: yup.string(),
  phone: yup.string().nullable(),
});

function ReassignAccountOwner({ resource, handleClose, type, assignedUser }) {
  const { name: resourceName, number: resourceNumber } = resource;
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [userList, setUserList] = useState([]);

  const [debounceId, setDebounceId] = useState(null);
  const [reassignAccountOwner] = useMutation(
    type === accountExecutiveTypes.customer
      ? REASSIGN_CUSTOMER_ACCOUNT_OWNER
      : REASSIGN_ORDER_ACCOUNT_OWNER
  );
  const { loading: userListLoading, refetch: getUserList } = useQuery(GET_USERS, {
    variables: {
      first: 10,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const userEdges = get(data, 'users.edges', []);
      setUserList(
        userEdges
          .map(({ node }) => ({
            ...node,
            value: node.number,
            label: node.fullName,
          }))
          .filter(
            (user) =>
              user?.email !== assignedUser?.email && user?.firstName && user?.lastName
          )
      );
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const handleInputChange = (value) => {
    if (value) {
      clearTimeout(debounceId);
      setDebounceId(
        setTimeout(() => {
          getUserList({
            fullName: value,
            first: 10,
          });
        }, 1000)
      );
    }
  };

  const handleOnInputSelect = (selectedOption, setValues) => {
    const selectedUser = userList.find((user) => user.value === selectedOption.value);
    setValues({
      ...selectedUser,
      phone: selectedUser.phone || '',
    });
  };

  const handleSubmit = (values) => {
    setIsFormSubmitting(true);
    reassignAccountOwner({
      variables: {
        number: resourceNumber,
        userNumber: get(values, 'number'),
      },
    })
      .then(() => {
        const message = `${resourceName} has been reassigned to ${get(
          values,
          'fullName'
        )} (${get(values, 'email')}).`;
        toast.success(({ toastProps }) => {
          return <CustomNotification type={toastProps.type} message={message} />;
        });
        handleClose();
      })
      .catch((error) => {
        console.error(error);
        toast.warning(({ toastProps }) => {
          return (
            <CustomNotification type={toastProps.type} message="Something went wrong." />
          );
        });
      })
      .finally(() => setIsFormSubmitting(false));
  };
  return (
    <Formik
      initialValues={{
        number: '',
        fullName: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      }}
      validationSchema={AccountOwnerSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid, dirty, setValues, values }) => (
        <div className="gap-x-4 h-full w-full">
          <Form className="w-7/12 mx-auto small-card-width-range">
            <div tabIndex="0">
              <Card
                variant="fullWidth"
                tabindex="0"
                title={(
                  <div>
                    <div className="flex items-center gap-1">
                      <UserHeartIcon />
                      <Typography variant="heading20" color="color-black-100">
                        Reassign account owner
                      </Typography>
                    </div>
                    <Typography variant="paragraph14" color="color-black-100">
                      {`Reassign ${
                        type === accountExecutiveTypes.order ? 'order' : 'customer'
                      } to a different team member.`}
                    </Typography>
                  </div>
                )}
                content={(
                  <>
                    <div>
                      <div className="flex flex-col">
                        <div className="space-y-4">
                          <div className="flex flex-row space-x-4">
                            <CustomDropdown
                              id="firstName"
                              name="firstName"
                              label="First name"
                              placeholder="Search by first name"
                              options={userList}
                              onChange={(selectedInput) => {
                                handleOnInputSelect(selectedInput, setValues);
                              }}
                              onInputChange={handleInputChange}
                              isLoading={userListLoading}
                            />
                            <TextInputField
                              id="lastName"
                              name="lastName"
                              label="Last name"
                              placeholder="Last name"
                              type="text"
                              disabled
                            />
                          </div>
                          <div className="flex flex-row space-x-4">
                            <TextInputField
                              id="email"
                              name="email"
                              label="Email"
                              type="text"
                              disabled
                              placeholder="example@email.com"
                            />
                            <PatternInput
                              id="phone"
                              name="phone"
                              label="Phone"
                              type="text"
                              disableFullStoryRecording
                              disabled
                              mask="_"
                              value={values.phone}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between mt-8">
                      <ButtonV2
                        variant={{ type: 'ghost', typography: 'paragraph14' }}
                        type="button"
                        text="Cancel"
                        iconLeft="chevron_left"
                        onClick={() => handleClose()}
                      />
                      <AutoLoad loading={isFormSubmitting} containerClassName="px-14">
                        <ButtonV2
                          type="submit"
                          disabled={!isValid || !dirty}
                          text="Reassign"
                          variant={{ type: 'primary', typography: 'paragraph14' }}
                        />
                      </AutoLoad>
                    </div>
                  </>
                )}
              >
              </Card>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
}

ReassignAccountOwner.propTypes = {
  resource: PropTypes.shape({
    name: PropTypes.string,
    number: PropTypes.string,
  }).isRequired,
  assignedUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.values(accountExecutiveTypes)),
};

ReassignAccountOwner.defaultProps = {
  type: accountExecutiveTypes.order,
};

export default ReassignAccountOwner;
