import './checkbox.scss';

import { ReactComponent as CheckboxUnchecked } from '../../assets/checkbox_unchecked.svg';
import { ReactComponent as CheckboxChecked } from '../../assets/checkbox_checked.svg';
import { ReactComponent as CheckboxDisabled } from '../../assets/checkbox_disabled.svg';

interface CheckboxProps {
  checked: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCheck: () => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  disabled = false,
  onChange,
  onCheck,
}) => {
  return (
    <div className="checkbox-wrapper">
      <label>
        <input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          onClick={onCheck}
        />
        {/* visibility of svgs are handled in scss */}
        <CheckboxChecked className="checkbox-checked" />
        <CheckboxDisabled className="checkbox-disabled" />
        <CheckboxUnchecked className="checkbox-unchecked" />
      </label>
    </div>
  );
};

export default Checkbox;
