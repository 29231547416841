/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import React, { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { get } from 'lodash';
import { Typography } from '@vartanainc/design-system';
import Table from '../../../../components/Table';
import SortableHeader from '../../../../components/Table/SortableHeader';
import { formatDate } from '../../../../utils/helpers';
import ZeroState from '../../../../components/ZeroState/ZeroState';
import '../CommonTable.scss';

function CustomerAccountStataments() {
  const { customerData, customerLoading: loading } = useOutletContext();
  const accountStatements = get(customerData, 'company.accountStatements', []);

  const columns = useMemo(
    () => [
      {
        Header: (props) => <SortableHeader props={props} title="STATEMENT AS OF" />,
        accessor: 'date.raw',
        Cell: (props) => (
          <Typography variant="paragraph14" className="text-vartana-gray-60">
            {props.row.original.date.formatted}
          </Typography>
        ),
      },
      {
        Header: (props) => <SortableHeader props={props} title="Number" />,
        accessor: 'number',
        Cell: (props) => (
          <Typography variant="paragraph14" className="text-vartana-gray-60">
            {props.value}
          </Typography>
        ),
      },
      {
        Header: (props) => <SortableHeader props={props} title="AMOUNT DUE" />,
        accessor: 'amount.raw',
        Cell: (props) => (
          <Typography variant="paragraph14" className="text-vartana-gray-60">
            {props.row.original.amount.formatted}
          </Typography>
        ),
      },
      {
        Header: '',
        accessor: 'pdf',
        Cell: (props) => (
          <Typography variant="paragraph14" className="text-vartana-gray-60">
            <a
              href={props.value}
              className="break-all vp-text-link-bold  text-vartana-blue-60"
              target="_blank"
              rel="noreferrer"
            >
              View
            </a>
          </Typography>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => {
    let formattedAccountStatements = [];
    if (!loading) {
      formattedAccountStatements = accountStatements.map((as) => {
        return {
          number: as.number,
          date: {
            formatted: formatDate(as.statementDate),
            raw: Date.parse(as.statementDate),
          },
          amount: {
            formatted: as.formattedAmount,
            raw: as.amount,
          },
          pdf: as.url,
        };
      });
    }
    return formattedAccountStatements;
  }, [accountStatements, loading]);

  const sortBy = useMemo(() => [{ id: 'date.raw' }, { id: 'amount.raw' }], []);

  const dataTable = useMemo(() => {
    if (data.length) {
      return (
        <div className='common-table'>
          <Table
            columns={columns}
            data={data}
            sortBy={sortBy}
            getHeaderProps={() => ({
              className:
                'px-4 py-4 text-left text-xs font-medium text-vartana-gray-60 tracking-wider whitespace-nowrap uppercase',
            })}
          />
        </div>
      );
    }
    return (
      <div className="h-full w-full flex items-center">
        <ZeroState page="customer_invoices" cta="none" />
      </div>
    );
  }, [data, columns, sortBy]);

  return dataTable;
}

export default CustomerAccountStataments;
